import {faBold, faFileImport} from '@fortawesome/free-solid-svg-icons'
import React, {useState} from 'react';
import Button from "../../../components/Buttons/Button";
import ContextMenuItem from "../../../components/ContextMenu/ContextMenuItem";
import {addFreeRows, numOfFreeRows} from "../../../components/Grid/freeRows";
import Grid from "../../../components/Grid/Grid";
import onRowChange from "../../../components/Grid/onRowChange";
import RemoveColumn from "../../../components/Grid/RemoveColumn";
import TextGridInput from "../../../components/Grid/TextGridInput";
import Icon from "../../../enums/Icon";
import {deepCopy} from "../../../utils/ObjectUtils";
import TenderhowtotemplateDialog from "./TenderhowtotemplateDialog";

const NUM_OF_FREE_ROWS = 1

const TenderhowtoGrid = props => {
    const [showFetchTemplates, setShowFetchTemplates] = useState(false);

    const disabled = props.disabled;
    const rowList = addFreeRows(props.value, NUM_OF_FREE_ROWS, {
        id: 0, name: ''
    });

    const getSlicedList = list => list.slice(0, list.length - numOfFreeRows(list))

    const fireChange = (list) => {
        props.onChange(getSlicedList(list));
    }

    const columns = [
        {
            field: 'name', headerI18n: 'description',
            editor: disabled ? undefined : e => <TextGridInput editorEvent={e} maxLength={512}/>,
            onCellEditComplete: e => onRowChange(e, e.newValue, fireChange),
        },
        RemoveColumn(row => fireChange(rowList.filter(r => r !== row)))
    ]

    const contextMenu = [
        ContextMenuItem({
            i18n: 'new_row', icon: Icon.ADD, command: (selectedRow) => {
                const _selectedRow = selectedRow
                const _rowList = [...rowList]
                const index = rowList.indexOf(_selectedRow)
                _rowList.splice(index, 0, {
                    id: 0, name: ''
                })
                fireChange(_rowList)
            }
        }),
        ContextMenuItem({
            i18n: 'copy_row', icon: Icon.COPY, command: (selectedRow) => {
                const _selectedRow = selectedRow
                const _rowList = [...rowList]
                const index = rowList.indexOf(_selectedRow)
                const oldRow = deepCopy(rowList[index])
                delete oldRow.id
                _rowList.splice(index + 1, 0, oldRow)
                fireChange(_rowList)
            }
        }),
        ContextMenuItem({
            i18n: 'bold', icon: faBold, command: (_row) => {
                _row.headline = !_row.headline
                fireChange(rowList)
            }
        }),
    ].filter(item => !item.hidden);

    const rowClassName = data => {
        return {
            'bold': data.headline,
        }
    }

    return <div className='TenderhowtoGrid'>
        <Grid
            scrollable={false}
            columns={columns}
            value={rowList}
            footerColumnGroup={<div/>}
            onRowReorder={e => fireChange([...e.value])}
            sortable={false}
            contextMenu={contextMenu}
            rowClassName={rowClassName}
        />

        <div style={styles.fetchButton}>
            <Button faicon={faFileImport} labelI18n='templates' onClick={e => setShowFetchTemplates(true)}/>
        </div>
        {
            showFetchTemplates &&
            <TenderhowtotemplateDialog
                visible={showFetchTemplates}
                onChange={_templateList => {
                    const _list = getSlicedList(rowList).concat(_templateList.map(t => ({
                        id: 0, name: t.name, headline: t.headline
                    })))
                    fireChange(_list)
                    setShowFetchTemplates(false)
                }}
                onHide={() => setShowFetchTemplates(false)}/>
        }
    </div>
}
export default TenderhowtoGrid;

const styles = {
    fetchButton: {
        marginTop: '20px',
    }
}