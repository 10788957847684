import {faUserCheck} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useState} from "react";
import Button from "../Buttons/Button";
import './XlnzSideBar.scss'

export default function XlnzSideBar(props) {
    const {
        faicon = faUserCheck,
        children
    } = props

    const [visible, setVisible] = useState(false)

    useEffect(() => {
        const sideBarContentElem = document.getElementById('XlnzSideBar')
        sideBarContentElem.style.right = 'calc(45px - ' + (sideBarContentElem.offsetWidth + 0) + 'px'
    }, [children]);

    return <div id='XlnzSideBar' className={'XlnzSideBar' + (visible ? ' visible' : '')}>
        <Button faicon={faicon}
                className='show-button'
                onClick={e => {
                    e.preventDefault()
                    setVisible(!visible)
                }}
        />
        <div className='side-bar-content'>
            {children}
        </div>
    </div>
}