import React from "react";
import {FetchButton} from "../../../../components/Buttons/Button";
import {hideLoading, showLoading} from "../../../../components/Overlay/Loading";
import Companyprop from "../../../../enums/Companyprop";
import Unit from "../../../../enums/Unit";
import Vat from "../../../../enums/Vat";
import useCompanyProp from "../../../../hooks/useCompanyProp";
import usePreload from "../../../../hooks/usePreload";
import {i18n} from "../../../../I18n/I18n";
import {findById, pushArray} from "../../../../utils/ArrayUtil";
import {formatCurrencyTwoDecimalsIfNeeded, formatHours, formatTwoDecimalsIfNeeded} from "../../../../utils/NumberUtils";
import TimeregarticleService from "../../../Timereg/TimeregarticleService";
import TimeregService from "../../../Timereg/TimeregService";
import {addTitle} from "../FetchHelper";

export const FetchSummaryButton = props => {
    const {
        rowList,
        onAddRows,
    } = props

    const preload = usePreload()
    const debittypeList = preload.getTimeregdebittypeList()
    const articleList = preload.getArticleList()

    const INVOICE_HIDE_PRICES = useCompanyProp(Companyprop.INVOICE_HIDE_PRICES, false)

    function getRow(name) {
        return {
            headline: false,
            onlyTotal: true,
            housework: false,
            name: name,
            unit: undefined,
            quantity: 0,
            aprice: 0,
            discount: 0,
            price: 0,
            vat: Vat.VAT25.id
        }
    }

    async function getTimeregRows() {
        const result = []
        const irTimeregList = rowList
            .filter(ir => ir.timeregList?.length > 0)
            .flatMap(ir => ir.timeregList)

        const timeregIdList = irTimeregList.map(ir => ir.timereg)
        if (timeregIdList.length === 0) return result

        const timeregList = await TimeregService.findByIds(timeregIdList)

        const debittypeMap = {}
        for (const ir of rowList) {
            if (ir.timeregList?.length > 0) {
                const timeregId = ir.timeregList[0].timereg
                const timereg = timeregList.find(item => item.id === timeregId)
                const debittypeId = timereg.debittype
                const debittype = debittypeList.find(item => item.id === debittypeId)

                if (!debittypeMap[debittypeId]) {
                    debittypeMap[debittypeId] = {
                        debittype: debittype,
                        hours: 0,
                        price: 0
                    }
                }
                debittypeMap[debittypeId].hours += ir.quantity
                if (!INVOICE_HIDE_PRICES) {
                    debittypeMap[debittypeId].price += ir.price
                }
            }
        }

        Object.keys(debittypeMap).forEach(debittypeId => {
            const item = debittypeMap[debittypeId]
            result.push(getRow(
                item.debittype.name + ': ' + formatHours(item.hours) +
                (item.price !== 0 ? ' / ' + formatCurrencyTwoDecimalsIfNeeded(item.price) : '')
            ))
        })

        return result
    }

    async function getTimeregarticleRows() {
        const result = []
        const irTimeregarticleList = rowList
            .filter(ir => ir.timeregarticleList?.length > 0)
            .flatMap(ir => ir.timeregarticleList)

        const timeregarticleIdList = irTimeregarticleList.map(ir => ir.timeregarticle)
        if (timeregarticleIdList.length === 0) return result

        const timeregarticleList = await TimeregarticleService.findByIds(timeregarticleIdList)

        const articleMap = {}
        for (const ir of rowList) {
            if (!ir.article) continue

            if (ir.timeregarticleList?.length > 0) {
                const timeregarticleId = ir.timeregarticleList[0].timeregarticle
                const timeregarticle = timeregarticleList.find(item => item.id === timeregarticleId)

                const articleId = timeregarticle.article
                const article = findById(articleList, articleId)

                if (!articleMap[articleId]) {
                    articleMap[articleId] = {
                        article: article,
                        quantity: 0,
                        price: 0
                    }
                }
                articleMap[articleId].quantity += ir.quantity
                if (!INVOICE_HIDE_PRICES) {
                    articleMap[articleId].price += ir.price
                }
            }
        }

        Object.keys(articleMap).forEach(articleId => {
            const item = articleMap[articleId]

            const unitSymbol = getUnitSymbol(item.article, item.quantity)

            result.push(getRow(
                item.article.name + ': ' +
                formatTwoDecimalsIfNeeded(item.quantity, 2) + (unitSymbol.length > 0 ? ' ' + unitSymbol : '') +
                (item.price !== 0 ? ' / ' + formatCurrencyTwoDecimalsIfNeeded(item.price) : '')
            ))
        })

        return result
    }

    function getUnitSymbol(article, quantity) {
        const unit = Unit.findById(article?.unit)
        if (unit) {
            if (Math.abs(quantity) > 1 && unit.symbolPlural) return unit.symbolPlural
            else return unit.symbol
        } else {
            return ''
        }
    }

    const onSave = async () => {
        showLoading()
        const invoiceRows = []
        addTitle(rowList, {showTitle: true}, invoiceRows, i18n('summary'))

        let tenderPrice = 0
        let tenderoptionPrice = 0
        let projectrentPrice = 0
        let supplierinvoicePrice = 0

        for (const ir of rowList) {
            if (ir.tenderrowList?.length > 0) tenderPrice += ir.price
            if (ir.tenderoptionList?.length > 0) tenderoptionPrice += ir.price
            if (ir.projectrentList?.length > 0) projectrentPrice += ir.price
            if (ir.supplierinvoicerowList?.length > 0) supplierinvoicePrice += ir.price
        }

        if (!INVOICE_HIDE_PRICES) {
            if (tenderPrice !== 0) invoiceRows.push(getRow(i18n('tender') + ': ' + formatCurrencyTwoDecimalsIfNeeded(tenderPrice)))
            if (tenderoptionPrice !== 0) invoiceRows.push(getRow(i18n('tender') + ' - ' + i18n('options') + ': ' + formatCurrencyTwoDecimalsIfNeeded(tenderoptionPrice)))
            if (projectrentPrice !== 0) invoiceRows.push(getRow(i18n('rent') + ': ' + formatCurrencyTwoDecimalsIfNeeded(projectrentPrice)))
        }
        pushArray(invoiceRows, await getTimeregRows())
        pushArray(invoiceRows, await getTimeregarticleRows())

        if (!INVOICE_HIDE_PRICES) {
            if (supplierinvoicePrice !== 0) invoiceRows.push(getRow(i18n('supplier_invoices') + ': ' + formatCurrencyTwoDecimalsIfNeeded(supplierinvoicePrice)))
        }

        onAddRows(invoiceRows)
        hideLoading()
    }

    return <FetchButton labelI18n='summary' onClick={onSave}/>
}
