import NetUtils from "../../utils/NetUtils";

const TimeregarticleService = {
    findByProject: projectId => NetUtils.doGet('/api/timeregarticles', {project: projectId}),

    findById: id => NetUtils.doGet('/api/timeregarticles/' + id),
    findByIds: idList => NetUtils.doGet('/api/timeregarticles/id-list/' + idList),
    update: model => NetUtils.doPost('/api/timeregarticles', model),
    delete: model => NetUtils.doDelete('/api/timeregarticles', model),
};
export default TimeregarticleService;