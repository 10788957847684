import React, {useContext, useState} from 'react';
import AppContext from "../../AppContext";
import {AddButton} from "../../components/Buttons/Button";
import Label from "../../components/EditForm/Label";
import ActiveFilter from "../../components/Grid/ActiveFilter";
import {useFilter} from "../../components/Grid/filter";
import Grid from "../../components/Grid/Grid";
import {infoAlert} from "../../components/Overlay/Alert";
import {hideLoading, showLoading} from "../../components/Overlay/Loading";
import XlnzDropdown from "../../components/XlnzDropdown/XlnzDropdown";
import Companyprop from "../../enums/Companyprop";
import CustomerType from "../../enums/CustomerType";
import Icon from "../../enums/Icon";
import Role from "../../enums/Role";
import {match, matchActive, matchSearch} from "../../functions/match";
import {GridTemplates} from "../../GridTemplates";
import useCompanyProp from "../../hooks/useCompanyProp";
import useMountEffect from "../../hooks/useMountEffect";
import usePreload, {PreloadType} from "../../hooks/usePreload";
import useRoles from "../../hooks/useRoles";
import {i18n} from "../../I18n/I18n";
import {findByField, findById} from "../../utils/ArrayUtil";
import NetUtils from "../../utils/NetUtils";
import {deepCopy} from "../../utils/ObjectUtils";
import StringUtils from "../../utils/StringUtil";
import CustomerService from "./CustomerService";

const CustomerGrid = () => {
    const context = useContext(AppContext)
    const roles = useRoles()
    const preload = usePreload()
    const pricelistList = preload.getPricelistList()
    const pyramid = useCompanyProp(Companyprop.PYRAMID)

    const filter = useFilter('CustomerGrid', [
        {name: 'active', defaultValue: true},
        {name: 'type', defaultValue: undefined},
        {name: 'search', defaultValue: ''},
    ])

    const [list, setList] = useState([])
    const filteredList = list.filter(c => matchActive(filter, c) && match(filter, c, 'type') && matchSearch(filter, c))

    async function load() {
        showLoading()
        const _list = deepCopy(await preload.load(PreloadType.CUSTOMER_LIST))
        _list.forEach(item => {
            item.addressStr = StringUtils.formatAddress(item.address)
            item.visitingAddressStr = StringUtils.formatAddress(item.visitingAddress)

            const companypricelist = findByField(item.companypricelistList, 'company', context.currentCompany.id)
            if (companypricelist) item.pricelistName = findById(pricelistList, companypricelist.pricelist)?.name || ''

            // if (item.pricelist) item.pricelistName = findById(pricelistList, item.pricelist)?.name || ''
        })
        setList(_list)
        hideLoading()
    }

    useMountEffect(() => {
        load()
    })

    const leftFilters = [
        roles.hasRole(Role.CUSTOMER_WRITE) ? <div>
            <label className="label">&nbsp;</label>
            <AddButton/>
        </div> : undefined,
        <div>
            <Label valueI18n='status'/>
            <ActiveFilter value={filter.active} onChange={e => filter.update('active', e.value)}/>
        </div>,
        <div>
            <label className="label">{i18n('type')}</label>
            <XlnzDropdown
                value={filter.type}
                options={CustomerType.values()}
                optionLabel='name'
                optionValue='id'
                showClear={true}
                placeholder={i18n('select') + '...'}
                onChange={e => filter.update('type', e.value)}
                style={{width: '160px'}}/>
        </div>,
    ]

    const menuItems = [{
        label: i18n('export_email_addresses_to_excel'),
        faicon: Icon.DOWNLOAD,
        command: async () => {
            showLoading()
            await CustomerService.downloadEmailsExcel(filteredList.map(item => item.id))
            hideLoading()
        }
    }]

    if (pyramid) {
        menuItems.push({
            label: i18n('fetch_from_pyramid'),
            faicon: Icon.PLUGIN,
            command: async item => {
                showLoading()
                const _resp = await NetUtils.doGet('/api/pyramid/sync-customers')
                await preload.load(PreloadType.CUSTOMER_LIST)
                await load()
                infoAlert(_resp.value)
                hideLoading()
            }
        })
    }

    const columns = [
        {field: 'number', headerI18n: 'number', width: 100},
        {field: 'name', headerI18n: 'name'},
        {field: 'regNo', headerI18n: 'reg_no', width: 130, mobile: true},
        {field: 'addressStr', headerI18n: 'address'},
        {field: 'visitingAddressStr', headerI18n: 'visiting_address'},
        {field: 'pricelistName', headerI18n: 'pricelist', hidden: pricelistList.length === 0},
        {field: 'note', headerI18n: 'note', body: GridTemplates.stringLimit100, mobile: true},
        {field: 'active', headerI18n: 'active', width: 60, body: GridTemplates.boolean},
    ].filter(item => !item.hidden)

    return <Grid
        id='CustomerGrid'
        labelI18n='customers'
        icon={Icon.CUSTOMER}
        leftFilters={leftFilters}
        filter={filter}
        updateFilter={filter.update}
        columns={columns}
        value={filteredList}
        paginator={true}
        menuItems={menuItems}
    />
}

export default CustomerGrid;
