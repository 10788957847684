import {groupBy} from "lodash";
import Companyprop from "../../../../enums/Companyprop";
import Unit from "../../../../enums/Unit";
import Vat from "../../../../enums/Vat";
import VatType from "../../../../enums/VatType";
import calculatePrice from "../../../../functions/calculatePrice";
import useCompanyProp from "../../../../hooks/useCompanyProp";
import usePreload from "../../../../hooks/usePreload";
import {findByField} from "../../../../utils/ArrayUtil";


export default function useTimeregInvoicerowsGenerator(props) {
    const {
        vatType
    } = props
    const preload = usePreload()
    const accountList = preload.getAccountList()
    const timeregdebittypeList = preload.getTimeregdebittypeList()

    const ACCOUNT_NUMBER_DEBIT_TIME = useCompanyProp(Companyprop.ACCOUNT_NUMBER_DEBIT_TIME, undefined)
    const ACCOUNT_NUMBER_DEBIT_TIME_REVERSED_VAT = useCompanyProp(Companyprop.ACCOUNT_NUMBER_DEBIT_TIME_REVERSED_VAT, undefined)

    function getAccount() {
        switch (vatType) {
            case VatType.SEREVERSEDVAT.id:
                if (ACCOUNT_NUMBER_DEBIT_TIME_REVERSED_VAT) return accountList.find(a => a.number === Number(ACCOUNT_NUMBER_DEBIT_TIME_REVERSED_VAT))
                break;
            default:
                if (ACCOUNT_NUMBER_DEBIT_TIME) return accountList.find(a => a.number === Number(ACCOUNT_NUMBER_DEBIT_TIME))
                break
        }
    }

    function getWorkDoneFromEmployeeComments(rowList) {
        let result = ''
        rowList.filter(item => item.employeeComment?.length > 0)
            .forEach((item, i) => {
                if (i > 0) {
                    if (!result.endsWith('.')) result += '.'
                    result += ' '
                }
                result += item.employeeComment
            })
        return result
    }

    function getName(keyObj, workDone, employeeComments) {
        let result = ''

        if (keyObj.date) {
            result += keyObj.date + ', '
        }
        result += keyObj.debittypeName

        if (keyObj.employeeName) {
            result += ', ' + keyObj.employeeName
        }


        if (workDone?.length > 0) {
            result += result.endsWith('.') ? '' : '. '
            result += workDone
        }
        if (employeeComments?.length > 0) {
            result += result.endsWith('.') ? '' : '. '
            result += employeeComments
        }
        return result
    }

    return {
        getSeparatedOnDate: (project, joinedList, config) => {
            const invoiceRows = []
            const timeregList = joinedList.filter(item => item.type === 'timereg')

            const groupedTimeregList = groupBy(timeregList, item => {
                // let result = ''
                let result2 = {}
                if (config.separateDates) {
                    // result += item.date + ', '
                    result2.date = item.date
                }
                // result += item.debittypeName
                result2.debittype = item.debittype
                result2.debittypeName = item.debittypeName

                if (config.separateEmployees) {
                    // result += ', ' + item.employeeName
                    result2.employeeName = item.employeeName
                }
                // return result
                return JSON.stringify(result2)
            })

            for (const key in groupedTimeregList) {
                const keyObj = JSON.parse(key)
                let date = ''
                let debittypeListOrder
                let quantity = 0
                let aprice = 0
                let workDone = ''
                let employeeComments = config.includeEmployeeComments
                    ? getWorkDoneFromEmployeeComments(groupedTimeregList[key])
                    : undefined

                const invoicerowtimeregList = []
                groupedTimeregList[key].forEach(tr => {
                    date = tr.date
                    debittypeListOrder = findByField(timeregdebittypeList, 'name', tr.debittypeName)?.listOrder
                    quantity += tr.quantity
                    workDone = tr.workDone
                    aprice = tr.aprice

                    invoicerowtimeregList.push({
                        invoicerow: 0,
                        timeregdebittype: tr.debittype,
                        timereg: tr.id,
                        hours: tr.debitHours
                    })
                })

                invoiceRows.push({
                    priority: 1,
                    date: date,
                    debittypeListOrder: debittypeListOrder,
                    name: getName(keyObj, workDone, employeeComments),
                    unit: Unit.HOUR_WORK.id,
                    quantity: quantity,
                    aprice: aprice,
                    discount: 0,
                    price: calculatePrice(quantity, aprice, 0),
                    vat: Vat.VAT25.id,
                    account: getAccount()?.id,
                    costcenter: project.costcenter,
                    timeregList: invoicerowtimeregList
                })
            }
            return invoiceRows
        },
        // getGrouped: (joinedList) => {
        //     const invoiceRows = []
        //     const timeregList = joinedList.filter(item => item.type === 'timereg')
        //     const groupedTimeregList = groupBy(timeregList, item => item.debittypeName)
        //     for (const key in groupedTimeregList) {
        //         let quantity = 0
        //         let aprice = 0
        //
        //         const invoicerowtimeregList = []
        //         groupedTimeregList[key].forEach(tr => {
        //             quantity += tr.debitHours
        //             aprice = tr.timeregdebittypeprice
        //
        //             invoicerowtimeregList.push({
        //                 invoicerow: 0,
        //                 timereg: tr.id,
        //                 hours: tr.debitHours
        //             })
        //         })
        //
        //         invoiceRows.push({
        //             name: key,
        //             unit: Unit.HOUR_WORK.id,
        //             quantity: quantity,
        //             aprice: aprice,
        //             discount: 0,
        //             price: calculatePrice(quantity, aprice, 0),
        //             vat: Vat.VAT25.id,
        //             account: getAccount()?.id,
        //             costcenter: project.costcenter,
        //             timeregList: invoicerowtimeregList
        //         })
        //     }
        //     return invoiceRows
        // }
    }
}