import React, {useState} from 'react';
import {CancelButton} from "../../../components/Buttons/Button";
import Grid from "../../../components/Grid/Grid";
import {hideLoading, showLoading} from "../../../components/Overlay/Loading";
import XlnzDialog from "../../../components/XlnzDialog/XlnzDialog";
import {GridTemplates} from "../../../GridTemplates";
import useMountEffect from "../../../hooks/useMountEffect";
import HyrletOrderStatuses from "../Orders/HyrletOrderStatuses";
import PortalRentService from "./PortalRentService";

const PortalRentHistoryDialog = props => {
    const {
        model,
        onHide,
        customer
    } = props
    const [list, setList] = useState([]);


    useMountEffect(async () => {
        showLoading()
        const _list = await PortalRentService.history({
            customer: customer,
            article: model.id
        })

        _list.forEach(item => {
            item.statusName = HyrletOrderStatuses.getName(item.status)
        })

        setList(_list.reverse())
        hideLoading()
    }, [model.id, customer])

    const columns = [
        {
            field: 'number',
            header: 'Ordernr',
        },
        {
            field: 'deliveryDate',
            header: "Leveransdatum",
            width: 120,
        },
        {
            field: 'statusName',
            header: "Status",
            width: 120,
        },
        {
            field: 'quantity',
            header: "Antal",
            body: GridTemplates.integer,
            width: 120,
            align: 'right'
        },
        {
            field: 'stockQuantity',
            header: 'Lagersaldo',
            body: GridTemplates.integer,
            width: 120,
            align: 'right'
        }
    ]

    return <XlnzDialog header={model.number + " - " + model.name}
                       onHide={onHide}
                       buttons={() => [
                           <CancelButton key='cancel' onClick={e => onHide()}/>
                       ]}
    >
        <div style={{width: '700px'}}>
            <Grid
                forceDesktop={true}
                sortable={false}
                columns={columns}
                value={list}
                scrollable={true}
                heightOffset={400}
                onRowSelect={() => {
                }}
            />
        </div>
    </XlnzDialog>
}

export default PortalRentHistoryDialog;
