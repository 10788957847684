import React from 'react';
import {AddButton} from "../../components/Buttons/Button";
import Checkbox from "../../components/EditForm/Checkbox";
import Label from "../../components/EditForm/Label";
import ActiveFilter from "../../components/Grid/ActiveFilter";
import {useFilter} from "../../components/Grid/filter";
import Grid from "../../components/Grid/Grid";
import {infoAlert} from "../../components/Overlay/Alert";
import {hideLoading, showLoading} from "../../components/Overlay/Loading";
import XlnzDropdown from "../../components/XlnzDropdown/XlnzDropdown";
import Companyprop from "../../enums/Companyprop";
import Icon from "../../enums/Icon";
import Role from "../../enums/Role";
import {match, matchActive, matchOnlyEmployees, matchSearch} from "../../functions/match";
import {GridTemplates} from "../../GridTemplates";
import useCompanyProp from "../../hooks/useCompanyProp";
import useDialog from "../../hooks/useDialog";
import useEmployees from "../../hooks/useEmployees";
import usePreload, {PreloadType} from "../../hooks/usePreload";
import useUserPersmissions from "../../hooks/useUserPersmissions";
import {i18n} from "../../I18n/I18n";
import NetUtils from "../../utils/NetUtils";
import {deepCopy} from "../../utils/ObjectUtils";
import ImportEmployeesFromExcelDialog from "./ImportEmployeesFromExcelDialog";

const EmployeeGrid = () => {
    const userPersmissions = useUserPersmissions()
    const preload = usePreload()
    const pyramid = useCompanyProp(Companyprop.PYRAMID)
    const dialog = useDialog()

    const subcontractorList = preload.getSupplierList().filter(item => item.subcontractor)


    const filter = useFilter('EmployeeGrid', [
        {name: 'active', defaultValue: true},
        {name: 'department', defaultValue: undefined},
        {name: 'onlyEmployees', defaultValue: false},
        {name: 'subcontractor', defaultValue: undefined},
        {name: 'search', defaultValue: ''},
    ])

    const employees = useEmployees()
    const filteredList = deepCopy(employees.usable.filter(e =>
        matchActive(filter, e) &&
        match(filter, e, 'department') &&
        matchOnlyEmployees(e, filter.onlyEmployees) &&
        (filter.onlyEmployees || match(filter, e, 'subcontractor')) &&
        matchSearch(filter, e)
    ))


    const leftFilters = [
        userPersmissions.hasRole(Role.EMPLOYEE_WRITE) ? <div>
            <Label/>
            <AddButton/>
        </div> : undefined,
        <div>
            <Label valueI18n='status'/>
            <ActiveFilter value={filter.active} onChange={e => filter.update('active', e.value)}/>
        </div>,
        <div>
            <Label valueI18n='only_employees'/>
            <Checkbox checked={filter.onlyEmployees} onChange={e => filter.update('onlyEmployees', e.target.checked)}
                      style={{marginTop: '5px'}}/>
        </div>,
        <div>
            {
                !filter.onlyEmployees &&
                <>
                    <Label valueI18n='subcontractor'/>
                    <XlnzDropdown
                        value={filter.subcontractor}
                        options={subcontractorList}
                        showClear={true}
                        placeholder={i18n('select') + '...'}
                        optionValue='id'
                        optionLabel='name'
                        onChange={e => filter.update('subcontractor', e.value)}
                        style={{width: '250px'}}/>
                </>
            }
        </div>
    ]

    const menuItems = []

    if (userPersmissions.hasRole(Role.EMPLOYEE_WRITE)) {
        menuItems.push({
            label: i18n('import_from_excel'),
            faicon: Icon.EXCEL,
            command: () => dialog.show(<ImportEmployeesFromExcelDialog onDone={async () => {
                await preload.load(PreloadType.EMPLOYEE_LIST)
                employees.forceUpdate()
            }}/>)
        })
    }

    if (pyramid) {
        menuItems.push({
            label: i18n('fetch_from_pyramid'),
            faicon: Icon.PLUGIN,
            command: async item => {
                showLoading()
                const _resp = await NetUtils.doGet('/api/pyramid/sync-employees')
                await preload.load(PreloadType.EMPLOYEE_LIST)
                infoAlert(_resp.value)
                employees.forceUpdate()
                hideLoading()
            }
        })
    }

    const columns = [
        {field: 'name', headerI18n: 'name'},
        {field: 'alias', headerI18n: 'alias'},
        {field: 'number', headerI18n: 'number', width: 80, mobileWithHeader: true},
        {
            field: 'departmentsStr',
            headerI18n: 'departments',
            mobileWithHeader: true,
            hidden: employees.departmentList.length === 0
        },
        {field: 'ssn', headerI18n: 'ssn', width: 100, mobileWithHeader: true},
        // {field: 'address.address', headerI18n: 'address'},
        // {field: 'address.zip', headerI18n: 'zip', width: 60, body: GridTemplates.zip},
        // {field: 'address.city', headerI18n: 'city', width: 120},
        {field: 'phone0', headerI18n: 'mobile', width: 110},
        {field: 'email', headerI18n: 'email'},
        {field: 'subcontractorName', headerI18n: 'subcontractor'},
        {
            field: 'salary',
            header: i18n('salary') + '/' + i18n('cost').toLowerCase(),
            body: GridTemplates.currencyPerHoursTwoDecimals,
            width: 100,
            align: 'right'
        },
        {field: 'note', headerI18n: 'note', body: GridTemplates.stringLimit100},
        {field: 'active', headerI18n: 'active', width: 50, body: GridTemplates.boolean},
    ].filter(item => !item.hidden)


    return <>
        <Grid
            id='EmployeeGrid'
            labelI18n='employees'
            leftFilters={leftFilters}
            filter={filter}
            updateFilter={filter.update}
            menuItems={menuItems}
            columns={columns}
            value={filteredList}
        />
        {dialog.render()}
    </>
}

export default EmployeeGrid;
