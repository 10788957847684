import React from 'react';
import {FooterAggregate} from "../../components/Grid/getFooterGroup";
import Grid from "../../components/Grid/Grid";
import {GridTemplates} from "../../GridTemplates";
import StringUtils from "../../utils/StringUtil";

const UserActivityGrid = ({value}) => {

    const columns = [
        {field: 'user.id', header: 'ID', width: 80},
        {field: 'user.name', header: 'User', width: 150},
        {field: 'user.email', header: 'E-mail', width: 250},
        {
            field: 'activeDuration', header: 'ActiveDuration',
            body: row => {
                return StringUtils.formatDuration(row.activeDuration)
            },
            footer: FooterAggregate.SUM_DURATION,
            width: 100,
            align: 'right'
        },
        {
            field: 'numOfTenders',
            header: 'Tenders',
            body: GridTemplates.integer,
            footer: FooterAggregate.SUM_INTEGER,
            width: 60,
            align: 'right'
        },
        {
            field: 'numOfProjects',
            header: 'Projects',
            body: GridTemplates.integer,
            footer: FooterAggregate.SUM_INTEGER,
            width: 60,
            align: 'right'
        },
        {
            field: 'numOfFormanswers',
            header: 'Forms',
            body: GridTemplates.integer,
            footer: FooterAggregate.SUM_INTEGER,
            width: 60,
            align: 'right'
        },
        {
            field: 'numOfInvoices',
            header: 'Invoices',
            body: GridTemplates.integer,
            footer: FooterAggregate.SUM_INTEGER,
            width: 60,
            align: 'right'
        },
        {
            field: 'numOfSupplierinvoices',
            header: 'Supplierinvoices',
            body: GridTemplates.integer,
            footer: FooterAggregate.SUM_INTEGER,
            width: 110,
            align: 'right'
        },
    ]

    return <div style={{width: '950px'}}>
        <Grid
            scrollable={false}
            columns={columns}
            value={value}
            onRowSelect={() => {
            }}
            forceDesktop={true}
        />
    </div>
}
export default UserActivityGrid;