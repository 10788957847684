import React from 'react';
import Grid from "../../components/Grid/Grid";
import {infoAlert} from "../../components/Overlay/Alert";
import XlnzCard from "../../components/XlnzCard/XlnzCard";
import {GridTemplates} from "../../GridTemplates";

const ApiCalls = props => {
    const {
        value
    } = props

    const columns = [
        {field: 'id', header: 'ID', width: 80},
        {field: 'time', headerI18n: 'time', body: GridTemplates.time, width: 140},
        {field: 'companyName', headerI18n: 'company', width: 200},
        {field: 'requestUrl', header: 'Request URL'},
    ]


    return <XlnzCard title='API Calls'>
        <Grid
            scrollable={false}
            columns={columns}
            value={value}
            onRowSelect={row => {
                navigator.clipboard.writeText(row.data.requestUrl);
                infoAlert('Copied to clipboard');
            }}
            forceDesktop={true}
        />
    </XlnzCard>

}
export default ApiCalls;