import React, {useCallback, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {MainButton} from "../../../components/Buttons/Button";
import ContextMenuItem from "../../../components/ContextMenu/ContextMenuItem";
import DatePicker from "../../../components/Date/DatePicker";
import XlnzDropdown from "../../../components/XlnzDropdown/XlnzDropdown";
import {useFilter} from "../../../components/Grid/filter";
import {FooterAggregate} from "../../../components/Grid/getFooterGroup";
import Grid from "../../../components/Grid/Grid";
import {hideLoading, showLoading} from "../../../components/Overlay/Loading";
import Icon from "../../../enums/Icon";
import Role from "../../../enums/Role";
import {match, matchSearch} from "../../../functions/match";
import {GridTemplates} from "../../../GridTemplates";
import useDialog from "../../../hooks/useDialog";
import usePreload, {PreloadType} from "../../../hooks/usePreload";
import useRoles from "../../../hooks/useRoles";
import {i18n} from "../../../I18n/I18n";
import {distinct, findById, sortByField} from "../../../utils/ArrayUtil";
import {getFirstDateOfMonth, todayDate} from "../../../utils/DateUtils";
import NetUtils from "../../../utils/NetUtils";
import ProjectSummaryDialog from "../../Projects/ProjectSummary/ProjectSummaryDialog";

const ProjectHoursAndInvoiced = () => {
    const preload = usePreload()
    const dialog = useDialog()

    const employeeList = preload.get(PreloadType.EMPLOYEE_LIST)

    const filter = useFilter('ProjectHoursAndInvoiced', [
        {name: 'startDate', defaultValue: getFirstDateOfMonth()},
        {name: 'endDate', defaultValue: todayDate()},
        {name: 'projectLeader', defaultValue: undefined},
        {name: 'search', defaultValue: ''},
    ])
    const [list, setList] = useState([])
    const projectLeaderList = sortByField(distinct(list.map(item => item.projectLeader)).map(id => findById(employeeList, id)), 'name')

    const filteredList = list.filter(item => matchSearch(filter, item) && match(filter, item, 'projectLeader'))

    const roles = useRoles()
    const navigate = useNavigate()

    const loadData = useCallback(() => {
        showLoading()
        NetUtils.doGet('/api/reports/project-hours-and-invoiced', {
            startDate: filter.startDate,
            endDate: filter.endDate,
        }).then(res => {
            setList(res)
            hideLoading()
        });
    }, [filter.startDate, filter.endDate]);

    const colWidth = 120

    const columns = [
        {field: 'projectName', headerI18n: 'project', minWidth: 150},
        {field: 'customerName', headerI18n: 'customer', minWidth: 100},
        {
            field: 'fixedHours',
            headerI18n: 'fixed_time',
            body: GridTemplates.hours,
            excelBody: (row, column) => row[column.field],
            footer: FooterAggregate.SUM_HOURS,
            width: colWidth, align: 'right'
        },
        {
            field: 'costplusHours',
            headerI18n: 'costplus_time',
            body: GridTemplates.hours,
            excelBody: (row, column) => row[column.field],
            footer: FooterAggregate.SUM_HOURS,
            width: colWidth, align: 'right'
        },
        {
            field: 'debitHours',
            headerI18n: 'debit_time',
            body: GridTemplates.hours,
            excelBody: (row, column) => row[column.field],
            footer: FooterAggregate.SUM_HOURS,
            width: colWidth, align: 'right'
        },
        {
            field: 'hours',
            headerI18n: 'total_time',
            body: GridTemplates.hours,
            footer: FooterAggregate.SUM_HOURS,
            width: colWidth, align: 'right',
            formula: 'C#ROW+D#ROW'
        },
        {
            field: 'invoiced',
            headerI18n: 'invoiced',
            body: GridTemplates.currency,
            excelBody: (row, column) => row[column.field],
            footer: FooterAggregate.SUM_CURRENCY,
            width: colWidth, align: 'right'
        },
        {
            field: 'invoicedRent',
            headerI18n: 'invoiced_rent',
            body: GridTemplates.currency,
            excelBody: (row, column) => row[column.field],
            footer: FooterAggregate.SUM_CURRENCY,
            width: colWidth, align: 'right'
        },
        {
            field: 'invoicedPerHour',
            headerI18n: 'inv_per_hour',
            body: GridTemplates.currencyPerHours,
            width: colWidth, align: 'right',
            formula: 'IF(F#ROW=0,0, ROUND(G#ROW/F#ROW, 0) )'
        },
        {
            field: 'invoicedPerHourExclRent',
            headerI18n: 'inv_per_hour_excl_rent',
            body: GridTemplates.currencyPerHours,
            width: colWidth, align: 'right',
            formula: 'IF(F#ROW=0,0, ROUND((G#ROW-H#ROW)/F#ROW, 0))'
        },

    ];

    const leftFilters = [
        <div>
            <label className="label">{i18n('start_date')}</label>
            <DatePicker value={filter.startDate} onChange={date => filter.update('startDate', date)}/>
        </div>,

        <div>
            <label className="label">{i18n('end_date')}</label>
            <DatePicker value={filter.endDate} onChange={date => filter.update('endDate', date)}/>
        </div>
    ]

    leftFilters.push(<div>
        <label className="label">{i18n('project_leader')}</label>
        <XlnzDropdown
            value={filter.projectLeader}
            options={projectLeaderList}
            optionLabel='name'
            showClear={true}
            placeholder={i18n('select') + '...'}
            optionValue='id'
            onChange={e => filter.update('projectLeader', e.value)}
            style={{width: '200px'}}/>
    </div>)

    leftFilters.push(<div>
        <label className="label">&nbsp;</label>
        <MainButton labelI18n='create_report' onClick={() => loadData()}/>
    </div>)

    const contextMenu = [
        ContextMenuItem({
            i18n: 'project_summary', icon: Icon.LOG,
            command: item => dialog.show(<ProjectSummaryDialog projectId={item.projectId}/>)
        }),
        ContextMenuItem({
            i18n: 'edit_tender', icon: Icon.TENDER,
            command: item => navigate('/tenders/' + item.tenderId),
            hidden: row => !roles.hasRole(Role.TENDER_READ) || !row?.tenderId,
        }),
        ContextMenuItem({
            i18n: 'do_invoice', icon: Icon.INVOICE,
            command: item => navigate('/invoices/0?project=' + item.projectId),
            hidden: row => !roles.hasRole(Role.INVOICE_WRITE)
        }),
    ]

    return <div className="Grid" style={{padding: '10px 0'}}>
        <Grid
            id='ProjectHoursAndInvoiced'
            forceDesktop={true}
            labelI18n='project_hours_and_invoiced'
            icon={Icon.REPORT}
            leftFilters={leftFilters}
            filter={filter}
            updateFilter={filter.update}
            columns={columns}
            value={filteredList}
            onRowSelect={e => {
                navigate('/projects/' + e.data.projectId)
            }}
            contextMenu={contextMenu}
        />
        {dialog.render()}
    </div>
}

export default ProjectHoursAndInvoiced;