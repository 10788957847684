import {faChevronDown, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useEffect, useState} from "react";

export default function useXlnxGridExpandable(props) {
    const {
        value: data,
        expandableField,
        getSessionState,
        setSessionState
    } = props

    const [topParentMap, setTopParentMap] = useState(undefined);
    const [expandedList, setExpandedList] = useState(JSON.parse(getSessionState('expandedList') || '[]'));
    const [foundAnyChilds, setFoundAnyChilds] = useState(false)

    const TEMP_PROJECT_EXPANDABLE = true
    // const TEMP_PROJECT_EXPANDABLE = useCompanyProp(Companyprop.TEMP_PROJECT_EXPANDABLE, false)

    const expandRow = (id) => {
        setExpandedList(prevList => {
            let _expandedList
            if (prevList.includes(id)) {
                _expandedList = prevList.filter(expandedId => expandedId !== id);
            } else {
                _expandedList = [...prevList, id];
            }
            setSessionState('expandedList', JSON.stringify(_expandedList))
            return _expandedList
        });
    };

    const isRowExpanded = (id) => expandedList.includes(id);

    useEffect(() => {

        function calculateTopParents(data) {
            if (data.length === 0) return

            const _topParentMap = {}; // Objekt för att cacha toppförälder för varje id

            function findTopParent(item) {
                // Om toppföräldern redan är beräknad, returnera den direkt
                if (_topParentMap[item.id] !== undefined) {
                    return _topParentMap[item.id];
                }

                // Om item är en rotnod (utan parent), sätt toppföräldern till undefined
                if (!item.parent) {
                    _topParentMap[item.id] = undefined;
                } else {
                    // Hitta föräldern i data och följ kedjan uppåt
                    const parent = data.find(i => i.id === item.parent);
                    // Om föräldern saknas, sätt toppföräldern till undefined
                    _topParentMap[item.id] = parent ? findTopParent(parent) || parent : undefined;
                }
                return _topParentMap[item.id];
            }

            let _foundAnyChilds = false
            data.forEach(item => {
                item.topParent = findTopParent(item)?.id;
                if (!_foundAnyChilds && item.topParent > 0) _foundAnyChilds = true
            });

            setTopParentMap(_topParentMap)

            if (TEMP_PROJECT_EXPANDABLE) setFoundAnyChilds(_foundAnyChilds)
        }

        if (expandableField) calculateTopParents(data)
    }, [data, expandableField, TEMP_PROJECT_EXPANDABLE])

    function hasChildren(row) {
        // Kontrollera att `topParentMap` är laddat
        if (!topParentMap || Object.keys(topParentMap).length === 0) return false;

        // Kontrollera om det finns barn med `row` som förälder
        const children = data.some(item => item.parent === row.id);

        // Returnera `true` endast om `row` har barn och är en toppförälder (nivå 1)
        return children && !row.parent;
    }

    return {
        isRowVisible: row => {
            if (!TEMP_PROJECT_EXPANDABLE) return true;
            if (!expandableField) return true
            if (!topParentMap) return false
            const topParentId = topParentMap[row.id]?.id
            if (!topParentId) return true
            return isRowExpanded(topParentId)
        },
        renderHeader: row => {
            if (!expandableField) return undefined
            if (!foundAnyChilds) return undefined
            return <div style={{width: '25px'}}/>
        },
        renderContent: row => {
            if (!expandableField) return undefined
            if (!foundAnyChilds) return undefined

            const topParentId = topParentMap[row.id]?.id


            return <div className='content-cell'
                        style={{
                            minWidth: '25px',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                        onClick={e => {
                            e.preventDefault()
                            expandRow(row.id)
                        }}
            >
                {
                    hasChildren(row) &&
                    <div className='expandable-cell'>
                        <FontAwesomeIcon
                            icon={isRowExpanded(row.id) ? faChevronDown : faChevronRight}
                            className='faIcon'
                            style={{color: '#2d4a1a', fontSize: '14px'}}/>
                    </div>
                }
                {
                    !hasChildren(row) && isRowExpanded(topParentId) &&
                    <div style={{width: '2.5px', height: '100%', backgroundColor: '#699b2c'}}/>
                }
            </div>
        },
        renderFooter: row => {
            if (!expandableField) return undefined
            if (!foundAnyChilds) return undefined
            return <div style={{width: '25px'}}/>
        },
    }
}