import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {CancelButton, MainButton} from "../../../../components/Buttons/Button";
import FormItemCheckbox from "../../../../components/EditForm/FormItemCheckbox";
import {useFilter} from "../../../../components/Grid/filter";
import {FooterAggregate} from "../../../../components/Grid/getFooterGroup";
import Grid from "../../../../components/Grid/Grid";
import NumberGridInput from "../../../../components/Grid/NumberGridInput";
import onRowChange from "../../../../components/Grid/onRowChange";
import PdfColumn from "../../../../components/Grid/PdfColumn";
import XlnzDialog from "../../../../components/XlnzDialog/XlnzDialog";
import {GridTemplates} from "../../../../GridTemplates";
import {i18n} from "../../../../I18n/I18n";
import {notContains} from "../../../../utils/ArrayUtil";
import SupplierinvoiceService from "../../../Supplierinvoices/SupplierinvoiceService";

export default function FetchSupplierinvoicerowsDialog(props) {
    const filter = useFilter('FetchSupplierinvoicerowsDialog', [
        {name: 'showTitle', defaultValue: true},
        {name: 'separatePercentageMarkup', defaultValue: false}
    ])

    const {
        onSave,
        onHide
    } = props
    const [supplierinvoicerowList, setSupplierinvoicerowList] = useState(props.supplierinvoicerowList)
    const [selection, setSelection] = useState([])

    const defaultValues = {
        showTitle: filter.showTitle,
        separatePercentageMarkup: filter.separatePercentageMarkup,
    }

    const form = {...useForm({defaultValues: defaultValues})}
    const {handleSubmit, formState: {isSubmitting}, getValues} = form

    const onSubmit = () => {
        const data = getValues();
        filter.update('showTitle', data.showTitle)
        filter.update('separatePercentageMarkup', data.separatePercentageMarkup)
        onSave(supplierinvoicerowList.filter(item => item.priceToInvoice !== 0), {
            showTitle: data.showTitle,
            separatePercentageMarkup: data.separatePercentageMarkup,
        })
        onHide()
    }

    const onQuantityToInvoice = list => {
        setSupplierinvoicerowList(list)
    }

    const columns = [
        PdfColumn(rowData => rowData.supplierinvoiceFileSize > 0,
            rowData => rowData.supplierinvoiceNumber + ' - ' + rowData.supplierName,
            async rowData => (await SupplierinvoiceService.getImageUrl(rowData.supplierinvoiceId)).url
        ),
        {field: 'supplierName', headerI18n: 'supplier'},
        {field: 'supplierinvoiceDate', headerI18n: 'invoice_date', width: 100},
        {field: 'supplierinvoiceNumber', headerI18n: 'invoice_number', width: 120},
        {field: 'name', headerI18n: 'description'},
        {
            field: 'price', headerI18n: 'price', body: GridTemplates.currency, align: 'right',
            footer: FooterAggregate.SUM_CURRENCY,
            width: 100
        },
        {
            field: 'percentageMarkup', headerI18n: 'percentage_markup', body: GridTemplates.percentTimes100,
            align: 'right', width: 100
        },
        {
            field: 'leftToInvoicePrice', headerI18n: 'left_to_invoice', body: GridTemplates.currency, align: 'right',
            footer: FooterAggregate.SUM_CURRENCY,
            width: 120
        },
        {
            field: 'priceToInvoice', headerI18n: 'to_invoice', align: 'right',
            body: GridTemplates.numberTwoDecimals,
            editor: e => <NumberGridInput editorEvent={e}/>,
            onCellEditComplete: e => {
                let newValue = e.newValue
                const row = e.rowData
                if (row.leftToInvoicePrice > 0 && newValue > row.leftToInvoicePrice) {
                    newValue = row.leftToInvoicePrice
                } else if (row.leftToInvoicePrice < 0 && newValue < row.leftToInvoicePrice) {
                    newValue = row.leftToInvoicePrice
                }
                onRowChange(e, newValue, onQuantityToInvoice)

                // Check the box
                if (notContains(selection, row)) {
                    const _selection = [...selection]
                    _selection.push(row)
                    setSelection(_selection)
                }
                // const row = e.newRowData
                // const _supplierinvoicerowList = [...supplierinvoicerowList]
                // if (row.priceToInvoice > row.leftToInvoicePrice) {
                //     _supplierinvoicerowList[e.rowIndex].priceToInvoice = row.leftToInvoicePrice
                // }
                // _supplierinvoicerowList[e.rowIndex].checked = e.newRowData.priceToInvoice > 0
                // setSupplierinvoicerowList(_supplierinvoicerowList)
                // onRowChange(e, e.newValue, onQuantityToInvoice)
            },
            footer: FooterAggregate.SUM_CURRENCY,
            width: 120
        },
        {field: 'selection', selectionMode: 'multiple', width: 40},
    ]

    function onSelectionChange(e) {
        if (e.row) {
            e.row.priceToInvoice = e.checked ? e.row.leftToInvoicePrice : 0
        } else {
            const _supplierinvoicerowList = [...supplierinvoicerowList]
            _supplierinvoicerowList.forEach(row => row.priceToInvoice = e.checked ? row.leftToInvoicePrice : 0)
            setSupplierinvoicerowList(_supplierinvoicerowList)
        }
        setSelection(e.value)
    }

    return <XlnzDialog header={i18n('supplier_invoices')}
                       onHide={props.onHide}
                       leftButtons={() => [
                           <FormItemCheckbox key='showTitle' name='showTitle' labelI18n='show_title' {...form}/>,
                           <FormItemCheckbox key='separatePercentageMarkup' name='separatePercentageMarkup'
                                             labelI18n='separate_percentage_markup' {...form}/>
                       ]}
                       buttons={() => [
                           <MainButton key='do_invoice' labelI18n='do_invoice' onClick={e => handleSubmit(onSubmit)()}
                                       disabled={isSubmitting}/>,
                           <CancelButton key='cancel' onClick={e => props.onHide()}/>
                       ]}
    >
        <div style={{width: '1200px'}}>
            <Grid forceDesktop={true}
                  columns={columns}
                  value={supplierinvoicerowList}
                  heightOffset={370}
                  selection={selection}
                  onSelectionChange={onSelectionChange}
                  rowClassName={e => ({'bold': e.headline})}
            />
        </div>

    </XlnzDialog>
}