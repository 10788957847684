import {groupBy, uniqBy} from "lodash";
import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import XlnzDropdown from "../../../components/XlnzDropdown/XlnzDropdown";
import Label from "../../../components/EditForm/Label";
import {useFilter} from "../../../components/Grid/filter";
import Grid from "../../../components/Grid/Grid";
import {hideLoading, showLoading} from "../../../components/Overlay/Loading";
import Icon from "../../../enums/Icon";
import {match} from "../../../functions/match";
import useMountEffect from "../../../hooks/useMountEffect";
import usePreload from "../../../hooks/usePreload";
import {i18n} from "../../../I18n/I18n";
import {distinct, findById, sortByField} from "../../../utils/ArrayUtil";
import {getDayName} from "../../../utils/DateUtils";
import EmployeeAbsenceInfoGridTemplete from "../Dayplannings/absencegrid/EmployeeAbsenceInfoGridTemplete";
import EmployeeAllowanceInfoGridTemplete from "../Dayplannings/allowancegrid/EmployeeAllowanceInfoGridTemplete";
import EmployeeInfoGridTemplete from "../Dayplannings/dayplanninggrid/EmployeeInfoGridTemplete";
import DayplanningService from "../Dayplannings/DayplanningService";
import '../Dayplannings/DayplanningView.scss'
import TimeregabsenceService from "../TimeregabsenceService";
import TimeregallowanceService from "../TimeregallowanceService";

const NotarizeView = () => {
    const navigate = useNavigate()

    const preload = usePreload()
    const employeeList = preload.getEmployeeList()
    const timeregsalarytypeList = preload.getTimeregsalarytypeList()
    const timeregabsencetypeList = preload.getTimeregabsencetypeList()
    const timeregallowancetypeList = preload.getTimeregallowancetypeList()

    const filter = useFilter('NotarizeView', [
        {name: 'projectLeader'},
        {name: 'project'},
    ])

    const [list, setList] = useState([])
    const dayplanningList = list.filter(item => item.dayplanning)

    const projectLeaderList = sortByField(distinct(dayplanningList.map(item => item.dayplanning.projectLeader))
        .map(id => findById(employeeList, id)), 'name')

    if (projectLeaderList.length > 0 && filter.projectLeader && !findById(projectLeaderList, filter.projectLeader))
        filter.remove('projectLeader')


    const projectList = sortByField(uniqBy(dayplanningList, item => item.dayplanning.project)
        .filter(item => !filter.projectLeader || filter.projectLeader === item.dayplanning.projectLeader)
        .map(item => ({
            id: item.dayplanning.project,
            name: item.dayplanning.projectLabel

        })), 'name')

    if (projectList.length > 0 && filter.project && !findById(projectList, filter.project))
        filter.remove('project')


    let filteredList = list
    if (filter.projectLeader || filter.project) {
        filteredList = dayplanningList?.filter(item =>
            match(filter, item.dayplanning, 'projectLeader') &&
            match(filter, item.dayplanning, 'project')
        )
    }

    const loadData = async () => {
        showLoading()

        const [
            _dayplanningList,
            _timeregabsenceList,
            _timeregallowanceList
        ] = await Promise.all([
            DayplanningService.findByFilter({unnotarized: true, search: ''}),
            TimeregabsenceService.findUnnotarized(),
            TimeregallowanceService.findUnnotarized()
        ])

        const _list = []
        _dayplanningList.forEach(item => {
            _list.push({
                type: 'dayplanning',
                prio: 10,
                date: item.date,
                label: item.projectLabel,
                customerName: item.customerName,
                dayplanning: item,
                onSelect: () => navigate('/timereg/dayplanning/' + item.id + '/' + item.date)
            })
        })


        const groupedTimeregabsenceList = groupBy(_timeregabsenceList, item => item.date)
        for (const key in groupedTimeregabsenceList) {
            const _dateTimeregabsenceList = groupedTimeregabsenceList[key]
            _list.push({
                type: 'absence',
                prio: 20,
                date: _dateTimeregabsenceList[0].date,
                label: i18n('absence'),
                timeregabsenceList: _dateTimeregabsenceList,
                onSelect: () => navigate('/timeregabsence/' + _dateTimeregabsenceList[0].date)
            })
        }

        const groupedTimeregalloanceList = groupBy(_timeregallowanceList, item => item.date)
        for (const key in groupedTimeregalloanceList) {
            const _dateTimeregallowanceList = groupedTimeregalloanceList[key]
            _list.push({
                type: 'allowance',
                prio: 30,
                date: _dateTimeregallowanceList[0].date,
                label: i18n('allowances'),
                timeregallowanceList: _dateTimeregallowanceList,
                onSelect: () => navigate('/timeregallowance/' + _dateTimeregallowanceList[0].date)
            })
        }

        sortByField(_list, 'date')
        setList(_list)

        hideLoading()
    }

    useMountEffect(() => {
        loadData(filter.date)
    })

    const columns = [
        {
            field: 'label', headerI18n: 'project',
            body: item => <div>
                <div style={{
                    fontWeight: 'bold',
                    fontSize: '16px',
                    paddingTop: '5px'
                }}>{item.date} - {getDayName(item.date)}</div>

                <div style={{fontWeight: 'bold'}}>{item.label}</div>
                <div>{item.customerName}</div>
            </div>,
            width: 250,
            mobile: true
        },
        {
            field: 'employeeName', headerI18n: 'employee',
            body: item => {

                if (item.type === 'dayplanning') {
                    return <EmployeeInfoGridTemplete
                        dayplanning={item.dayplanning}
                        employeeList={employeeList}
                        timeregsalarytypeList={timeregsalarytypeList}
                    />
                }

                if (item.type === 'absence') {
                    return <EmployeeAbsenceInfoGridTemplete
                        employeeList={employeeList}
                        timeregabsenceList={item.timeregabsenceList}
                        timeregabsencetypeList={timeregabsencetypeList}
                    />
                }

                if (item.type === 'allowance') {
                    return <EmployeeAllowanceInfoGridTemplete
                        employeeList={employeeList}
                        timeregallowanceList={item.timeregallowanceList}
                        timeregallowancetypeList={timeregallowancetypeList}
                    />
                }
            },
            mobile: true
        },
    ]

    const leftFilters = [
        <div>
            <Label valueI18n='project_leader'/>
            <XlnzDropdown
                value={filter.projectLeader}
                options={projectLeaderList}
                optionLabel='name'
                showClear={true}
                placeholder={i18n('select') + '...'}
                optionValue='id'
                onChange={e => {
                    filter.update('projectLeader', e.value)
                    filter.remove('project')
                }}
                style={{width: '200px'}}/>
        </div>,
        <div>
            <Label valueI18n='project'/>
            <XlnzDropdown
                value={filter.project}
                options={projectList}
                filterBy='filterBy'
                optionLabel='name'
                showClear={true}
                placeholder={i18n('select') + '...'}
                optionValue='id'
                onChange={e => filter.update('project', e.value)}
                style={{width: '300px'}}/>
        </div>
    ]

    return <div className="NotarizeView">
        <Grid
            id='NotarizeView'
            forceDesktop={true}
            labelI18n='notarize'
            icon={Icon.NOTARIZE}
            leftFilters={leftFilters}
            filter={filter}
            updateFilter={filter.update}
            columns={columns}
            value={filteredList}
            onRowSelect={item => item.data.onSelect()}
        />
    </div>
}

export default NotarizeView;
