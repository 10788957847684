import {useContext, useState} from "react";
import AppContext from "../../../../AppContext";
import {FetchButton} from "../../../../components/Buttons/Button";
import {hideLoading, showLoading} from "../../../../components/Overlay/Loading";
import Companyprop from "../../../../enums/Companyprop";
import Vat from "../../../../enums/Vat";
import VatType from "../../../../enums/VatType";
import calculatePrice from "../../../../functions/calculatePrice";
import useCompanyProp from "../../../../hooks/useCompanyProp";
import useDepsEffect from "../../../../hooks/useEffect";
import usePreload from "../../../../hooks/usePreload";
import {i18n} from "../../../../I18n/I18n";
import {findByField, pushArray, sortByFields, sum} from "../../../../utils/ArrayUtil";
import {isAfter} from "../../../../utils/DateUtils";
import NetUtils from "../../../../utils/NetUtils";
import {round} from "../../../../utils/NumberUtils";
import ProjectService from "../../../Projects/ProjectService";
import TenderService from "../../../Tenders/TenderService";
import TimeregarticleService from "../../../Timereg/TimeregarticleService";
import TimeregService from "../../../Timereg/TimeregService";
import useTimeregarticleInvoicerowsGenerator from "../fetchCostplusWork/useTimeregarticleInvoicerowsGenerator";
import useTimeregInvoicerowsGenerator from "../fetchCostplusWork/useTimeregInvoicerowsGenerator";
import {addEmptyRow, addTitle} from "../FetchHelper";
import {getProjectrentsInvoicerows} from "../fetchProjectrents/FetchProjectrentsButton";
import FetchChangeordersDialog from "./FetchChangeordersDialog";

export const FetchChangeordersButton = props => {
    const context = useContext(AppContext)

    const {
        project,
        rowList,
        vatType,
        invoiceDate,
        onAddRows,
        onLeftToInvoiceReport
    } = props

    const timeregInvoicerowsGenerator = useTimeregInvoicerowsGenerator(vatType)
    const timeregarticleInvoicerowsGenerator = useTimeregarticleInvoicerowsGenerator()

    const [numOfUninvoiced, setNumOfUninvoiced] = useState(0)
    const [allChangeordersList, setAllChangeordersList] = useState()
    const [changeorderList, setChangeorderList] = useState()
    const [showFetchHoursDialog, setShowFetchHoursDialog] = useState(false)

    const preload = usePreload()
    const accountList = preload.getAccountList()
    const articleList = preload.getArticleList()

    // When project is changed
    useDepsEffect(() => {
        if (project && project.id > 0) {
            async function load() {
                const [
                    _changeorderList,
                ] = await Promise.all([
                    NetUtils.doGet('/api/changeorders', {
                        project: project.id,
                        date: invoiceDate
                    }),
                ])
                setAllChangeordersList(filterItemsInOtherInvoices(_changeorderList))
            }

            load()
        }
    }, [project?.id, invoiceDate])

    const filterItemsInOtherInvoices = timeregList => {
        return timeregList
        // return changeorderList.filter(tr => !tr.invoicerowId || isTimeregInThisInvoice(tr))
    }


    // function isTimeregInThisInvoice(proposalIrtr) {
    //     return getInvoiceTimeregIrtrList().filter(invoiceIrtr =>
    //         proposalIrtr.invoicerowId === invoiceIrtr.invoicerow &&
    //         proposalIrtr.id === invoiceIrtr.timereg)
    //         .length > 0
    // }

    // function getInvoiceTimeregIrtrList() {
    //     return rowList
    //         .flatMap(r => r.timeregList)
    //         .filter(invoiceIrtr => invoiceIrtr !== undefined)
    // }

    // When invoice rows are changed
    useDepsEffect(() => {
        if (project && project.id > 0) {

            if (allChangeordersList) {

                const _changeorderList = []
                allChangeordersList.forEach(changeOrder => {
                    const _item = {...changeOrder}

                    const isNotInThisInvoiced = findByField(rowList, 'changeOrder', changeOrder.id) === undefined
                    const isStillLeftToInvoice = changeOrder.totalUninvoiced !== 0

                    if (isNotInThisInvoiced && isStillLeftToInvoice) {
                        _changeorderList.push(_item)
                    }
                })

                setChangeorderList(_changeorderList)
                const _numOfUninvoiced = _changeorderList.length
                setNumOfUninvoiced(_numOfUninvoiced)
                onLeftToInvoiceReport(_numOfUninvoiced)
            }
        }
    }, [allChangeordersList, rowList])

    const onSave = async (list, config) => {
        showLoading()

        let invoiceRows = []
        let i = 0
        for (const _changeOrder of list) {
            if (i++ > 0) {
                addEmptyRow(invoiceRows)
            }

            addTitle(rowList, {showTitle: true}, invoiceRows, _changeOrder.label)
            if (_changeOrder.markup?.length > 0) {
                invoiceRows.push({
                    name: i18n('reference_no') + ': ' + _changeOrder.markup,
                    headline: false,
                    quantity: 0,
                    aprice: 0,
                    discount: 0,
                    vat: Vat.VAT25.id,
                })
            }
            pushArray(invoiceRows, await addTenderrows(_changeOrder))
            pushArray(invoiceRows, await addCostplusWork(_changeOrder, config))
            pushArray(invoiceRows, await addSupplierinvoices(_changeOrder, config))
            pushArray(invoiceRows, await addProjectrents(_changeOrder, config))
        }

        onAddRows(invoiceRows)
        setShowFetchHoursDialog(false)
        hideLoading()
    }


    async function addTenderrows(_changeOrder) {
        if (!_changeOrder.tender) return []

        const result = []

        const _tenderrowList = await TenderService.findRowsByProject(_changeOrder.id)
        _tenderrowList.forEach(tr => {

            const invoicedPrice = sum(tr.invoicerowtenderrowList, 'price')
            const priceLeft = round(tr.price - invoicedPrice, 2)

            const quantity = round(priceLeft / tr.aprice / getDiscountFactor(tr), 2)
            const comesOutEven = quantity * tr.aprice === priceLeft

            if (priceLeft !== 0) {

                const invoicerowtenderrowList = []
                invoicerowtenderrowList.push({
                    invoicerow: 0,
                    tenderrow: tr.id,
                    price: priceLeft
                })

                result.push({
                    changeOrder: _changeOrder.id,
                    headline: tr.headline,
                    onlyTotal: tr.onlyTotal,
                    housework: tr.housework,
                    name: tr.name,
                    unit: tr.unit,
                    quantity: comesOutEven ? quantity : 1,
                    aprice: comesOutEven ? tr.aprice : priceLeft,
                    discount: comesOutEven ? tr.discount : 0,
                    price: priceLeft,
                    vat: Vat.VAT25.id,
                    account: tr.accountId,
                    costcenter: _changeOrder.costcenter,
                    tenderrowList: invoicerowtenderrowList
                })
            }
        })
        return result
    }

    function getDiscountFactor(tr) {
        return (1 - tr.discount / 100);
    }


    async function addCostplusWork(_changeOrder, config) {
        const result = []
        let [
            _timeregList,
            _timeregarticleList
        ] = await Promise.all([
            TimeregService.findByProject(_changeOrder.id),
            TimeregarticleService.findByProject(_changeOrder.id)
        ])

        _timeregList = _timeregList.filter(item => item.debitHours !== 0)
            .filter(item => !isAfter(item.date, invoiceDate))

        _timeregList.forEach(item => {
            item.type = 'timereg'
            item.typeName = item.debittypeName
            item.quantity = item.debitHours
            item.aprice = item.timeregdebittypeprice
        })

        _timeregarticleList = _timeregarticleList.filter(item => !isAfter(item.date, invoiceDate))

        _timeregarticleList.forEach(item => {
            item.type = "timeregarticle"
            item.typeName = item.name
        })

        pushArray(result, timeregInvoicerowsGenerator.getSeparatedOnDate(
            _changeOrder,
            _timeregList.filter(item => !item.invoicerowId),
            config))

        pushArray(result, timeregarticleInvoicerowsGenerator.getSeparated(
            _changeOrder,
            _timeregarticleList.filter(item => !item.invoicerowId),
            config))

        const sortFieldArray = []
        sortFieldArray.push('priority')
        if (config.separateDates) sortFieldArray.push('date')
        sortByFields(result, sortFieldArray)

        result.forEach(item => item.changeOrder = _changeOrder.id)
        return result
    }

    const ACCOUNT_SUPPLIER_INVOICE = useCompanyProp(Companyprop.ACCOUNT_SUPPLIER_INVOICE, undefined)
    const ACCOUNT_SUPPLIER_INVOICE_REVERSED_VAT = useCompanyProp(Companyprop.ACCOUNT_SUPPLIER_INVOICE_REVERSED_VAT, undefined)

    function getAccount() {
        switch (vatType) {
            case VatType.SEREVERSEDVAT.id:
                if (ACCOUNT_SUPPLIER_INVOICE_REVERSED_VAT) return accountList.find(a => a.number === Number(ACCOUNT_SUPPLIER_INVOICE_REVERSED_VAT))
                break;
            default:
                if (ACCOUNT_SUPPLIER_INVOICE) return accountList.find(a => a.number === Number(ACCOUNT_SUPPLIER_INVOICE))
                break
        }
    }

    async function addSupplierinvoices(_changeOrder) {
        const result = []
        const _supplierinvoicerowList = (await ProjectService.findSupplierrowsByProject(_changeOrder.id))
            .filter(item => !isAfter(item.supplierinvoiceDate, invoiceDate))

        // console.log("_supplierinvoicerowList", _supplierinvoicerowList)

        _supplierinvoicerowList.forEach(sir => {
            const invoicedPrice = sum(sir.invoicerowsupplierinvoicerowList, 'price')
            const priceLeftToinvoice = round(sir.toInvoice - invoicedPrice, 2)

            if (priceLeftToinvoice !== 0) {
                const invoicerowsupplierinvoicerowList = []
                invoicerowsupplierinvoicerowList.push({
                    invoicerow: 0,
                    supplierinvoicerow: sir.id,
                    price: priceLeftToinvoice,
                    leftToInvoicePrice: 0,
                })

                result.push({
                    changeOrder: _changeOrder.id,

                    headline: sir.headline,
                    onlyTotal: sir.onlyTotal,
                    // name: sir.supplierName + (sir.name?.length > 0 ? ' - ' + sir.name : ''),
                    name: sir.supplierName
                        + (sir.name?.length > 0 ? ' - ' + sir.name : '')
                        + (sir.supplierinvoiceNumber?.length > 0 ? ', ' + sir.supplierinvoiceNumber : ''),
                    unit: sir.unit,
                    quantity: 1,
                    aprice: priceLeftToinvoice,
                    discount: 0,
                    price: calculatePrice(1, priceLeftToinvoice, 0),
                    vat: Vat.VAT25.id,
                    account: getAccount()?.id,
                    costcenter: _changeOrder.costcenter,
                    supplierinvoicerowList: invoicerowsupplierinvoicerowList
                })
            }
        })
        return result
    }

    // {
    //     "id": 148178,
    //     "projectrentbasis": 4783,
    //     "date": "2024-08-30",
    //     "headline": false,
    //     "onlyTotal": true,
    //     "name": "Hyra 2024-08-30: 2: J.Enarsson. Läckage",
    //     "type": "DAY",
    //     "unit": "CUBIC_METER",
    //     "quantity": 10,
    //     "aprice": 0.9,
    //     "discount": 0,
    //     "price": 9,
    //     "invoicerowprojectrentList": []
    // }

    async function addProjectrents(_changeOrder) {
        const _projectrentList = (await ProjectService.findRentsByProject(_changeOrder.id))
            .filter(item => !isAfter(item.date, invoiceDate))

        _projectrentList.forEach(pr => {
            pr.leftToInvoiceQuantity = pr.quantity
            pr.quantityToInvoice = pr.quantity

            const proposalIrtrList = pr.invoicerowprojectrentList

            proposalIrtrList.forEach(proposalIrtr => {
                // if (proposalIrtr.invoicerow > 0 && !isInThisInvoice(proposalIrtr)) {
                if (proposalIrtr.invoicerow > 0) {
                    pr.leftToInvoiceQuantity -= proposalIrtr.quantity
                    pr.quantityToInvoice -= proposalIrtr.quantity
                }
            })
        })

        const result = getProjectrentsInvoicerows(context,
            accountList,
            project,
            rowList,
            0,
            _projectrentList.filter(pr => pr.quantityToInvoice !== 0),
            {
                showTitle: false,
                separateDates: false
            },
            articleList)

        for (const pr of result) {
            pr.changeOrder = _changeOrder.id
        }

        return result
    }


    function getButtonLabel() {
        return i18n('subproject') + '/' + i18n('change_orders') + ' (' + numOfUninvoiced + ')'
    }

    if (numOfUninvoiced === 0) return undefined
    return <>
        <FetchButton
            label={getButtonLabel()}
            onClick={e => setShowFetchHoursDialog(true)}
        />
        {
            showFetchHoursDialog &&
            <FetchChangeordersDialog
                changeorderList={changeorderList}
                onSave={onSave}
                onHide={() => setShowFetchHoursDialog(false)}/>
        }
    </>
}
