import React, {useCallback} from 'react';
import Button from "../../../components/Buttons/Button";
import AppendixColumn from "../../../components/Grid/AppendixColumn";
import Grid from "../../../components/Grid/Grid";
import renderOverlay from "../../../components/Overlay/renderOverlay";
import Icon from "../../../enums/Icon";
import Role from "../../../enums/Role";
import {GridTemplates} from "../../../GridTemplates";
import usePreload from "../../../hooks/usePreload";
import useRoles from "../../../hooks/useRoles";
import {findById} from "../../../utils/ArrayUtil";
import {isBefore, todayDate} from "../../../utils/DateUtils";
import EmployeeService from "../EmployeeService";
import TrainingEditFormDialog from "./TrainingEditFormDialog";

const TrainingGrid = props => {
    const {
        value,
        form,
        save,
        onChange
    } = props

    const roles = useRoles()
    const preload = usePreload()

    const trainingtypeList = preload.getTrainingtypeList()

    value.forEach(item => {
        item.trainingName = findById(trainingtypeList, item.type).name
    })

    const fireChange = useCallback(list => {
        onChange(list);
    }, [onChange])

    const columns = [
        AppendixColumn(rowData => rowData.fileSize > 0,
            rowData => rowData.filename,
            async rowData => (await EmployeeService.getImageUrl(rowData.employee, rowData.id)).url
        ),
        {field: 'trainingName', headerI18n: 'training',},
        {field: 'date', headerI18n: 'date', width: 80},
        {field: 'location', headerI18n: 'location', width: 150},
        {field: 'approved', headerI18n: 'approved', width: 70, body: GridTemplates.booleanHideFalse},
        {field: 'certificateIssued', headerI18n: 'certificate_issued', width: 90, body: GridTemplates.booleanHideFalse},
        {
            field: 'validUntil', headerI18n: 'valid_until',
            body: rowData =>
                <span style={{
                    color: isBefore(rowData.validUntil, todayDate()) ? 'red' : undefined,
                }}>{rowData.validUntil}</span>,
            excelBody: rowData => rowData.validUntil,
            width: 80
        },
        {field: 'note', headerI18n: 'note'}
    ]

    const onSave = useCallback(_model => {
        const _item = findById(value, _model.id)
        const editIndex = value.indexOf(_item)

        if (editIndex < 0) {
            fireChange([...value, _model]);
        } else {
            const _value = [...value]
            _value[editIndex] = _model
            fireChange(_value);
        }




    }, [fireChange, value])

    function showEditDialog(modelToEdit) {
        renderOverlay(<TrainingEditFormDialog
            model={modelToEdit}
            onSave={onSave}
            onRemove={removeModel => {
                let newList = [...props.value]
                newList.splice(
                    props.value.findIndex(d => d.id === removeModel.id),
                    1
                );
                fireChange(newList);
            }}
            form={form}
            save={save}
        />)
    }

    return <div>
        <Grid
            hideFooter={true}
            scrollable={false}
            columns={columns}
            value={value}
            onRowSelect={e => showEditDialog(e.data)}
        />

        {
            roles.hasRole(Role.EMPLOYEE_WRITE) &&
            <div style={{paddingTop: '10px'}}>
                <Button faicon={Icon.ADD} onClick={e => {
                    e.preventDefault()

                    form.trigger().then(isOk => {
                        if (isOk) {
                            showEditDialog({
                                date: todayDate(),
                                approved: true
                            })
                        }
                    })
                }}/>
            </div>
        }

    </div>
}

export default TrainingGrid;
