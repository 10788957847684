import React, {useState} from 'react';
import {CancelButton, MainButton} from "../../../components/Buttons/Button";
import DateEditor from "../../../components/Grid/DateEditor";
import {addFreeRows} from "../../../components/Grid/freeRows";
import Grid from "../../../components/Grid/Grid";
import NumberGridInput from "../../../components/Grid/NumberGridInput";
import onRowChange from "../../../components/Grid/onRowChange";
import RemoveColumn from "../../../components/Grid/RemoveColumn";
import TextGridInput from "../../../components/Grid/TextGridInput";
import {hideLoading, showLoading} from "../../../components/Overlay/Loading";
import XlnzDialog from "../../../components/XlnzDialog/XlnzDialog";
import Role from "../../../enums/Role";
import {GridTemplates} from "../../../GridTemplates";
import useRoles from "../../../hooks/useRoles";
import {todayDate} from "../../../utils/DateUtils";
import ProjectService from "../../Projects/ProjectService";

const NUM_OF_FREE_ROWS = 1

export default function ErcDialog(props) {
    const {
        onHide,
        rowData,
        onChange
    } = props

    const roles = useRoles()
    const disabled = !roles.hasRole(Role.PROJECT_WRITE);
    const [value, setValue] = useState(rowData.estimatedRemainingCostAdjustmentList || [])

    const rowList = addFreeRows(value, NUM_OF_FREE_ROWS, {
        date: todayDate(), value: 0, note: ''
    }, item => {
        return item.value !== 0
    })


    const fireChange = (list) => {
        const _list = list
            .filter(item => item.value !== 0)
            .map(item => ({
                date: item.date,
                value: item.value,
                note: item.note
            }))
        setValue(_list)
    }

    const columns = [
        {
            field: 'date', headerI18n: 'date',
            editor: DateEditor,
            onCellEditComplete: e => onRowChange(e, e.newValue, fireChange),
            width: 90
        },
        {
            field: 'value', headerI18n: 'cost',
            body: GridTemplates.currencyTwoDecimals,
            editor: e => <NumberGridInput editorEvent={e}/>,
            onCellEditComplete: e => onRowChange(e, e.newValue, fireChange),
            width: 100,
            align: 'right'
        },
        {
            field: 'note', headerI18n: 'note',
            editor: disabled ? undefined : e => <TextGridInput editorEvent={e} maxLength={512}/>,
            onCellEditComplete: e => onRowChange(e, e.newValue, fireChange),
        },
        RemoveColumn(
            row => fireChange(rowList.filter(r => r !== row)),
            0,
            rowData => rowData.value !== 0
        )
    ]

    async function onSave() {
        showLoading()
        await ProjectService.updateValue(rowData.id, 'estimatedRemainingCostAdjustmentList', value)
        onChange(value)
        onHide()
        hideLoading()
    }

    return <XlnzDialog header='Beräknad återstående kostnad (BÅK) +/-'
                       onHide={props.onHide}
                       buttons={props => [
                           <MainButton key='save' labelI18n='save' onClick={onSave}/>,
                           <CancelButton key='cancel' onClick={e => onHide()}/>
                       ]}
    >
        <div style={{width: '500px'}}>
            <Grid
                columns={columns}
                value={rowList}
                disabled={disabled}
                heightOffset={600}
                footerColumnGroup={<div/>}
                onRowSelect={item => {
                }}
            />
        </div>
    </XlnzDialog>
}
