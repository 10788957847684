import React, {useCallback, useState} from 'react';
import {MainButton} from "../../../../../components/Buttons/Button";
import DatePicker from "../../../../../components/Date/DatePicker";
import Label from "../../../../../components/EditForm/Label";
import {useFilter} from "../../../../../components/Grid/filter";
import {FooterAggregate} from "../../../../../components/Grid/getFooterGroup";
import Grid from "../../../../../components/Grid/Grid";
import {hideLoading, showLoading} from "../../../../../components/Overlay/Loading";
import XlnzDropdown from "../../../../../components/XlnzDropdown/XlnzDropdown";
import Icon from "../../../../../enums/Icon";
import {match, matchSearch} from "../../../../../functions/match";
import {GridTemplates} from "../../../../../GridTemplates";
import {i18n} from "../../../../../I18n/I18n";
import {getFirstDateOfMonth, todayDate} from "../../../../../utils/DateUtils";
import NetUtils from "../../../../../utils/NetUtils";
import {projectNumberComparator} from "../../../../../utils/SortUtils";

const _ = require('lodash');

const EnterNovoReport = () => {
    const filter = useFilter('EnterMonthlyReport', [
        {name: 'startDate', defaultValue: getFirstDateOfMonth()},
        {name: 'endDate', defaultValue: todayDate()},
        {name: 'projectNumber', defaultValue: undefined},
        {name: 'search', defaultValue: ''},
    ])
    const [model, setModel] = useState()
    const [projectNumberList, setProjectNumberList] = useState([])


    const filteredList = model?.itemList.filter(item => match(filter, item, 'projectNumber') && matchSearch(filter, item)) || []

    const loadData = useCallback(() => {
        showLoading()
        NetUtils.doGet('/api/reports/enter/novo', {
            startDate: filter.startDate,
            endDate: filter.endDate
        }).then(_model => {
            // const _projectNumberList = _.uniq(_.map(_model.itemList, 'projectNumber'))
            //     .sort(projectNumberComparator)

            const _projectNumberList = _.uniqBy(
                _.map(_model.itemList, item => ({id: item.project, projectNumber: item.projectNumber})),
                'id'
            ).sort((a, b) => projectNumberComparator(a.projectNumber, b.projectNumber))

            // .sort(projectNumberComparator)

            setProjectNumberList(_projectNumberList)

            // console.log("_projectNumberList", _projectNumberList)
            for (const item of _model.itemList) {
                for (const debittypeName of _model.debittypeNameList) {
                    // console.log("ITEM", item, debittypeName)

                    item[debittypeName] = item.debitHours
                        .filter(row => row.debittypeName === debittypeName)
                        .reduce((sum, row) => sum + row.hours, 0);

                    // break
                }
            }

            setModel(_model)
            hideLoading()
        });
    }, [filter.startDate, filter.endDate]);

    const columns = [
        {field: 'date', header: 'Datum', width: 100},
        {
            field: 'projectNumber',
            headerI18n: 'project',
            width: 80
        },
        {
            field: 'projectMarkup',
            headerI18n: 'reference_no',
            width: 80
        },
        {field: 'employeeName', headerI18n: 'employee'},
    ]


    model?.debittypeNameList.forEach(debittypeName => {
        columns.push({
            field: debittypeName,
            header: debittypeName,
            body: GridTemplates.hours,
            footer: FooterAggregate.SUM_HOURS,
            width: 80,
            align: 'right'
        })
    })

    columns.push({
        field: 'hours',
        header: 'Totalt',
        body: GridTemplates.hours,
        footer: FooterAggregate.SUM_HOURS,
        width: 80,
        align: 'right'
    },)

    columns.push(...[
        {
            field: 'workDone',
            headerI18n: 'work_done'
        },
        {
            field: 'articleName',
            headerI18n: 'article'
        },
        {
            field: 'articleQuantity',
            headerI18n: 'quantity',
            body: GridTemplates.numberTwoDecimalsIfNeeded,
            footer: FooterAggregate.SUM_DECIMALS_IF_NEEDED,
            align: 'right',
            width: 80,
        },
        {
            field: 'unit',
            headerI18n: 'unit',
            body: GridTemplates.unit,
            width: 120,
        },
        {
            field: 'aprice',
            headerI18n: 'aprice',
            body: GridTemplates.numberTwoDecimalsIfNeeded,
            // footer: FooterAggregate.SUM_DECIMALS_IF_NEEDED,
            // excelExclude: true,
            align: 'right',
            width: 80,
        },
        {
            field: 'price',
            headerI18n: 'price',
            body: GridTemplates.numberTwoDecimalsIfNeeded,
            footer: FooterAggregate.SUM_DECIMALS_IF_NEEDED,
            // excelExclude: true,
            align: 'right',
            width: 80,
        },
    ])

    // columns.push({field: 'padding', header: '',})

    const leftFilters = [
        <div>
            <Label valueI18n='start_date'/>
            <DatePicker value={filter.startDate} onChange={date => filter.update('startDate', date)}/>
        </div>,

        <div>
            <Label valueI18n='end_date'/>
            <DatePicker value={filter.endDate} onChange={date => filter.update('endDate', date)}/>
        </div>,
        <div>
            <Label valueI18n='project'/>
            <XlnzDropdown
                value={filter.projectNumber}
                options={projectNumberList}
                showClear={true}
                placeholder={i18n('select') + '...'}
                optionValue='projectNumber'
                optionLabel='projectNumber'
                onChange={e => filter.update('projectNumber', e.value)}
                style={{width: '140px'}}/>
        </div>,
    ]

    leftFilters.push(<div>
        <label className="label">&nbsp;</label>
        <MainButton labelI18n='create_report' onClick={() => loadData()}/>
    </div>)

    return <Grid
        id='Enter - Novo'
        name='Enter - Novo'
        forceDesktop={true}
        label='Enter - Novo'
        icon={Icon.REPORT}
        leftFilters={leftFilters}
        filter={filter}
        updateFilter={filter.update}
        columns={columns}
        value={filteredList}
        onRowSelect={e => {
        }}
    />
}

export default EnterNovoReport;