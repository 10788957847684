import React from 'react';
import {GridTemplates} from "../../GridTemplates";
import {formatDate, todayDate} from "../../utils/DateUtils";
import DateEditor from "../Grid/DateEditor";
import {addFreeRows} from "../Grid/freeRows";
import Grid from "../Grid/Grid";
import NumberGridInput from "../Grid/NumberGridInput";
import onRowChange from "../Grid/onRowChange";
import RemoveColumn from "../Grid/RemoveColumn";

const NUM_OF_FREE_ROWS = 1

export default function AmountWithStartDateGrid(props) {
    const {
        value,
        disabled,

        itemLabel,
        itemLabelI18n,
        itemValue,
    } = props


    const rowList = addFreeRows(value, NUM_OF_FREE_ROWS, {
        id: 0, startDate: todayDate(), [itemValue]: 0
    }, item => {
        return item[itemValue] > 0
    })

    const fireChange = (list) => {
        props.onChange(list);
    }

    const columns = [
        {
            field: 'startDate', headerI18n: 'start_date',
            editor: DateEditor,
            onCellEditComplete: e => onRowChange(e, e.newValue, fireChange),
            width: 100
        },
        {
            field: itemValue,
            header: itemLabel,
            headerI18n: itemLabelI18n,
            body: GridTemplates.currencyPerHoursTwoDecimals,
            editor: e => <NumberGridInput editorEvent={e}/>,
            onCellEditComplete: e => onRowChange(e, e.newValue, fireChange),
            width: 100,
            align: 'right'
        },
        RemoveColumn(row => fireChange(rowList.filter(r => r !== row)))
    ]

    return <div style={{width: '400px'}}>
        <Grid
            columns={columns}
            value={rowList}
            disabled={disabled}
            footerColumnGroup={<div/>}
        />
    </div>
}


export function getAmountFromStartDateList(list, itemValue, currentDate = formatDate()) {
    // Sortera listan efter startdatum i fallande ordning (nyaste först)
    const sortedPrices = list.sort((a, b) => new Date(b.startDate) - new Date(a.startDate));

    // Hitta första posten där startdatumet är tidigare än eller lika med dagens datum
    const currentPriceItem = sortedPrices.find(item => new Date(item.startDate) <= new Date(currentDate));

    // Returnera priset om det finns, annars returnera null eller ett standardvärde
    return currentPriceItem ? currentPriceItem[itemValue] : undefined;
}
