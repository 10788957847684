import React from 'react';
import {NBSP} from "../../../App";
import {addFreeRows, numOfFreeRows} from "../../../components/Grid/freeRows";
import Grid from "../../../components/Grid/Grid";
import RemoveColumn from "../../../components/Grid/RemoveColumn";
import SelectEditor from "../../../components/Grid/SelectEditor";
import {GridTemplates} from "../../../GridTemplates";
import usePreload, {PreloadType} from "../../../hooks/usePreload";
import {i18n} from "../../../I18n/I18n";
import {findById} from "../../../utils/ArrayUtil";
import SupplierinvoiceStatus from "../SupplierinvoiceStatus";

const NUM_OF_FREE_ROWS = 1

const SupplierinvoiceattestGrid = props => {
    const {
        value
    } = props

    const preload = usePreload()
    const employeeList = preload.get(PreloadType.EMPLOYEE_LIST)

    const rowList = addFreeRows(value, NUM_OF_FREE_ROWS, {
            id: 0,
            supplierinvoice: 0,
            employee: 0,
            comment: ''
        }
    );

    const fireChange = (list) => {
        const slicedList = list.slice(0, list.length - numOfFreeRows(list))
            .filter(item => item.employee > 0)
        props.onChange(slicedList);
    }

    const onArticleChange = (rowIndex, rowData, employee) => {
        rowData.employee = employee?.id > 0 ? employee.id : undefined
        const _rowList = [...rowList]
        Object.assign(_rowList[rowIndex], rowData)
        fireChange(_rowList)
    }


    function getEmployeeName(row) {
        return findById(employeeList, row.employee)?.name || NBSP
    }

    const columns = [
        {
            field: 'employee',
            header: i18n('employee'),
            body: row => <div className='nowrap' style={{width: '250px'}}>{getEmployeeName(row)}</div>,
            editor: e =>
                e.rowData.answerTime ? getEmployeeName(e.rowData) :
                    <SelectEditor event={e}
                                  options={employeeList.filter(item => item.attestant && item.active)}
                                  optionLabel='name'
                                  onChange={e2 => e.editorCallback(e2?.value)}
                    />,
            onCellEditComplete: e => onArticleChange(e.rowIndex, e.rowData, e.newValue),
            width: 250,
            mobile: true
        },
        {field: 'answerTime', header: i18n('answer_time'), body: GridTemplates.time, width: 140, mobile: true},
        {
            field: 'status', header: i18n('status'),
            body: rowData => rowData.status ? SupplierinvoiceStatus.getName(rowData.status) : undefined,
            width: 150,
            mobile: true
        },
        {field: 'comment', header: i18n('comment'), mobile: true},
        RemoveColumn(row => fireChange(rowList.filter(r => r !== row)), NUM_OF_FREE_ROWS)
    ]

    return <div style={{width: '1000px'}}>
        <Grid columns={columns}
              value={rowList}
              footerColumnGroup={<div/>}
              sortable={false}
              scrollable={false}
              onRowReorder={e => fireChange([...e.value])}
        />
    </div>
}
export default SupplierinvoiceattestGrid;
