import React, {useMemo} from "react";
import useEmployees from "../../hooks/useEmployees";
import usePreload from "../../hooks/usePreload";
import {findById} from "../../utils/ArrayUtil";
import {SubInfoItemRenderer} from "../Grid/SelectEditor";
import FormItemDropdown from "./FormItemDropdown";

const FormItemEmployee = props => {
    const {
        getValues,
        name
    } = props

    const employees = useEmployees()
    const value = getValues(name)

    const preload = usePreload()

    const list = useMemo(() => {
        const result = employees.getActiveUsableOrWithId(value)

        const subcontractorList = preload.getSupplierList().filter(item => item.subcontractor)

        result.forEach(item => {
            const subcontractorName = findById(subcontractorList, item.subcontractor)?.name
            item.subcontractorName = subcontractorName

            item.filterBy = [item.number, item.name, item.subcontractorName].join(';')
        })

        return result
    }, [employees, preload, value])

    function getSubInfo(item) {
        let result = ''
        if (item.subcontractorName) {
            result += item.subcontractorName
        }
        return result
    }


    return <FormItemDropdown options={list}
                             optionLabel='name'
                             filterBy='filterBy'
                             itemTemplate={item => <SubInfoItemRenderer name={item.name} subInfo={getSubInfo(item)}/>}
                             {...props} />
}
export default FormItemEmployee