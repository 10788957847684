import fetchIntercept from "fetch-intercept";
import {addSeconds, isAfter} from "../utils/DateUtils";
import Env from "../utils/Env";
import LocalStorageUtils from "../utils/LocalStorageUtils";
import NetUtils from "../utils/NetUtils";
import SessionStorageUtils from "../utils/SessionStorageUtils";
import useApiVersion from "./useApiVersion";

const NO_AUTH_URLS = [
    '/api/users/authenticate',
    '/api/users/authenticate/send-code',
    '/api/users/reAuthenticate',
    '/api/users/send-new-password'
];
const isAnAuthRequest = url => NO_AUTH_URLS.includes(url);

let reAuthenticating = false

const useSecurity = () => {
    const apiVersion = useApiVersion()

    const getAccessToken = () => new AccessToken(
        LocalStorageUtils.getItem("accessToken"),
        LocalStorageUtils.getItem("accessTokenExpire")
    )

    const generateRequestPromise = async (url, config) => {
        if (isAnAuthRequest(url)) {
            return [url, config]
        }
        if (url.indexOf("amazonaws") !== -1) {
            return [url, config]
        }

        // await waitForReAuthenticationToFinish(url);

        if (getAccessToken().isExpired() && getAccessToken().accessToken) {
            await reAuthenticate(url, config);
        }

        return generateReturnValue(url, config);
    };

    // const waitForReAuthenticationToFinish = async url => {
    // while (reAuthenticating) {
    //     console.log("SECURITY: Waiting for re-authentication...", url)
    //     await new Promise(resolve => setTimeout(resolve, 256));
    // }
    // }

    const reAuthenticate = async (url) => {
        if (reAuthenticating) {
            while (reAuthenticating) {
                console.log("SECURITY: Waiting for re-authentication...", url)
                await new Promise(resolve => setTimeout(resolve, 256));
            }
            return
        }

        reAuthenticating = true;

        let resp = await fetch('/api/users/reAuthenticate', {
            method: 'POST'
        });

        if (resp.ok) {
            let newAt = await resp.json();
            LocalStorageUtils.setItem("accessToken", newAt.accessToken);
            LocalStorageUtils.setItem("accessTokenExpire", newAt.accessTokenExpire);
            console.log("SECURITY: Re-authenticated user!")
        } else {
            console.warn("SECURITY: Failed to re-authenticated user. Probably an expired refresh token.")
        }
        reAuthenticating = false;
    }

    const generateReturnValue = (url, config) => {
        if (!config) config = {};
        if (!config.headers) config.headers = {};

        if (SessionStorageUtils.getItem("company")) {
            config.headers.company = SessionStorageUtils.getItem("company", undefined);
        }

        config.headers.deviceId = LocalStorageUtils.getItem("deviceId")

        const at = getAccessToken();
        if (!at.isExpired()) {
            config.headers.Authorization = "Bearer " + at.accessToken;
            return [url, config];
        } else {

        }
    }

    return {
        init: () => {
            fetchIntercept.register({
                request: (url, config) => generateRequestPromise(url, config),

                // Called when an alert occured during another 'request' interceptor call
                requestError: error => Promise.reject(error),

                // Modify the response object
                response: response => {
                    if (response.headers.get('multiple-user-usage') && !Env.isMobile()) {
                        signOut("multiple-user-usage")
                    }

                    apiVersion.setApiBuildTime(response.headers.get('api-build-time'));
                    return response;
                },

                // Handle an fetch alert
                responseError: error => Promise.reject(error)
            });
            console.info("SECURITY: Requests are now secured!")
        },

        authenticate: async (username, password, code) => {
            let payload = {
                username: username,
                password: password,
                code: code
            }
            let resp = await fetch('/api/users/authenticate', {
                method: 'POST',
                headers: {"Content-Type": "application/json; charset=utf-8"},
                body: JSON.stringify(payload)
            });

            const result = await resp.json()
            if (resp.ok) {
                LocalStorageUtils.setItem("accessToken", result.accessToken);
                LocalStorageUtils.setItem("accessTokenExpire", result.accessTokenExpire);
            } else {
                return Promise.reject(result);
            }
        },

        sendNewPassword: username => {
            return fetch('/api/users/send-new-password', {
                method: 'POST',
                headers: {"Content-Type": "application/json; charset=utf-8"},
                body: JSON.stringify({username: username})
            });
        },

        changePassword: newPassword => {
            return fetch('/api/users/change-password', {
                method: 'POST',
                headers: {"Content-Type": "application/json; charset=utf-8"},
                body: JSON.stringify({newPassword: newPassword})
            });
        },

        signOut: signOut
    }
}
export default useSecurity

let signedOut = false

async function signOut(reason) {
    if (!signedOut) {
        signedOut = true
        await NetUtils.doPost("/api/users/sign-out", {
            accessToken: LocalStorageUtils.getItem("accessToken"),
            reason: reason
        })
        LocalStorageUtils.removeItem("accessToken");
        LocalStorageUtils.removeItem("accessTokenExpire");
        SessionStorageUtils.removeItem("company");
        window.location = '/' + (reason ? '?reason=' + reason : '');
    }
}


class AccessToken {
    accessToken = undefined;
    accessTokenExpireStr = undefined

    constructor(accessToken, accessTokenExpireStr) {
        if (accessToken) this.accessToken = accessToken;
        if (accessTokenExpireStr) this.accessTokenExpireStr = accessTokenExpireStr;
    }

    isValid = () => Boolean(this.accessToken && (this.accessTokenExpireStr && this.accessTokenExpireStr !== 'undefined'));

    isExpired = () => {
        if (!this.isValid) return true;
        const nowPlus30s = addSeconds(new Date(), 30)
        return isAfter(nowPlus30s, this.accessTokenExpireStr)
    }
}
