import React from 'react';
import {addFreeRows, numOfFreeRows} from "../../../components/Grid/freeRows";
import Grid from "../../../components/Grid/Grid";
import onRowChange from "../../../components/Grid/onRowChange";
import RemoveColumn from "../../../components/Grid/RemoveColumn";
import TextGridInput from "../../../components/Grid/TextGridInput";

const NUM_OF_FREE_ROWS = 1

const LeadcontactGrid = props => {
    const {
        disabled,
        value
    } = props

    // const roles = useRoles()


    const rowList = addFreeRows(value, NUM_OF_FREE_ROWS, {
        id: 0, name: '', phone0: '', email: ''
    });

    const getSlicedList = list => list.slice(0, list.length - numOfFreeRows(list))


    const fireChange = (list) => {
        props.onChange(getSlicedList(list));
    }

    const columns = [
        {
            field: 'name', headerI18n: 'name',
            editor: disabled ? undefined : e => <TextGridInput editorEvent={e} maxLength={512}/>,
            onCellEditComplete: e => onRowChange(e, e.newValue, fireChange),
        },
        {
            field: 'phone0', headerI18n: 'mobile', width: 120,
            editor: disabled ? undefined : e => <TextGridInput editorEvent={e} maxLength={512}/>,
            onCellEditComplete: e => onRowChange(e, e.newValue, fireChange),
        },
        {
            field: 'email', headerI18n: 'email',
            editor: disabled ? undefined : e => <TextGridInput editorEvent={e} maxLength={512}/>,
            onCellEditComplete: e => onRowChange(e, e.newValue, fireChange),
        },
        RemoveColumn(row => fireChange(rowList.filter(r => r !== row)))
    ]

    // function showEditDialog(modelToEdit) {
    //     renderOverlay(<CustomercontactEditFormDialog visible={modelToEdit !== undefined}
    //                                                  model={modelToEdit}
    //                                                  onSave={savedModel => {
    //                                                      // console.log("modelToEditmodelToEdit", modelToEdit)
    //                                                      if (modelToEdit.newPrice) {
    //                                                          delete modelToEdit.newPrice;
    //                                                          let newList = [...props.value];
    //                                                          newList.push(savedModel);
    //                                                          fireChange(newList);
    //                                                      } else {
    //                                                          fireChange([...props.value]);
    //                                                      }
    //                                                  }}
    //                                                  onRemove={removeModel => {
    //                                                      let newList = [...props.value]
    //                                                      newList.splice(
    //                                                          props.value.findIndex(d => d.id === removeModel.id),
    //                                                          1
    //                                                      );
    //                                                      fireChange(newList);
    //                                                  }}
    //     />)
    // }

    return <div style={{width: '800px'}}>
        <Grid
            hideFooter={true}
            scrollable={false}
            columns={columns}
            value={rowList}
            onRowSelect={e => {
            }}
        />
    </div>
}

export default LeadcontactGrid;
