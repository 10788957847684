import React, {useState} from 'react';
import {useForm} from "react-hook-form";
import {APP_NAME} from "../../App";
import useSecurity from "../../hooks/useSecurity";
import {i18n} from "../../I18n/I18n";
import logo from '../../resources/images/logo.png'
import NetUtils from "../../utils/NetUtils";
import RouterUtils from "../../utils/RouterUtils";
import Button, {LinkButton, MainButton} from "../Buttons/Button";
import FormItemEmail from "../EditForm/FormItemEmail";
import FormItemInteger from "../EditForm/FormItemInteger";
import FormItemText from "../EditForm/FormItemText";
import {infoAlert} from "../Overlay/Alert";
import {hideLoading, showLoading} from "../Overlay/Loading";
import './Login.scss';
import TabPanel from "../TabView/TabPanel";
import TabView from "../TabView/TabView";

const Login = props => {
    const redirectReason = NetUtils.getParam('reason')

    let defaultErrorMsg = undefined
    if (redirectReason === 'multiple-user-usage') {
        defaultErrorMsg = i18n('error_multiple_user_usage')
    }

    // const [loading, setLoading] = useState()
    const [errorMsg, setErrorMsg] = useState(defaultErrorMsg)
    const [showForgotPassword, setShowForgotPassword] = useState(false)
    const [enterCode, setEnterCode] = useState(false)

    const security = useSecurity()

    // console.log("redirectReason", redirectReason)
    //multiple-user-usage

    const username = RouterUtils.getQueryParam('username') || ''
    const password = RouterUtils.getQueryParam('password') || ''

    const defaultValues = {
        username: username,
        password: password,
    }

    const form = {...useForm({defaultValues: defaultValues})}
    const {handleSubmit, reset} = form

    const onSubmit = data => {
        console.log("onSubmit", data)
        // setLoading(true)
        showLoading()

        if (!showForgotPassword) {
            security.authenticate(data.username, data.password).then(() => {
                window.location = '/';
            }, handleAutenticateErrorResponse)
        } else {
            security.sendNewPassword(data.username).then(res => {
                infoAlert(i18n('new_password_sent_to_email'))
                setShowForgotPassword(false);
                // setLoading(false);
                hideLoading()
                reset({
                    username: data.username
                })
            });
        }
    }

    // if (loading) {
    //     return <Spinner/>
    // }

    async function sendCode(data) {
        showLoading()
        let resp = await fetch('/api/users/authenticate/send-code', {
            method: 'POST',
            headers: {"Content-Type": "application/json; charset=utf-8"},
            body: JSON.stringify({
                username: data.username
            })
        });
        if (resp.ok) {
            setEnterCode(true)
        }
        hideLoading()
    }

    async function authenticateByCode(data) {
        // setLoading(true)
        showLoading()
        security.authenticate(data.username, undefined, data.code).then(() => {
            window.location = '/';
        }, handleAutenticateErrorResponse);
    }

    function handleAutenticateErrorResponse(res) {
        let newErrorMsg;
        switch (res.message) {
            case 'USER_DISABLED':
                newErrorMsg = i18n('error_user_is_inactive')
                break;
            case 'INVALID_CREDENTIALS':
                newErrorMsg = i18n('error_wrong_username_or_password')
                break;

            case 'INVALID_USERNAME':
                newErrorMsg = 'Det gick inte att logga in med den angivna e-postadressen'
                break;
            case 'INVALID_CODE':
                newErrorMsg = 'Koden är ogiltig'
                break;
            case 'CODE_EXPIRED':
                newErrorMsg = 'Koden är inte längre giltig. Koden är giltig i 5 minuter.'
                break;
            case 'CODE_ATTEMPTS_EXCEEDED':
                newErrorMsg = 'Du har överskridit maxantalet försök att ange en giltig kod. Av säkerhetsskäl har din inloggning tillfälligt låsts.\n' +
                    'Vänligen vänta 5 minuter innan du försöker igen eller begär en ny engångskod.'
                break;

            default:
                newErrorMsg = res.message ? res.message : JSON.stringify(res)
                break

        }
        setErrorMsg(newErrorMsg)
        // setLoading(false);
        hideLoading()
    }

    return <div className="Login">
        <div className='container'>
            <div>
                <img src={logo} alt={APP_NAME} className='logo'/>
                {errorMsg && <div className="errorMsg">{errorMsg}</div>}

                <TabView renderActiveOnly={true}>
                    <TabPanel header='E-post & lösenord' key='1'>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="cred-box">
                                <FormItemEmail name='username' labelI18n='email' autoComplete='username'
                                               required {...form}/>

                                {
                                    !showForgotPassword &&
                                    <div>
                                        <FormItemText name='password' type='password' labelI18n='password'
                                                      maxLength={128}
                                                      required {...form}/>
                                        <div>
                                            <LinkButton labelI18n='forgot_password'
                                                        onClick={() => setShowForgotPassword(true)}/>
                                        </div>
                                    </div>
                                }
                            </div>
                            {
                                !showForgotPassword &&
                                <div className='button-row'>
                                    <MainButton labelI18n='log_in' onClick={handleSubmit(onSubmit)}/>
                                </div>
                            }

                            {
                                showForgotPassword &&
                                <div className='button-row'>
                                    <Button labelI18n='back' onClick={() => setShowForgotPassword(false)}/>
                                    <MainButton labelI18n='send_new_password' onClick={handleSubmit(onSubmit)}/>
                                </div>
                            }
                        </form>
                    </TabPanel>

                    <TabPanel header='Kod via E-post' key='2'>

                        {
                            !enterCode &&
                            <form onSubmit={handleSubmit(sendCode)}>
                                <div className="cred-box">
                                    <div className='info'>
                                        Ange din e-postadress så skickas en engångskod till dig. Fyll sedan i koden
                                        i
                                        nästa ruta för att logga in. Inget lösenord behövs!
                                    </div>
                                    <FormItemEmail name='username' labelI18n='email' autoComplete='username'
                                                   required {...form}/>
                                </div>

                                <div className='button-row'>
                                    <MainButton label='Skicka engångkod' onClick={handleSubmit(sendCode)}/>
                                </div>
                            </form>
                        }
                        {
                            enterCode &&
                            <form onSubmit={handleSubmit(authenticateByCode)}>
                                <div className="cred-box">
                                    <div className='info'>
                                        En engångskod har skickats till din e-postadress. Ange koden i fältet nedan
                                        för att fortsätta. Kontrollera din inkorg (och eventuell skräppostmapp) om
                                        du
                                        inte ser mailet direkt.<br/>
                                        <br/>
                                        Koden är giltig i 5 minuter.
                                    </div>
                                    <FormItemInteger name='code' label='Engångkod' required {...form}/>
                                </div>

                                <div className='button-row'>
                                    <Button labelI18n='back' onClick={() => setEnterCode(false)}/>
                                    <MainButton labelI18n='log_in' onClick={handleSubmit(authenticateByCode)}/>
                                </div>
                            </form>
                        }

                    </TabPanel>
                </TabView>
            </div>
        </div>
    </div>
}
export default Login;