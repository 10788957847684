import {isAfter, isBefore} from "./DateUtils";
import {isNumeric} from "./NumberUtils";

export const findById = (arr, id) => {
    if (!arr) return undefined
    if (!id) return undefined
    const _id = Number(id);
    return arr.find(c => c.id === _id);
}

export const findByField = (arr, field, value) => {
    if (!arr) return undefined
    if (!value) return undefined
    return arr.find(c => c[field] === value);
}

export const findByObject = (arr, obj) => arr.find(c => c === obj)

export const isEmpty = arr => !arr || arr.length === 0

export const arraySum = (arr, field) => arr.reduce((sum, item) => {
    return sum + (item[field] ? item[field] : 0)
}, 0)

// export const filterActive = (arr) => arr.filter(c => c.active)
export const filterActive = (arr, forceId) => arr.filter(c => c.active || c.id === forceId);

export const move = (arr, oldIndex, newIndex) => {
    if (newIndex >= arr.length) {
        var k = newIndex - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr; // for testing
}

export const removeByIndex = (arr, index) => arr.splice(index, 1)
export const removeByValue = (arr, value) => removeByIndex(arr, arr.indexOf(value))

export const sortByFields = (arr, ...fields) => {
    for (const field of fields) {
        if (Array.isArray(fields)) {
            for (const subField of field) sortByField(arr, subField)
        } else {
            sortByField(arr, field)
        }
    }
    // fields.forEach(field => sortByField(arr, field))
}

export const sortByField = (arr, field) => arr.sort((a, b) => {
    const aVal = a[field] || ''
    const bVal = b[field] || ''

    if (isNumeric(aVal) && isNumeric(bVal)) {
        const aValNum = Number(aVal)
        const bValNum = Number(bVal)
        return aValNum > bValNum ? 1 : bValNum > aValNum ? -1 : 0
    } else {
        return aVal > bVal ? 1 : bVal > aVal ? -1 : 0
    }
    // return (a[field] > b[field]) ? 1 : ((b[field] > a[field]) ? -1 : 0)
})

export const sum = (arr, field) => arr.reduce((sum, item) => sum + (item[field] ? item[field] : 0), 0)

export const distinct = (arr) => arr.filter((v, i, a) => a.indexOf(v) === i);

export const insert = (arr, item, index) => arr.splice(index, 0, item)
export const pushArray = (arr, items) => items.forEach(item => arr.push(item))

export const findMinDate = (arr, field) => {
    if (isEmpty(arr)) return null
    return arr.map(objekt => objekt[field])
        .reduce((result, value) => isBefore(value, result) ? value : result, arr[0][field]);
}
export const findMaxDate = (arr, field) => {
    if (isEmpty(arr)) return null
    return arr.map(objekt => objekt[field])
        .reduce((result, value) => isAfter(value, result) ? value : result, arr[0][field]);
}


export const findMaxId = arr => {
    if (isEmpty(arr)) return null
    return arr.reduce((max, obj) => (obj.id > max ? obj.id : max), 0)
}

export const contains = (arr, obj) => arr.indexOf(obj) !== -1
export const notContains = (arr, obj) => !contains(arr, obj)

export const trimArray = (arr, size) => {
    if (size < 0) throw new Error("Size must be a non-negative number");
    return arr.slice(0, size);
};

export const findByMaxId = arr => {
    let result = undefined
    let maxId = -1
    for (let i = 0; i < arr.length; i++) {
        if (arr[i].id > maxId) {
            maxId = arr[i].id
            result = arr[i]
        }
    }
    return result
}

export const hasDuplicates = (list) => {
    const set = new Set();
    const duplicates = [];

    list.forEach((userObject) => {
        if (set.has(userObject.user)) {
            duplicates.push(userObject);
        } else {
            set.add(userObject.user);
        }
    });

    return duplicates.length > 0;
}

const ArrayUtil = {
    sum: (arr, field) => arr.reduce((sum, item) => {
        return sum + (item[field] ? item[field] : 0)
    }, 0),
    pushArray: (arr, items) => items.forEach(item => arr.push(item)),

    sortOnField: (array, field) => array.sort((a, b) => (a[field] > b[field]) ? 1 : ((b[field] > a[field]) ? -1 : 0)),

    findById: (arr, id) => {
        if (!arr) return undefined
        const _id = Number(id);
        return arr.find(c => c.id === _id);
    },
    findByField: (arr, field, value) => {
        if (!arr) return undefined
        return arr.find(c => c[field] === value);
    }, findByObject: (arr, obj) => {
        return arr.find(c => c === obj);
    },
    findByMaxId(arr) {
        let result = undefined
        let maxId = -1
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].id > maxId) {
                maxId = arr[i].id
                result = arr[i]
            }
        }
        return result
    },

    active: (arr) => arr.filter(item => item.active), isEmpty: arr => !arr || arr.length === 0,
};

export default ArrayUtil