import React from 'react';
import {Controller, useForm} from "react-hook-form";
import Button, {CancelButton, SaveButton} from "../../../components/Buttons/Button";
import FormItemText from "../../../components/EditForm/FormItemText";
import Grid from "../../../components/Grid/Grid";
import RemoveColumn from "../../../components/Grid/RemoveColumn";
import {hideLoading, showLoading} from "../../../components/Overlay/Loading";
import TabPanel from "../../../components/TabView/TabPanel";
import TabView from "../../../components/TabView/TabView";
import XlnzDialog from "../../../components/XlnzDialog/XlnzDialog";
import Icon from "../../../enums/Icon";
import Role from "../../../enums/Role";
import {GridTemplates} from "../../../GridTemplates";
import useDialog from "../../../hooks/useDialog";
import useMountEffect from "../../../hooks/useMountEffect";
import useUserPersmissions from "../../../hooks/useUserPersmissions";
import {i18n} from "../../../I18n/I18n";
import {findById} from "../../../utils/ArrayUtil";
import FormrowGrid from "./FormrowGrid";

const FormsectionGrid = props => {
    const {
        value,
        onChange
    } = props

    const editDialog = useDialog()

    const fireChange = (list) => {
        onChange(list)
    }

    const columns = [
        {field: 'name', headerI18n: 'name'},
        {field: 'active', headerI18n: 'active', body: GridTemplates.boolean, width: 100},
        RemoveColumn(row => {
            fireChange(value.filter(r => r !== row));
        })
    ]

    return <div className='FormsectionGrid'>
        <Grid
            columns={columns}
            value={value}
            sortable={false}
            scrollable={false}
            onRowSelect={e => {
                editDialog.show(<FormsectionEditDialog model={e.data} onSave={_model => {
                    if (_model.id > 0) {
                        const rowData = findById(value, _model.id)
                        const index = value.indexOf(rowData)
                        value.splice(index, 1, _model);
                        fireChange([...value]);
                    }
                }}/>)
                // navigate('/forms/section/' + e.data.id + '?parent=' + e.data.parentId);
            }}
        />


        <div style={{paddingTop: '10px'}}>
            <Button faicon={Icon.ADD} onClick={e => {
                e.preventDefault()

                const defaultValue = {
                    "rowList": []
                }

                editDialog.show(<FormsectionEditDialog model={defaultValue} onSave={_model => {
                    console.log("_model", _model)

                    value.push(_model)
                    fireChange([...value]);

                }}/>)

            }}/>
        </div>

        {editDialog.render()}
    </div>
}
export default FormsectionGrid;


const FormsectionEditDialog = props => {
    const {
        model,
        onSave,
        onHide
    } = props

    console.log("model", model)

    const userPersmissions = useUserPersmissions()
    const disabled = !userPersmissions.hasRole(Role.SUPERADMIN)

    const form = {...useForm({defaultValues: model}), disabled: disabled}
    const {handleSubmit, getValues, control} = form

    useMountEffect(async () => {
    })


    const onSubmit = () => {
        showLoading();
        const data = getValues();
        onSave(data)
        onHide()
        hideLoading()
    }

    return <XlnzDialog headerI18n='section'
                       onHide={onHide}
                       buttons={() => [
                           <SaveButton key='save' onClick={() => {
                               handleSubmit(onSubmit)()
                           }}/>,
                           <CancelButton key='cancel' onClick={e => onHide()}/>
                       ]}
    >
        <div style={{width: '1400px'}}>
            <div className='EditForm'>
                <div className='columnContainer'>
                    <div className='column'>
                        <FormItemText key='name' name='name' labelI18n='name' maxLength={128} required {...form}/>
                    </div>
                </div>
                <TabView>
                    <TabPanel key='rowList' header={i18n('rows')}>
                        <Controller name="rowList"
                                    control={control}
                                    render={({field: {ref, value, onChange}}) =>
                                        <FormrowGrid inputRef={ref} value={value} onChange={onChange}/>
                                    }/>
                    </TabPanel>
                </TabView>
            </div>
        </div>
    </XlnzDialog>


}