import {faInfoCircle, faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useMemo} from 'react';
import Button from "../../../../components/Buttons/Button";
import {addFreeRows, numOfFreeRows} from "../../../../components/Grid/freeRows";
import Grid from "../../../../components/Grid/Grid";
import RemoveColumn from "../../../../components/Grid/RemoveColumn";
import SelectEditor, {SubInfoItemRenderer} from "../../../../components/Grid/SelectEditor";
import XlnzMenu from "../../../../components/XlnzMenu/XlnzMenu";
import useEmployees from "../../../../hooks/useEmployees";
import usePreload from "../../../../hooks/usePreload";
import {i18n} from "../../../../I18n/I18n";
import {findByField, findById} from "../../../../utils/ArrayUtil";

const NUM_OF_FREE_ROWS = 1

const QnafollowerGrid = props => {
    const {
        value,
        disabled,
        project,
        ourReference
    } = props

    const rowList = addFreeRows(value, NUM_OF_FREE_ROWS, {
        id: 0, name: '', email: ''
    })


    const employees = useEmployees()
    const preload = usePreload()
    const supplierList = preload.getSupplierList()
    const customerList = preload.getCustomerList()
    const subcontractorList = useMemo(() => supplierList.filter(item => item.subcontractor), [supplierList])

    for (const row of rowList) {
        if (row.employee) {
            const employee = employees.findById(row.employee)
            row.typeName = i18n('employee')
            row.followerName = employee.name
            row.companyName = employee.subcontractorName
            row.email = employee.email
        }
        if (row.customercontact) {
            const customercontact = findById(customerList.flatMap(item => item.contactList), row.customercontact)
            const customer = findById(customerList, customercontact.customer)
            row.typeName = i18n('customer')
            row.followerName = customercontact.name
            row.companyName = customer.name
            row.email = customercontact.email
        }
        if (row.suppliercontact) {
            const suppliercontact = findById(supplierList.flatMap(item => item.contactList), row.suppliercontact)
            const supplier = findById(supplierList, suppliercontact.supplier)
            row.typeName = i18n('subcontractor')
            row.followerName = suppliercontact.name
            row.companyName = supplier.name
            row.email = suppliercontact.email
        }
    }

    const predefinedList = useMemo(() => {
        const result = []
        if (project) {
            if (ourReference && ourReference.id !== project.projectLeader) {
                if (!findByField(rowList, 'employee', project.projectLeader)) {
                    const projectLeader = employees.findById(project.projectLeader)
                    result.push({
                        employee: projectLeader.id,
                        type: 'EMPLOYEE',
                        name: i18n('project_leader') + ' - ' + projectLeader.name,
                        subInfo: projectLeader.subcontractorName,
                        email: projectLeader.email
                    })
                }
            }

            const customer = findById(customerList, project.customer)
            if (customer.contactList.length <= 10) {
                for (const customercontact of customer.contactList) {
                    if (customercontact.email.length === 0) continue
                    if (findByField(rowList, 'customercontact', customercontact.id)) continue

                    result.push({
                        type: 'CUSTOMER',
                        customercontact: customercontact.id,
                        name: i18n('customer') + ' - ' + customercontact.name,
                        subInfo: customer.name,
                        email: customercontact.email
                    })
                }
            }
        }
        return result
    }, [rowList, employees, ourReference, customerList, project])

    const options = useMemo(() => {
        const result = []

        for (const employee of employees.activeUsable) {
            if (employee.email.length > 0) {
                // Do not add already added
                if (findByField(rowList, 'employee', employee.id)) continue

                result.push({
                    employeeId: employee.id,
                    type: 'EMPLOYEE',
                    name: i18n('employee') + ' - ' + employee.name,
                    subInfo: employee.subcontractorName,
                    email: employee.email,
                    filterBy: employee.name + ';' +
                        employee.subcontractorName
                })
            }
        }

        for (const customer of customerList) {
            for (const customercontact of customer.contactList) {
                if (customercontact.email.length > 0) {
                    // Do not add already added
                    if (findByField(rowList, 'customercontact', customercontact.id)) continue

                    result.push({
                        customercontactId: customercontact.id,
                        type: 'CUSTOMER',
                        name: i18n('customer') + ' - ' + customercontact.name,
                        subInfo: customer.name,
                        email: customercontact.email,
                        filterBy: customercontact.name + ';' +
                            customer.name + ';' +
                            customercontact.name + ';' +
                            customercontact.email
                    })
                }
            }
        }

        for (const supplier of subcontractorList) {
            for (const suppliercontact of supplier.contactList) {
                if (suppliercontact.email.length > 0) {
                    // Do not add already added
                    if (findByField(rowList, 'suppliercontact', suppliercontact.id)) continue

                    result.push({
                        suppliercontactId: suppliercontact.id,
                        type: 'SUPPLIER',
                        name: i18n('subcontractor') + ' - ' + suppliercontact.name,
                        subInfo: supplier.name,
                        email: suppliercontact.email,
                        filterBy: suppliercontact.name + ';' +
                            supplier.name + ';' +
                            suppliercontact.name + ';' +
                            suppliercontact.email
                    })
                }
            }
        }

        return result
    }, [rowList, employees, customerList, subcontractorList])

    const getSlicedList = list => list.slice(0, list.length - numOfFreeRows(list))

    const fireChange = (list) => {
        props.onChange(getSlicedList(list));
    }
    const columns = [
        {
            field: 'followerName', headerI18n: 'follower',
            editor: e => <SelectEditor
                event={e}
                options={options}
                filterBy='filterBy'
                hideNoneOption={true}
                itemTemplate={item => {
                    return <SubInfoItemRenderer name={item.name} subInfo={item.subInfo}/>;
                }}
                onChange={e2 => e.editorCallback(e2?.value)}
            />,
            onCellEditComplete: e => {
                if (!e.newValue?.type) return
                const _rowList = [...e.props.value]

                const value = {
                    id: e.newValue?.id,
                    name: e.newValue.email,
                    email: e.newValue.email
                }

                if (e.newValue.type === 'EMPLOYEE') {
                    value.employee = e.newValue.employeeId
                }
                if (e.newValue.type === 'CUSTOMER') {
                    value.customercontact = e.newValue.customercontactId
                }
                if (e.newValue.type === 'SUPPLIER') {
                    value.suppliercontact = e.newValue.suppliercontactId
                }


                _rowList[e.rowIndex] = value
                _rowList[e.rowIndex].dirty = true
                fireChange(_rowList)
            },
        },
        {field: 'typeName', headerI18n: 'type', width: 120},
        {field: 'companyName', headerI18n: 'company'},
        {field: 'email', headerI18n: 'email'},
        RemoveColumn(row => fireChange(rowList.filter(r => r !== row)))
    ]

    return <div className='formItem' style={{width: '900px'}}>
        <Grid forceDesktop={true}
              disabled={disabled}
              columns={columns}
              value={rowList}
              sortable={false}
              scrollable={false}
              onRowReorder={e => fireChange([...e.value])}
              footerColumnGroup={<div/>}
        />
        {
            predefinedList?.length > 0 &&
            <div style={{marginTop: '10px'}}>
                <XlnzMenu model={predefinedList.map(item => ({
                        label: item.name,
                        command: () => {
                            console.log("item", item)
                            const _rowList = getSlicedList(rowList)
                            _rowList.push({
                                employee: item.employee,
                                customercontact: item.customercontact,
                                name: item.email,
                                email: item.email
                            })
                            fireChange(_rowList)
                        }
                    })
                )}
                          ref={el => predefinedList.current = el}/>
                <Button faicon={faPlus} onClick={e => predefinedList.current.toggle(e)}/>
            </div>
        }

        <div style={{display: 'flex', alignItems: 'center', marginTop: '20px', color: '#777', fontStyle: 'italic'}}>
            <FontAwesomeIcon icon={faInfoCircle} style={{fontSize: '16px'}}/>&nbsp;
            Vår referens {ourReference?.name} är alltid följare och behöver inte läggas till.
        </div>
    </div>
}

export default QnafollowerGrid;
