import React, {useState} from "react";
import useUserPersmissions from "../../../hooks/useUserPersmissions";
import {useFilter} from "../../../components/Grid/filter";
import {matchSearch} from "../../../functions/match";
import useMountEffect from "../../../hooks/useMountEffect";
import {GridTemplates} from "../../../GridTemplates";
import {FooterAggregate} from "../../../components/Grid/getFooterGroup";
import Spinner from "../../../components/Spinner/Spinner";
import Grid from "../../../components/Grid/Grid";
import {i18n} from "../../../I18n/I18n";
import {deepCopy} from "../../../utils/ObjectUtils";
import RentType from "../../../enums/RentType";

export default function ProjectSummaryRentGrid({project, tabPanelRef}) {
    const [list, setList] = useState()
    const userPersmissions = useUserPersmissions()
    const filter = useFilter('ProjectSummaryRentGrid', [
        {name: 'search', defaultValue: ''},
    ])

    const HIDE_ARTICLE_PRICES = userPersmissions.isHideArticlePrices()

    const filteredList = list?.filter(item => matchSearch(filter, item)) || []

    useMountEffect(async () => {
        const _list = deepCopy(project.rentList)
        _list.forEach(item => {
            item.typeName = RentType.getName(item.type)
        })

        tabPanelRef.current.updateQuantity(_list.length)
        setList(_list)
    })

    const columns = [
        {field: 'name', headerI18n: 'description',},
        {field: 'typeName', headerI18n: 'type', width: 200},
        {
            field: 'unit', headerI18n: 'unit',
            body: GridTemplates.unit,
            width: 100,
        },
        {
            field: 'quantity',
            headerI18n: 'quantity',
            body: GridTemplates.numberTwoDecimals,
            width: 120,
            align: 'right'
        },
        {
            field: 'aprice',
            headerI18n: 'aprice',
            body: GridTemplates.currencyTwoDecimals,
            width: 120,
            align: 'right',
            hide: HIDE_ARTICLE_PRICES
        },
        {
            field: 'discount',
            headerI18n: 'discount',
            body: GridTemplates.percentTimes100,
            width: 80,
            align: 'right',
            hide: HIDE_ARTICLE_PRICES
        },
        {
            field: 'price',
            headerI18n: 'sum',
            body: GridTemplates.currencyTwoDecimals,
            width: 120,
            align: 'right',
            footer: FooterAggregate.SUM_CURRENCY_TWO_DECIMALS,
            hide: HIDE_ARTICLE_PRICES
        }
    ].filter(item => !item.hide)

    if (!filteredList) return <Spinner/>
    return <Grid
        name={project.name + ' - ' + i18n('rents')}
        filter={filter}
        updateFilter={filter.update}
        columns={columns}
        value={filteredList}
        onRowSelect={() => {
        }}
        heightOffset={520}
    />
}