import React, {useState} from "react";
import {FetchButton} from "../../../../components/Buttons/Button";
import renderOverlay from "../../../../components/Overlay/renderOverlay";
import useDepsEffect from "../../../../hooks/useEffect";
import {i18n} from "../../../../I18n/I18n";
import ArrayUtil, {sortByFields} from "../../../../utils/ArrayUtil";
import {formatCurrency, formatHours} from "../../../../utils/NumberUtils";
import TimeregarticleService from "../../../Timereg/TimeregarticleService";
import TimeregService from "../../../Timereg/TimeregService";
import {addTitle} from "../FetchHelper";
import FetchCostplusWorkDialog from "./FetchCostplusWorkDialog";
import useTimeregarticleInvoicerowsGenerator from "./useTimeregarticleInvoicerowsGenerator";
import useTimeregInvoicerowsGenerator from "./useTimeregInvoicerowsGenerator";

export const FetchCostplusWorkButton = props => {
    const {
        project,
        rowList,
        vatType,
        onAddRows,
        onLeftToInvoiceReport
    } = props

    const timeregInvoicerowsGenerator = useTimeregInvoicerowsGenerator({vatType: vatType})
    const timeregarticleInvoicerowsGenerator = useTimeregarticleInvoicerowsGenerator()

    const [uninvoiceHours, setUninvoiceHours] = useState(0)
    const [uninvoicePrice, setUninvoicePrice] = useState(0)
    const [allTimeregList, setAllTimeregList] = useState()
    const [allTimeregarticleList, setAllTimeregarticleList] = useState()
    const [timeregList, setTimeregList] = useState()
    const [timeregarticleList, setTimeregarticleList] = useState()
    // const [showFetchHoursDialog, setShowFetchHoursDialog] = useState(false)

    // When project is changed
    useDepsEffect(() => {
        if (project && project.id > 0) {
            async function load() {
                const [
                    _timeregList,
                    _timeregarticleList
                ] = await Promise.all([
                    TimeregService.findByProject(project.id),
                    TimeregarticleService.findByProject(project.id)
                ])
                setAllTimeregList(filterTimeregsInOtherInvoices(_timeregList))
                setAllTimeregarticleList(filterTimeregarticlesInOtherInvoices(_timeregarticleList))
            }

            load()
        }
    }, [project?.id])

    const filterTimeregsInOtherInvoices = timeregList => {
        return timeregList.filter(tr => !tr.invoicerowId || isTimeregInThisInvoice(tr))
    }


    function isTimeregInThisInvoice(proposalIrtr) {
        return getInvoiceTimeregIrtrList().filter(invoiceIrtr =>
            proposalIrtr.invoicerowId === invoiceIrtr.invoicerow &&
            proposalIrtr.id === invoiceIrtr.timereg)
            .length > 0
    }

    function getInvoiceTimeregIrtrList() {
        return rowList
            .flatMap(r => r.timeregList)
            .filter(invoiceIrtr => invoiceIrtr !== undefined)
    }

    const filterTimeregarticlesInOtherInvoices = timeregarticleList => {
        return timeregarticleList.filter(tr => !tr.invoicerowId || isTimeregarticleInThisInvoice(tr))
    }

    function isTimeregarticleInThisInvoice(proposalIrtr) {
        return getInvoiceTimeregarticleIrtrList().filter(invoiceIrtr =>
            proposalIrtr.invoicerowId === invoiceIrtr.invoicerow &&
            proposalIrtr.id === invoiceIrtr.timeregarticle)
            .length > 0
    }

    function getInvoiceTimeregarticleIrtrList() {
        return rowList
            .flatMap(r => r.timeregarticleList)
            .filter(invoiceIrtr => invoiceIrtr !== undefined)
    }

    // When invoice rows are changed
    useDepsEffect(() => {
        if (project && project.id > 0) {

            if (allTimeregList) {
                const invoicerowtimeregList = rowList
                    .filter(item => item.timeregList !== undefined)
                    .flatMap(item => item.timeregList)

                const _timeregList = []
                allTimeregList.forEach(tr => {
                    const _tr = {...tr}
                    // Find and remove the already invoiced hours.
                    invoicerowtimeregList.filter(irtr => irtr.timereg === tr.id).forEach(invoicedIrtr => {
                        _tr.debitHours -= invoicedIrtr.hours
                    })
                    _timeregList.push(_tr)
                })

                setTimeregList(_timeregList.filter(tr => tr.debitHours !== 0))
                const _uninvoiceHours = ArrayUtil.sum(_timeregList, 'debitHours')
                setUninvoiceHours(_uninvoiceHours)
                onLeftToInvoiceReport(_uninvoiceHours)
            }

            if (allTimeregarticleList) {
                const invoicerowtimeregarticleList = rowList
                    .filter(item => item.timeregarticleList !== undefined)
                    .flatMap(item => item.timeregarticleList)

                const _timeregarticleList = []
                allTimeregarticleList.forEach(tr => {
                    const _tr = {...tr}
                    // Find and remove the already invoiced quantity.
                    invoicerowtimeregarticleList
                        .filter(irtr => irtr.timeregarticle === tr.id)
                        .forEach(invoicedIrtr => {
                            _tr.price = 0
                            _tr.quantity -= invoicedIrtr.quantity
                        })
                    _timeregarticleList.push(_tr)
                })

                setTimeregarticleList(_timeregarticleList.filter(tr => tr.quantity !== 0))
                const _uninvoicePrice = ArrayUtil.sum(_timeregarticleList, 'price')
                setUninvoicePrice(_uninvoicePrice)
                // onLeftToInvoiceReport(_uninvoicePrice)
            }
        }
    }, [allTimeregList, allTimeregarticleList, rowList])

    const onSave = (joinedList, config) => {
        let invoiceRows = []
        addTitle(rowList, config, invoiceRows, i18n('costplus_work'))

        // if (!config.separateDates) {
        //     invoiceRows = invoiceRows.concat(timeregInvoicerowsGenerator.getGrouped(joinedList, config))
        //     invoiceRows = invoiceRows.concat(timeregarticleInvoicerowsGenerator.getGrouped(joinedList))
        //     sortByFields(invoiceRows, 'priority')
        // } else if (config.separateDates && config.separateEmployees) {
        //     invoiceRows = invoiceRows.concat(timeregInvoicerowsGenerator.getSeparatedOnDateAndEmployee(joinedList, config))
        //     // invoiceRows = invoiceRows.concat(timeregarticleInvoicerowsGenerator.getSeparated(joinedList))
        //     sortByFields(invoiceRows, 'priority', 'date')
        // } else {
        invoiceRows = invoiceRows.concat(timeregInvoicerowsGenerator.getSeparatedOnDate(project, joinedList, config))
        invoiceRows = invoiceRows.concat(timeregarticleInvoicerowsGenerator.getSeparated(project, joinedList, config))
        // console.log("invoiceRows", invoiceRows)

        const sortFieldArray = []
        sortFieldArray.push('priority')
        if (config.separateDates) sortFieldArray.push('date')
        sortByFields(invoiceRows, sortFieldArray)

        onAddRows(invoiceRows)
        // setShowFetchHoursDialog(false)
    }

    function getButtonLabel() {
        let result = i18n('costplus_work') + ' ('

        if (uninvoiceHours) {
            result += formatHours(uninvoiceHours)
        }
        if (uninvoicePrice) {
            if (uninvoiceHours) {
                result += ' + '
            }
            result += formatCurrency(uninvoicePrice)
        }

        result += ')'
        return result
    }

    if ((uninvoiceHours === 0 && uninvoicePrice === 0)) return undefined
    return <>
        <FetchButton
            label={getButtonLabel()}
            // onClick={e => setShowFetchHoursDialog(true)}
            onClick={e => renderOverlay(<FetchCostplusWorkDialog
                timeregList={timeregList}
                timeregarticleList={timeregarticleList}
                onSave={onSave}/>)}
        />
    </>
}
