import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useEffect, useState} from "react";
import {MainButton} from "../../../components/Buttons/Button";
import {useFilter} from "../../../components/Grid/filter";
import {FooterAggregate} from "../../../components/Grid/getFooterGroup";
import Grid from "../../../components/Grid/Grid";
import NumberGridInput from "../../../components/Grid/NumberGridInput";
import onRowChange from "../../../components/Grid/onRowChange";
import TextGridInput from "../../../components/Grid/TextGridInput";
import {hideLoading, showLoading} from "../../../components/Overlay/Loading";
import Tooltip from "../../../components/Overlay/Tooltip";
import Spinner from "../../../components/Spinner/Spinner";
import Icon from "../../../enums/Icon";
import Role from "../../../enums/Role";
import {matchSearch} from "../../../functions/match";
import {GridTemplates} from "../../../GridTemplates";
import useDialog from "../../../hooks/useDialog";
import useEmployees from "../../../hooks/useEmployees";
import usePreload, {PreloadType} from "../../../hooks/usePreload";
import useUserPersmissions from "../../../hooks/useUserPersmissions";
import {i18n} from "../../../I18n/I18n";
import {findById} from "../../../utils/ArrayUtil";
import {formatHours, NumberUtils} from "../../../utils/NumberUtils";
import DayplanningService from "../../Timereg/Dayplannings/DayplanningService";
import TimeregService from "../../Timereg/TimeregService";
import ProjectSummaryTimeregEditDialog from "./ProjectSummaryTimeregEditDialog";

export default function ProjectSummaryTimeregGrid({
                                                      project,
                                                      dayplanningList,
                                                      daylockSet,
                                                      timeregList,
                                                      tabPanelRef
                                                  }) {
    const userPersmissions = useUserPersmissions()
    const preload = usePreload()
    const employees = useEmployees()

    const [_dayplanningList, setDayplanningList] = useState(dayplanningList)
    const [list, setList] = useState()

    const filter = useFilter('ProjectSummaryTimeregGrid', [
        {name: 'search', defaultValue: ''},
    ])

    const filteredList = list?.filter(item => matchSearch(filter, item)) || []

    const timeregsalarytypeList = preload.get(PreloadType.TIMEREGSALARYTYPE_LIST)
    const getSalarytypeName = id => id !== null ? findById(timeregsalarytypeList, id)?.name : ''

    const timeregdebittypeList = preload.get(PreloadType.TIMEREGDEBITTYPE_LIST)
    const getDebittypeName = id => id !== null ? findById(timeregdebittypeList, id)?.name : ''

    const editDialog = useDialog()

    useEffect(() => {
        const _list = []
        _dayplanningList.forEach(dp => {
            dp.timeregList.forEach(tr => {
                _list.push({
                    id: tr.id,
                    dayplanning: dp.id,
                    date: dp.date,
                    employee: tr.employee,
                    employeeName: employees.findById(tr.employee)?.name || '',
                    fixedHours: tr.fixedHours,
                    costplusHours: tr.costplusHours,
                    salarytype: tr.salarytype,
                    debitHours: tr.debitHours,
                    debittype: tr.debittype,
                    notarized: tr.notarized,
                    employeeComment: tr.employeeComment,
                    workDone: dp.workDone,
                    todo: dp.todo,
                    note: dp.note,
                    locked: daylockSet.has(dp.date),
                    invoiced: timeregList.find(item => item.id === tr.id)?.invoicerowId > 0
                })
            })
        })

        setList(_list)
        tabPanelRef.current.updateQuantity(_list.length)
    }, [_dayplanningList, daylockSet, employees, timeregList, tabPanelRef])

    function isCellEditable(e) {
        return !e.rowData.locked
    }

    function isInvoiced(e) {
        return e.rowData.invoiced
    }

    function fireChange(rowList, editorEvent, value) {
        setList(rowList)
    }

    function getTimereg(id) {
        return _dayplanningList.flatMap(dp => dp.timeregList).find(tr => tr.id === id)
    }

    const onTimeregChange = async (editorEvent, value) => {
        const field = editorEvent.field

        if (field === 'employee' && !value) value = undefined

        const rowData = editorEvent.rowData
        value = NumberUtils.round(Number(value), 2);

        const timereg = getTimereg(rowData.id)

        if (timereg[field] !== value) {
            timereg[field] = value

            const salarytype = findById(timeregsalarytypeList, timereg.salarytype)
            if (!salarytype) {
                timereg.salarytype = timeregsalarytypeList[0].id
                console.log("salarytype", salarytype, timereg)
            }

            showLoading()
            await TimeregService.update(timereg)
            hideLoading()
        }

        onRowChange(editorEvent, value, fireChange)
    }

    const onDayplanningChange = async (editorEvent, value) => {
        const field = editorEvent.field
        const rowData = editorEvent.rowData
        const dp = _dayplanningList.find(item => item.id === rowData.dayplanning)

        if (dp[field] !== value) {
            dp[field] = value
            list.filter(item => item.dayplanning === dp.id)
                .forEach(item => {
                    item[field] = value
                })

            showLoading()
            await DayplanningService.update(dp)
            hideLoading()
        }

        onRowChange(editorEvent, value, fireChange)
    }

    const columns = [
        {
            field: 'date', headerI18n: 'date',
            body: rowData => <div style={{
                display: 'flex',
                alignItems: 'center',
                gap: '5px'

            }}>
                {rowData.date}
                {
                    rowData.locked &&
                    <Tooltip value={i18n('day_locked')}>
                        <FontAwesomeIcon icon={Icon.LOCK} style={{color: '#666'}}/>
                    </Tooltip>
                }
            </div>,
            excelBody: (row, column) => row[column.field],
            width: 100
        },
        {
            field: 'employeeName', headerI18n: 'employee',
            // body: row => <Tooltip value={row.invoiced ? i18n('invoiced') : undefined}>
            //     <div className='nowrap' style={{width: '100%'}}>{getEmployeeName(row.employee)}{NBSP}</div>
            // </Tooltip>,
            // excelBody: (row) => getEmployeeName(row.employee),
            // editor: e => isCellEditable(e) && !isInvoiced(e)
            //     ? <SelectEditor event={e} options={filterActive(employeeList)} optionValue='id'
            //                     onChange={e2 => e.editorCallback(e2?.value)}
            //     />
            //     : undefined,
            // onCellEditComplete: e => onTimeregChange(e, e.newValue),
            width: 150
        },
        {
            field: 'fixedHours', header: i18n('fixed_time'),
            body: GridTemplates.hours,
            editor: e => isCellEditable(e)
                ? <NumberGridInput editorEvent={e} min={0} max={999}/>
                : undefined,
            onCellEditComplete: e => onTimeregChange(e, e.newValue),
            align: 'right', width: 60,
            footer: FooterAggregate.SUM_HOURS
        },
        {
            field: 'costplusHours', header: i18n('costplus_time'),
            body: GridTemplates.hours,
            editor: e => isCellEditable(e)
                ? <NumberGridInput editorEvent={e} min={0} max={999}/>
                : undefined,
            onCellEditComplete: e => onTimeregChange(e, e.newValue),
            align: 'right', width: 80,
            footer: FooterAggregate.SUM_HOURS
        },
        {
            field: 'salarytype', header: i18n('salary_type'),
            body: row => <div className='nowrap' style={{width: '100%'}}>{getSalarytypeName(row.salarytype)}</div>,
            excelBody: (row) => getSalarytypeName(row.salarytype),
            // editor: e => isCellEditable(e)
            //     ? <SelectEditor
            //         event={e}
            //         options={filterActive(timeregsalarytypeList)}
            //         optionValue='id'
            //         onChange={e2 => e.editorCallback(e2?.value)}
            //     />
            //     : undefined,
            // onCellEditComplete: e => onTimeregChange(e, e.newValue),
            width: 100,
        },
        {
            field: 'debitHours', header: i18n('debit_time'),
            body: rowData => <Tooltip value={rowData.invoiced ? i18n('invoiced') : undefined}>
                {formatHours(rowData.debitHours)}
            </Tooltip>,
            excelBody: (row, column) => row[column.field],
            editor: e => isCellEditable(e) && !isInvoiced(e)
                ? <NumberGridInput editorEvent={e} min={0} max={999}/>
                : undefined,
            onCellEditComplete: e => onTimeregChange(e, e.newValue),
            align: 'right', width: 100,
            footer: FooterAggregate.SUM_HOURS
        },
        {
            field: 'debittype', header: i18n('debit_type'),
            body: row => <Tooltip value={row.invoiced ? i18n('invoiced') : undefined}>
                <div className='nowrap' style={{width: '100%'}}>{getDebittypeName(row.debittype)}</div>
            </Tooltip>,
            excelBody: (row) => getDebittypeName(row.debittype),
            // editor: e => isCellEditable(e) && !isInvoiced(e)
            //     ? <SelectEditor
            //         event={e}
            //         options={filterActive(timeregdebittypeList)}
            //         optionValue='id'
            //         onChange={e2 => e.editorCallback(e2?.value, true)}
            //     />
            //     : undefined,
            // onCellEditComplete: e => onTimeregChange(e, e.newValue),
            width: 100
        },
        {
            field: 'workDone', header: i18n('work_done'), body: GridTemplates.multilineString,
            editor: e => isCellEditable(e) ? <TextGridInput editorEvent={e} maxLength={1000}/> : undefined,
            onCellEditComplete: e => onDayplanningChange(e, e.newValue)
        },
        {
            field: 'todo', header: i18n('todo'), body: GridTemplates.multilineString,
            editor: e => isCellEditable(e) ? <TextGridInput editorEvent={e} maxLength={1000}/> : undefined,
            onCellEditComplete: e => onDayplanningChange(e, e.newValue)
        },
        {
            field: 'note', header: i18n('note'), body: GridTemplates.multilineString,
            editor: e => isCellEditable(e) ? <TextGridInput editorEvent={e} maxLength={1000}/> : undefined,
            onCellEditComplete: e => onDayplanningChange(e, e.newValue)
        },
        {field: 'employeeComment', header: i18n('comment_from_employee')},
    ]

    if (!filteredList) return <Spinner/>

    const onEdit = e => {
        editDialog.show(<ProjectSummaryTimeregEditDialog
            project={project}
            model={e?.data}
            onSave={async () => {
                showLoading()
                setDayplanningList(await DayplanningService.findByProject(project.id))
                hideLoading()
            }}
        />)
    }

    const leftFilters = [
        userPersmissions.hasRole(Role.TIMEREG_WRITE) ? <div>
            <label className="label">&nbsp;</label>
            <MainButton faicon={faPlus}
                        onClick={onEdit}/>
        </div> : undefined,
    ]

    return <>
        <Grid name={project.name + ' - ' + i18n('time_registration')}
              scrollable={true}
              columns={columns}
              value={filteredList}
              leftFilters={leftFilters}
              filter={filter}
              updateFilter={filter.update}
              onRowSelect={onEdit}
              heightOffset={520}
              rowClassName={e => ({'redRow': !e.notarized})}
        />
        {editDialog.render()}
    < />
}