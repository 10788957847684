import React, {useCallback, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {MainButton} from "../../../components/Buttons/Button";
import DatePicker from "../../../components/Date/DatePicker";
import Checkbox from "../../../components/EditForm/Checkbox";
import Label from "../../../components/EditForm/Label";
import AppendixColumn from "../../../components/Grid/AppendixColumn";
import {useFilter} from "../../../components/Grid/filter";
import Grid from "../../../components/Grid/Grid";
import {hideLoading, showLoading} from "../../../components/Overlay/Loading";
import XlnzDropdown from "../../../components/XlnzDropdown/XlnzDropdown";
import Icon from "../../../enums/Icon";
import {match, matchOnlyEmployees, matchSearch} from "../../../functions/match";
import {GridTemplates} from "../../../GridTemplates";
import useEmployees from "../../../hooks/useEmployees";
import usePreload from "../../../hooks/usePreload";
import {i18n} from "../../../I18n/I18n";
import {isBefore, todayDate} from "../../../utils/DateUtils";
import NetUtils from "../../../utils/NetUtils";
import EmployeeService from "../../Employees/EmployeeService";

const TrainingsReport = () => {
    const filter = useFilter('TrainingsReport', [
        {name: 'date', defaultValue: todayDate()},
        {name: 'trainingtype', defaultValue: undefined},
        {name: 'onlyEmployees', defaultValue: false},
        {name: 'subcontractor', defaultValue: undefined},
        {name: 'search', defaultValue: ''},
    ])
    const [list, setList] = useState([])

    const preload = usePreload()
    const trainingtypeList = preload.getTrainingtypeList()
    const subcontractorList = preload.getSupplierList().filter(item => item.subcontractor)

    const employees = useEmployees()

    const navigate = useNavigate()

    const filteredList = list.filter(e =>
        matchOnlyEmployees(e, filter.onlyEmployees) &&
        (filter.onlyEmployees || match(filter, e, 'subcontractor')) &&
        matchSearch(filter, e)
    )

    const loadData = useCallback(() => {
        showLoading()
        NetUtils.doGet('/api/reports/trainings', {
            date: filter.date,
            trainingtype: filter.trainingtype,
            employees: employees.activeUsable.map(item => item.id),
        }).then(res => {
            setList(res)
            hideLoading()
        });
    }, [filter.date, filter.trainingtype, employees]);

    const columns = [
        AppendixColumn(rowData => rowData.fileSize > 0,
            rowData => rowData.filename,
            async rowData => (await EmployeeService.getImageUrl(rowData.employeeId, rowData.id)).url
        ),
        {field: 'employeeName', headerI18n: 'employee', minWidth: 150},
        {field: 'trainingtypeName', headerI18n: 'training_type', minWidth: 150},
        {field: 'date', headerI18n: 'date', width: 80},
        {field: 'location', headerI18n: 'location', width: 150},
        {field: 'approved', headerI18n: 'approved', width: 70, body: GridTemplates.booleanHideFalse},
        {field: 'certificateIssued', headerI18n: 'certificate_issued', width: 90, body: GridTemplates.booleanHideFalse},
        {
            field: 'validUntil', headerI18n: 'valid_until',
            body: rowData =>
                <span style={{
                    color: isBefore(rowData.validUntil, filter.date) ? 'red' : undefined,
                }}>{rowData.validUntil}</span>,
            excelBody: rowData => rowData.validUntil,
            width: 80
        },
        {field: 'note', headerI18n: 'note'}
    ]

    const leftFilters = [
        <div>
            {
                trainingtypeList?.length > 0 &&
                <>
                    <Label valueI18n='training_type'/>
                    <XlnzDropdown
                        value={filter.trainingtype}
                        options={trainingtypeList}
                        optionLabel='name'
                        showClear={true}
                        placeholder={i18n('select') + '...'}
                        optionValue='id'
                        onChange={e => filter.update('trainingtype', e.value)}
                        style={{width: '250px'}}/>
                </>
            }
        </div>,
        <div>
            <Label valueI18n='date'/>
            <DatePicker value={filter.date} onChange={date => filter.update('date', date)}/>
        </div>,
        <div>
            <Label valueI18n='only_employees'/>
            <Checkbox checked={filter.onlyEmployees} onChange={e => filter.update('onlyEmployees', e.target.checked)}
                      style={{marginTop: '5px'}}/>
        </div>,
        <div>
            {
                !filter.onlyEmployees &&
                <>
                    <Label valueI18n='subcontractor'/>
                    <XlnzDropdown
                        value={filter.subcontractor}
                        options={subcontractorList}
                        showClear={true}
                        placeholder={i18n('select') + '...'}
                        optionValue='id'
                        optionLabel='name'
                        onChange={e => filter.update('subcontractor', e.value)}
                        style={{width: '250px'}}/>
                </>
            }
        </div>,
        <div>
            <label className="label">&nbsp;</label>
            <MainButton labelI18n='create_report' onClick={() => loadData()}/>
        </div>
    ]

    return <div className="Grid" style={{padding: '10px 0'}}>
        <Grid
            id='TrainingsReport'
            forceDesktop={true}
            labelI18n='trainings'
            icon={Icon.REPORT}
            leftFilters={leftFilters}
            filter={filter}
            updateFilter={filter.update}
            columns={columns}
            value={filteredList}
            onRowSelect={item => navigate('/employees/' + item.data.employeeId)}
        />
    </div>
}

export default TrainingsReport;