import React, {useState} from 'react';
import {HashRouter as Router, Navigate, Route, Routes} from "react-router-dom";
import './App.scss';
import AppContext from "./AppContext";
import Header from "./components/Header/Header";
import Login from "./components/Login/Login";
import {InitSpinner} from "./components/Spinner/Spinner";
import Companyprop from "./enums/Companyprop";
import Currency from "./enums/Currency";
import {uuidv4} from "./functions/makeId";
import useMountEffect from "./hooks/useMountEffect";
import usePreload from "./hooks/usePreload";
import useRoles from "./hooks/useRoles";
import useSecurity from "./hooks/useSecurity";
import useUserPersmissions from "./hooks/useUserPersmissions";
import {initI18n} from "./I18n/I18n";
import {allModules, modules} from "./modules/modules";
import UserService from "./modules/Users/UserService";
import {sortByField} from "./utils/ArrayUtil";
import LocalStorageUtils from "./utils/LocalStorageUtils";
import SessionStorageUtils from "./utils/SessionStorageUtils";

export const APP_NAME = 'Verktyg1'
export const NBSP = '\u00A0'

export let COMPANY
export let CURRENCY

function initDeviceId() {
    if (!LocalStorageUtils.getItem("deviceId")) {
        LocalStorageUtils.setItem("deviceId", uuidv4())
    }
}

initDeviceId()


const App = () => {
    const [state, setState] = useState({
        initialized: false,
        user: undefined,
        companies: undefined,
        currentCompany: undefined
    })

    const security = useSecurity()
    const userPersmissions = useUserPersmissions()
    const roles = useRoles('App')
    const preload = usePreload()

    const signIn = async (loginInfo) => {
        if (loginInfo.companyList.length === 0) {
            alert("No company access.")
            security.signOut('no-company-access');
        }
        loginInfo.user.superAdmin = loginInfo.user.id === 1;

        loginInfo.companyList.forEach(c => c.type = 'COMPANY');
        const companies = loginInfo.companyList

        var currentCompany;
        let companyId = Number(SessionStorageUtils.getItem('company', 0))
        if (companyId === 0) {
            companyId = Number(LocalStorageUtils.getItem('company', 0))
        }
        if (companyId > 0) currentCompany = companies.find(r => r.id === companyId)

        function getDefaultCompany(loginInfo) {
            loginInfo.companyList.forEach(c => {
                c.priority = Number(c.propList.find(p => p.name === Companyprop.PRIORITY.name)?.value) || 0
            })
            const cList = [...loginInfo.companyList]
            sortByField(cList, 'priority').reverse()
            return cList[0]
        }

        if (!currentCompany && loginInfo.companyList.length > 0) currentCompany = getDefaultCompany(loginInfo)

        console.log("SELECTED COMPANY:", currentCompany.name)
        LocalStorageUtils.setItem("company", currentCompany.id)
        SessionStorageUtils.setItem("company", currentCompany.id)
        document.title = APP_NAME + ' - ' + currentCompany.name;

        COMPANY = currentCompany
        CURRENCY = Currency.findById(currentCompany.currency)

        const userPermissions = await UserService.getPermissions()
        userPersmissions.set(userPermissions)

        roles.set(userPermissions.roleList)
        console.info("ROLES:", roles.getAll().join(", "))

        if (userPermissions.restrictPathList.length > 0) console.info("RESTRICT PATHS:", userPermissions.restrictPathList.join(", "));

        await preload.loadAll()

        setState(Object.assign({}, state, {
            initialized: true,
            user: loginInfo.user,
            companies: companies,
            currentCompany: currentCompany,
        }))
    }

    useMountEffect(async () => {
        window.SERVER_URL = ''
        security.init()

        const loginInfo = await UserService.getLoginInfo()
        if (loginInfo) {
            console.log("USER:", loginInfo.user.email)
            await initI18n(loginInfo.user)
            signIn(loginInfo);
        } else {
            await initI18n()
            LocalStorageUtils.removeItem("company");
            setState({initialized: true})
        }
    })

    const isModuleAccessible = m => {
        if (m.roles && m.roles[0] === undefined) console.log("Invalid module roles", m)
        return (!m.roles || userPersmissions.hasRoles(m.roles)) &&
            userPersmissions.hasRestrictPath(m.routeProps.path)
    }

    const getDefaultRoute = () => {
        const defaultModule = modules.find(m => {
                return userPersmissions.hasRoles(m.roles) &&
                    userPersmissions.hasRestrictPath(m.routeProps.path)
            }
        )
        return defaultModule?.routeProps.path ?? ''
    }

    let view;
    if (!state.user && state.initialized) {
        return <div className="AppContainer">
            <div id='App' className="App"><Login/></div>
        </div>
    } else if (state.user && state.initialized && !state.companies) {
        view = <h1>Du har inte tillgång till några företag</h1>
    } else if (!state.initialized) {
        view = <InitSpinner/>
    } else if (state.user && state.companies && state.companies.length > 0) {
        view = <Router>
            <div className="AppContainer">
                <div id='App' className="App">
                    <div className="container">
                        <Header/>
                        <div className="content" style={{
                            // Not working when resizing dayplanning. Dont know why this is needed? 2022-11-08
                            //height: window.innerHeight - 64 - 0
                        }}>
                            <Routes>
                                {allModules
                                    .filter(m => isModuleAccessible(m))
                                    .map(m => <Route {...m.routeProps} key={m.routeProps.path}/>)}

                                {allModules
                                    .filter(m => isModuleAccessible(m))
                                    .filter(m => m.routeProps.items?.length > 0)
                                    .flatMap(m => m.routeProps.items)
                                    .map(i => <Route {...i} key={i.path}/>)}

                                {allModules
                                    .filter(m => isModuleAccessible(m))
                                    .filter(m => m.subModules)
                                    .map(m => m.subModules.map(sub => <Route {...sub.routeProps}
                                                                             key={sub.routeProps.path}/>))}

                                <Route path="*" element={<Navigate to={getDefaultRoute()} replace/>}/>
                            </Routes>
                        </div>
                    </div>
                </div>
            </div>
        </Router>
    } else {
        view = <Login/>
    }

    return (
        <AppContext.Provider value={state}>
            <React.StrictMode>
                {view}
            </React.StrictMode>
        </AppContext.Provider>
    );
}
export default App