import React, {useState} from "react";
import {FetchButton} from "../../../../components/Buttons/Button";
import renderOverlay from "../../../../components/Overlay/renderOverlay";
import Companyprop from "../../../../enums/Companyprop";
import Vat from "../../../../enums/Vat";
import useCompanyProp from "../../../../hooks/useCompanyProp";
import useDepsEffect from "../../../../hooks/useEffect";
import usePreload from "../../../../hooks/usePreload";
import {i18n} from "../../../../I18n/I18n";
import {findById, sum} from "../../../../utils/ArrayUtil";
import {getDiscountFactor, NumberUtils, round} from "../../../../utils/NumberUtils";
import {deepCopy} from "../../../../utils/ObjectUtils";
import TenderService from "../../../Tenders/TenderService";
import {addTitle} from "../FetchHelper";
import FetchTenderoptionsDialog from "./FetchTenderoptionsDialog";

export const FetchTenderoptionsButton = props => {
    const {
        project,
        rowList,
        tender,
        onAddRows
    } = props

    const [uninvoicePrice, setUninvoicePrice] = useState(0)
    const [allTenderoptionList, setAllTenderoptionList] = useState(0)
    const [tenderoptionList, setTenderoptionList] = useState(0)

    const preload = usePreload()
    const accountList = preload.getAccountList()
    const ACCOUNT_NUMBER_TENDER = useCompanyProp(Companyprop.ACCOUNT_NUMBER_TENDER)

    // When project is changed
    useDepsEffect(() => {
        if (project && project.id > 0 && project.tender > 0) {
            TenderService.findOptionsByProject(project.id).then(_tenderoptionList => {
                _tenderoptionList = filterTenderoptionsInOtherInvoices(_tenderoptionList)
                setAllTenderoptionList(_tenderoptionList)
            })
        }
    }, [project?.id])

    // When invoice rows are changed
    useDepsEffect(() => {
        if (allTenderoptionList && project && project.id > 0) {
            let _tenderoptionList = deepCopy(allTenderoptionList)

            decreaseQuantityOfUnsavedRowsInThisInvoice(_tenderoptionList)
            calculateLeftToInvoice(_tenderoptionList)

            setTenderoptionList(_tenderoptionList)
            const _leftToInvoicePrice = sum(_tenderoptionList, 'leftToInvoicePrice');
            setUninvoicePrice(_leftToInvoicePrice)
        }
    }, [allTenderoptionList, rowList])

    const filterTenderoptionsInOtherInvoices = tenderoptionList => {
        tenderoptionList.forEach(tr => {
            tr.leftToInvoicePrice = tr.price

            const proposalIrtrList = tr.invoicerowtenderoptionList
            proposalIrtrList.forEach(proposalIrtr => {
                if (proposalIrtr.invoicerow > 0 && !isInThisInvoice(proposalIrtr)) {
                    tr.leftToInvoicePrice -= proposalIrtr.price
                }
            })
        })

        return tenderoptionList
    }

    function isInThisInvoice(proposalIrtr) {
        return getInvoiceIrtrList().filter(invoiceIrtr => proposalIrtr.invoicerow === invoiceIrtr.invoicerow &&
            proposalIrtr.tenderoption === invoiceIrtr.tenderoption).length > 0
    }

    function getInvoiceIrtrList() {
        return rowList
            .flatMap(r => r.tenderoptionList)
            .filter(invoiceIrtr => invoiceIrtr !== undefined)
    }

    function decreaseQuantityOfUnsavedRowsInThisInvoice(_tenderoptionList) {
        getInvoiceIrtrList().forEach(invoiceIrtr => {
            const _tr = findById(_tenderoptionList, invoiceIrtr.tenderoption)
            _tr.leftToInvoicePrice -= invoiceIrtr.price
        })
    }

    function calculateLeftToInvoice(tenderoptionList) {
        tenderoptionList.forEach(_tr => _tr.priceToInvoice = 0)
    }

    function getAccountId(tr) {
        if (tr.accountId) {
            return tr.accountId
        } else {
            if (ACCOUNT_NUMBER_TENDER) {
                return accountList.find(a => a.number === Number(ACCOUNT_NUMBER_TENDER))?.id
            }
        }
    }

    const onSave = (tenderoptionList, config) => {
        const invoiceRows = []
        addTitle(rowList, config, invoiceRows, i18n('tender') + ' ' + tender?.number + ' - ' + i18n('options'))
        console.log("tenderoptionList", tenderoptionList)
        tenderoptionList.forEach(tr => {
            const invoicerowtenderoptionList = []
            invoicerowtenderoptionList.push({
                invoicerow: 0,
                tenderoption: tr.id,
                price: tr.priceToInvoice
            })

            const quantity = round(tr.priceToInvoice / tr.aprice / getDiscountFactor(tr.discount), 2)
            const comesOutEven = quantity * tr.aprice * getDiscountFactor(tr.discount) === tr.priceToInvoice

            invoiceRows.push({
                headline: tr.headline,
                onlyTotal: tr.onlyTotal,
                housework: tr.housework,
                name: tr.name,
                unit: tr.unit,
                quantity: comesOutEven ? quantity : 1,
                aprice: comesOutEven ? tr.aprice : tr.priceToInvoice,
                discount: comesOutEven ? tr.discount : 0,
                price: tr.priceToInvoice,
                vat: Vat.VAT25.id,
                account: getAccountId(tr),
                costcenter: project.costcenter,
                tenderoptionList: invoicerowtenderoptionList
            })
        })
        onAddRows(invoiceRows)
    }

    if (uninvoicePrice === 0) return undefined
    return <>
        <FetchButton
            label={i18n('option') + ' (' + NumberUtils.formatCurrency(uninvoicePrice) + ')'}
            onClick={e => {
                tenderoptionList.forEach(item => {
                    item.quantityToInvoice = 0
                    item.priceToInvoice = 0
                })
                renderOverlay(<FetchTenderoptionsDialog
                    tenderoptionList={tenderoptionList}
                    onSave={onSave}/>)
            }}
        />
    </>
}