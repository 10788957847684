import {i18n} from "../I18n/I18n";
import ArrayUtil from "../utils/ArrayUtil";

export default class Companyprop {
    static get TEMP_PROJECT_EXPANDABLE() {
        return Object.freeze({
            name: 'temp-project-expandableList', label: 'TEMP Expandable Project List', type: 'boolean'
        });
    }

    static get ACCOUNT_NUMBER_DEBIT_TIME() {
        return Object.freeze({name: 'accountNumber-debitTime', label: 'Account number debit time'});
    }

    static get ACCOUNT_NUMBER_DEBIT_TIME_REVERSED_VAT() {
        return Object.freeze({
            name: 'accountNumber-debitTimeReversedVat',
            label: 'Account number debit time reversed VAT'
        });
    }

    static get ACCOUNT_NUMBER_TENDER() {
        return Object.freeze({name: 'accountNumber-tender', label: 'Account number quote rows'});
    }

    static get ACCOUNT_NUMBER_RENT() {
        return Object.freeze({name: 'accountNumber-rent', label: 'Account number rent'});
    }

    static get ACCOUNT_SUPPLIER_INVOICE() {
        return Object.freeze({name: 'accountNumber-supplierInvoice', label: 'Account number supplier invoice'});
    }

    static get ACCOUNT_SUPPLIER_INVOICE_REVERSED_VAT() {
        return Object.freeze({
            name: 'accountNumber-supplierInvoiceReversedVat',
            label: 'Account number supplier invoice reversed VAT'
        });
    }

    static get APPFORLEAVE_NOTIFICATION_EMAILS() {
        return Object.freeze({
            name: 'appforleave-notificationEmails',
            label: 'Application for leave - Notification emails (comma separated)'
        });
    }

    static get PRIORITY() {
        return Object.freeze({name: 'priority', label: 'Priority reagarding selecting companies after sign in'});
    }

    static get DEPARTMENT() {
        return Object.freeze({name: 'department', label: 'Department-ID'});
    }

    static get PROJECT_NO() {
        return Object.freeze({name: 'projectNo', label: 'Project number'});
    }

    static get OBJECT_NO() {
        return Object.freeze({name: 'objectNo', label: 'Object number'});
    }

    static get CUSTOM_SUPPLIER_INVOICE_EMAIL() {
        return Object.freeze({
            name: 'customSupplierInvoiceEmail',
            label: 'Custom e-mail address for incoming in supplier invoices'
        });
    }

    static get CUSTOMER_PAYMENT_TERMS_COMPANY() {
        return Object.freeze({
            name: 'customerPaymentTermsCompany',
            label: 'Customer - Payment Terms Company'
        });
    }

    static get CUSTOMER_PAYMENT_TERMS_PRIVATE() {
        return Object.freeze({
            name: 'customerPaymentTermsPrivate',
            label: 'Customer - Payment Terms Private Person'
        });
    }

    static get EMP_TIMEREG_ENABLE_DEBIT_TYPES() {
        return Object.freeze({
            name: 'empTimereg-enableDebitTypes',
            label: 'EmpTimereg - Enable debit types',
            type: 'boolean'
        });
    }

    static get EMP_TIMEREG_REQUIRE_COMMENT() {
        return Object.freeze({
            name: 'empTimereg-requireComment',
            label: 'EmpTimereg - Require comment',
            type: 'boolean'
        });
    }

    static get EMP_TIMEREG_ONLY_PLANNED_REGISTRATIONS() {
        return Object.freeze({
            name: 'empTimereg-onlyPlannedRegistrations',
            label: 'EmpTimereg - Only planned registrations',
            type: 'boolean'
        });
    }

    static get FORTNOX_INVOICE_SEPARATE_APPENDICES() {
        return Object.freeze({
            name: 'fortnox-invoiceSeparateAppendices',
            label: 'Fortnox Invoice - Separate Appendices',
            type: 'boolean'
        });
    }

    static get FORTNOX_INVOICE_FACTORING_TEMPLATE_ID() {
        return Object.freeze({
            name: 'fortnox-invoiceFactoringTemplateId',
            label: 'Fortnox Invoice - Factoring Template ID'
        });
    }

    static get FORTNOX_INVOICE_SYNC_START() {
        return Object.freeze({name: 'fortnox-invoiceSyncStart', label: 'Fortnox Invoice - Number to start sync from'});
    }

    static get FORTNOX_SUPPLIER_INVOICE_SYNC_START() {
        return Object.freeze({
            name: 'fortnox-supplierInvoiceSyncStart',
            label: 'Fortnox Supplier Invoice - Number to start sync from'
        });
    }

    static get FORTNOX_USE_PROJECTS() {
        return Object.freeze({
            name: 'fortnox-useProjects',
            label: 'Fortnox - Use Projects',
            type: 'boolean'
        });
    }

    static get HYRLET_CUSTOMER_ID() {
        return Object.freeze({
            name: 'hyrlet-customerId',
            label: 'Hyrlet - Customer ID'
        });
    }

    static get INVOICE_HIDE_PRICES() {
        return Object.freeze({
            name: 'invoice-hidePrices',
            label: 'Invoice - Hide prices',
            type: 'boolean'
        });
    }

    static get INVOICE_INTEREST_ON_OVERDUE_PAYMENT() {
        return Object.freeze({
            name: 'invoice-interestOnOverduePayment',
            label: 'Invoice - Interest on overdue payment (%)'
        });
    }

    static get INVOICE_TEXT() {
        return Object.freeze({
            name: 'invoice-text',
            label: 'Invoice - Text'
        });
    }

    static get INVOICE_FACTORING_TEXT() {
        return Object.freeze({
            name: 'invoice-factoringText',
            label: 'Invoice - Factoring text'
        });
    }

    static get INVOICE_USE_INVOICING_DATA() {
        return Object.freeze({
            name: 'invoice-useInvoicingData',
            label: 'Invoice - Use Invoicing Data',
            type: 'boolean'
        });
    }

    static get PROJECT_EMP_TIMEREG_HIDE() {
        return Object.freeze({
            name: 'project-empTimereg-hide',
            label: 'New project - Timereg hide',
            type: 'boolean'
        });
    }

    static get PROJECT_EMP_TIMEREG_FIXED_TIME_DISABLED() {
        return Object.freeze({
            name: 'project-empTimereg-fixedTimeDisabled',
            label: 'New project - Timereg fixed time disabled',
            type: 'boolean'
        });
    }

    static get PROJECT_EMP_TIMEREG_COSTPLUS_TIME_DISABLED() {
        return Object.freeze({
            name: 'project-empTimereg-costplusTimeDisabled',
            label: 'New project - Timereg costplus time disabled',
            type: 'boolean'
        });
    }

    static get PYRAMID() {
        return Object.freeze({
            name: 'pyramid',
            label: 'Using Pyramid ERP',
            type: 'boolean'
        });
    }

    static get PYRAMID_ARTICLE_NO_CSV() {
        return Object.freeze({
            name: 'pyramid-articleNoCsv',
            label: 'Allowed article numbers in export'
        });
    }

    static get PYRAMID_SYNC_CUSTOMERS() {
        return Object.freeze({
            name: 'pyramid-syncCustomers',
            label: 'Sync Customers',
            type: 'boolean'
        });
    }

    static get PYRAMID_SYNC_PROJECT_WITH_OBJNO() {
        return Object.freeze({
            name: 'pyramid-syncProjectWithObjno',
            label: 'Sync Projects with Object Numbers (CSV)',
        });
    }

    static get PYRAMID_SYNC_COSTCENTERS() {
        return Object.freeze({
            name: 'pyramid-syncCostcenters',
            label: 'Sync Costcenters',
            type: 'boolean'
        });
    }

    static get PYRAMID_SYNC_TIMEREGS() {
        return Object.freeze({
            name: 'pyramid-syncTimeregs',
            label: 'Sync Timeregs',
            type: 'boolean'
        });
    }

    static get RECO_VENUE_ID() {
        return Object.freeze({
            name: 'reco-venueId',
            label: 'Reco - Venue ID'
        });
    }

    static get STIB_AUTHORIZED() {
        return Object.freeze({
            name: 'stib-authorized',
            label: 'STIB authorized',
            type: 'boolean'
        });
    }

    static get SUPPLIERINVOICE_DENIED_ATTEST_NOTIFICATION_EMAILS() {
        return Object.freeze({
            name: 'supplierinvoice-deniedAttestNotificationEmails',
            label: 'Supplierinvoice - Denied attest notification E-mail (comma separated)'
        });
    }


    static get TENDER_DAYS_UNTIL_AUTO_ANSWER() {
        return Object.freeze({name: 'tender-daysUntilAutoAnswer', label: 'Tender - Days until auto-answer'});
    }

    static get TENDER_EXPECTED_MARGIN() {
        return Object.freeze({name: 'tender-expectedMargin', label: 'Tender - Expected margin %'});
    }

    static get TENDER_MESSAGE() {
        return Object.freeze({name: 'tender-message', label: i18n('tender') + ' - ' + i18n('message_to_customer')});
    }

    static get TENDER_PURCHASE_PERCENTAGE_MARKUP() {
        return Object.freeze({name: 'tender-purchasePercentageMarkup', label: 'Tender - Purchase percentage markup'});
    }

    static get TENDER_RENT_ROW0() {
        return Object.freeze({name: 'tender-rentRow0', label: i18n('tender') + ' - Rent Row 0'});
    }

    static get TENDER_REQUIRE_KMA() {
        return Object.freeze({
            name: 'tender-requireKma',
            label: 'Tender - Require KMA (NOT IMPLEMENTED!)',
            type: 'boolean'
        });
    }

    static get TENDER_DONT_USE_STALLNING19() {
        return Object.freeze({
            name: 'tender-dontUseStallning19',
            label: 'Tender - Dont use Ställning 19',
            type: 'boolean'
        });
    }

    static get TENDER_VALIDITY_TIME() {
        return Object.freeze({name: 'tender-validityTime', label: 'Tender - Validity time'});
    }

    static get RENT_DEFAULT_STRATEGY() {
        return Object.freeze({
            name: 'rent-default-strategy',
            label: 'Rent - Default Strategy'
        });
    }

    static get RENT_WORK_DAY_STRATEGY() {
        return Object.freeze({
            name: 'rent-workDayStrategy',
            label: 'Rent - Work Day Strategy',
            type: 'boolean'
        });
    }

    static values() {
        return ArrayUtil.sortOnField([
            this.TEMP_PROJECT_EXPANDABLE,

            this.ACCOUNT_NUMBER_DEBIT_TIME,
            this.ACCOUNT_NUMBER_DEBIT_TIME_REVERSED_VAT,
            this.ACCOUNT_NUMBER_TENDER,
            this.ACCOUNT_NUMBER_RENT,
            this.ACCOUNT_SUPPLIER_INVOICE,
            this.ACCOUNT_SUPPLIER_INVOICE_REVERSED_VAT,
            this.APPFORLEAVE_NOTIFICATION_EMAILS,
            this.PRIORITY,
            this.DEPARTMENT,
            this.PROJECT_NO,
            this.OBJECT_NO,
            this.CUSTOM_SUPPLIER_INVOICE_EMAIL,
            this.CUSTOMER_PAYMENT_TERMS_COMPANY,
            this.CUSTOMER_PAYMENT_TERMS_PRIVATE,
            this.EMP_TIMEREG_ENABLE_DEBIT_TYPES,
            this.EMP_TIMEREG_REQUIRE_COMMENT,
            this.EMP_TIMEREG_ONLY_PLANNED_REGISTRATIONS,
            this.FORTNOX_INVOICE_SEPARATE_APPENDICES,
            this.FORTNOX_INVOICE_FACTORING_TEMPLATE_ID,
            this.FORTNOX_INVOICE_SYNC_START,
            this.FORTNOX_SUPPLIER_INVOICE_SYNC_START,
            this.FORTNOX_USE_PROJECTS,
            this.HYRLET_CUSTOMER_ID,
            this.INVOICE_HIDE_PRICES,
            this.INVOICE_INTEREST_ON_OVERDUE_PAYMENT,
            this.INVOICE_TEXT,
            this.INVOICE_FACTORING_TEXT,
            this.INVOICE_USE_INVOICING_DATA,
            this.PROJECT_EMP_TIMEREG_HIDE,
            this.PROJECT_EMP_TIMEREG_FIXED_TIME_DISABLED,
            this.PROJECT_EMP_TIMEREG_COSTPLUS_TIME_DISABLED,

            this.PYRAMID,
            this.PYRAMID_ARTICLE_NO_CSV,
            this.PYRAMID_SYNC_CUSTOMERS,
            this.PYRAMID_SYNC_PROJECT_WITH_OBJNO,
            this.PYRAMID_SYNC_COSTCENTERS,
            this.PYRAMID_SYNC_TIMEREGS,

            this.RECO_VENUE_ID,
            this.RENT_DEFAULT_STRATEGY,
            this.RENT_WORK_DAY_STRATEGY,
            this.STIB_AUTHORIZED,
            this.SUPPLIERINVOICE_DENIED_ATTEST_NOTIFICATION_EMAILS,
            this.TENDER_DAYS_UNTIL_AUTO_ANSWER,
            this.TENDER_EXPECTED_MARGIN,
            this.TENDER_MESSAGE,
            this.TENDER_PURCHASE_PERCENTAGE_MARKUP,
            this.TENDER_RENT_ROW0,
            this.TENDER_REQUIRE_KMA,
            this.TENDER_DONT_USE_STALLNING19,
            this.TENDER_VALIDITY_TIME,
        ], 'label');
    }

    static findById(name) {
        return this.values().filter(item => item.name === name)[0]
    }
}