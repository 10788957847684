import React, {useEffect, useState} from 'react';
import {AddButton} from "../../../components/Buttons/Button";
import XlnzDropdown from "../../../components/XlnzDropdown/XlnzDropdown";
import YearFilter from "../../../components/filters/YearFilter";
import {useFilter} from "../../../components/Grid/filter";
import Grid from "../../../components/Grid/Grid";
import {hideLoading, showLoading} from "../../../components/Overlay/Loading";
import Role from "../../../enums/Role";
import {match, matchSearch} from "../../../functions/match";
import {GridTemplates} from "../../../GridTemplates";
import usePreload from "../../../hooks/usePreload";
import useRoles from "../../../hooks/useRoles";
import {i18n} from "../../../I18n/I18n";
import {findById} from "../../../utils/ArrayUtil";
import {todayYear} from "../../../utils/DateUtils";
import AppforleaveService from "./AppforleaveService";
import AppforleaveStatus from "./AppforleaveStatus";

const AppforleaveGrid = () => {
    const roles = useRoles()
    const preload = usePreload()
    const employeeList = preload.getEmployeeList()
    const timeregabsencetypeList = preload.getTimeregabsencetypeList()

    const filter = useFilter('AppforleaveGrid', [
        {name: 'status', defaultValue: AppforleaveStatus.DRAFT.id},
        {name: 'year', defaultValue: todayYear()},
        {name: 'search', defaultValue: ''},
    ])

    const [list, setList] = useState([]);
    const filteredList = list.filter(c =>
        match(filter, c, 'status') &&
        (filter.status || c.status !== AppforleaveStatus.CANCELLED.id) &&
        matchSearch(filter, c)
    )

    filteredList.forEach(item => {
        item.employeeName = findById(employeeList, item.employee).name
        item.timeregabsencetypeName = findById(timeregabsencetypeList, item.timeregabsencetype).name
        item.statusName = AppforleaveStatus.getName(item.status)
    })

    useEffect(() => {
        const loadData = async () => {
            showLoading()
            const params = {
                year: filter.year
            }
            setList(await AppforleaveService.findAll(params))
            hideLoading()
        };
        loadData()
    }, [filter.year])

    const leftFilters = [
        roles.hasRole(Role.ADMIN) ? <div>
            <label className="label">&nbsp;</label>
            <AddButton/>
        </div> : undefined,
        <div>
            <label className="label">{i18n('year')}</label>
            <YearFilter value={filter.year} onChange={e => filter.update('year', e.value)}
            />
        </div>,
        <div>
            <label className="label">{i18n('status')}</label>
            <XlnzDropdown
                value={filter.status}
                options={AppforleaveStatus.values()}
                optionLabel='namePlural'
                optionValue='id'
                showClear={true}
                placeholder={i18n('select') + '...'}
                onChange={e => filter.update('status', e.value)}
                style={{width: '180px'}}/>
        </div>
    ]

    const columns = [
        {field: 'ctime', headerI18n: 'created', body: GridTemplates.time, width: 140, mobile: true},
        {field: 'employeeName', headerI18n: 'employee', mobile: true},
        {field: 'timeregabsencetypeName', headerI18n: 'absence_type', mobile: true},
        {field: 'startDate', headerI18n: 'start_date', width: 80},
        {field: 'endDate', headerI18n: 'end_date', width: 80},
        {field: 'hoursPerDay', headerI18n: 'hours_per_day', body: GridTemplates.hours, width: 100, align: 'right'},
        {field: 'employeeComment', headerI18n: 'comment_from_employee'},
        {field: 'note', headerI18n: 'note'},
        {field: 'statusName', headerI18n: 'status'},
        {field: 'importedTime', headerI18n: 'imported', body: GridTemplates.time, width: 140, mobile: true},
    ];

    return <Grid
        labelI18n='applications_for_leave'
        leftFilters={leftFilters}
        filter={filter}
        updateFilter={filter.update}
        columns={columns}
        value={filteredList}
    />
}

export default AppforleaveGrid;
