import React from 'react';
import DateEditor from "../../../components/Grid/DateEditor";
import {addFreeRows} from "../../../components/Grid/freeRows";
import Grid from "../../../components/Grid/Grid";
import NumberGridInput from "../../../components/Grid/NumberGridInput";
import onRowChange from "../../../components/Grid/onRowChange";
import RemoveColumn from "../../../components/Grid/RemoveColumn";
import {GridTemplates} from "../../../GridTemplates";
import {todayDate} from "../../../utils/DateUtils";
import {isNumeric, round} from "../../../utils/NumberUtils";

const NUM_OF_FREE_ROWS = 1

const ArticlepriceGrid = props => {
    const {
        value,
        disabled
    } = props

    const rowList = addFreeRows(value, NUM_OF_FREE_ROWS, {
        id: 0, startDate: todayDate(), purchasePrice: 0, percentageMarkup: 0, price: 0
    }, item => {
        return item.purchasePrice > 0 || item.price > 0
    })

    const fireChange = (list) => {
        props.onChange(list);
    }

    const updatePercentageMarkup = article => {
        article.percentageMarkup = round((article.price / article.purchasePrice - 1) * 100, 2)
        if (!isNumeric(article.percentageMarkup)) article.percentageMarkup = 0
    }

    if (value) {
        value.forEach(item => {
            if (!item.percentageMarkup) updatePercentageMarkup(item)
        })
    }


    const columns = [
        {
            field: 'startDate', headerI18n: 'start_date',
            editor: DateEditor,
            onCellEditComplete: e => onRowChange(e, e.newValue, fireChange),
            width: 100
        },
        {
            field: 'purchasePrice', headerI18n: 'purchase_price',
            body: GridTemplates.currencyTwoDecimals,
            editor: e => <NumberGridInput editorEvent={e}/>,
            onCellEditComplete: e => {
                updatePercentageMarkup(e.rowData)
                onRowChange(e, e.newValue, fireChange)
            },
            width: 100,
            align: 'right'
        },
        {
            field: 'percentageMarkup', headerI18n: 'percentage_markup',
            body: GridTemplates.percentTimes100,
            editor: e => <NumberGridInput editorEvent={e}/>,
            onCellEditComplete: e => {
                const r = value[e.rowIndex]
                if (!r) return
                r.percentageMarkup = round(e.newValue, 2)
                r.price = round(r.purchasePrice * (1 + r.percentageMarkup / 100), 2)
                e.rowData.percentageMarkup = r.percentageMarkup
                e.rowData.price = r.price
                fireChange(value)
            },
            width: 100,
            align: 'right'
        },
        {
            field: 'price', headerI18n: 'price',
            body: GridTemplates.currencyTwoDecimals,
            editor: e => <NumberGridInput editorEvent={e}/>,
            onCellEditComplete: e => {
                updatePercentageMarkup(e.rowData)
                onRowChange(e, e.newValue, fireChange)
            },
            width: 100,
            align: 'right'
        },
        RemoveColumn(row => fireChange(rowList.filter(r => r !== row)))
    ]

    return <div style={{width: '450px'}}>
        <Grid
            columns={columns}
            value={rowList}
            scrollable={false}
            sortable={false}
            disabled={disabled}
            footerColumnGroup={<div/>}
        />
    </div>
}
export default ArticlepriceGrid;
