import NetUtils from "../../utils/NetUtils";

const FormService = {
    findAll: inactive => NetUtils.doGet('/api/forms', {inactive: inactive}),
    findById: id => NetUtils.doGet('/api/forms/' + id),
    update: model => NetUtils.doPost('/api/forms', model),
    delete: model => NetUtils.doDelete('/api/forms', model),

    generate: id => NetUtils.doGet('/api/forms/' + id + '/generate'),
}
export default FormService;