import {useCallback, useState} from "react";

// export default function useForceUpdate() {
//     // eslint-disable-next-line no-unused-vars
//     const [, setValue] = useState(0)
//     return () => setValue(value => value + 1)
// }

export default function useForceUpdate() {
    const [, setValue] = useState(0);
    return useCallback(() => setValue((value) => value + 1), []);
}