import React, {useRef, useState} from "react";
import {CancelButton, MainButton} from "../../../components/Buttons/Button";
import Checkbox from "../../../components/EditForm/Checkbox";
import {useFilter} from "../../../components/Grid/filter";
import Grid from "../../../components/Grid/Grid";
import {errorAlert} from "../../../components/Overlay/Alert";
import {hideLoading, showLoading} from "../../../components/Overlay/Loading";
import XlnzDialog from "../../../components/XlnzDialog/XlnzDialog";
import XlnzMenu from "../../../components/XlnzMenu/XlnzMenu";
import Icon from "../../../enums/Icon";
import {matchOnlyEmployees} from "../../../functions/match";
import {i18n} from "../../../I18n/I18n";
import {findById, sortByField} from "../../../utils/ArrayUtil";
import MonthoverviewService from "./MonthoverviewService";

const CreateSalasyBaseDialog = ({
                                    employeeList,
                                    startDate,
                                    endDate,
                                    onHide
                                }) => {

    const filter = useFilter('CreateSalasyBaseDialog', [
        {name: 'onlyEmployees', defaultValue: true},
    ])
    const menuRef = useRef()

    const [selection, setSelection] = useState([])
    const [sortField, setSortField] = useState()
    const [sortOrder, setSortOrder] = useState()

    const filteredEmployeeList = employeeList?.filter(e => matchOnlyEmployees(e, filter.onlyEmployees))

    const columns = [
        {field: 'name', headerI18n: 'employee'},
        {field: 'ssn', headerI18n: 'ssn', width: 100},
        {field: 'number', headerI18n: 'employee_number', width: 150},
        {field: 'selection', selectionMode: 'multiple', width: 40},
    ]

    function getSelectedIdList() {
        const _selection = [...selection]
        sortByField(_selection, sortField || 'name')
        if (sortOrder < 0) _selection.reverse()
        return _selection.map(item => item.id)
    }

    async function onDownloadPdf() {
        showLoading()
        // const _selection = [...selection]
        // if (sortField) sortByField(_selection, sortField)
        // const employeeIdList = _selection.map(item => item.id)
        await MonthoverviewService.downloadPdf(startDate, endDate, getSelectedIdList())
        hideLoading()
    }

    async function onDownloadVismalon() {
        if (validate()) {
            showLoading()
            const employeeIdList = selection.map(item => item.id)
            await MonthoverviewService.downloadVismalon(startDate, endDate, employeeIdList)
            hideLoading()
        }
    }

    async function onDownloadFortnoxlon() {
        if (validate()) {
            showLoading()
            const employeeIdList = selection.map(item => item.id)
            await MonthoverviewService.downloadFortnoxlon(startDate, endDate, employeeIdList)
            hideLoading()
        }
    }

    async function onDownloadHogiabygglon() {
        if (validate()) {
            showLoading()
            const employeeIdList = selection.map(item => item.id)
            await MonthoverviewService.downloadHogiabygglon(startDate, endDate, employeeIdList)
            hideLoading()
        }
    }

    function validate() {
        const employeeIdList = selection.map(item => item.id)
        let errorMsg = ''
        employeeIdList.forEach(employeeId => {
            const employee = findById(employeeList, employeeId)
            if (!employee.number || employee.number.length === 0) {
                errorMsg += employee.name + ': ' + i18n('error_invalid_employee_number') + '\n'
            }
        })

        if (errorMsg.length > 0) {
            errorAlert(errorMsg)
            return false
        } else {
            return true
        }

    }

    const items = [
        {
            label: 'PDF',
            faicon: Icon.PDF,
            command: onDownloadPdf
        },
        {
            label: 'Visma Lön',
            faicon: Icon.PLUGIN,
            command: onDownloadVismalon
        },
        {
            label: 'Fortnox Lön',
            faicon: Icon.PLUGIN,
            command: onDownloadFortnoxlon
        },
        {
            label: 'Hogia Bygglön',
            faicon: Icon.PLUGIN,
            command: onDownloadHogiabygglon
        }
    ]

    return <XlnzDialog headerI18n='create_salary_base' onHide={onHide}
                       buttons={() => [
                           <MainButton key='create_salary_base' label={i18n('create_salary_base') + '...'}
                                       onClick={e => menuRef.current.toggle(e)}/>,
                           <CancelButton key='cancel' onClick={e => onHide()}/>
                       ]}
    >
        <XlnzMenu
            model={items}
            popup={true}
            ref={menuRef}
            id="popup_menu"
            appendTo={document.body}
        />

        <div style={{width: '600px'}}>
            <div className="Grid">
                <div className="filter">
                    <div>
                        <label className="label">{i18n('only_employees')}</label>
                        <Checkbox checked={filter.onlyEmployees}
                                  onChange={e => filter.update('onlyEmployees', e.target.checked)}/>
                    </div>
                </div>
            </div>

            <Grid columns={columns}
                  value={filteredEmployeeList}
                  heightOffset={370}
                  selection={selection}
                  onSelectionChange={e => setSelection(e.value)}
                  onRowSelect={() => {
                  }}
                  onSortFieldChange={setSortField}
                  onSortOrderChange={setSortOrder}
            />
        </div>
    </XlnzDialog>
}

export default CreateSalasyBaseDialog;
