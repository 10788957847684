import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useCallback, useEffect, useState} from "react";
import {MainButton} from "../../../components/Buttons/Button";
import {useFilter} from "../../../components/Grid/filter";
import {FooterAggregate} from "../../../components/Grid/getFooterGroup";
import Grid from "../../../components/Grid/Grid";
import {hideLoading, showLoading} from "../../../components/Overlay/Loading";
import Tooltip from "../../../components/Overlay/Tooltip";
import Spinner from "../../../components/Spinner/Spinner";
import Icon from "../../../enums/Icon";
import Role from "../../../enums/Role";
import {matchSearch} from "../../../functions/match";
import {GridTemplates} from "../../../GridTemplates";
import useDialog from "../../../hooks/useDialog";
import useEmployees from "../../../hooks/useEmployees";
import usePreload from "../../../hooks/usePreload";
import useUserPersmissions from "../../../hooks/useUserPersmissions";
import {i18n} from "../../../I18n/I18n";
import {findById} from "../../../utils/ArrayUtil";
import DayplanningService from "../../Timereg/Dayplannings/DayplanningService";
import ProjectSummaryTimeregarticleEditDialog from "./ProjectSummaryTimeregarticleEditDialog";

export default function ProjectSummaryTimeregarticleGrid({
                                                             project,
                                                             dayplanningList,
                                                             daylockSet,
                                                             timeregarticleList,
                                                             tabPanelRef
                                                         }) {
    const userPersmissions = useUserPersmissions()
    const preload = usePreload()
    const employees = useEmployees()

    const [_dayplanningList, setDayplanningList] = useState(dayplanningList)
    const [list, setList] = useState()

    const filter = useFilter('ProjectSummaryTimeregarticleGrid', [
        {name: 'search', defaultValue: ''},
    ])

    const HIDE_ARTICLE_PRICES = userPersmissions.isHideArticlePrices()

    const filteredList = list?.filter(item => matchSearch(filter, item)) || []

    const articleList = preload.getArticleList()
    const getArticleLabel = useCallback(id => findById(articleList, id)?.label || '', [articleList])

    const editDialog = useDialog()

    useEffect(() => {
        const _list = []
        _dayplanningList.forEach(dp => {
            dp.timeregarticleList.forEach(tr => {
                _list.push({
                    id: tr.id,
                    date: dp.date,
                    // employee: tr.employee,
                    employeeName: employees.findById(tr.employee)?.name || '',
                    articleName: getArticleLabel(tr.article),
                    name: tr.name,
                    unit: tr.unit,
                    quantity: tr.quantity,
                    aprice: tr.aprice,
                    discount: tr.discount,
                    price: tr.price,
                    notarized: tr.notarized,
                    locked: daylockSet.has(tr.date),
                    invoiced: timeregarticleList.find(item => item.id === tr.id)?.invoicerowId > 0
                })
            })
        })

        setList(_list)
        tabPanelRef.current.updateQuantity(_list.length)
    }, [_dayplanningList, daylockSet, timeregarticleList, employees, getArticleLabel, tabPanelRef])

    const columns = [
        {
            field: 'date', headerI18n: 'date',
            body: rowData => <div style={{
                display: 'flex',
                alignItems: 'center',
                gap: '5px'

            }}>
                {rowData.date}
                {
                    rowData.locked &&
                    <Tooltip value={i18n('day_locked')}>
                        <FontAwesomeIcon icon={Icon.LOCK} style={{color: '#666'}}/>
                    </Tooltip>
                }
            </div>,
            excelBody: (row, column) => row[column.field],
            width: 100
        },
        {field: 'employeeName', headerI18n: 'employee', width: 200},
        {field: 'articleName', headerI18n: 'article'},
        {field: 'name', headerI18n: 'description'},
        {
            field: 'unit', headerI18n: 'unit',
            body: GridTemplates.unit,
            width: 100,
        },
        {
            field: 'quantity',
            headerI18n: 'quantity',
            body: GridTemplates.numberTwoDecimals,
            width: 120,
            align: 'right'
        },
        {
            field: 'aprice',
            headerI18n: 'aprice',
            body: GridTemplates.currencyTwoDecimals,
            width: 120,
            align: 'right',
            hide: HIDE_ARTICLE_PRICES
        },
        {
            field: 'discount',
            headerI18n: 'discount',
            body: GridTemplates.percentTimes100,
            width: 80,
            align: 'right',
            hide: HIDE_ARTICLE_PRICES
        },
        {
            field: 'price',
            headerI18n: 'sum',
            body: GridTemplates.currencyTwoDecimals,
            width: 120,
            align: 'right',
            footer: FooterAggregate.SUM_CURRENCY_TWO_DECIMALS,
            hide: HIDE_ARTICLE_PRICES
        },
    ].filter(item => !item.hide)

    if (!filteredList) return <Spinner/>

    const onEdit = e => {
        //Todo: Implement this!
        if (HIDE_ARTICLE_PRICES) return

        editDialog.show(<ProjectSummaryTimeregarticleEditDialog
            project={project}
            model={e?.data}
            onSave={async () => {
                showLoading()
                setDayplanningList(await DayplanningService.findByProject(project.id))
                hideLoading()
            }}
        />)
    }

    const leftFilters = [
        !HIDE_ARTICLE_PRICES && userPersmissions.hasRole(Role.TIMEREG_WRITE) ? <div>
            <label className="label">&nbsp;</label>
            <MainButton faicon={faPlus}
                        onClick={onEdit}/>
        </div> : undefined,
    ]


    return <>
        <Grid
            name={project.name + ' - ' + i18n('articles')}
            columns={columns}
            value={filteredList}
            leftFilters={leftFilters}
            filter={filter}
            updateFilter={filter.update}
            onRowSelect={onEdit}
            heightOffset={520}
            rowClassName={e => ({'redRow': !e.notarized})}
        />
        {editDialog.render()}
    </>
}