import React, {useContext, useState} from 'react';
import AppContext from "../../AppContext";
import {AddButton} from "../../components/Buttons/Button";
import ActiveFilter from "../../components/Grid/ActiveFilter";
import {useFilter} from "../../components/Grid/filter";
import Grid from "../../components/Grid/Grid";
import {hideLoading, showLoading} from "../../components/Overlay/Loading";
import Icon from "../../enums/Icon";
import Role from "../../enums/Role";
import {matchActive, matchSearch} from "../../functions/match";
import {GridTemplates} from "../../GridTemplates";
import useMountEffect from "../../hooks/useMountEffect";
import useRoles from "../../hooks/useRoles";
import {i18n} from "../../I18n/I18n";
import {findById} from "../../utils/ArrayUtil";
import NetUtils from "../../utils/NetUtils";
import FormService from "./FormService";

const FormGrid = () => {
    const context = useContext(AppContext)
    const roles = useRoles()

    const filter = useFilter('FormGrid', [
        {name: 'active', defaultValue: true},
        {name: 'search', defaultValue: ''},
    ])

    const [list, setList] = useState([]);
    const filteredList = list.filter(c => matchActive(filter, c) && matchSearch(filter, c))

    useMountEffect(async () => {
        showLoading()
        const _list = await FormService.findAll(true)
        _list.forEach(item => {
            item.companyName = findById(context.companies, item.company)?.name
        })
        setList(_list)
        hideLoading()
    }, [])

    const leftFilters = [
        roles.hasRole(Role.ADMIN) ? <div>
            <label className="label">&nbsp;</label>
            <AddButton/>
        </div> : undefined,
        <div>
            <label className="label">{i18n('status')}</label>
            <ActiveFilter value={filter.active} onChange={e => filter.update('active', e.value)}/>
        </div>
    ]

    const columns = [
        {field: 'name', headerI18n: 'name'},
        {field: 'companyName', headerI18n: 'company'},
        {field: 'active', headerI18n: 'active', body: GridTemplates.boolean, width: 100}
    ]

    const menuItems = [{
        label: '### Migrera riskanalys ###',
        faicon: Icon.GENERATE,
        command: async () => {
            showLoading()
            await NetUtils.doGet('/api/superadmin/risk/migrate')

            const _list = await FormService.findAll()
            _list.forEach(item => {
                item.companyName = findById(context.companies, item.company)?.name
            })
            setList(_list)
            hideLoading()
        }
    }]

    return <Grid
        labelI18n='forms'
        leftFilters={leftFilters}
        filter={filter}
        updateFilter={filter.update}
        columns={columns}
        value={filteredList}
        menuItems={menuItems}
    />
}

export default FormGrid;
