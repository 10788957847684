import I18n from "../../I18n/I18n";

export default class SupplierinvoiceVatType {
    static get NORMAL() {
        return Object.freeze({id: 'NORMAL', name: I18n.t('normal')});
    }

    static get EUINTERNAL() {
        return Object.freeze({id: 'EUINTERNAL', name: 'UIF'});
    }

    static get REVERSE() {
        return Object.freeze({id: 'REVERSE', name: I18n.t('reversed_vat')});
    }

    static values() {
        return [
            {id: this.NORMAL.id, name: this.NORMAL.name},
            {id: this.EUINTERNAL.id, name: this.EUINTERNAL.name},
            {id: this.REVERSE.id, name: this.REVERSE.name}
        ];
    }

    static getName(id) {
        return this.values().find(is => is.id === id)?.name || 'ERROR'
    }
}