import React, {useCallback, useEffect} from 'react';
import {addFreeRows, isFreeRow, numOfFreeRows} from "../../../../components/Grid/freeRows";
import {FooterAggregate} from "../../../../components/Grid/getFooterGroup";
import Grid from "../../../../components/Grid/Grid";
import NumberGridInput from "../../../../components/Grid/NumberGridInput";
import onRowChange from "../../../../components/Grid/onRowChange";
import RemoveColumn from "../../../../components/Grid/RemoveColumn";
import TextGridInput from "../../../../components/Grid/TextGridInput";
import {GridTemplates} from "../../../../GridTemplates";
import {insert, trimArray} from "../../../../utils/ArrayUtil";
import {formatCurrency, isNumeric} from "../../../../utils/NumberUtils";
import PaymentplanRowGridFooter from "./PaymentplanRowGridFooter";
import {i18n} from "../../../../I18n/I18n";

const NUM_OF_FREE_ROWS = 1

const PaymentplanrowGrid = props => {
    const {
        value,
        onChange,
        disabled,
        numOfDraws,
        getMaxRetainedFunds,
        getRetainedFunds,
        retainedFundsPercentage,
        retainedFundsDeductionPercentage,
        drawDateList,
        draws,
        difference
    } = props

    const newRow = () => ({
        id: 0,
        name: '',
        price: 0,
        comment: ''
    })

    const rowList = addFreeRows(value, NUM_OF_FREE_ROWS, newRow())

    rowList.forEach(item => {
        if (!isFreeRow(value, item)) {
            let _invoiced = 0
            for (let i = 0; i < numOfDraws; i++) {

                let _value = 0
                if (item?.drawPriceList) {
                    _value = item?.drawPriceList[i] || 0
                }

                if (!item['draw' + i]) item['draw' + i] = _value
                _invoiced += item['draw' + i]
            }
            const _retainedFunds = item.price * retainedFundsPercentage / 100
            item.difference = item.price - _invoiced
            item.leftToInvoice = _retainedFunds
        }
    })


    const getSlicedList = list => list.slice(0, list.length - numOfFreeRows(list))

    const fireChange = useCallback((list) => {
        const _list = getSlicedList(list).map(item => {

            const _drawPriceList = []
            for (let i = 0; i < numOfDraws; i++) {
                _drawPriceList.push(item['draw' + i])
            }

            // console.log("_drawPriceList", _drawPriceList)

            return {
                id: item.id,
                name: item.name,
                price: item.price,
                drawPriceList: _drawPriceList,
                comment: item.comment
            }
        })
        onChange(_list);
    }, [numOfDraws, onChange])

    useEffect(() => {
        value.forEach(item => {
            if (item.drawPriceList.length < numOfDraws) {
                const numToAdd = numOfDraws - value.length
                for (let i = 0; i < numToAdd; i++) item.drawPriceList.push(newRow())
                fireChange([...value])
            } else if (item.drawPriceList.length > numOfDraws) {
                item.drawPriceList = trimArray(item.drawPriceList, numOfDraws)
                fireChange([...value])
            }
        })
    }, [value, numOfDraws, fireChange]);


    const columns = [
        {
            field: 'name', headerI18n: 'name', minWidth: 120,
            editor: disabled ? undefined : e => <TextGridInput editorEvent={e} maxLength={5000}/>,
            onCellEditComplete: e => onRowChange(e, e.newValue, fireChange),
            footer: FooterAggregate.SUM_CURRENCY,
            customFooter0: () => i18n('retained_funds'),
            customFooter1: () => i18n('to_invoice')
        },
        {
            field: 'price', headerI18n: 'sum',
            body: GridTemplates.currency,
            editor: e => <NumberGridInput editorEvent={e}/>,
            onCellEditComplete: e => onRowChange(e, e.newValue, fireChange),
            footer: FooterAggregate.SUM_CURRENCY,
            customFooter0: () => formatCurrency(getMaxRetainedFunds(value)),
            customFooter1: () => formatCurrency(getToinvoice()),
            width: 100, align: 'right'
        },
        {
            field: 'difference', headerI18n: 'difference',
            body: GridTemplates.currency,
            footer: FooterAggregate.SUM_CURRENCY,
            width: 110, align: 'right'
        },
        {
            field: 'leftToInvoice', headerI18n: 'retained_funds',
            body: GridTemplates.currency,
            footer: FooterAggregate.SUM_CURRENCY,
            customFooter1: () => formatCurrency(getMaxRetainedFunds(value)),
            width: 110, align: 'right'
        },
        {
            field: 'comment', headerI18n: 'comment', minWidth: 300,
            editor: disabled ? undefined : e => <TextGridInput editorEvent={e} maxLength={5000}/>,
            onCellEditComplete: e => onRowChange(e, e.newValue, fireChange),
        },
        RemoveColumn(row => fireChange(rowList.filter(r => r !== row)), NUM_OF_FREE_ROWS
            , rowData => !isRowDataInvoiced(rowData)
        )
    ]


    function isRowDataInvoiced(rowData, drawIndex) {
        const invoicerowpaymentplanrowList = draws.flatMap(item => item.invoicerowpaymentplanrowList)

        const irprList = invoicerowpaymentplanrowList.filter(item =>
            item.paymentplanrow === rowData.id &&
            (
                !isNumeric(drawIndex) ||
                item.drawIndex === drawIndex
            )
        )
        return irprList.length > 0
    }

    function getToinvoice() {
        let result = value.reduce((sum, item) => sum + item.price, 0)
        return result
    }

    function getDrawToinvoice(index) {
        let drawPrice = value.reduce((sum, item) => sum + item.drawPriceList[index], 0)
        drawPrice -= getRetainedFunds(value, index)
        return drawPrice
    }

    for (let i = 0; i < numOfDraws; i++) {
        insert(columns, {
            field: 'draw' + i, header: '#' + (i + 1) + ' ' + drawDateList[i],
            body: GridTemplates.currency,
            editor: e => isRowDataInvoiced(e.rowData, i) ? undefined : <NumberGridInput editorEvent={e}/>,
            onCellEditComplete: e => onRowChange(e, e.newValue, fireChange),
            footer: FooterAggregate.SUM_CURRENCY,
            customFooter0: () => formatCurrency(getRetainedFunds(value, i)),
            customFooter1: () => formatCurrency(getDrawToinvoice(i)),
            width: 100, align: 'right'
        }, 2 + i)
    }

    return <div>
        <Grid
            scrollable={false}
            columns={columns}
            value={rowList}
            // footerColumnGroup={}
            onRowReorder={e => fireChange([...e.value])}
            sortable={false}
            customFooter={retainedFundsDeductionPercentage !== 0 && retainedFundsPercentage !== 0}
        />
        <PaymentplanRowGridFooter difference={difference}/>
    </div>
}
export default PaymentplanrowGrid;
