import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import Icon from "../../enums/Icon";
import NetUtils from "../../utils/NetUtils";
import AppendixViewer from "../Appendix/AppendixViewer";
import {hideLoading, showLoading} from "../Overlay/Loading";
import renderOverlay from "../Overlay/renderOverlay";

const AppendixColumn = (isVisible, getLabel, getImageUrl) => ({
    field: 'pdf', header: '', width: 20, body: rowData => {

        const download = async url => {
            showLoading()
            await NetUtils.downloadFile(url)
            hideLoading()
        }

        if (isVisible(rowData)) {
            const name = getLabel(rowData)

            const indexOfDot = name.lastIndexOf('.')
            const ext = name.substring(indexOfDot + 1).toLowerCase();
            const isPdf = ext === 'pdf'
            const isImage = ext === 'jpg' || ext === 'jpeg' || ext === 'png'
            const isUnknown = !isPdf && !isImage

            let icon
            if (isPdf) {
                icon = Icon.PDF
            } else if (isImage) {
                icon = Icon.IMAGE
            } else {
                icon = Icon.FILE
            }

            return <FontAwesomeIcon
                icon={icon}
                onClick={async e => {
                    e.stopPropagation()
                    const imageUrl = await getImageUrl(rowData)

                    if (isUnknown) {
                        download(imageUrl)
                    } else {
                        renderOverlay(<AppendixViewer
                            imageList={[{
                                name: name,
                                url: imageUrl
                            }]}
                            index={0}
                            // onReload={fireChange}
                        />)
                    }
                }}
                style={{fontSize: '16px', opacity: '75%'}}
            />
        }
    }
})

export default AppendixColumn