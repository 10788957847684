import Role from "../../enums/Role";
import FormEditForm from "./edit/FormEditForm";
import FormGrid from "./FormGrid";

const FormsIndex = {
    routeProps: {
        path: '/forms',
        exact: true,
        element: <FormGrid/>,
    },
    nameKey: 'forms',
    icon: 'pi pi-fw pi-folder',
    subModules: [
        {
            routeProps: {
                path: '/forms/:id',
                exact: true,
                element: <FormEditForm/>,
            }
        }
    ],
    roles: [Role.SUPERADMIN]
};
export default FormsIndex;