import React, {useMemo} from 'react';
import {addFreeRows} from "../../../../components/Grid/freeRows";
import Grid from "../../../../components/Grid/Grid";
import RemoveColumn from "../../../../components/Grid/RemoveColumn";
import SelectEditor, {SubInfoItemRenderer} from "../../../../components/Grid/SelectEditor";
import usePreload from "../../../../hooks/usePreload";
import {findById} from "../../../../utils/ArrayUtil";

const NUM_OF_FREE_ROWS = 1

const SupplierAccessGrid = props => {
    const {
        value,
        disabled,
        onChange
    } = props

    const preload = usePreload()
    const supplierList = preload.getSupplierList()
    const subcontractorList = useMemo(() => supplierList.filter(item => item.subcontractor), [supplierList])

    const options = useMemo(() => {
        const result = []

        for (const supplier of subcontractorList) {
            for (const suppliercontact of supplier.contactList) {
                if (suppliercontact.email.length > 0) {
                    // Do not add already added
                    if (value.includes(suppliercontact.id)) continue

                    result.push({
                        id: suppliercontact.id,
                        name: suppliercontact.name,
                        supplierName: supplier.name,
                        email: suppliercontact.email,
                        filterBy: suppliercontact.name + ';' +
                            supplier.name + ';' +
                            suppliercontact.name + ';' +
                            suppliercontact.email
                    })
                }
            }
        }

        return result
    }, [subcontractorList, value])

    const fireChange = (list) => {
        let result = list
            .filter(item => item.id > 0)
            .map(item => item.id)
        console.log("result", result, list)
        onChange(result)
    }
    const columns = [
        {
            field: 'name', headerI18n: 'supplier',
            editor: e => <SelectEditor
                event={e}
                options={options}
                filterBy='filterBy'
                hideNoneOption={true}
                itemTemplate={item => {
                    return <SubInfoItemRenderer name={item.name} subInfo={item.supplierName}/>;
                }}
                onChange={e2 => e.editorCallback(e2?.value)}
            />,
            onCellEditComplete: e => {
                if (!e.newValue) return
                const _rowList = [...e.props.value]

                const value = {
                    id: e.newValue?.id,
                    name: e.newValue.name,
                    email: e.newValue.email
                }

                _rowList[e.rowIndex] = value
                _rowList[e.rowIndex].dirty = true
                fireChange(_rowList)
            },
        },
        {field: 'supplierName', headerI18n: 'supplier'},
        // {field: 'email', headerI18n: 'email'},
        // RemoveColumn(row => fireChange(rowList.filter(r => r !== row)), NUM_OF_FREE_ROWS)
        RemoveColumn(row => onChange(value.filter(id => id !== row.id)), NUM_OF_FREE_ROWS)
    ]

    // function getRowList(value) {
    //     let result = value.map(id => {
    //         const suppliercontact = findById(subcontractorList.flatMap(item => item.contactList), id);
    //         const supplier = findById(subcontractorList, suppliercontact.supplier)
    //
    //         return {
    //             id: suppliercontact.id,
    //             name: suppliercontact.name,
    //             supplierName: supplier.name
    //         }
    //     })
    //     if (!disabled && subcontractorList.length > 0)
    //         result = addFreeRows(result, NUM_OF_FREE_ROWS, {id: 0})
    //     console.log("getRowList", result)
    //     return result
    // }

    const rowList = useMemo(() => {
        let result = value.map(id => {
            const suppliercontact = findById(subcontractorList.flatMap(item => item.contactList), id);
            const supplier = findById(subcontractorList, suppliercontact.supplier)

            return {
                id: suppliercontact.id,
                name: suppliercontact.name,
                supplierName: supplier.name
            }
        })
        if (!disabled && options.length > 0)
            result = addFreeRows(result, NUM_OF_FREE_ROWS, {id: 0})
        return result
    }, [subcontractorList, disabled, value, options])


    return <div className='formItem'>
        <Grid forceDesktop={true}
              disabled={disabled}
              columns={columns}
              value={rowList}
            // value={getRowList(value)}
              sortable={false}
              scrollable={false}
              onRowReorder={e => fireChange([...e.value])}
              footerColumnGroup={<div/>}
        />
    </div>
}

export default SupplierAccessGrid;
