import React from 'react';
import Label from "../../components/EditForm/Label";
import {i18n} from "../../I18n/I18n";
import {getMonthName, getYear} from "../../utils/DateUtils";
import {formatCurrency, formatCurrencyPerHour, formatHours} from "../../utils/NumberUtils";
import './TurnoverInfo.scss'

const TurnoverInfo = props => {
    const {
        date
    } = props
    const turnoverInfp = props.data.turnoverInfo;

    return <div className='TurnoverInfo'>
        <div className='row'>
            <div className='item'>
                <Label value={getMonthName(date)}/>
                <div>{formatCurrency(turnoverInfp.month, 0, true)}</div>
            </div>
            <div className='item'>
                <Label value={i18n('year') + ' ' + getYear(date)}/>
                <div>{formatCurrency(turnoverInfp.year, 0, true)}</div>
            </div>
            <div className='item'>
                <Label valueI18n='rolling12'/>
                <div>{formatCurrency(turnoverInfp.rolling12, 0, true)}</div>
            </div>
        </div>

        <div className='row'>
            <div className='item'>
                <Label valueI18n='worked_hours'/>
                <div>{formatHours(turnoverInfp.workedHours, true)}</div>
            </div>
            {
                turnoverInfp.invoicedPerHour > 0 &&
                <div className='item'>
                    <Label valueI18n='invoiced_per_hour'/>
                    <div>{formatCurrencyPerHour(turnoverInfp.invoicedPerHour, 0, true)}</div>
                </div>
            }
        </div>
    </div>
}

export default TurnoverInfo;
