import React, {useCallback, useState} from 'react';
import {MainButton} from "../../../../../components/Buttons/Button";
import DatePicker from "../../../../../components/Date/DatePicker";
import Label from "../../../../../components/EditForm/Label";
import {useFilter} from "../../../../../components/Grid/filter";
import {FooterAggregate} from "../../../../../components/Grid/getFooterGroup";
import Grid from "../../../../../components/Grid/Grid";
import {hideLoading, showLoading} from "../../../../../components/Overlay/Loading";
import XlnzDropdown from "../../../../../components/XlnzDropdown/XlnzDropdown";
import Icon from "../../../../../enums/Icon";
import {matchSearch} from "../../../../../functions/match";
import {GridTemplates} from "../../../../../GridTemplates";
import {i18n} from "../../../../../I18n/I18n";
import {findByField} from "../../../../../utils/ArrayUtil";
import {getFirstDateOfMonth, todayDate} from "../../../../../utils/DateUtils";
import NetUtils from "../../../../../utils/NetUtils";

const EnterMonthlyReport = () => {
    const filter = useFilter('EnterMonthlyReport', [
        {name: 'startDate', defaultValue: getFirstDateOfMonth()},
        {name: 'endDate', defaultValue: todayDate()},
        {name: 'billingType', defaultValue: BillingType.BOTH.id},
        {name: 'search', defaultValue: ''},
    ])
    const [model, setModel] = useState()
    const filteredList = model?.itemList.filter(item => matchSearch(filter, item)) || []

    const loadData = useCallback(() => {
        showLoading()
        NetUtils.doGet('/api/reports/enter/monthly-report', {
            startDate: filter.startDate,
            endDate: filter.endDate,
            billingType: filter.billingType
        }).then(_model => {

            for (const item of _model.itemList) {
                for (const debittypeName of _model.debittypeNameList) {
                    // console.log("ITEM", item, debittypeName)

                    item[debittypeName] = item.debitHours
                        .filter(row => row.debittypeName === debittypeName)
                        .reduce((sum, row) => sum + row.hours, 0);

                    // break
                }
            }

            setModel(_model)
            hideLoading()
        });
    }, [filter.startDate, filter.endDate, filter.billingType]);

    const columns = [{field: 'companyName', header: 'Avdelning', body: GridTemplates.onelineString, minWidth: 160}]

    model?.debittypeNameList.forEach(debittypeName => {
        columns.push({
            field: debittypeName,
            header: debittypeName,
            body: GridTemplates.hours,
            footer: FooterAggregate.SUM_HOURS,
            width: 100,
            align: 'right'
        })
    })

    columns.push({
        field: 'hours',
        header: 'Totalt',
        body: GridTemplates.hours,
        footer: FooterAggregate.SUM_HOURS,
        width: 80,
        align: 'right'
    },)

    const leftFilters = [
        <div>
            <Label valueI18n='start_date'/>
            <DatePicker value={filter.startDate} onChange={date => filter.update('startDate', date)}/>
        </div>,

        <div>
            <Label valueI18n='end_date'/>
            <DatePicker value={filter.endDate} onChange={date => filter.update('endDate', date)}/>
        </div>,

        <div>
            <Label valueI18n='type'/>
            <XlnzDropdown
                value={filter.billingType}
                options={BillingType.values()}
                optionValue='id'
                optionLabel='name'
                onChange={e => filter.update('billingType', e.value)}
                style={{width: '180px'}}/>
        </div>
    ]

    leftFilters.push(
        <div>
            <Label/>
            <MainButton labelI18n='create_report' onClick={() => loadData()}/>
        </div>
    )

    return <Grid
        id='Enter-Månadsrapport'
        name='Enter - Månadsrapport'
        forceDesktop={true}
        label='Enter - Månadsrapport'
        icon={Icon.REPORT}
        leftFilters={leftFilters}
        filter={filter}
        updateFilter={filter.update}
        columns={columns}
        value={filteredList}
        onRowSelect={e => {
        }}
    />
}

export default EnterMonthlyReport;







class BillingType {
    static get FIXED() {
        return Object.freeze({id: 'FIXED', name: i18n('fixed_time')});
    }

    static get COSTPLUS() {
        return Object.freeze({id: 'COSTPLUS', name: i18n('debit_time')});
    }

    static get BOTH() {
        return Object.freeze({
            id: 'BOTH',
            name: i18n('fixed_time') + ' & ' + i18n('debit_time').toLowerCase()
        })
    }

    static values() {
        const result = [
            this.FIXED,
            this.COSTPLUS,
            this.BOTH,
        ];
        return result
    }

    static findById(id) {
        return findByField(this.values(), 'id', id)
    }

    static getName(id) {
        if (!id) return ''
        return findByField(this.values(), 'id', id)?.name
    }
}