import {useContext} from "react";
import AppContext from "../../../AppContext";
import Companyprop from "../../../enums/Companyprop";
import Country from "../../../enums/Country";
import useCompanyProp from "../../../hooks/useCompanyProp";
import {todayDate} from "../../../utils/DateUtils";
import TenderStatus from "../../Tenders/TenderStatus";

const useProjectDefaultValues = props => {
    const context = useContext(AppContext);

    const PROJECT_EMP_TIMEREG_HIDE = useCompanyProp(Companyprop.PROJECT_EMP_TIMEREG_HIDE, false)
    const PROJECT_EMP_TIMEREG_FIXED_TIME_DISABLED = useCompanyProp(Companyprop.PROJECT_EMP_TIMEREG_FIXED_TIME_DISABLED, false)
    const PROJECT_EMP_TIMEREG_COSTPLUS_TIME_DISABLED = useCompanyProp(Companyprop.PROJECT_EMP_TIMEREG_COSTPLUS_TIME_DISABLED, false)

    return {
        id: 0,
        company: context.currentCompany.id,
        cuser: context.user.id,
        customer: undefined,
        customercontact: undefined,
        projectLeader: undefined,
        startDate: todayDate(),
        address: {
            address: '',
            zip: 0,
            city: '',
            country: Country.SE.id
        },
        markup: '',
        workorderInfo: '',
        standardValues: {
            purchasePercentageMarkup: 0,
            budgetPrice: 0,
            purchasePrice: 0,
            estimatedRemainingCostAdjustmentList: [],
        },
        access: {
            empTimeregHide: PROJECT_EMP_TIMEREG_HIDE,
            empTimeregFixedTimeDisabled: PROJECT_EMP_TIMEREG_FIXED_TIME_DISABLED,
            empTimeregCostplusTimeDisabled: PROJECT_EMP_TIMEREG_COSTPLUS_TIME_DISABLED,
            empTimeregEmployeeAccessSet: [],
            supplierAccessSet: [],
        },
        note: '',
        cancelled: false,
        timeregdebittypepriceList: [],
        rentbasisList: [],
        rentList: [],
        appendixList: [],
        documentsentlogList: [],

        // Change Order
        tenderStatus: TenderStatus.DRAFT,
        tenderRowList: [],
    }
}
export default useProjectDefaultValues