import React from "react";

export default function XlnzGridCustomFooter({
                                                 editable,
                                                 columns,
                                                 data,
                                                 getColumnWidth,
                                                 xlnxGridExpandable
                                             }) {

    const footerList = []

    function hasCustomColumns(index) {
        const foo = columns.find(col => {
            return col['customFooter' + index] !== undefined
        })
        return foo
    }

    for (let i = 0; i < 10; i++) {
        if (!hasCustomColumns(i)) break
        footerList.push(
            <div key={'customFooter' + i} className='footer'>
                {xlnxGridExpandable.renderFooter()}
                {
                    columns.map((col, index) => {
                        const style = {...col.style} || {}
                        const colWidth = getColumnWidth(col)
                        style.minWidth = col.minWidth > 0 ? col.minWidth : colWidth
                        style.width = colWidth
                        style.maxWidth = colWidth
                        style.textAlign = col.align

                        return <div key={col.field} className='footer-cell' style={style}>
                            {col['customFooter' + i] && col['customFooter' + i](col)}
                        </div>
                    })
                }
            </div>
        )
        // footerList.push(<div key={col.field} className='footer-cell' style={style}>
        //     {col.customFooter0 && col.customFooter0()}
        // </div>)
    }

    return footerList
    // const getFooterColumns = (columns, list) => {
    //     return <>
    //         {
    //             footerList
    //         }
    //     </>
    // }
    //
    // return <div className='footer'>
    //     {getFooterColumns(columns, data)}
    // </div>
}