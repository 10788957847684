import React from "react";
import {i18n} from "../../I18n/I18n";
import {findByField} from "../../utils/ArrayUtil";
import Checkbox from "../EditForm/Checkbox";

export default function XlnzGridHeader({
                                           disabled,
                                           uberColumns,
                                           columns,
                                           data,
                                           getColumnWidth,

                                           sortable,
                                           sortField,
                                           onSortFieldChange,
                                           sortOrder,
                                           onSortOrderChange,

                                           selection,
                                           onSelectionChange,
                                           xlnxGridExpandable
                                       }) {


    function getHeaderCell(col, value, selection, onSelectionChange) {
        const style = {
            minWidth: col.minWidth > 0 ? col.minWidth : getColumnWidth(col),
            width: getColumnWidth(col),
            maxWidth: getColumnWidth(col),
            textAlign: col.align,
            justifyContent: col.align
        }
        const classNames = ['header-cell']

        if (sortable) style.cursor = 'pointer'
        if (sortField === col.field) classNames.push('sort-active')

        if (col.selectionMode === 'multiple') {
            style.justifyContent = 'center'
            const selected = value.length === selection?.length

            return <div key={'col-' + col.field}
                        name={col.field}
                        className='header-cell'
                        style={style}>
                <Checkbox checked={selected}
                          disabled={disabled}
                          onChange={e => onSelectionChange({
                              value: selected ? [] : [...value],
                              checked: !selected
                          })}
                />
            </div>
        }

        return <div key={'col-' + col.field}
                    name={col.field}
                    className={classNames.join(' ')}
                    style={style}
                    onClick={() => sortable ? onSortClick(col) : undefined}
        >
            {col.header}
            {col.headerI18n && i18n(col.headerI18n)}
        </div>
    }

    function onSortClick(col) {
        const _sortField = col.field
        let _sortOrder = sortOrder || 1

        if (sortField !== _sortField) {
            onSortFieldChange(_sortField)
            onSortOrderChange(1)
            _sortOrder = 1
        } else {
            // Back to original after two click
            if (sortOrder === -1) {
                onSortOrderChange(1)
                onSortFieldChange(undefined)
                return
            }
            _sortOrder = -sortOrder
            onSortOrderChange(_sortOrder)
        }
    }

    return <div className='header'>
        {xlnxGridExpandable.renderHeader()}
        {
            columns.map((col, index) => {
                const uberCol = findByField(uberColumns, 'startColumnField', col.field)
                if (uberCol) {
                    const subCols = []
                    for (let j = 0; j < uberCol.colSpan; j++) {
                        const subCol = columns[index + j]
                        if (!subCol)
                            throw new Error('uberCol "' + uberCol.header + '" is missing subCol with index ' + (index + j))
                        subCols.push(getHeaderCell(subCol))
                    }
                    return <div className='uber-header-cell' key={'uberCol' + uberCol.startColumnField}>
                        <div style={uberCol.style}>{uberCol.header}</div>
                        <div className='sub-cols'>{subCols}</div>
                    </div>

                } else {
                    if (col.hasUberHeader) return undefined
                    return getHeaderCell(col, data, selection, onSelectionChange)
                }

            })
        }
    </div>
}