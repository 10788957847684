import Vat from "../../../enums/Vat";

export function addEmptyRow(invoiceRows) {
    invoiceRows.push({
        name: '',
        quantity: 0,
        aprice: 0,
        discount: 0,
        price: 0,
        vat: Vat.VAT25.id,
    })
}

export function addTitle(rowList, config, invoiceRows, title) {
    if (config.showTitle) {
        if (rowList.length > 0) {
            addEmptyRow(invoiceRows)
        }

        invoiceRows.push({
            name: title,
            headline: true,
            quantity: 0,
            aprice: 0,
            discount: 0,
            price: 0,
            vat: Vat.VAT25.id,
        })
    }
}