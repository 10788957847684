import {faAngleLeft, faAngleRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useCallback, useEffect, useRef, useState} from 'react';
import makeId from "../../functions/makeId";
import './AppendixViewer.scss'
import {ImageViewerModal} from "./ImageViewer";
import {PdfViewerModal} from "./PdfViewer";

export default function AppendixViewer(props) {
    const {
        onHide,
        imageList,
        onReload
    } = props


    // To make the state visible to document.addEventListener we need to do this hack. /JW 2023-06-23
    const [appendix, _setAppendix] = useState(imageList[props.index])
    const appendixRef = useRef(appendix);
    const setAppendix = data => {
        appendixRef.current = data;
        _setAppendix(data);
    };

    const indexOfDot = appendix.name.lastIndexOf('.')
    const ext = appendix.name.substring(indexOfDot + 1).toLowerCase();
    const isPdf = ext === 'pdf'
    const isImage = ext === 'jpg' || ext === 'jpeg' || ext === 'png'

    const changeIndex = useCallback(value => {
        let _index = imageList.indexOf(appendixRef.current) + value
        if (_index < 0) _index = imageList.length - 1
        if (_index > imageList.length - 1) _index = 0
        setAppendix(imageList[_index])
    }, [imageList]);

    useEffect(() => {
        const handleKeyup = e => {
            if (e.key === 'ArrowLeft') changeIndex(-1)
            if (e.key === 'ArrowRight') changeIndex(1)
        }
        document.addEventListener("keyup", handleKeyup);
        return () => document.removeEventListener("keyup", handleKeyup)
    }, [changeIndex])

    return <div className='AppendixViewer'>
        {
            imageList.length > 1 &&
            <>
                <div className='previous-image' onClick={() => changeIndex(-1)}>
                    <FontAwesomeIcon className='arrow' icon={faAngleLeft} style={{height: '200px'}}/>
                </div>
                <div className='next-image' onClick={() => changeIndex(1)}>
                    <FontAwesomeIcon className='arrow' icon={faAngleRight} style={{height: '200px'}}/>
                </div>
            </>
        }

        {
            isPdf &&
            <PdfViewerModal label={appendix.name}
                            url={
                                appendix.url ? appendix.url :
                                    appendix.token
                                        ? '/api/files/' + appendix.token
                                        : appendix.pdfUrl
                            }
                            onHide={onHide}
            />
        }

        {
            isImage &&
            <ImageViewerModal label={appendix.name}
                              token={appendix.token}
                              url={appendix.url ? appendix.url : '/api/files/' + appendix.token}
                              onReload={() => {
                                  appendix.hash = makeId()
                                  onReload([...imageList])
                              }}
                              onHide={onHide}
            />
        }
    </div>
}