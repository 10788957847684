import React, {useState} from 'react';
import {AddButton} from "../../components/Buttons/Button";
import {useFilter} from "../../components/Grid/filter";
import Grid from "../../components/Grid/Grid";
import {hideLoading, showLoading} from "../../components/Overlay/Loading";
import Role from "../../enums/Role";
import {matchSearch} from "../../functions/match";
import {GridTemplates} from "../../GridTemplates";
import useMountEffect from "../../hooks/useMountEffect";
import useRoles from "../../hooks/useRoles";
import NewsletterService from "./NewsletterService";

const UnsubscribeGrid = () => {
    const roles = useRoles()

    const filter = useFilter('UnsubscribeGrid', [
        {name: 'search', defaultValue: ''},
    ])

    const [list, setList] = useState([])
    const filteredList = list.filter(c => matchSearch(filter, c))

    useMountEffect(async () => {
        showLoading()
        setList(await NewsletterService.findAllUnsubscribes())
        hideLoading()
    })

    const leftFilters = [
        roles.hasRole(Role.CRM_WRITE) ? <div>
            <label className="label">&nbsp;</label>
            <AddButton/>
        </div> : undefined,
    ]

    const columns = [
        {field: 'ctime', headerI18n: 'created', width: 140, body: GridTemplates.time, mobileWithHeader: true},
        {field: 'email', headerI18n: 'email', mobile: true},
        {field: 'newsletterName', headerI18n: 'newsletter'},
    ]

    return <Grid
        labelI18n='unsubscribes'
        leftFilters={leftFilters}
        filter={filter}
        updateFilter={filter.update}
        columns={columns}
        value={filteredList}
        paginator={true}
    />
}

export default UnsubscribeGrid;
