import React, {useContext} from "react";
import {Controller} from "react-hook-form";
import '../../components/EditForm/EditForm.scss'
import ReactQuill from "react-quill";
import AppContext from "../../AppContext";
import AppendixViewer from "../../components/Appendix/AppendixViewer";
import {PdfButton} from "../../components/Buttons/Button";
import FormItemText from "../../components/EditForm/FormItemText";
import SimpleEditForm from "../../components/EditForm/SimpleEditForm";
import {infoAlert} from "../../components/Overlay/Alert";
import renderOverlay from "../../components/Overlay/renderOverlay";
import TabPanel from "../../components/TabView/TabPanel";
import DocumentType from "../../enums/DocumentType";
import Role from "../../enums/Role";
import {i18n} from "../../I18n/I18n";
import KmaService from "./KmaService";
import './KmaView.scss'
import 'react-quill/dist/quill.snow.css'; // För standard "snow" tema

const KmaView = () => {
    const context = useContext(AppContext)

    const postLoadFunction = _model => {
        if (_model && Object.keys(_model).length === 0) {
            Object.assign(_model, {
                text: '',
                version: '',
                riskList: []
            })
        }
    }

    const preSaveFunction = data => {
        if (!data.company) data.company = context.currentCompany.id
        data.text = data.text.replaceAll('<p><br></p>', '<br>')
    }

    const postSaveFunction = data => {
        infoAlert(i18n('saved'))
    }

    return <div className='KmaView'>
        <SimpleEditForm
            writeRole={Role.ADMIN}
            headline={'KMA-plan'}
            defaultValues={{
                text: '',
                version: '',
                riskList: []
            }}
            loadFunction={KmaService.get}
            postLoadFunction={postLoadFunction}
            preSaveFunction={preSaveFunction}
            postSaveFunction={postSaveFunction}
            saveFunction={KmaService.update}
            initRightButtons={form => [
                <PdfButton key='preview' labelI18n='preview' onClick={e => {
                    const data = form.getValues()
                    preSaveFunction(data)
                    KmaService.update(data).then(resp => {
                        renderOverlay(<AppendixViewer
                            imageList={[{
                                id: 0,
                                type: 'KMA',
                                name: DocumentType.KMA.name + '.pdf',
                                fileSize: 0,
                                pdfUrl: '/api/projects/' + 0 + '/kma-pdf',
                                selected: false,
                            }]}
                            index={0}
                            // onReload={fireChange}
                        />)
                    })
                }}/>,
            ]}
            initLeftFields={form => [
                <FormItemText key='version' name='version' labelI18n='version'
                              maxLength={10} width={150} {...form}/>,
            ]}
            initCenterFields={form => []}
            initRightFields={form => []}
            initTabs={form => [
                <TabPanel key='kma_plan' header='KMA-plan'>
                    <Controller name="text"
                                control={form.control}
                                render={({field: {ref, value, onChange}}) =>
                                    <ReactQuill
                                        value={value}
                                        onChange={onChange}
                                        modules={{
                                            toolbar: [
                                                [{'header': '1'}, {'header': '2'}],
                                                ['bold', 'italic', 'underline'],
                                                [{'list': 'bullet'}, {'list': 'ordered'}]
                                            ],
                                            clipboard: {
                                                matchVisual: false
                                            }
                                        }}
                                    />
                                }/>
                </TabPanel>,
            ]}
        />
    </div>
}
export default KmaView;