import {useContext} from "react";
import AppContext from "../../../AppContext";
import Country from "../../../enums/Country";

const useEmployeeDefaultValues = () => {
    const context = useContext(AppContext);

    return {
        company: context.currentCompany.id,
        additionalDepartments: [],
        ssn: '',
        address: {
            address: '',
            zip: 0,
            city: '',
            country: Country.SE.id
        },
        email: '',
        contactName: '',
        contactPhone0: '',
        username: '',
        password: '',
        note: '',
        hired: false,
        attestant: false,
        active: true,
        salaryList: [],
        trainingList: []
    }
}
export default useEmployeeDefaultValues