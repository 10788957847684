import Role from "../../enums/Role";
import TrainingtypeEditForm from "./TrainingtypeEditForm";
import TrainingtypeGrid from "./TrainingtypeGrid";

const TrainingtypesIndex = {
    routeProps: {
        path: '/trainingtypes',
        exact: true,
        element: <TrainingtypeGrid/>,
    },
    nameKey: 'training_types',
    icon: 'pi pi-fw pi-folder',
    subModules: [{
        routeProps: {
            path: '/trainingtypes/:id',
            exact: true,
            element: <TrainingtypeEditForm/>,
        }
    }],
    roles: [Role.INVOICE_READ]
};
export default TrainingtypesIndex;