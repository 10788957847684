import React, {useCallback, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {MainButton} from "../../../components/Buttons/Button";
import DatePicker from "../../../components/Date/DatePicker";
import {useFilter} from "../../../components/Grid/filter";
import {FooterAggregate} from "../../../components/Grid/getFooterGroup";
import Grid from "../../../components/Grid/Grid";
import {hideLoading, showLoading} from "../../../components/Overlay/Loading";
import Icon from "../../../enums/Icon";
import {matchSearch} from "../../../functions/match";
import {GridTemplates} from "../../../GridTemplates";
import {i18n} from "../../../I18n/I18n";
import {getFirstDateOfMonth, todayDate} from "../../../utils/DateUtils";
import NetUtils from "../../../utils/NetUtils";

const CustomerHoursAndInvoiced = () => {
    const filter = useFilter('CustomerHoursAndInvoiced', [
        {name: 'startDate', defaultValue: getFirstDateOfMonth()},
        {name: 'endDate', defaultValue: todayDate()},
        {name: 'search', defaultValue: ''},
    ])
    const [list, setList] = useState([])
    const filteredList = list.filter(item => matchSearch(filter, item))

    const navigate = useNavigate()

    const loadData = useCallback(() => {
        showLoading()
        NetUtils.doGet('/api/reports/customers-hours-and-invoiced', {
            startDate: filter.startDate,
            endDate: filter.endDate,
        }).then(res => {
            setList(res)
            hideLoading()
        });
    }, [filter.startDate, filter.endDate]);

    const columns = [
        {field: 'customerName', headerI18n: 'customer', minWidth: 150},
        {
            field: 'fixedHours',
            headerI18n: 'fixed_time',
            body: GridTemplates.hours,
            excelBody: (row, column) => row[column.field],
            footer: FooterAggregate.SUM_HOURS,
            width: 160, align: 'right'
        },
        {
            field: 'costplusHours',
            headerI18n: 'costplus_time',
            body: GridTemplates.hours,
            excelBody: (row, column) => row[column.field],
            footer: FooterAggregate.SUM_HOURS,
            width: 160, align: 'right'
        },
        {
            field: 'debitHours',
            headerI18n: 'debit_time',
            body: GridTemplates.hours,
            excelBody: (row, column) => row[column.field],
            footer: FooterAggregate.SUM_HOURS,
            width: 160, align: 'right'
        },
        {
            field: 'hours',
            headerI18n: 'total_time',
            body: GridTemplates.hours,
            footer: FooterAggregate.SUM_HOURS,
            width: 160, align: 'right',
            formula: 'B#ROW+C#ROW'
        },
        {
            field: 'invoiced',
            headerI18n: 'invoiced',
            body: GridTemplates.currency,
            excelBody: (row, column) => row[column.field],
            footer: FooterAggregate.SUM_CURRENCY,
            width: 160, align: 'right'
        },
        {
            field: 'invoicedRent',
            headerI18n: 'invoiced_rent',
            body: GridTemplates.currency,
            excelBody: (row, column) => row[column.field],
            footer: FooterAggregate.SUM_CURRENCY,
            width: 160, align: 'right'
        },
        {
            field: 'invoicedPerHour',
            headerI18n: 'inv_per_hour',
            body: GridTemplates.currencyPerHours,
            width: 160, align: 'right',
            formula: 'ROUND(F#ROW/E#ROW, 0)'
        },
        {
            field: 'invoicedPerHourExclRent',
            headerI18n: 'inv_per_hour_excl_rent',
            body: GridTemplates.currencyPerHours,
            width: 160, align: 'right',
            formula: 'ROUND((F#ROW-G#ROW)/E#ROW, 0)'
        },

    ];

    const leftFilters = [
        <div>
            <label className="label">{i18n('start_date')}</label>
            <DatePicker value={filter.startDate} onChange={date => filter.update('startDate', date)}/>
        </div>,

        <div>
            <label className="label">{i18n('end_date')}</label>
            <DatePicker value={filter.endDate} onChange={date => filter.update('endDate', date)}/>
        </div>
    ]

    leftFilters.push(<div>
        <label className="label">&nbsp;</label>
        <MainButton labelI18n='create_report' onClick={() => loadData()}/>
    </div>)

    return <div className="Grid" style={{padding: '10px 0'}}>
        <Grid
            id='CustomerHoursAndInvoiced'
            forceDesktop={true}
            labelI18n='customers_hours_and_invoiced'
            icon={Icon.REPORT}
            leftFilters={leftFilters}
            filter={filter}
            updateFilter={filter.update}
            columns={columns}
            value={filteredList}
            onRowSelect={e => {
                navigate('/customers/' + e.data.customerId)
            }}
        />
    </div>
}

export default CustomerHoursAndInvoiced;