import React, {useContext, useState} from 'react';
import {useNavigate} from "react-router-dom";
import AppContext from "../../AppContext";
import AppendixViewer from "../../components/Appendix/AppendixViewer";
import ImageViewer from "../../components/Appendix/ImageViewer";
import PdfViewer from "../../components/Appendix/PdfViewer";
import {DownloadButton, RemoveButton} from "../../components/Buttons/Button";
import FormItemCheckbox from "../../components/EditForm/FormItemCheckbox";
import FormItemText from "../../components/EditForm/FormItemText";
import FormItemTextArea from "../../components/EditForm/FormItemTextArea";
import SimpleEditForm from "../../components/EditForm/SimpleEditForm";
import FileUpload from "../../components/FileUpload/FileUpload";
import ExtraMenu from "../../components/Grid/ExtraMenu";
import {confirmYes} from "../../components/Overlay/Confirm";
import {hideLoading, showLoading} from "../../components/Overlay/Loading";
import renderOverlay from "../../components/Overlay/renderOverlay";
import TabPanel from "../../components/TabView/TabPanel";
import Role from "../../enums/Role";
import usePreload, {PreloadType} from "../../hooks/usePreload";
import useRoles from "../../hooks/useRoles";
import {i18n} from "../../I18n/I18n";
import NetUtils from "../../utils/NetUtils";
import './DocumentEditForm.scss'
import RouterUtils from "../../utils/RouterUtils";
import {isImageFile, isPdfFile} from "../../utils/StringUtil";
import DocumentService from "./DocumentService";

const DocumentEditForm = props => {
    const context = useContext(AppContext)
    const roles = useRoles()
    const navigate = useNavigate()
    const preload = usePreload()

    const [form, setForm] = useState()
    const [deletable, setDeletable] = useState(false)


    async function postLoadFunction(_model) {
        const _resp = await DocumentService.deletable(_model.id)
        setDeletable(_resp.value)
    }

    function preSaveFunction(data) {
        if (!form.getValues('fileSize')) {
            data.name = ''
        }
    }

    const save = async (data, files) => {
        const _model = await DocumentService.update(data, files)
        if (_model) {
            navigate('/documents/' + _model.id, {replace: true});
            form.reset(_model);

            return _model;
        }
    }

    const onRemove = (id) => {
        confirmYes(async () => {
            await DocumentService.delete(id)
            RouterUtils.goBack(navigate)
        });
    }

    function getUploadTab(form) {
        const [name, fileSize, token] = form?.getValues(['name', 'fileSize', 'token'])
        const imageUrl = '/api/files/' + token


        return <div key='uploadSection' className='upload-section'>
            <div className='bar'>
                {
                    fileSize > 0 &&
                    <DownloadButton labelI18n='download'
                                    onClick={async () => {
                                        showLoading()
                                        NetUtils.downloadFile(imageUrl).then(() => hideLoading())
                                    }}
                    />
                }
                {
                    deletable && fileSize > 0 && roles.hasRole(Role.DOCUMENT_WRITE) &&
                    <RemoveButton onClick={e => {
                        confirmYes(() => {
                            const data = form.getValues()

                            showLoading()
                            delete data.fileSize
                            save(data).then(_model => {
                                form.reset(_model);
                                hideLoading()
                            })
                        })
                    }}/>
                }
            </div>

            {
                fileSize > 0 &&
                <div className='pdf-viewer-container'>
                    <div className='filename'>{i18n('filename') + ': ' + name}</div>
                    {isPdfFile(name) && <PdfViewer url={imageUrl} enableZoom={false}/>}
                    {isImageFile(name) && <ImageViewer
                        url={imageUrl}
                        style={{
                            cursor: "pointer"
                        }}
                        onClick={() => {
                            renderOverlay(<AppendixViewer
                                imageList={[{
                                    name: name,
                                    token: token
                                }]}
                                index={0}
                                onReload={async _list => {
                                    const _document = await DocumentService.findById(form.getValues('id'))
                                    form.reset(_document)
                                    await preload.load(PreloadType.DOCUMENT_LIST)
                                }}
                            />)
                        }}/>}
                </div>
            }
            {
                !fileSize && roles.hasRole(Role.DOCUMENT_WRITE) &&
                <FileUpload multiple={false} save={save}
                            accept='application/pdf,image/jpeg,image/png'
                            {...form}/>
            }
        </div>
    }

    return <div className='DocumentEditForm'>
        <SimpleEditForm
            writeRole={Role.DOCUMENT_WRITE}
            headlineI18n='document'
            defaultValues={{
                company: context.currentCompany.id,
                name: '',
                note: '',
                active: true
            }}
            onFormCreated={setForm}
            loadFunction={DocumentService.findById}
            postLoadFunction={postLoadFunction}
            preSaveFunction={preSaveFunction}
            saveFunction={DocumentService.update}
            initRightButtons={form => [
                deletable && !roles.hasRole(Role.DOCUMENT_WRITE) ? undefined : <ExtraMenu key='remove' itemList={[
                    {
                        label: i18n('remove'),
                        icon: 'pi pi-fw pi-trash',
                        command: () => onRemove(form.getValues('id'))
                    }
                ]}/>
            ]}
            initLeftFields={form => {
                const result = [<FormItemText key='description' name='description' labelI18n='name' maxLength={128}
                                              required {...form}/>]

                if (form.getValues('fileSize') > 0) {
                    result.push(<FormItemText key='filename' name='name' labelI18n='filename' maxLength={128}
                                              required {...form}/>,)
                }
                result.push(...[
                    <FormItemTextArea key='note' name='note' labelI18n='note' maxLength={5000} {...form}/>,
                    <FormItemCheckbox key='active' name='active' labelI18n='active' {...form}/>
                ])
                return result
            }}
            initTabs={form => [
                <TabPanel key='file' header={i18n('file')}>
                    {getUploadTab(form)}
                </TabPanel>
            ]}
            preloadType={PreloadType.DOCUMENT_LIST}
        />
    </div>
}

export default DocumentEditForm;
