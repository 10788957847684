import {truncate as _truncate} from "lodash";
import {COMPANY} from "../App";
import Country from "../enums/Country";

export const capitalizeFirstLetter = str => str.charAt(0).toUpperCase() + str.slice(1)

export const isPdfFile = (filename) => {
    if (!filename) return false
    const indexOfDot = filename.lastIndexOf('.')
    const ext = filename.substring(indexOfDot + 1).toLowerCase();
    return ext === 'pdf'
}

export const isImageFile = (filename) => {
    if (!filename) return false
    const indexOfDot = filename.lastIndexOf('.')
    const ext = filename.substring(indexOfDot + 1).toLowerCase();
    return ext === 'jpg' || ext === 'jpeg' || ext === 'png'
}

export const truncate = (str, len, ellipsis = '...') => _truncate(str, {
    length: len
})


export function generateVATNumber(regNo) {
    // Rensa organisationsnumret från eventuella bindestreck
    const cleanOrgNumber = regNo.replace(/-/g, '');

    // Kontrollera att organisationsnumret består av 10 siffror
    if (cleanOrgNumber.length !== 10) {
        return ''
        // throw new Error('Organisationsnumret måste bestå av 10 siffror.');
    }

    // Skapa VAT-numret genom att lägga till 'SE' i början och '01' i slutet
    return 'SE' + cleanOrgNumber + '01';
}

export const formatAddress = (item) => {
    let result = (item.address?.length > 0 ? item.address.replace('\n', ', ') : '')

    if (result.length > 0 && (item.zip > 0 || item.city?.length > 0)) {
        result += ', '
    }

    result += (item.zip > 0 ? item.zip + ' ' : '')
    result += (item.city?.length > 0 ? item.city : '')

    if (result.length > 0 && item.country && COMPANY.address.country !== item.country) {
        result += ', ' + (Country.findById(item.country)?.name || 'UNKNOWN')
    }

    return result
}

const StringUtils = {
    formatDuration: (timeMillis) => {
        var sec_num = parseInt(timeMillis, 10) / 1000; // don't forget the second param
        var hours = Math.floor(sec_num / 3600);
        var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        var seconds = Math.round(sec_num - (hours * 3600) - (minutes * 60));

        if (hours < 10) {
            hours = "0" + hours;
        }
        if (minutes < 10) {
            minutes = "0" + minutes;
        }
        if (seconds < 10) {
            seconds = "0" + seconds;
        }
        return hours + ':' + minutes + ':' + seconds;
    },

    formatAddress: (item) => {
        let result = (item.address?.length > 0 ? item.address.replace('\n', ', ') : '')

        if (item.zip > 0 || item.city?.length > 0) {
            result += ', '
        }

        result += (item.zip > 0 ? item.zip + ' ' : '')
        result += (item.city?.length > 0 ? item.city : '')

        if (result.length > 0 && item.country && COMPANY.address.country !== item.country) {
            result += ', ' + (Country.findById(item.country)?.name || 'UNKNOWN')
        }

        return result
    }
};

export default StringUtils