import React, {useState} from 'react';
import {AddButton} from "../../components/Buttons/Button";
import {useFilter} from "../../components/Grid/filter";
import Grid from "../../components/Grid/Grid";
import {hideLoading, showLoading} from "../../components/Overlay/Loading";
import Role from "../../enums/Role";
import {matchSearch} from "../../functions/match";
import useEmployees from "../../hooks/useEmployees";
import useMountEffect from "../../hooks/useMountEffect";
import useUserPersmissions from "../../hooks/useUserPersmissions";
import WorkcrewService from "./WorkcrewService";

export default function WorkcrewGrid() {
    const userPersmissions = useUserPersmissions()

    const filter = useFilter('WorkcrewGrid', [
        {name: 'search', defaultValue: ''},
    ])

    const employees = useEmployees()

    const [list, setList] = useState([]);
    const filteredList = list.filter(c => matchSearch(filter, c))

    filteredList.forEach(item => {
        item.employeeNames = item.employeeIdList.map(eId => employees.findById(eId).name).join(', ')
    })


    useMountEffect(async () => {
        showLoading()
        setList(await WorkcrewService.findAll())
        hideLoading()
    })

    const leftFilters = [
        userPersmissions.hasRole(Role.EMPLOYEE_WRITE) ? <div>
            <label className="label">&nbsp;</label>
            <AddButton/>
        </div> : undefined,
    ]

    const columns = [
        {field: 'name', headerI18n: 'name', width: 200},
        {field: 'employeeNames', headerI18n: 'employees'},
    ];

    return <Grid
        labelI18n='work_crews'
        leftFilters={leftFilters}
        filter={filter}
        updateFilter={filter.update}
        columns={columns}
        value={filteredList}
    />
}
