import I18n from '@I18n';
import React, {useCallback, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {MainButton} from "../../../components/Buttons/Button";
import ContextMenuItem from "../../../components/ContextMenu/ContextMenuItem";
import DatePicker from "../../../components/Date/DatePicker";
import {useFilter} from "../../../components/Grid/filter";
import {FooterAggregate} from "../../../components/Grid/getFooterGroup";
import Grid from "../../../components/Grid/Grid";
import {hideLoading, showLoading} from "../../../components/Overlay/Loading";
import renderOverlay from "../../../components/Overlay/renderOverlay";
import Icon from "../../../enums/Icon";
import Role from "../../../enums/Role";
import {matchSearch} from "../../../functions/match";
import {GridTemplates} from "../../../GridTemplates";
import useMountEffect from "../../../hooks/useMountEffect";
import useRoles from "../../../hooks/useRoles";
import {getLastDateOfMonth} from "../../../utils/DateUtils";
import ProjectSummaryDialog from "../../Projects/ProjectSummary/ProjectSummaryDialog";
import ProjectService from "../../Projects/ProjectService";

const OngoingProjectsReport = () => {
    const roles = useRoles()
    const navigate = useNavigate()

    const filter = useFilter('OngoingProjectsReport', [
        {name: 'date', defaultValue: getLastDateOfMonth()},
        {name: 'search', defaultValue: ''},
    ])
    const [list, setList] = useState([]);

    const filteredList = list.filter(item => matchSearch(filter, item))

    const loadData = useCallback(async () => {
        showLoading()
        setList(await ProjectService.ongoingProjectsReport(filter.date))
        hideLoading()
    }, [filter.date]);

    useMountEffect(() => {
        loadData()
    })

    const columns = [
        {field: 'projectName', headerI18n: 'project', minWidth: 150},
        {
            field: 'tenderPrice',
            headerI18n: 'tender_value',
            body: GridTemplates.currency,
            excelBody: (row, column) => row[column.field],
            footer: FooterAggregate.SUM_CURRENCY,
            width: 100, align: 'right'
        },
        {
            field: 'invoiced',
            headerI18n: 'invoiced',
            body: GridTemplates.currency,
            excelBody: (row, column) => row[column.field],
            footer: FooterAggregate.SUM_CURRENCY,
            width: 100, align: 'right'
        },
        {
            field: 'cost',
            headerI18n: 'cost',
            body: GridTemplates.currency,
            excelBody: (row, column) => row[column.field],
            footer: FooterAggregate.SUM_CURRENCY,
            width: 100, align: 'right'
        },
        {
            field: 'profit',
            headerI18n: 'profit',
            body: GridTemplates.currency,
            excelBody: (row, column) => row[column.field],
            footer: FooterAggregate.SUM_CURRENCY,
            width: 100, align: 'right'
        },
        {
            field: 'expectedMargin',
            headerI18n: 'expected_margin_percentage',
            body: GridTemplates.percentTimes100,
            excelBody: (row, column) => row[column.field],
            width: 140, align: 'right'
        },
        {
            field: 'expectedProfit',
            headerI18n: 'expected_profit',
            body: GridTemplates.currency,
            excelBody: (row, column) => row[column.field],
            footer: FooterAggregate.SUM_CURRENCY,
            width: 100, align: 'right'
        },
        {
            field: 'expectedEndCost',
            headerI18n: 'expected_end_cost',
            body: GridTemplates.currency,
            excelBody: (row, column) => row[column.field],
            footer: FooterAggregate.SUM_CURRENCY,
            width: 140, align: 'right'
        },
        {
            field: 'expectedEndProfit',
            headerI18n: 'expected_end_profit',
            body: GridTemplates.currency,
            excelBody: (row, column) => row[column.field],
            footer: FooterAggregate.SUM_CURRENCY,
            width: 120, align: 'right'
        },
        {
            field: 'completionDegree',
            headerI18n: 'completion_degree',
            body: GridTemplates.percentTimes100,
            excelBody: (row, column) => row[column.field],
            width: 120, align: 'right'
        },
        {
            field: 'profitBasadOnCompletionDegree',
            header: 'Vinst utifrån färdigställandegrad',
            body: GridTemplates.currency,
            excelBody: (row, column) => row[column.field],
            footer: FooterAggregate.SUM_CURRENCY,
            width: 120, align: 'right'
        },
        {
            field: 'bookedValue',
            header: 'Bokfört värde',
            body: GridTemplates.currency,
            excelBody: (row, column) => row[column.field],
            footer: FooterAggregate.SUM_CURRENCY,
            width: 120, align: 'right'
        },
        {field: 'projectNote', headerI18n: 'note', minWidth: 100},
    ];

    const leftFilters = [
        <div>
            <label className="label">{I18n.t('date')}</label>
            <DatePicker value={filter.date} onChange={date => filter.update('date', date)}/>
        </div>,
        <div>
            <label className="label">&nbsp;</label>
            <MainButton labelI18n='create_report' onClick={() => loadData()}/>
        </div>
    ]

    const contextMenu = [
        ContextMenuItem({
            i18n: 'project_summary', icon: Icon.LOG,
            command: item => renderOverlay(<ProjectSummaryDialog projectId={item.projectId}/>)
        }),
        ContextMenuItem({
            i18n: 'edit_tender', icon: Icon.TENDER,
            command: item => navigate('/tenders/' + item.tenderId),
            hidden: row => !roles.hasRole(Role.TENDER_READ) || !row?.tenderId,
        }),
        ContextMenuItem({
            i18n: 'do_invoice', icon: Icon.INVOICE,
            command: item => navigate('/invoices/0?project=' + item.projectId),
            hidden: row => !roles.hasRole(Role.INVOICE_WRITE)
        }),
    ]

    return <div className="Grid" style={{padding: '10px 0'}}>
        <Grid id='OngoingProjectsReport'
              forceDesktop={true}
              labelI18n='ongoing_projects'
              icon={Icon.REPORT}
              leftFilters={leftFilters}
              filter={filter}
              updateFilter={filter.update}
              columns={columns}
              value={filteredList}
              contextMenu={contextMenu}
              onRowSelect={item => navigate('/projects/' + item.data.projectId)}
        />
    </div>
}

export default OngoingProjectsReport;