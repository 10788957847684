import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {CancelButton, MainButton} from "../../../../components/Buttons/Button";
import FormItemCheckbox from "../../../../components/EditForm/FormItemCheckbox";
import {useFilter} from "../../../../components/Grid/filter";
import {FooterAggregate} from "../../../../components/Grid/getFooterGroup";
import Grid from "../../../../components/Grid/Grid";
import XlnzDialog from "../../../../components/XlnzDialog/XlnzDialog";
import {GridTemplates} from "../../../../GridTemplates";
import {i18n} from "../../../../I18n/I18n";


export default function FetchPaymentplanrowsDialog(props) {
    const {
        onSave,
        onHide
    } = props

    const filter = useFilter('FetchPaymentplanrowsDialog', [
        {name: 'showTitle', defaultValue: true}
    ])

    const [paymentplandrawList, setPaymentplandrawList] = useState(props.paymentplanrowList.filter(item => item.leftToInvoicePrice !== 0))
    const [selection, setSelection] = useState([])

    function isLeftToInvoice(drawItem) {
        return drawItem.drawIndex === 1000000
    }

    paymentplandrawList.forEach(drawItem => {
        if (isLeftToInvoice(drawItem)) {
            drawItem.name = i18n('retained_funds')
        } else {
            drawItem.name = i18n('draw') + ' ' + (drawItem.drawIndex + 1)
        }

    })


    const defaultValues = {
        showTitle: filter.showTitle,
    }

    const form = {...useForm({defaultValues: defaultValues})}
    const {handleSubmit, formState: {isSubmitting}, getValues} = form

    const onSubmit = () => {
        const data = getValues();
        filter.update('showTitle', data.showTitle)
        onSave(paymentplandrawList.filter(item => item.priceToInvoice !== 0), {
            showTitle: data.showTitle,
        })
        onHide()
    }

    // const onQuantityToInvoice = list => {
    //     setPaymentplandrawList(list)
    // }

    const columns = [
        {field: 'name', header: ''},
        {field: 'date', headerI18n: 'date', width: 90},
        {
            field: 'price', headerI18n: 'price', body: GridTemplates.currencyTwoDecimals, align: 'right',
            footer: FooterAggregate.SUM_CURRENCY_TWO_DECIMALS,
            width: 120
        },
        {
            field: 'retainedFunds',
            headerI18n: 'retained_funds',
            body: GridTemplates.currencyTwoDecimals,
            align: 'right',
            footer: FooterAggregate.SUM_CURRENCY_TWO_DECIMALS,
            width: 120
        },
        {
            field: 'leftToInvoicePrice',
            headerI18n: 'to_invoice',
            body: GridTemplates.currencyTwoDecimals,
            align: 'right',
            footer: FooterAggregate.SUM_CURRENCY_TWO_DECIMALS,
            width: 120
        },
        // {
        //     field: 'priceToInvoice', headerI18n: 'to_invoice', align: 'right',
        //     body: GridTemplates.currencyTwoDecimals,
        //     editor: e => <NumberGridInput editorEvent={e}/>,
        //     onCellEditComplete: e => {
        //         let newValue = e.newValue
        //         const row = e.rowData
        //         if (row.leftToInvoicePrice > 0 && newValue > row.leftToInvoicePrice) {
        //             newValue = row.leftToInvoicePrice
        //         } else if (row.leftToInvoicePrice < 0 && newValue < row.leftToInvoicePrice) {
        //             newValue = row.leftToInvoicePrice
        //         }
        //         onRowChange(e, newValue, onQuantityToInvoice)
        //
        //         // Check the box
        //         if (notContains(selection, row)) {
        //             const _selection = [...selection]
        //             _selection.push(row)
        //             setSelection(_selection)
        //         }
        //     },
        //     footer: FooterAggregate.SUM_CURRENCY_TWO_DECIMALS,
        //     width: 120
        // },
        {field: 'selection', selectionMode: 'multiple', width: 40},
    ]

    function onSelectionChange(e) {
        if (e.row) {
            e.row.priceToInvoice = e.checked ? e.row.leftToInvoicePrice : 0
        } else {
            const _paymentplanrowList = [...paymentplandrawList]
            _paymentplanrowList.forEach(row => row.priceToInvoice = e.checked ? row.leftToInvoicePrice : 0)
            setPaymentplandrawList(_paymentplanrowList)
        }
        setSelection(e.value)
    }

    return <XlnzDialog headerI18n='payment_plan'
                       onHide={props.onHide}
                       leftButtons={() => [
                           <FormItemCheckbox key='showTitle' name='showTitle' labelI18n='show_title' {...form}/>
                       ]}
                       buttons={() => [
                           <MainButton key='do_invoice' labelI18n='do_invoice' onClick={e => handleSubmit(onSubmit)()}
                                       disabled={isSubmitting}/>,
                           <CancelButton key='cancel' onClick={e => props.onHide()}/>
                       ]}
    >
        <div style={{width: '750px'}}>
            <Grid forceDesktop={true}
                  columns={columns}
                  value={paymentplandrawList}
                  heightOffset={370}
                  selection={selection}
                  onSelectionChange={onSelectionChange}
                  rowClassName={e => ({'bold': e.headline})}
            />
        </div>

    </XlnzDialog>
}