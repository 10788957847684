import React, {useContext} from 'react';
import AppContext from "../../../../../AppContext";
import CheckboxEditor from "../../../../../components/Grid/CheckboxEditor";
import EmployeeSelectEditor from "../../../../../components/Grid/EmployeeSelectEditor";
import {addFreeRows, isFreeRow, numOfFreeRows} from "../../../../../components/Grid/freeRows";
import Grid from "../../../../../components/Grid/Grid";
import NumberGridInput from "../../../../../components/Grid/NumberGridInput";
import onRowChange from "../../../../../components/Grid/onRowChange";
import RemoveColumn from "../../../../../components/Grid/RemoveColumn";
import SelectEditor from "../../../../../components/Grid/SelectEditor";
import {GridTemplates} from "../../../../../GridTemplates";
import useEmployees from "../../../../../hooks/useEmployees";
import usePreload, {PreloadType} from "../../../../../hooks/usePreload";
import {findById} from "../../../../../utils/ArrayUtil";
import {NumberUtils} from "../../../../../utils/NumberUtils";

const NUM_OF_FREE_ROWS = 1

const TimeregabsenceGrid = props => {
    const {
        value,
        date,
        disabled,
        onChange
    } = props

    const context = useContext(AppContext);
    const preload = usePreload()

    const employees = useEmployees()
    const timeregabsencetypeList = preload.get(PreloadType.TIMEREGABSENCETYPE_LIST)

    const rowList = disabled ? value : addFreeRows(value, NUM_OF_FREE_ROWS, {
            company: context.currentCompany.id,
            date: date,
            hours: 0,
        }
    )

    const getEmployee = id => employees.findById(id)
    const getType = id => findById(timeregabsencetypeList, id)

    const fireChange = (list) => {
        const slicedList = list.slice(0, list.length - numOfFreeRows(list))
            .filter(item => item.employee > 0)
        onChange(slicedList);
    }

    const updateCustomFieldValue = (editorEvent, field, value) => {
        const salarytypeEditorEvent = Object.assign({}, editorEvent)
        salarytypeEditorEvent.field = field
        onRowChange(salarytypeEditorEvent, value, fireChange)
    }

    const columns = [
        {
            field: 'employee', headerI18n: 'employee',
            body: row => <div className='nowrap' style={{width: '100%'}}>{getEmployee(row.employee)?.name}</div>,
            editor: e => <EmployeeSelectEditor event={e}/>,
            onCellEditComplete: e => onRowChange(e, e.newValue, fireChange),
        },
        {
            field: 'hours', headerI18n: 'time',
            body: GridTemplates.hours,
            editor: e => <NumberGridInput editorEvent={e} min={0} max={999}/>,
            onCellEditComplete: e => {
                updateCustomFieldValue(e, 'notarized', true)
                onRowChange(e, NumberUtils.round(Number(e.newValue), 2), fireChange)
            },
            width: 120,
            align: 'right'
        },

        {
            field: 'type', headerI18n: 'absence_type',
            body: row => <div className='nowrap' style={{width: '100%'}}>{getType(row.type)?.name}</div>,
            editor: e =>
                <SelectEditor event={e} options={timeregabsencetypeList.filter(item => item.active)} optionValue='id'
                              onChange={e2 => e.editorCallback(e2?.value)}/>,
            onCellEditComplete: e => onRowChange(e, e.newValue, fireChange),
            width: 200,
        },
        {
            field: 'notarized', headerI18n: 'notarized',
            body: (rowData, e) => isFreeRow(rowList, rowData)
                ? ''
                : CheckboxEditor(Object.assign(e, {rowData: rowData}), fireChange, disabled),
            rendererIsEditor: true,
            width: 80,
            align: 'center'
        }
    ]

    if (!disabled) {
        columns.push(RemoveColumn(row => fireChange(rowList.filter(r => r !== row)), NUM_OF_FREE_ROWS))
    }


    return <div className='TimeregabsenceGrid' style={{width: '700px'}}>
        <Grid forceDesktop={true}
              disabled={disabled}
              columns={columns}
              value={rowList}
              sortable={false}
              scrollable={false}
        />
    </div>

}
export default TimeregabsenceGrid;