import React from 'react';
import FormItemCheckbox from "../../components/EditForm/FormItemCheckbox";
import FormItemNumber from "../../components/EditForm/FormItemNumber";
import FormItemText from "../../components/EditForm/FormItemText";
import SimpleEditForm from "../../components/EditForm/SimpleEditForm";
import Role from "../../enums/Role";
import Unit from "../../enums/Unit";
import {PreloadType} from "../../hooks/usePreload";
import {i18n} from "../../I18n/I18n";
import TrainingtypeService from "./TrainingtypeService";

const TrainingtypeEditForm = () => {
    return <SimpleEditForm
        writeRole={Role.ADMIN}
        headlineI18n='training_type'
        defaultValues={{
            monthsValid: 0,
            active: true
        }}
        loadFunction={TrainingtypeService.findById}
        saveFunction={TrainingtypeService.update}
        legalCompany={true}
        initLeftFields={form => [
            <FormItemText key='name' name='name' labelI18n='name' maxLength={250} required {...form}/>,
            <FormItemNumber key='monthsValid' name='monthsValid'
                            label={i18n('validity_time') + ' (' + Unit.MONTH.name + ')'} required {...form}/>,
            <FormItemCheckbox key='active' name='active' labelI18n='active' {...form}/>
        ]}
        preloadType={PreloadType.TRAININGTYPE_LIST}
    />
}

export default TrainingtypeEditForm;
