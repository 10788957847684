import Grid from "@components/Grid/Grid";
import React from 'react';
import Button from "../../components/Buttons/Button";
import {hideLoading, showLoading} from "../../components/Overlay/Loading";
import XlnzCard from "../../components/XlnzCard/XlnzCard";
import {GridTemplates} from "../../GridTemplates";
import SuperadminService from "./SuperadminService";

const ErrorLogs = props => {
    const {
        value,
        onReload
    } = props

    // Needed for footer total value.
    value.forEach((item, index) => item.id = index + 1)

    const columns = [
        {
            field: 'time', header: 'Time',
            body: GridTemplates.time,
            width: 140,
        },
        {field: 'threadName', header: 'Thread', width: 150},
        {field: 'loggerName', header: 'Logger'},
        {field: 'message', header: 'Message', body: GridTemplates.multilineString},
    ]


    return <XlnzCard>
        <div className="Grid">
            <div style={{display: 'flex', alignItems: 'center'}}>
                <h1 style={{display: 'flex', flexGrow: 1}}>Error Logs</h1>
                <Button labelI18n='clear' onClick={async () => {
                    showLoading()
                    await SuperadminService.deleteErrorLogs()
                    onReload()
                    hideLoading()
                }}/>
            </div>
            <Grid
                scrollable={false}
                columns={columns}
                value={value}
                onRowSelect={() => {
                }}
                forceDesktop={true}
            />
        </div>
    </XlnzCard>

}
export default ErrorLogs;

/**
 {
 "threadName": "http-nio-8080-exec-6",
 "loggerName": "se.verktyg1.adapter.in.SuperadminController",
 "loggerContextVO": {
 "name": "default",
 "propertyMap": {
 "FILE_LOG_PATTERN": "%d{yyyy-MM-dd'T'HH:mm:ss.SSSXXX} %5p 2640 --- [%t] %-40.40logger{39} : %m%n%wEx",
 "FILE_LOG_CHARSET": "UTF-8",
 "org.jboss.logging.provider": "slf4j",
 "PID": "2640",
 "CONSOLE_LOG_PATTERN": "%clr(%d{yyyy-MM-dd'T'HH:mm:ss.SSSXXX}){faint} %clr(%5p) %clr(2640){magenta} %clr(---){faint} %clr([%15.15t]){faint} %clr(%-40.40logger{39}){cyan} %clr(:){faint} %m%n%wEx",
 "CONSOLE_LOG_CHARSET": "UTF-8"
 },
 "birthTime": 1676403437974
 },
 "level": {
 "levelInt": 40000,
 "levelStr": "ERROR"
 },
 "message": "error",
 "formattedMessage": "error",
 "instant": "2023-02-14T19:40:43.245336300Z",
 "timeStamp": 1676403643245,
 "nanoseconds": 245336300,
 "sequenceNumber": 0,
 "mdcpropertyMap": {},
 "callerData": [],
 "mdc": {},
 "contextBirthTime": 1676403437974
 }
 */