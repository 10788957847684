import Accounts from './Accounts';
import Agreements from "./Agreements";
import Articles from './Articles';
import Companies from "./Companies";
import Costcenters from "./Costcenters";
import Customers from './Customers';
import Dashboard from "./Dashboard";
import Documents from "./Documents";

import Employees from './Employees';
import Forms from "./Forms";
import FortnoxView from "./Fortnox";
import Hyrlet from "./Hyrlet";
import I18nsIndex from "./I18ns";
import Increments from "./Increments";
import Invoices from './Invoices';
import Kma from "./Kma";
import Leads from "./Leads";
import Pricelists from "./Pricelists";
import Projects from './Projects';
import Reports from "./Reports";
import Superadmin from './Superadmin';
import Supplierinvoices from "./Supplierinvoices";
import Suppliers from './Suppliers';
import Tenders from './Tenders';
import Tenderterms from "./Tenderterms";
import Timereg from './Timereg';
import Timeregabsencetypes from './Timeregabsencetypes';
import Timeregallowancetypes from './Timeregallowancetypes';
import Timeregdebittypes from './Timeregdebittypes';
import Timeregsalarytypes from './Timeregsalarytypes';
import Trainingtypes from "./Trainingtypes";
import Usergroups from './Usergroups';
import Users from './Users';
import Workcrews from "./Workcrews";

export const modules = [
    Dashboard,
    Leads,
    Customers,
    Agreements,
    Tenders,
    Projects,
    Timereg,
    Invoices,
    Supplierinvoices,
    Documents,
    Reports,
    Hyrlet,
    Superadmin,
];

export const registerModules = [
    Accounts,
    Articles,
    Employees,
    Costcenters,
    Pricelists,
    Suppliers,
    Timeregsalarytypes,
    Timeregdebittypes,
    Timeregabsencetypes,
    Timeregallowancetypes,
    Trainingtypes,
    Workcrews
];

export const settingModules = [
    Users,
    Usergroups,
    Companies,
    Kma,
    I18nsIndex,
    FortnoxView,
    Tenderterms,
    Forms,
    Increments
];

export const allModules = [...modules, ...registerModules, ...settingModules]

export function getModule(path) {
    path = path.split("?")[0];
    let m = allModules.find(m => m.routeProps.path === path);
    if (!m) {
        m = allModules
            .filter(m => m.routeProps.items?.length > 0)
            .flatMap(m => m.routeProps.items)
            .find(i => i.path === path);
    }
    return m;
}