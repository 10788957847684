import React, {useRef} from "react";
import './Checkbox.scss'
import makeId from "../../functions/makeId";

export default function Checkbox(props) {
    const {
        checked,
        label,
        labelStyle,
        onChange,
        disabled
    } = props

    const idRef = useRef(makeId());

    return <div className='Checkbox'>
        <input id={idRef.current}
               type='checkbox'
               checked={checked || false}
               onChange={e => {
                   // The value to receive: e.target.checked
                   if (onChange) onChange(e)
               }}
               disabled={disabled}

        />
        {label && <label htmlFor={idRef.current} style={{...labelStyle, paddingLeft: '5px'}}>{label}</label>}

    </div>
}