import {i18n} from "../../../I18n/I18n";
import {findByField} from "../../../utils/ArrayUtil";

const QUESTION = 'QUESTION';
const DEVIATION = 'DEVIATION';
const MESSAGE = 'MESSAGE';

export default class QnaType {
    static get QUESTION() {
        return Object.freeze({
            id: QUESTION,
            label: i18n('question'),
            labelMultiple: i18n('questions')
        })
    }

    static get DEVIATION() {
        return Object.freeze({
            id: DEVIATION,
            label: i18n('deviation'),
            labelMultiple: i18n('deviations')
        })
    }

    static get MESSAGE() {
        return Object.freeze({
            id: MESSAGE,
            label: i18n('message'),
            labelMultiple: i18n('messages')
        })
    }

    static values() {
        return [
            this.QUESTION,
            this.DEVIATION,
            this.MESSAGE,
        ];
    }

    static findById(id) {
        return findByField(this.values(), 'id', id)
    }
}