import {createRoot} from "react-dom/client";
import {pdfjs} from "react-pdf";
import App from "./App";
import {initLoader} from "./components/Overlay/Loading";
import {FortnoxIntegrationRedirect} from "./external/FortnoxIntegrationRedirect/FortnoxIntegrationRedirect";
import {SupplierinvoiceAttest} from "./external/SupplierinvoiceAttest/SupplierinvoiceAttest";
import {TenderAnswer} from "./external/TenderAnswer/TenderAnswer";
import './index.scss';
import {Unsubscribe} from "./external/Unsubscribe/Unsubscribe";
import {FORTNOX_INTEGRATION_STATE} from "./modules/Fortnox/FortnoxAuthorizeButton";
import Env from "./utils/Env";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;
// pdfjs.GlobalWorkerOptions.workerSrc = `pdf.worker.min.${pdfjs.version}.mjs`;


// if (isBrowserOutdated()) {
//     createRoot(document.getElementById('root')).render(<TooOldBrowserPage/>)
// } else {

initLoader()

const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
});


function getParam(name) {
    return params[name]?.length > 0 ? params[name] : undefined
}

const supplierInvoiceAttestToken = getParam('supplier-invoice-attest-token')

if (params.token && params.token.length > 0 && params.token[0] === 'o') {
    createRoot(document.getElementById('root')).render(<TenderAnswer token={params.token}/>)
} else if (supplierInvoiceAttestToken) {
    createRoot(document.getElementById('root')).render(<SupplierinvoiceAttest token={supplierInvoiceAttestToken}/>)
} else if (isFortnoxRedirect(params)) {
    createRoot(document.getElementById('root')).render(
        <FortnoxIntegrationRedirect
            code={params.code}
            authState={params.state}
        />)
// } else if (isPdfPopup(params)) {
//     createRoot(document.getElementById('root')).render(<PdfPopup pdfurl={params.pdfurl}/>)
} else if (isUnsubscribe(params)) {
    createRoot(document.getElementById('root')).render(<Unsubscribe token={params.unsubscribe}/>)
} else {
    createRoot(document.getElementById('root')).render(<App/>)
}


function isFortnoxRedirect(params) {
    const result = params?.state?.indexOf(FORTNOX_INTEGRATION_STATE) >= 0 && params.code?.length > 0

    if (Env.isDev() && window.location.host.indexOf('ngrok.io') !== -1) {
        console.log("Redirecting to localhost...")
        const url = 'http://localhost:3000/' + window.location.search
        console.log(url)
        window.location.href = url
    }

    return result
}

// function isPdfPopup(params) {
//     return params.pdfurl && params.pdfurl.length > 0
// }

function isUnsubscribe(params) {
    return params.unsubscribe?.length > 0
}
