import React from 'react';
import {useForm} from "react-hook-form";
import {CancelButton, SendButton} from "../Buttons/Button";
import FormItemEmail from "../EditForm/FormItemEmail";
import FormItemTextArea from "../EditForm/FormItemTextArea";
import XlnzDialog from "../XlnzDialog/XlnzDialog";

export default function SendByEmailDialog({info, email, message, customFields, onSend, onHide}) {
    const defaultValues = {
        toEmail: email,
        message: message
    }

    const form = {...useForm({defaultValues: defaultValues})}
    const {formState, handleSubmit} = form

    const onSubmit = data => {
        onSend(data.toEmail, data.message, data)
        onHide()
    }

    return <XlnzDialog headerI18n='send_by_email'
                       onHide={onHide}
                       buttons={() => [
                           <SendButton key='send' labelI18n='send'
                                       disabled={formState.isSubmitting}
                                       onClick={handleSubmit(onSubmit)}/>,
                           <CancelButton key='cancel' onClick={e => onHide()}/>
                       ]}
    >

        <div className='EditForm' style={{width: '500px', margin: '0'}}>
            <form onSubmit={handleSubmit(onSubmit)}>
                {info}
                <FormItemEmail name='toEmail' labelI18n='email' required {...form}/>
                {message && <FormItemTextArea name='message' labelI18n='message' {...form}/>}

                {customFields && customFields(form)}
            </form>
        </div>
    </XlnzDialog>
}
