import React from 'react';
import {Controller} from "react-hook-form";
import Label from "../../../components/EditForm/Label";
import {addFreeRows} from "../../../components/Grid/freeRows";
import Grid from "../../../components/Grid/Grid";
import onRowChange from "../../../components/Grid/onRowChange";
import RemoveColumn from "../../../components/Grid/RemoveColumn";
import SelectEditor from "../../../components/Grid/SelectEditor";
import useEmployees from "../../../hooks/useEmployees";
import {i18n} from "../../../I18n/I18n";

const NUM_OF_FREE_ROWS = 1

export default function EmpTimeregEmployeeGridFormItem(props) {
    const {
        name,
        control,
    } = props

    return <div className='EmpTimeregEmployeeGridFormItem'>
        <div className="formItem">
            <Label valueI18n='only_visible_to'/>
            <Controller name={name}
                        control={control}
                        render={({field: {ref, value, onChange}}) =>
                            <EmpTimeregEmployeeGrid
                                inputRef={ref}
                                value={value}
                                onChange={onChange}
                            />
                        }/>
        </div>
    </div>
}

function EmpTimeregEmployeeGrid(props) {
    const {
        value,
        disabled,
        onChange
    } = props

    const employees = useEmployees()

    const selectableEmployees = employees.activeUsable.filter(item => !value.includes(item.id))


    const fireChange = (list) => {
        let result = list
            .filter(item => item.id > 0)
            .map(item => item.id)
        onChange(result)
    }


    const columns = [
        {
            field: 'id', header: i18n('employee'),
            body: row => employees.findById(row.id)?.name,
            editor: e => <SelectEditor event={e} options={selectableEmployees}
                                       optionValue='id'
                                       hideNoneOption={true}
                                       onChange={e2 => e.editorCallback(e2?.value, true)}
            />,
            onCellEditComplete: e => onRowChange(e, e.newValue, fireChange),
        }
    ]

    if (!disabled) columns.push(RemoveColumn(row => onChange(value.filter(id => id !== row.id)), NUM_OF_FREE_ROWS))


    const rowClassName = data => {
        return {
            'bold': data.headline,
        }
    }

    function getRowList(value) {
        let result = value.map(item => ({id: item}))
        if (!disabled && selectableEmployees.length > 0)
            result = addFreeRows(result, NUM_OF_FREE_ROWS, {id: 0})
        return result
    }

    return <Grid
        scrollable={false}
        disabled={disabled}
        columns={columns}
        value={getRowList(value)}
        footerColumnGroup={<div/>}
        onRowReorder={disabled ? undefined : e => fireChange([...e.value])}
        sortable={false}
        rowClassName={rowClassName}
    />
}