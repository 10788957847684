import React, {useState} from 'react';
import {AddButton} from "../../components/Buttons/Button";
import ActiveFilter from "../../components/Grid/ActiveFilter";
import {useFilter} from "../../components/Grid/filter";
import Grid from "../../components/Grid/Grid";
import {hideLoading, showLoading} from "../../components/Overlay/Loading";
import Role from "../../enums/Role";
import {matchActive, matchSearch} from "../../functions/match";
import {GridTemplates} from "../../GridTemplates";
import useMountEffect from "../../hooks/useMountEffect";
import useRoles from "../../hooks/useRoles";
import {i18n} from "../../I18n/I18n";
import {NumberUtils} from "../../utils/NumberUtils";
import TrainingtypeService from "./TrainingtypeService";

const TrainingtypeGrid = () => {
    const roles = useRoles()

    const filter = useFilter('TrainingtypeGrid', [
        {name: 'active', defaultValue: true},
        {name: 'search', defaultValue: ''},
    ])

    const [list, setList] = useState([]);
    const filteredList = list.filter(c => matchActive(filter, c) && matchSearch(filter, c))

    useMountEffect(async () => {
        showLoading()
        setList(await TrainingtypeService.findAll())
        hideLoading()
    }, [])

    const leftFilters = [
        roles.hasRole(Role.ADMIN) ? <div>
            <label className="label">&nbsp;</label>
            <AddButton/>
        </div> : undefined,
        <div>
            <label className="label">{i18n('status')}</label>
            <ActiveFilter value={filter.active} onChange={e => filter.update('active', e.value)}/>
        </div>
    ]

    const columns = [
        {field: 'name', headerI18n: 'name'},
        {
            field: 'monthsValid',
            header: i18n('validity_time'),
            body: rowData => NumberUtils.formatMonths(rowData.monthsValid, 0),
            align: 'right',
            width: 120,
            mobile: true
        },
        {field: 'active', headerI18n: 'active', body: GridTemplates.boolean, width: 100},
    ];

    return <Grid
        id='TrainingtypeGrid'
        labelI18n='training_types'
        leftFilters={leftFilters}
        filter={filter}
        updateFilter={filter.update}
        columns={columns}
        value={filteredList}
    />
}

export default TrainingtypeGrid;
