import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import MonthDropdown from "../../../components/Date/MonthDropdown";
import YearFilter from "../../../components/filters/YearFilter";
import {useFilter} from "../../../components/Grid/filter";
import {FooterAggregate} from "../../../components/Grid/getFooterGroup";
import Grid from "../../../components/Grid/Grid";
import PdfColumn from "../../../components/Grid/PdfColumn";
import {hideLoading, showLoading} from "../../../components/Overlay/Loading";
import XlnzDropdown from "../../../components/XlnzDropdown/XlnzDropdown";
import Icon from "../../../enums/Icon";
import {GridTemplates} from "../../../GridTemplates";
import {i18n} from "../../../I18n/I18n";
import {todayYear} from "../../../utils/DateUtils";
import NetUtils from "../../../utils/NetUtils";
import ProjectStatus from "../../Projects/ProjectStatus";

const TenderFollowups = () => {
    const navigate = useNavigate()

    const filter = useFilter('TenderFollowups', [
        {name: 'status', defaultValue: ProjectStatus.ONGOING.id},
        {name: 'year', defaultValue: todayYear()},
        {name: 'month', defaultValue: undefined},
        {name: 'search', defaultValue: ''},
    ])

    const [list, setList] = useState([]);

    useEffect(() => {
        async function fetchData() {
            showLoading()
            const params = {
                status: filter.status,
                year: filter.year,
                month: filter.month,
                search: filter.search
            }

            const _list = await NetUtils.doGet('/api/reports/tender-followups', params)
            setList(_list);
            hideLoading()
        }

        fetchData()
    }, [filter.status, filter.year, filter.month, filter.search])

    const columns = [
        PdfColumn(() => true,
            rowData => rowData.number + ' - ' + rowData.name,
            rowData => '/api/tenders/' + rowData.id + '/pdf'
        ),
        {field: 'name', headerI18n: 'name', minWidth: 150},
        {field: 'number', headerI18n: 'number', width: 100},
        {field: 'ourReferenceName', headerI18n: 'our_reference', width: 150},
        {field: 'customerName', headerI18n: 'customer', minWidth: 150},
        {
            field: 'tenderHours',
            headerI18n: 'tender_hours',
            body: GridTemplates.hours,
            excelBody: (row, column) => row[column.field],
            align: 'right',
            width: 140,
            footer: FooterAggregate.SUM_HOURS
        },
        {
            field: 'workedHours',
            headerI18n: 'worked_hours',
            body: GridTemplates.hours,
            excelBody: (row, column) => row[column.field],
            align: 'right',
            width: 140,
            footer: FooterAggregate.SUM_HOURS
        },
        {field: 'note', headerI18n: 'note', body: GridTemplates.stringLimit100, minWidth: 100}
    ];

    const leftFilters = [
        <div>
            <label className="label">{i18n('status')}</label>
            <XlnzDropdown
                value={filter.status}
                options={[ProjectStatus.ONGOING, ProjectStatus.COMPLETED]}
                optionLabel='labelMultiple'
                onChange={e => filter.update('status', e.value)}
                style={{width: '150px'}}/>
        </div>,
        <div>
            <label className="label">{i18n('year')}</label>
            <YearFilter value={filter.year} onChange={e => filter.update('year', e.value)}/>
        </div>,
        <div>
            <label className="label">{i18n('month')}</label>
            <MonthDropdown value={filter.month} onChange={e => {
                filter.update('month', e.value)
            }}/>
        </div>
    ]

    return <Grid
        id='TenderFollowups'
        forceDesktop={true}
        labelI18n='tender_followup'
        icon={Icon.REPORT}
        leftFilters={leftFilters}
        filter={filter}
        updateFilter={filter.update}
        columns={columns}
        value={list}
        onRowSelect={item => navigate('/tenders/' + item.data.id)}
    />
}

export default TenderFollowups;
