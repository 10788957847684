import NetUtils from "../../utils/NetUtils";

const SupplierinvoiceService = {
    findAll: params => NetUtils.doGet('/api/supplierinvoices', params),
    findById: id => NetUtils.doGet('/api/supplierinvoices/' + id),
    findSupplierAndNumber: (supplierId, number) => NetUtils.doGet('/api/supplierinvoices/by-supplier-and-number', {
        supplierId: supplierId,
        number: number
    }),
    findInvoice: (invoiceId) => NetUtils.doGet('/api/supplierinvoices/by-invoice', {invoiceId: invoiceId}),
    findByProject: projectId => NetUtils.doGet('/api/supplierinvoices/project/' + projectId),
    update: (model, files, filesName) => NetUtils.doPostWithFiles('/api/supplierinvoices', model, files, filesName),

    getImageUrl: id => NetUtils.doGet('/api/supplierinvoices/' + id + '/image-url'),

    downloadSupplierinvoicePdf: async invoiceId => NetUtils.downloadFile('/api/supplierinvoices/' + invoiceId + '/pdf'),

    interpretPdf: async invoiceId => NetUtils.doGet('/api/supplierinvoices/' + invoiceId + '/interpret-pdf'),
    exportToFortnox: async (invoiceId, bookkeep) => NetUtils.doGet('/api/supplierinvoices/' + invoiceId + '/export-to-fortnox', {bookkeep: bookkeep}),

    sendByEmail: async (invoiceId, toEmail) => NetUtils.doGet('/api/supplierinvoices/' + invoiceId + '/send-by-email', {toEmail: toEmail}),

    generateSelfInvoiceForCustomers: (model) => NetUtils.doPost('/api/supplierinvoices/generate-self-invoice-for-customers', model),

    attest: async (supplierinvoiceId, employeeId, approved, comment) =>
        NetUtils.doGet('/api/supplierinvoices/' + supplierinvoiceId + '/attest', {
            employeeId: employeeId,
            approved: approved,
            comment: comment
        }),

    numToAttest: () => NetUtils.doGet('/api/supplierinvoices/num-to-attest'),

    fetchFromFortnox: () => NetUtils.doGet('/api/supplierinvoices/fetch-from-fortnox'),
    fetchFromMailInbox: () => NetUtils.doGet('/api/supplierinvoices/fetch-from-mail-inbox'),

    getAutoCodeAccounts: (id, supplierId, priceInclVat, vatPrice) => NetUtils.doGet('/api/supplierinvoices/auto-code-accounts', {
        id: id,
        supplierId: supplierId,
        priceInclVat: priceInclVat,
        vatPrice: vatPrice

    }),
}
export default SupplierinvoiceService;