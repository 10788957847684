import React from 'react';
import Grid from "../../components/Grid/Grid";

const OnlineUsersGrid = ({value}) => {
    const columns = [
        {field: 'user.id', header: 'ID', width: 80},
        {field: 'user.name', header: 'User', width: 150},
        {field: 'company.label', header: 'Company'},
        {field: 'ipAddress', header: 'IP-Address', width: 110},
        {field: 'activeTimeString', header: 'Active Time', width: 110, align: 'right'},
        {field: 'idleTimeString', header: 'Idle Time', width: 110, align: 'right'},
    ]

    return <div style={{width: '900px'}}>
        <Grid
            scrollable={false}
            columns={columns}
            value={value}
            onRowSelect={() => {
            }}
            forceDesktop={true}
        />
    </div>
}
export default OnlineUsersGrid;