import {faXmark} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useRef, useState} from 'react';
import {useForm, useWatch} from "react-hook-form";
import {PdfViewerModal} from "../../../components/Appendix/PdfViewer";
import {CancelButton, RemoveButton, SaveButton} from "../../../components/Buttons/Button";
import FormItemCheckbox from "../../../components/EditForm/FormItemCheckbox";
import FormItemDate from "../../../components/EditForm/FormItemDate";
import FormItemDropdown from "../../../components/EditForm/FormItemDropdown";
import FormItemOptionalDate from "../../../components/EditForm/FormItemOptionalDate";
import FormItemText from "../../../components/EditForm/FormItemText";
import FormItemTextArea from "../../../components/EditForm/FormItemTextArea";
import {confirmYes} from "../../../components/Overlay/Confirm";
import {hideLoading, showLoading} from "../../../components/Overlay/Loading";
import renderOverlay from "../../../components/Overlay/renderOverlay";
import XlnzDialog from "../../../components/XlnzDialog/XlnzDialog";
import XlnzFileUpload from "../../../components/XlnzFileUpload/XlnzFileUpload";
import Icon from "../../../enums/Icon";
import Role from "../../../enums/Role";
import usePreload from "../../../hooks/usePreload";
import useRoles from "../../../hooks/useRoles";
import {i18n} from "../../../I18n/I18n";
import {filterActive, findById, findMaxId} from "../../../utils/ArrayUtil";
import {addMonths} from "../../../utils/DateUtils";
import {getConvertedImages} from "../../../utils/ImageUtils";
import EmployeeService from "../EmployeeService";

const TrainingEditFormDialog = props => {
    const {
        model: initModel,
        onHide,
        form: employeeForm,
        save: employeeSave,
    } = props

    const {
        getValues: employeeGetValues,
        reset: employeeReset
    } = employeeForm

    const roles = useRoles()
    const disabled = !roles.hasRole(Role.EMPLOYEE_WRITE)
    const [model, setModel] = useState(initModel)

    const preload = usePreload()
    const trainingtypeList = preload.getTrainingtypeList()

    const form = {...useForm({defaultValues: model}), disabled: disabled}
    const {handleSubmit, formState: {isSubmitting}, getValues, setValue, control, reset} = form

    const watchCertificateIssued = useWatch({control, name: 'certificateIssued'})

    const fileUploadRef = useRef(null);

    const onSubmit = async data => {
        const _model = Object.assign(model, data);
        props.onSave(_model);
        await employeeSave(employeeGetValues())
        props.onHide()
    }

    const onRemove = async () => {
        props.onRemove(model);
        props.onHide()
    }

    function onTypeChange(e) {
        const type = findById(trainingtypeList, e.target.value)
        if (type) {
            if (type.monthsValid > 0) {
                const validUntil = addMonths(getValues('date'), type.monthsValid)
                setValue('validUntil', validUntil)
            } else {
                setValue('validUntil', null)
            }
        }
    }

    function onDateChange(date) {
        const type = findById(trainingtypeList, getValues('type'))
        if (type?.monthsValid > 0) {
            const validUntil = addMonths(date, type.monthsValid)
            setValue('validUntil', validUntil)
        }
    }

    async function uploadHandler(e) {
        showLoading()

        if (!model.id) {
            props.onSave(Object.assign(model, getValues()))
            const _savedEmployee = await employeeSave(employeeGetValues())
            const id = findMaxId(_savedEmployee.trainingList)
            model.id = id
            model.employee = _savedEmployee.id
            reset({...model})
        }


        const data = getValues()
        const _files = await getConvertedImages(e.files)

        if (!data.employee || !data.id) {
            console.log("data.employee, data.id", data.employee, data.id)
            throw new Error("employee or id is null");
        }

        const _employee = await EmployeeService.uploadTrainingCertificate(data.employee, data.id, _files)
        afterFileUpload(_employee)
        fileUploadRef.current.clear()
        hideLoading()
    }

    async function afterFileUpload(_employee) {
        employeeReset(_employee)
        const _model = findById(_employee.trainingList, model.id)
        setModel(_model)
        reset(_model)
        // console.log("onFileUpload", model, files);
        // EmployeeService.uploadTrainingCertificate()
    }

    return <XlnzDialog headerI18n='training'
                       onHide={onHide}
                       buttons={() => [
                           disabled
                               ? undefined
                               : <SaveButton key='save' labelI18n='save'
                                             disabled={isSubmitting}
                                             onClick={handleSubmit(onSubmit)}/>,
                           disabled || !model?.id
                               ? undefined
                               : <RemoveButton key='remove' onClick={e => onRemove()}/>,
                           <CancelButton key='cancel' onClick={e => onHide()}/>
                       ]}
    >

        <div className="EditForm" style={{width: '400px'}}>
            <form>
                <FormItemDropdown key='type' name='type' labelI18n='training_type'
                                  options={filterActive(trainingtypeList, getValues('type'))}
                                  onChange={onTypeChange}
                                  required
                                  showSelect={true}
                                  {...form} />
                <FormItemDate name='date' labelI18n='date' onChange={onDateChange} {...form}/>
                <FormItemText name='location' labelI18n='location' maxLength={250} {...form}/>
                <FormItemCheckbox name='approved' labelI18n='approved' {...form}/>
                <FormItemOptionalDate name='validUntil' labelI18n='valid_until' {...form}/>
                <FormItemCheckbox name='certificateIssued' labelI18n='certificate_issued' {...form}/>
                {
                    watchCertificateIssued &&
                    <>
                        {
                            model.fileSize > 0 &&
                            <div style={{
                                width: 'fit-content',
                                marginTop: '10px',
                                padding: '10px',
                                border: '1px solid #999',
                                borderRadius: '5px',
                                backgroundColor: '#eee',

                            }}>
                                <FontAwesomeIcon
                                    icon={Icon.PDF}
                                    onClick={async e => {
                                        e.stopPropagation()
                                        const imageUrl = await (await EmployeeService.getImageUrl(model.employee, model.id)).url
                                        renderOverlay(<PdfViewerModal label={i18n('certificate')}
                                                                      url={imageUrl}/>)
                                    }}
                                    style={{fontSize: '16px', opacity: '75%', paddingRight: '10px', cursor: 'pointer'}}
                                />
                                {model.filename}
                                <FontAwesomeIcon
                                    icon={faXmark}
                                    style={{cursor: 'pointer', color: '#777', fontSize: '13px', paddingLeft: '10px'}}
                                    onClick={e2 => {
                                        e2.stopPropagation()
                                        e2.preventDefault()
                                        // onRowRemove(rowData)
                                        confirmYes(async () => {
                                            showLoading()
                                            const _employee = await EmployeeService.uploadTrainingCertificate(model.employee, model.id)
                                            afterFileUpload(_employee)
                                            hideLoading()
                                        })
                                    }}
                                />
                            </div>
                        }
                        {
                            !model.fileSize &&
                            <div style={{paddingTop: '10px'}}>
                                {/*<FileUpload multiple={false} save={onFileUpload} {...form}/>*/}
                                <XlnzFileUpload
                                    ref={fileUploadRef} name="files[]"
                                    auto={true}
                                    customUpload={true}
                                    uploadHandler={e => uploadHandler(e)}

                                    multiple={false}
                                    accept='application/pdf,image/jpeg,image/png,image/heic'
                                    maxFileSize={40000000}
                                    // headerTemplate={headerTemplate}
                                    headerClassName='headerTemplate'
                                    // emptyTemplate={emptyTemplate}

                                    chooseLabel={i18n('choose_file') + '...'}
                                />
                            </div>
                        }
                    </>
                }
                <FormItemTextArea name='note' labelI18n='note' maxLength={5000} {...form}/>
            </form>
        </div>

    </XlnzDialog>
}

export default TrainingEditFormDialog;
