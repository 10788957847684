import React, {useContext} from 'react';
import {Controller, useForm, useWatch} from "react-hook-form";
import {useLocation, useNavigate} from "react-router-dom";
import AppContext from "../../../AppContext";
import AmountWithStartDateGrid from "../../../components/AmountWithStartDateGrid/AmountWithStartDateGrid";
import {CancelButton, PdfButton, SaveButton} from "../../../components/Buttons/Button";
import '../../../components/EditForm/EditForm.scss'
import FormItemAddress from "../../../components/EditForm/FormItemAddress";
import FormItemCheckbox from "../../../components/EditForm/FormItemCheckbox";
import FormItemDropdown from "../../../components/EditForm/FormItemDropdown";
import FormItemEmail from "../../../components/EditForm/FormItemEmail";
import FormItemMultiSelect from "../../../components/EditForm/FormItemMultiSelect";
import FormItemPhone from "../../../components/EditForm/FormItemPhone";
import FormItemText from "../../../components/EditForm/FormItemText";
import FormItemTextArea from "../../../components/EditForm/FormItemTextArea";
import {errorAlert} from "../../../components/Overlay/Alert";
import {hideLoading, showLoading} from "../../../components/Overlay/Loading";
import TabPanel from "../../../components/TabView/TabPanel";
import TabView from "../../../components/TabView/TabView";
import Role from "../../../enums/Role";
import useMountEffect from "../../../hooks/useMountEffect";
import usePreload, {PreloadType} from "../../../hooks/usePreload";
import useRoles from "../../../hooks/useRoles";
import {i18n} from "../../../I18n/I18n";
import {filterActive, sortByField} from "../../../utils/ArrayUtil";
import RouterUtils from "../../../utils/RouterUtils";
import EmployeeService from "../EmployeeService";
import TrainingGrid from "./TrainingGrid";
import useEmployeeDefaultValues from "./useEmployeeDefaultValues";

const EmployeeEditForm = props => {
    const context = useContext(AppContext)
    const roles = useRoles()
    const preload = usePreload()

    const subcontractorList = sortByField(preload.getSupplierList().filter(item => item.subcontractor), 'name')

    const location = useLocation()
    const navigate = useNavigate()
    const id = RouterUtils.getId(location)

    const departmentList = filterActive(context.currentCompany.departmentList)

    const disabled = !roles.hasRole(Role.EMPLOYEE_WRITE);

    const defaultValues = useEmployeeDefaultValues()
    const form = {...useForm({defaultValues: defaultValues}), disabled: disabled}
    const {handleSubmit, reset, formState: {isSubmitting}, getValues, control} = form

    const watchEmail = useWatch({control, name: 'email'})
    const watchHired = useWatch({control, name: 'hired'})
    const watchTrainingList = useWatch({control, name: 'trainingList'})

    function validate(data) {
        if (data.password === undefined) {
            // Do nothing
        } else if (data.password === '') {
            delete data.password
        } else if (data.password.length < 8) {
            errorAlert(i18n('password_too_short'))
            return false
        } else { // Password OK
            if (!(data.email?.length > 0)) {
                errorAlert(i18n('error_password_without_username'))
                return false
            }
        }
        return true
    }

    const save = async (data) => {
        if (!validate(data)) {
            hideLoading()
            return undefined
        }
        if (!disabled) {
            if (data.company === "") data.company = context.currentCompany.parentId || context.currentCompany.id

            if (!data.hired) delete data.subcontractor
            if (data.number === '') delete data.number

            const saveModel = Object.assign({}, data);

            console.log("SAVE MODEL:", saveModel)
            return EmployeeService.update(saveModel).then(async _model => {
                if (_model) {
                    reset(_model)
                    await preload.load(PreloadType.EMPLOYEE_LIST)
                }
                return _model
            });
        } else {
            return data;
        }
    }

    const onSubmit = () => {
        showLoading();
        const data = getValues()
        console.log("data", data)
        return save(data).then(_model => {
            hideLoading()
            if (_model) RouterUtils.goBack(navigate)
        });
    }

    useMountEffect(async () => {
        if (id > 0) {
            showLoading()
            const _model = await EmployeeService.findById(id)
            reset(_model);
            hideLoading()
        }
    })

    return <div className="EditForm">
        <div>
            <div className="headline">
                <h1>{i18n('employee')}</h1>
            </div>
            <form>
                <div className="button-row">
                    <div className='left-buttons'>
                        {!disabled &&
                            <SaveButton onClick={e => handleSubmit(onSubmit)()} disabled={isSubmitting}/>}
                        <CancelButton onClick={e => RouterUtils.goBack(navigate)}/>
                    </div>
                    <div className='divider'></div>
                    <div className='right-buttons'>
                        {
                            roles.hasRole(Role.EMPLOYEE_WRITE) &&
                            <PdfButton key='downloadEmploymentContract'
                                       labelI18n='employment_contract'
                                       onClick={() => {
                                           form.trigger().then(async isOk => {
                                               if (isOk) {
                                                   showLoading()
                                                   const data = form.getValues();
                                                   const _model = await EmployeeService.update(data)
                                                   EmployeeService.downloadEmploymentContract(_model.id)
                                                   form.reset(_model)
                                                   hideLoading()
                                               }
                                           })
                                       }}/>
                        }
                    </div>
                </div>

                <div className='columnContainer'>
                    <div className='column'>
                        <FormItemText key='firstName' name='firstName' labelI18n='first_name' maxLength={50}
                                      required {...form}/>
                        <FormItemText key='lastName' name='lastName' labelI18n='last_name' maxLength={50}
                                      required {...form}/>
                        <FormItemText key='ssn' name='ssn' labelI18n='ssn' maxLength={128}
                                      width={150} {...form}/>
                        <FormItemText key='number' name='number' labelI18n='number' maxLength={128}
                                      width={150} {...form}/>

                        {
                            departmentList.length > 0 &&
                            <>
                                <FormItemDropdown key='company' name='company' labelI18n='department'
                                                  options={departmentList}
                                                  showSelect={true}
                                                  {...form} />

                                <FormItemMultiSelect key='additionalDepartments'
                                                     name='additionalDepartments'
                                                     labelI18n='additional_departments'
                                                     options={departmentList}
                                                     optionValue='id'
                                                     optionLabel='name'
                                                     filter={false}
                                                     {...form}/>
                            </>
                        }
                    </div>
                    <div className='column'>
                        <FormItemAddress key='address' name='address' label={i18n('address')} {...form}/>
                        <FormItemPhone key='phone0' name='phone0' label={i18n('mobile')} {...form}/>
                        <FormItemEmail key='email' name='email'
                                       label={i18n('email')}                                       {...form}/>
                        <FormItemText key='contactName' name='contactName' label={i18n('contact_person')}
                                      maxLength={128} {...form}/>
                        <FormItemText key='contactPhone0' name='contactPhone0' label={i18n('contact_person_phone')}
                                      maxLength={128} {...form}/>
                    </div>
                    <div className='column'>
                        <FormItemCheckbox key='hired' name='hired' label={i18n('hired')} {...form}/>
                        {
                            watchHired &&
                            <FormItemDropdown key='subcontractor' name='subcontractor' labelI18n='subcontractor'
                                              options={filterActive(subcontractorList, getValues('subcontractor'))}
                                              showSelect={true}
                                              {...form} />
                        }
                        <FormItemCheckbox key='attestant' name='attestant' label={i18n('attestant')} {...form}/>
                        <FormItemTextArea key='note' name='note' label={i18n('note')} maxLength={5000} {...form}/>
                        <FormItemCheckbox key='active' name='active' label={i18n('active')} {...form}/>
                    </div>
                </div>

                <TabView>
                    <TabPanel key='salary' header={i18n('salary')}>
                        <div className="formItem">
                            <Controller name='salaryList'
                                        control={form.control}
                                        render={({field: {ref, value, onChange}}) =>
                                            <AmountWithStartDateGrid
                                                inputRef={ref} value={value} disabled={form.disabled}
                                                onChange={onChange}
                                                itemLabelI18n={watchHired ? 'cost' : 'salary'}
                                                itemValue='salary'
                                            />
                                        }/>
                        </div>
                    </TabPanel>
                    <TabPanel key='login' header={i18n('login')}>
                        <div className='columnContainer'>
                            <div className='column'>
                                <FormItemText key='username' name='username'
                                              label={i18n('username') + ' (' + i18n('email') + ')'}
                                              value={watchEmail}
                                              forceDisabled={true} capitalize={false} {...form}/>
                                <div>
                                    <FormItemText key='password' name='password' label={i18n('password')}
                                                  maxLength={100} capitalize={false} {...form}/>
                                </div>
                            </div>
                            <div className='column'></div>
                            <div className='column'></div>
                        </div>
                    </TabPanel>
                    <TabPanel key='trainings' header={i18n('trainings') + ' (' + (watchTrainingList.length) + ')'}>
                        <Controller name="trainingList"
                                    control={control}
                                    render={({field: {ref, value, onChange}}) =>
                                        <TrainingGrid
                                            inputRef={ref} value={value} onChange={onChange}
                                            form={form}
                                            save={save}
                                        />
                                    }/>
                    </TabPanel>
                </TabView>
            </form>
        </div>
    </div>
}

export default EmployeeEditForm;
