import I18n from '@I18n';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import AppContext from "../../AppContext";
import {AddButton} from "../../components/Buttons/Button";
import ContextMenuItem from "../../components/ContextMenu/ContextMenuItem";
import MonthDropdown from "../../components/Date/MonthDropdown";
import YearFilter from "../../components/filters/YearFilter";
import {useFilter} from "../../components/Grid/filter";
import {FooterAggregate} from "../../components/Grid/getFooterGroup";
import Grid from "../../components/Grid/Grid";
import PdfColumn from "../../components/Grid/PdfColumn";
import {infoAlert} from "../../components/Overlay/Alert";
import {hideLoading, showLoading} from "../../components/Overlay/Loading";
import XlnzDropdown from "../../components/XlnzDropdown/XlnzDropdown";
import Companyprop from "../../enums/Companyprop";
import Icon from "../../enums/Icon";
import Role from "../../enums/Role";
import {match} from "../../functions/match";
import {GridTemplates} from "../../GridTemplates";
import useCompanyProp from "../../hooks/useCompanyProp";
import useDialog from "../../hooks/useDialog";
import usePreload, {PreloadType} from "../../hooks/usePreload";
import useRoles from "../../hooks/useRoles";
import {i18n} from "../../I18n/I18n";
import {distinct, findById, sortByField} from "../../utils/ArrayUtil";
import {formatTime, getDaysBetween, todayDate, todayYear} from "../../utils/DateUtils";
import ProjectSummaryDialog from "../Projects/ProjectSummary/ProjectSummaryDialog";
import InvoiceService from "./InvoiceService";
import InvoiceStatus from "./InvoiceStatus";

const InvoiceGrid = props => {
    const context = useContext(AppContext)
    const roles = useRoles()
    const preload = usePreload()
    const dialog = useDialog()

    const fortnoxInvoiceSyncStart = useCompanyProp(Companyprop.FORTNOX_INVOICE_SYNC_START, undefined)

    const filter = useFilter('InvoiceGrid', [
        {name: 'status', defaultValue: InvoiceStatus.DRAFT.id},
        {name: 'year', defaultValue: todayYear()},
        {name: 'month', defaultValue: undefined},
        {name: 'projectLeader', defaultValue: undefined},
        {name: 'search', defaultValue: ''},
    ])

    const costcenterList = preload.get(PreloadType.COSTCENTER_LIST)?.filter(item => item.active) || []
    const employeeList = preload.get(PreloadType.EMPLOYEE_LIST)

    const [list, setList] = useState([]);
    const projectLeaderList = sortByField(distinct(list.map(item => item.projectLeader))
        .filter(id => id > 0)
        .map(id => findById(employeeList, id)), 'name')
    const filteredList = list?.filter(item => match(filter, item, 'projectLeader'))

    const navigate = useNavigate()

    const hasTimefilter = useCallback(() => [
                InvoiceStatus.SENT.id,
                InvoiceStatus.PAID.id,
                InvoiceStatus.CANCELLED.id].indexOf(filter.status) !== -1
            || !filter.status
        , [filter.status])


    const loadData = useCallback(async () => {
        showLoading()
        const params = {
            status: filter.status,
            year: hasTimefilter() ? filter.year : undefined,
            month: hasTimefilter() ? filter.month : undefined,
            search: filter.search
        }
        const _list = await InvoiceService.findAll(params)
        _list.forEach(item => {
            item.statusName = InvoiceStatus.getName(item.status)
            if (item.status === InvoiceStatus.OVERDUE.id) {
                const days = getDaysBetween(todayDate(), item.dueDate) - 1
                item.statusName += ' (' + days + ' ' + i18n(days === 1 ? 'day' : 'days').toLowerCase() + ')'

                if (item.reminderDate) {
                    item.statusName += '\n' + i18n('reminded') + ' ' + item.reminderDate
                }
            }
            if (item.paymentDate) item.statusName += ' ' + item.paymentDate

            item.exportedTimeString = formatTime((item.exportedTime))
        })
        setList(_list);
        hideLoading()

    }, [hasTimefilter, filter.status, filter.year, filter.month, filter.search])

    useEffect(() => {
        loadData()
    }, [loadData])

    const columns = [
        PdfColumn(() => true,
            rowData => rowData.number ? I18n.t('invoice') + ' ' + rowData.number : '',
            rowData => '/api/invoices/' + rowData.id + '/pdf'
        ),
        {
            field: 'number', headerI18n: 'number',
            tooltip: rowData => i18n('created_by') + ' ' + rowData.cuserName + ', ' + formatTime(rowData.ctime),
            width: 100, mobile: true, mobileBold: true
        },
        {field: 'projectLabel', headerI18n: 'project', mobileWithHeader: true},
        {field: 'customerName', headerI18n: 'customer', mobileWithHeader: true},
        {field: 'invoiceDate', headerI18n: 'invoice_date', width: 100, mobileWithHeader: true},
        {field: 'dueDate', headerI18n: 'due_date', width: 100},
        {
            field: 'price',
            headerI18n: 'amount_excl_vat',
            body: GridTemplates.currency,
            align: 'right',
            width: 120,
            footer: FooterAggregate.SUM_CURRENCY,
            mobileWithHeader: true
        },
        {
            field: 'priceInclVat',
            headerI18n: 'amount_incl_vat',
            body: GridTemplates.currency,
            align: 'right',
            width: 120,
            footer: FooterAggregate.SUM_CURRENCY
        },
        {
            field: 'costcenterStr',
            headerI18n: 'costcenter',
            body: GridTemplates.onelineString,
            excelBody: GridTemplates.excelBody,
            width: 130,
            hidden: costcenterList?.length === 0
        },
        {field: 'statusName', headerI18n: 'status', width: 130},
        {field: 'exportedTimeString', headerI18n: 'exported_to_fortnox', width: 160},
        {field: 'note', headerI18n: 'note', body: GridTemplates.stringLimit100, mobile: true},
    ].filter(item => !item.hidden)

    const leftFilters = [
        roles.hasRole(Role.INVOICE_WRITE) ? <div>
            <label className="label">&nbsp;</label>
            <AddButton/>
        </div> : undefined,
        <div>
            <label className="label">{I18n.t('status')}</label>
            <XlnzDropdown
                value={filter.status}
                options={InvoiceStatus.values()}
                optionLabel='namePlural'
                showClear={true}
                placeholder={I18n.t('select') + '...'}
                optionValue='id'
                onChange={e => {
                    filter.update('status', e.value)
                }}
                style={{width: '180px'}}/>
        </div>
    ]

    if (hasTimefilter()) {
        leftFilters.push(<div>
            <label className="label">{I18n.t('year')}</label>
            <YearFilter value={filter.year} onChange={e => filter.update('year', e.value)}
            />
        </div>)
        leftFilters.push(<div>
            <label className="label">{I18n.t('month')}</label>
            <MonthDropdown value={filter.month} onChange={e => {
                filter.update('month', e.value)
            }}/>
        </div>)
    }

    leftFilters.push(<div>
        <label className="label">{i18n('project_leader')}</label>
        <XlnzDropdown
            value={filter.projectLeader}
            options={projectLeaderList}
            optionLabel='name'
            showClear={true}
            placeholder={i18n('select') + '...'}
            optionValue='id'
            onChange={e => filter.update('projectLeader', e.value)}
            style={{width: '200px'}}/>
    </div>)

    const contextMenu = [
        ContextMenuItem({
            i18n: 'project_summary', icon: Icon.LOG,
            command: item => dialog.show(<ProjectSummaryDialog projectId={item.project}/>),
            hidden: item => {
                // console.log("ITTEM", item)
                return !item?.project
            }
        }),
        ContextMenuItem({
            i18n: 'edit_project', icon: Icon.PROJECT,
            command: item => navigate('/projects/' + item.project),
            hidden: item => !item?.project
        }),
        ContextMenuItem({
            i18n: 'edit_tender', icon: Icon.TENDER,
            command: item => navigate('/tenders/' + item.tender),
            hidden: row => !roles.hasRole(Role.TENDER_READ) || !row?.tender,
        }),
    ]

    const menuItems = []
    if (context.currentCompany.usingFortnox) {
        menuItems.push({
            label: 'Hämta betalningar från Fortnox',
            faicon: Icon.INTEGRATION,
            command: () => {
                showLoading()
                InvoiceService.checkPaid().then(_resp => {
                    if (_resp) infoAlert("Klart!")
                    hideLoading()
                })
            }
        })
    }

    if (fortnoxInvoiceSyncStart) {
        menuItems.push({
            label: 'Hämta kundfakturor från Fortnox',
            faicon: Icon.INTEGRATION,
            command: async () => {
                showLoading()
                if (await InvoiceService.fetchFromFortnox()) {
                    await preload.load(PreloadType.CUSTOMER_LIST)
                    await preload.load(PreloadType.ACCOUNT_LIST)
                    await loadData()
                    infoAlert(I18n.t('done'))
                }
                hideLoading()
            }
        })
    }

    return <>
        <Grid id='InvoiceGrid'
              labelI18n='customer_invoices'
              icon={Icon.INVOICE}
              leftFilters={leftFilters}
              filter={filter}
              updateFilter={filter.update}
              columns={columns}
              value={filteredList}
              paginator={true}
              menuItems={menuItems}
              contextMenu={contextMenu}
        />
        {dialog.render()}
    </>
}

export default InvoiceGrid;
