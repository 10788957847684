import React, {useContext} from "react";
import {useWatch} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import AppContext from "../../../AppContext";
import {CancelButton, EmailButton, SendButton} from "../../../components/Buttons/Button";
import SendByEmailDialog from "../../../components/Dialog/SendByEmailDialog";
import {errorAlert} from "../../../components/Overlay/Alert";
import {hideLoading, showLoading} from "../../../components/Overlay/Loading";
import XlnzDialog from "../../../components/XlnzDialog/XlnzDialog";
import InvoiceSendMethod from "../../../enums/InvoiceSendMethod";
import usePreload, {PreloadType} from "../../../hooks/usePreload";
import {i18n} from "../../../I18n/I18n";
import {findById} from "../../../utils/ArrayUtil";
import RouterUtils from "../../../utils/RouterUtils";
import InvoiceService from "../InvoiceService";

export const SendInvoiceButton = props => {
    const context = useContext(AppContext)

    const {
        dialog,
        handleSubmit,
        save,
        reset,
        form
    } = props

    const preload = usePreload()

    const {control} = form
    const watchCustomer = Number(useWatch({control, name: 'customer'}))

    const customer = findById(preload.get(PreloadType.CUSTOMER_LIST), watchCustomer)
    const invoiceSendMethod = customer ? InvoiceSendMethod.findById(customer.invoiceSendMethod) : undefined

    const style = {}
    if (invoiceSendMethod?.id !== InvoiceSendMethod.EMAIL.id) {
        style.color = '#F00'
    }
    const info = <div style={style}>
        Förvald faktureringsmetod på kundkortet: <b>{invoiceSendMethod?.name}</b>
    </div>

    const navigate = useNavigate();

    // const [showSendInvoiceByEmail, setShowSendInvoiceByEmail] = useState(false);

    function validate(data) {
        if (context.currentCompany.usingFortnox) {
            if (!(data.number > 0)) {
                errorAlert(i18n('error_invoice_number_missing'))
                return false
            }
        }
        return true
    }

    const onSendByEmail = data => {
        showLoading();

        if (validate(data)) {
            save(data).then(_model => {
                if (_model) {
                    reset(_model);
                    hideLoading();

                    if (invoiceSendMethod.id === InvoiceSendMethod.EINVOICE.id && context.currentCompany.usingFortnox) {
                        dialog.show(<SendByEinvoiceDialog
                            // info={info}
                            email={customer.invoiceEmail}
                            onSend={toEmail => {
                                showLoading();
                                InvoiceService.sendByEinvoice(_model.id).then(_model => {
                                    if (_model) {
                                        RouterUtils.goBack(navigate)
                                    }
                                    hideLoading();
                                });
                            }}/>)
                    } else {
                        dialog.show(<SendByEmailDialog
                            info={info}
                            email={customer.invoiceEmail}
                            onSend={toEmail => {
                                showLoading();
                                InvoiceService.sendByEmail(_model.id, toEmail).then(_model => {
                                    if (_model) {
                                        RouterUtils.goBack(navigate)
                                    }
                                    hideLoading();
                                });
                            }}/>)
                    }

                } else {
                    hideLoading();
                }
            })
        } else {
            hideLoading()
        }
    }

    return <EmailButton onClick={handleSubmit(onSendByEmail)}/>
}


function SendByEinvoiceDialog({info, email, message, customFields, onSend, onHide}) {
    return <XlnzDialog header='Skicka via e-faktura'
                       onHide={onHide}
                       buttons={() => [
                           <SendButton key='send' labelI18n='send'
                                       onClick={e => {
                                           onSend()
                                           onHide()
                                       }}/>,
                           <CancelButton key='cancel' onClick={e => onHide()}/>
                       ]}
    >

        <div className='EditForm' style={{width: '500px', margin: '0'}}>
            Fakturan kommer att skickas via Fortnox e-fakturatjänst. Kostnaden för att skicka e-fakturan debiteras av
            Fortnox.<br/>
            <br/>
            För att kunna skicka e-faktura måste det finnas en aktiv koppling.&nbsp;
            <a href='https://support.fortnox.se/produkthjalp/fakturering/aktivera-skicka-e-faktura-till-foretag'
               target='_blank' rel="noreferrer">Läs mer hos Fortnox</a>
        </div>
    </XlnzDialog>
}