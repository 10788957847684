import React from 'react';
import {useForm} from "react-hook-form";
import {CancelButton, RemoveButton, SaveButton} from "../../../components/Buttons/Button";
import FormItemEmail from "../../../components/EditForm/FormItemEmail";
import FormItemPhone from "../../../components/EditForm/FormItemPhone";
import FormItemSsn from "../../../components/EditForm/FormItemSsn";
import FormItemText from "../../../components/EditForm/FormItemText";
import {errorAlert} from "../../../components/Overlay/Alert";
import {hideLoading, showLoading} from "../../../components/Overlay/Loading";
import XlnzDialog from "../../../components/XlnzDialog/XlnzDialog";
import Role from "../../../enums/Role";
import useRoles from "../../../hooks/useRoles";
import {i18n} from "../../../I18n/I18n";
import CustomerService from "../CustomerService";

const CustomercontactEditFormDialog = props => {
    const {
        model,
        onHide
    } = props

    const roles = useRoles()
    const disabled = !roles.hasRole(Role.CUSTOMER_WRITE)

    const form = {...useForm({defaultValues: model}), disabled: disabled}
    const {handleSubmit, formState: {isSubmitting}} = form

    const onSubmit = data => {
        Object.assign(model, data);
        props.onSave(model);
        props.onHide()
    }

    const onRemove = async () => {
        if (model.customer > 0 && model.id > 0) {
            showLoading()

            const _resp = await CustomerService.contactInUse(model.customer, model.id)
            if (_resp.value) {
                errorAlert(i18n('error_remove_contact_person_in_use'))
                hideLoading()
                return
            }
            hideLoading()
        }
        props.onRemove(model);
        props.onHide()
    }

    return <XlnzDialog headerI18n='contact_person'
                       onHide={onHide}
                       buttons={() => [
                           disabled
                               ? undefined
                               : <SaveButton key='save' labelI18n='save'
                                             disabled={isSubmitting}
                                             onClick={handleSubmit(onSubmit)}/>,
                           disabled || model.newPrice
                               ? undefined
                               : <RemoveButton key='remove' onClick={e => onRemove()}/>,
                           <CancelButton key='cancel' onClick={e => onHide()}/>
                       ]}
    >

        <div className="EditForm" style={{width: '400px'}}>
            <form>
                <FormItemText name='name' labelI18n='name' maxLength={250} required {...form}/>
                <FormItemSsn name='ssn' {...form}/>
                <FormItemPhone name='phone0' labelI18n='mobile' maxLength={250} {...form}/>
                <FormItemEmail name='email' labelI18n='email' maxLength={250} {...form}/>
            </form>
        </div>

    </XlnzDialog>
}

export default CustomercontactEditFormDialog;
