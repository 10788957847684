import React from 'react';
import Grid from "../../components/Grid/Grid";
import {infoAlert} from "../../components/Overlay/Alert";
import Tooltip from "../../components/Overlay/Tooltip";
import XlnzCard from "../../components/XlnzCard/XlnzCard";
import {GridTemplates} from "../../GridTemplates";
import StringUtil from "../../utils/StringUtil";

const Threads = props => {
    const {
        value
    } = props

    const columns = [
        {field: 'id', header: 'ID', width: 80},
        {field: 'name', header: 'Name'},
        {
            field: 'deamon', header: 'Deamon',
            body: GridTemplates.booleanHideFalse,
            width: 70,
        },
        {field: 'priority', header: 'Priority', width: 60},
        {
            field: 'cpuTimeMs', header: 'cpuTimeMs', width: 90, body: rowData => {
                if (rowData.cpuTimeMs === 0) return ''
                return StringUtil.formatDuration(rowData.cpuTimeMs);
            }
        },
        {
            field: 'state', header: 'State',
            body: rowData => {
                return <Tooltip value={rowData.stacktrace}>{rowData.state}</Tooltip>
            },
            width: 150,
        },
    ]


    return <XlnzCard title='Threads' style={{width: '750px'}}>
        <Grid
            scrollable={false}
            columns={columns}
            value={value}
            onRowSelect={row => {
                navigator.clipboard.writeText(JSON.stringify(row.data, null, 2));
                infoAlert('Copied to clipboard');
            }}
            forceDesktop={true}
        />
    </XlnzCard>

}
export default Threads;