import React from 'react';
import Grid from "../../components/Grid/Grid";
import XlnzCard from "../../components/XlnzCard/XlnzCard";
import {GridTemplates} from "../../GridTemplates";

const AuthenticationCodes = props => {
    const {
        value
    } = props

    const columns = [
        {field: 'email', headerI18n: 'email'},
        {field: 'code', header: 'Code', width: 80},
        {field: 'expire', header: 'Expires', width: 140, body: GridTemplates.time},
        {field: 'attempts', header: 'Attempts', width: 80, align: 'right'},
    ]

    return <XlnzCard title='Authentication Codes' style={{width: '580px'}}>
        <Grid
            scrollable={false}
            columns={columns}
            value={value}
            onRowSelect={row => {
            }}
            forceDesktop={true}
        />
    </XlnzCard>

}
export default AuthenticationCodes;