import React from 'react';
import {NewRowButton} from "../../../components/Buttons/Button";
import Grid from "../../../components/Grid/Grid";
import Role from "../../../enums/Role";
import {GridTemplates} from "../../../GridTemplates";
import useRoles from "../../../hooks/useRoles";
import {todayDate} from "../../../utils/DateUtils";
import ReminderEditFormDialog from "./ReminderEditFormDialog";

const ReminderGrid = ({
                          value,
                          invoiceModel,
                          save,
                          getCustomerEmail,
                          dialog
                      }) => {
    const roles = useRoles()
    // const [modelToEdit, setModelToEdit] = useState();

    const columns = [
        {field: 'date', headerI18n: 'date', width: 120},
        {field: 'sentTime', headerI18n: 'sent', body: GridTemplates.time},
    ];

    function showDialog(modelToEdit) {
        dialog.show(<ReminderEditFormDialog
            invoiceModel={invoiceModel}
            save={save}
            model={modelToEdit}
            visible={true}
            getCustomerEmail={getCustomerEmail}/>)
    }

    return <div className='formItem' style={{width: '400px'}}>
        <Grid
            scrollable={false}
            columns={columns}
            value={value}
            footerColumnGroup={<div/>}
            onRowSelect={e => showDialog(e.data)}/>

        {
            roles.hasRole(Role.INVOICE_WRITE) &&
            <div style={{paddingTop: '10px'}}>
                <NewRowButton onClick={e => {
                    showDialog(Object.freeze({
                        date: todayDate()
                    }))
                }}/>
            </div>
        }
    </div>
}

export default ReminderGrid;
