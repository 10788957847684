import React, {useEffect, useMemo, useState} from 'react';
import {AddButton} from "../../../components/Buttons/Button";
import XlnzDropdown from "../../../components/XlnzDropdown/XlnzDropdown";
import YearFilter from "../../../components/filters/YearFilter";
import {useFilter} from "../../../components/Grid/filter";
import {FooterAggregate} from "../../../components/Grid/getFooterGroup";
import Grid from "../../../components/Grid/Grid";
import {hideLoading, showLoading} from "../../../components/Overlay/Loading";
import Spinner from "../../../components/Spinner/Spinner";
import {GridTemplates} from "../../../GridTemplates";
import {i18n} from "../../../I18n/I18n";
import {sortByField} from "../../../utils/ArrayUtil";
import {todayYear} from "../../../utils/DateUtils";
import HyrletDisconnectedView from "../HyrletDisconnectedView";
import HyrletService from "../HyrletService";
import useHyrletLoginInfo from "../useHyrletLoginInfo";
import HyrletOrderStatuses from "./HyrletOrderStatuses";

const HyrletOrderGrid = () => {
    const filter = useFilter('HyrletOrderGrid', [{name: 'first', defaultValue: 0}, {
        name: 'rows', defaultValue: 5000
    }, {name: 'status', defaultValue: HyrletOrderStatuses.WAITING_FOR_CONFIRMATION}, {
        name: 'year', defaultValue: todayYear()
    }, {name: 'search', defaultValue: ''}])

    const [model, setModel] = useState();
    const hyrletLoginInfo = useHyrletLoginInfo()
    const customerList = useMemo(() => sortByField(hyrletLoginInfo.getCurrentCustomerList(), 'fullName'), [hyrletLoginInfo])

    useEffect(() => {
        showLoading()
        const promises = []
        customerList.forEach(item => {
            promises.push(HyrletService.findOrders(item.id, filter.first, filter.rows, filter.status, filter.year, filter.search))
        })

        Promise.all(promises).then(resp => {

            let list = []
            const metaData = {
                count: 0, listPrice: 0, weight: 0
            }

            resp.forEach(item => {
                list = list.concat(item.list)
                metaData.count += item.metaData.count
                metaData.listPrice += item.metaData.listPrice
                metaData.weight += item.metaData.weight
            })

            list.forEach(item => {

                const indexOfLegalCompany = item.customerSupplierString.indexOf(' - ')
                if (indexOfLegalCompany > 0) {
                    item.customerSupplierString = item.customerSupplierString.substring(indexOfLegalCompany + 3)
                }
            })

            setModel({
                list: list, metaData: metaData
            })
            hideLoading()
        })
    }, [customerList, filter.first, filter.rows, filter.status, filter.year, filter.search])

    const leftFilters = [<div>
        <label className="label">&nbsp;</label>
        <AddButton/>
    </div>, <div>
        <label className="label">{i18n('status')}</label>
        <XlnzDropdown
            value={filter.status}
            options={HyrletOrderStatuses.LIST}
            optionLabel='label'
            optionValue='value'
            onChange={e => {
                filter.update('status', e.value)
            }}
            style={{width: '200px'}}/>
    </div>, <div>
        <label className="label">{i18n('year')}</label>
        <YearFilter value={filter.year}
                    startYear={2010}
                    onChange={e => filter.update('year', e.value)}
        />
    </div>,]

    const columns = [
        {
            field: 'customerSupplierString', headerI18n: 'customer', body: GridTemplates.onelineString,
            hidden: customerList.length === 1,
            mobile: true,
            mobileBold: true
        },
        {
            field: 'number', headerI18n: 'number', width: 120, mobile: true, mobileBold: true
        },
        {
            field: 'createdString', headerI18n: 'created', body: GridTemplates.onelineString
        },
        {
            field: 'type', headerI18n: 'type', width: 90, body: rowData => HyrletOrderTypes[rowData.type], mobile: true
        },
        {field: 'deliveryDate', header: 'Leveransdatum', width: 100, mobile: true},
        {
            field: 'listPrice',
            header: 'Listpris',
            align: 'right',
            width: 100,
            body: GridTemplates.currency,
            footer: FooterAggregate.SUM_CURRENCY,
            mobile: true
        },
        {
            field: 'weight',
            header: 'Vikt',
            align: 'right',
            width: 100,
            body: GridTemplates.weight,
            footer: FooterAggregate.SUM_WEIGHT,
        }, {
            field: 'status',
            headerI18n: 'status',
            width: 140,
            body: rowData => HyrletOrderStatuses.getName(rowData.status),

            mobile: true
        }, {field: 'note', headerI18n: 'note', mobile: true}]
        .filter(item => !item.hidden)

    if (!hyrletLoginInfo.isConnected()) return <HyrletDisconnectedView/>
    if (!model) return <Spinner/>


    return <Grid
        id='HyrletOrderGrid'
        name='Hyrlet - Ordrar'
        label='Hyrlet - Ordrar'
        leftFilters={leftFilters}
        filter={filter}
        updateFilter={filter.update}
        columns={columns}
        value={model.list}
        paginator={true}
        onRowSelect={e => {
            console.log("ROOOW", e)
            window.location.href = '/#/hyrlet/orders/' + e.data.customer + '-' + e.data.id
        }}
    />
}

export default HyrletOrderGrid;

const HyrletOrderTypes = {
    RENT: 'Hyra', RETURN: 'Återlämning', BUY: 'Köp', SELL: 'Försäljning'
}