import React from 'react';
import Grid from "../../../components/Grid/Grid";
import NumberGridInput from "../../../components/Grid/NumberGridInput";
import onRowChange from "../../../components/Grid/onRowChange";
import Unit from "../../../enums/Unit";
import {GridTemplates} from "../../../GridTemplates";
import {i18n} from "../../../I18n/I18n";
import {findByField} from "../../../utils/ArrayUtil";
import {deepCopy} from "../../../utils/ObjectUtils";

const PricelistarticlepriceGrid = props => {
    const {
        articleList,
        value
    } = props

    const _articleList = deepCopy(articleList)

    _articleList
        .forEach(item => {
            const foo = findByField(value, 'article', item.id)
            item.price = foo?.price || 0
        })

    const fireChange = (list) => {
        const newValue = list.map(item => ({
            article: item.id,
            price: item.price
        }))
        props.onChange(newValue);
    }

    const columns = [
        {field: 'number', headerI18n: 'art_no', width: 100},
        {field: 'name', header: i18n('article'),},
        {
            field: 'price', header: i18n('price'),
            body: GridTemplates.currencyTwoDecimals,
            editor: e => <NumberGridInput editorEvent={e}/>,
            onCellEditComplete: e => onRowChange(e, e.newValue, fireChange),
            width: 100,
            align: 'right'
        },
        {
            field: 'unitStr', header: '',
            body: rowData => rowData.unit ? '/' + Unit.findById(rowData.unit).symbol : '',
            width: 50
        },

    ]

    return <div>
        <Grid forceDesktop={true}
              columns={columns}
              value={_articleList}
              scrollable={false}
        />
    </div>
}
export default PricelistarticlepriceGrid;
