import React, {useState} from 'react';
import {AddButton} from "../../components/Buttons/Button";
import ActiveFilter from "../../components/Grid/ActiveFilter";
import {useFilter} from "../../components/Grid/filter";
import Grid from "../../components/Grid/Grid";
import {hideLoading, showLoading} from "../../components/Overlay/Loading";
import Role from "../../enums/Role";
import {matchActive, matchSearch} from "../../functions/match";
import {GridTemplates} from "../../GridTemplates";
import useMountEffect from "../../hooks/useMountEffect";
import useRoles from "../../hooks/useRoles";
import {i18n} from "../../I18n/I18n";
import CompanyService from "./CompanyService";

const CompanyGrid = () => {
    const roles = useRoles()

    const filter = useFilter('CompanyGrid', [
        {name: 'active', defaultValue: true},
        {name: 'search', defaultValue: ''},
    ])

    const [list, setList] = useState([]);
    const filteredList = list.filter(c => matchActive(filter, c) && matchSearch(filter, c))

    useMountEffect(() => {
        showLoading()
        CompanyService.findAll().then(l => {
            setList(l)
            hideLoading()
        });
    })

    const leftFilters = [
        roles.hasRole(Role.ADMIN) ? <div>
            <label className="label">&nbsp;</label>
            <AddButton/>
        </div> : undefined,
        <div>
            <label className="label">{i18n('status')}</label>
            <ActiveFilter value={filter.active} onChange={e => filter.update('active', e.value)}/>
        </div>
    ]

    const columns = [
        {field: 'label', headerI18n: 'name'},
        {field: 'regNo', headerI18n: 'reg_no', mobile: true},
        {field: 'vatNumber', headerI18n: 'vat_no'},
        {field: 'department', headerI18n: 'department'},
        {field: 'objectNo', header: 'Object No'},
        {field: 'projectNo', headerI18n: 'project_no'},
        {field: 'active', headerI18n: 'active', body: GridTemplates.boolean, width: 100},
    ];

    return <Grid
        labelI18n='companies'
        leftFilters={leftFilters}
        filter={filter}
        updateFilter={filter.update}
        columns={columns}
        value={filteredList}
    />
}

export default CompanyGrid;
