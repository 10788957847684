import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import Icon from "../../enums/Icon";
import AppendixViewer from "../Appendix/AppendixViewer";
import renderOverlay from "../Overlay/renderOverlay";
import {PdfViewerModal} from "../Appendix/PdfViewer";

const PdfColumn = (isVisible, getLabel, getImageUrl) => ({
    field: 'pdf', header: '', width: 20, body: rowData => {
        if (isVisible(rowData)) {
            return <FontAwesomeIcon
                icon={Icon.PDF}
                onClick={async e => {
                    e.stopPropagation()
                    const name = getLabel(rowData)
                    const imageUrl = await getImageUrl(rowData)
                    renderOverlay(<PdfViewerModal label={name} url={imageUrl}/>)

                    // console.log("name", name)
                    //
                    // renderOverlay(<AppendixViewer
                    //     imageList={[{
                    //         name: name,
                    //         url: imageUrl
                    //     }]}
                    //     index={0}
                    //     // onReload={fireChange}
                    // />)


                }}
                style={{fontSize: '16px', opacity: '75%'}}
            />
        }
    }
})

export default PdfColumn