import React from 'react';
import Button from "../../../../components/Buttons/Button";
import {addFreeRows, numOfFreeRows} from "../../../../components/Grid/freeRows";
import {FooterAggregate} from "../../../../components/Grid/getFooterGroup";
import Grid from "../../../../components/Grid/Grid";
import NumberGridInput from "../../../../components/Grid/NumberGridInput";
import onRowChange from "../../../../components/Grid/onRowChange";
import RemoveColumn from "../../../../components/Grid/RemoveColumn";
import SelectEditor from "../../../../components/Grid/SelectEditor";
import renderOverlay from "../../../../components/Overlay/renderOverlay";
import {GridTemplates} from "../../../../GridTemplates";
import {i18n} from "../../../../I18n/I18n";
import {NumberUtils} from "../../../../utils/NumberUtils";
import HyrletOrderAddArticlesDialog from "./HyrletOrderAddArticlesDialog";
// import TenderhowtotemplateDialog from "./TenderhowtotemplateDialog";


const HyrletOrderrowGrid = props => {
    const {
        disabled,
        value,
        onChange,
        articleList,
        getCustomerPrice,
        stockQuantityMap,
        articlegroupList
    } = props

    if (!articleList) return

    const NUM_OF_FREE_ROWS = disabled ? 0 : 1

    const list = addFreeRows(value, NUM_OF_FREE_ROWS, {
        id: 0, name: '', quantity: 0
    });

    const getDayPrice = or => {
        const cp = getCustomerPrice(or.article?.id);
        return cp ? cp.dayPrice * or.quantity : 0;
    }

    const getFixedPrice = or => {
        const cp = getCustomerPrice(or.article?.id);
        return cp ? cp.fixedPrice * or.quantity : 0;
    }

    list.forEach(item => {
        item.dayPrice = getDayPrice(item)
        item.listPriceValue = getFixedPrice(item)
        item.stockQuantity = stockQuantityMap.get(item.article?.id)
    })

    const getSlicedList = list => list.slice(0, list.length - numOfFreeRows(list))

    const fireChange = (list) => {
        onChange(getSlicedList(list));
    }

    const getArticle = or => {
        if (!or.article) return undefined
        const result = articleList.find(a => a.id === or.article?.id);
        if (!result) {
            console.log("NOT FOUND", or)
            return {
                number: 'ERR',
                name: "Error - article=" + or.article
            }
        }
        return result;
    }

    const onArticleChange = e => {
        console.log("e", e)
        const rowIndex = e.rowIndex
        const rowData = e.rowData
        const article = e.newValue

        if (article) {
            rowData.article = article
            rowData.name = article.label
            rowData.weight = article.weight
            rowData.quantity = 1
            rowData.aprice = article?.price || 0
        } else {
            rowData.article = undefined
        }

        console.log("ARTICLE:", rowIndex, rowData, article)

        const _rowList = [...list]
        Object.assign(_rowList[rowIndex], rowData)
        fireChange(_rowList)
    }

    const getWeight = or => getArticle(or)?.weight * or.quantity;

    const columns = [
        {
            field: 'articleLabel',
            header: i18n('article'),
            body: row => getArticle(row)?.label,
            editor: e => <SelectEditor event={e} options={articleList}
                                       onChange={e2 => e.editorCallback(e2?.value)}/>,
            // onChange={e2 => onArticleChange(e.rowIndex, e.rowData, e2?.value)}/>,
            onCellEditComplete: e => onArticleChange(e)
        },
        {
            field: 'dayPrice',
            header: "Pris/dag",
            body: row => NumberUtils.formatCurrency(row.dayPrice, 4),
            footer: FooterAggregate.SUM_CURRENCY_FOUR_DECIMALS,
            width: 120,
            align: 'right'
        },
        {
            field: 'listPriceValue',
            header: "Fastpris",
            body: GridTemplates.currencyTwoDecimals,
            width: 120,
            footer: FooterAggregate.SUM_CURRENCY_TWO_DECIMALS,
            align: 'right'
        },
        {
            field: 'weight',
            header: "Vikt",
            body: GridTemplates.weight,
            footer: FooterAggregate.SUM_WEIGHT,
            width: 120,
            align: 'right'
        },
        {
            field: 'stockQuantity',
            header: 'Lagersalso',
            body: GridTemplates.integer,
            width: 120,
            align: 'right'
        },
        {
            field: 'quantity',
            header: i18n('quantity'),
            body: GridTemplates.integer,
            editor: e => <NumberGridInput editorEvent={e} integer={true}/>,
            onCellEditComplete: e => onRowChange(e, Math.min(e.newValue, e.rowData.stockQuantity), fireChange),
            width: 120,
            align: 'right'
        }
    ]
    if (!disabled) columns.push(RemoveColumn(row => fireChange(list.filter(r => r !== row))))


    list.forEach(item => {
        item.weight = getWeight(item)
    })
    
    return <div className='HyrletOrderrowGrid'>
        <Grid
            forceDesktop={true}
            disabled={disabled}
            sortable={false}
            columns={columns}
            value={list}
            // footerColumnGroup={<div/>}
            onRowReorder={e => fireChange([...e.value])}
            scrollable={false}
            // footerColumnGroup={<div/>}
        />

        {
            !disabled &&
            <div style={{marginTop: '20px'}}>
                <Button label={i18n('show_articles') + ' (' + articleList.length + ')'} onClick={() => {
                    renderOverlay(<HyrletOrderAddArticlesDialog
                        articlegroupList={articlegroupList}
                        articleList={articleList}
                        stockQuantityMap={stockQuantityMap}
                        rowList={list}
                        onSave={addList => {

                            const newList = getSlicedList(list).concat(addList.map((a, index) => ({
                                id: 0,
                                article: a,
                                name: 'dummy',
                                stockQuantity: a.stockQuantity,
                                quantity: a.quantity,
                                listOrder: list.length + index + 1
                            })))
                            fireChange(newList)

                        }}
                    />)
                }}/>
            </div>
        }
    </div>
}
export default HyrletOrderrowGrid;