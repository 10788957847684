import {faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {forwardRef, useEffect, useImperativeHandle, useLayoutEffect, useRef, useState} from 'react';
import ReactDOM from 'react-dom';
import './XlnzMenu.scss';
import {i18n} from "../../I18n/I18n";

const XlnzMenu = forwardRef((props, ref) => {
    const {
        model,
        style = {},
        id
    } = props;

    const menuRef = useRef(null);
    const [activeSubMenu, setActiveSubMenu] = useState(null);
    const submenuTimeout = useRef(null);
    const [mounted, setMounted] = useState(false);
    const [menuVisible, setMenuVisible] = useState(false);
    const [menuMeasured, setMenuMeasured] = useState(false);
    const [buttonRect, setButtonRect] = useState(null);
    const menuContainer = useRef(document.createElement('div'));

    useImperativeHandle(ref, () => ({
        toggle: (event) => {
            event.preventDefault();
            event.stopPropagation();

            if (event.currentTarget) {
                setButtonRect(event.currentTarget.getBoundingClientRect()); // Spara knappens position
                setMenuVisible(true);  // Gör menyn synlig för att mäta dess storlek och position
                setMenuMeasured(false); // För att indikera att vi behöver mäta menyn
            }
        },
        hide: () => {
            setMenuVisible(false);  // Göm menyn
            if (menuRef.current) {
                setActiveSubMenu(null);
            }
        }
    }));

    useEffect(() => {
        // Spara den aktuella referensen till menuContainer i en lokal variabel
        const container = menuContainer.current;

        // Lägg till menuContainer till DOM direkt vid montering
        if (container && !document.body.contains(container)) {
            document.body.appendChild(container);
        }

        return () => {
            // Ta bort från DOM när komponenten avmonteras
            if (container && document.body.contains(container)) {
                document.body.removeChild(container);
            }
        };
    }, []);


    useLayoutEffect(() => {
        if (menuVisible && buttonRect && !menuMeasured) {
            // Använd requestAnimationFrame för att säkerställa att alla layoutförändringar har trätt i kraft
            requestAnimationFrame(() => {
                if (menuRef.current) {
                    const menuElement = menuRef.current;

                    // Gör menyn synlig men osynlig visuellt för mätning
                    menuElement.style.display = 'block';
                    menuElement.style.opacity = '0';
                    menuElement.style.visibility = 'hidden';

                    const menuWidth = menuElement.offsetWidth;
                    const menuHeight = menuElement.offsetHeight;

                    const viewportWidth = window.innerWidth;
                    const viewportHeight = window.innerHeight;

                    let leftPosition = buttonRect.left + window.scrollX;
                    let topPosition = buttonRect.bottom + window.scrollY;

                    if (leftPosition + menuWidth > viewportWidth) {
                        leftPosition = buttonRect.right + window.scrollX - menuWidth;
                    }

                    if (leftPosition < 0) {
                        leftPosition = 0;
                    }

                    if (topPosition + menuHeight > viewportHeight + window.scrollY) {
                        topPosition = buttonRect.top + window.scrollY - menuHeight;
                    }
                    if (topPosition < 0) topPosition = 0;

                    menuElement.style.left = `${leftPosition}px`;
                    menuElement.style.top = `${topPosition}px`;

                    // Återställ stil efter mätning
                    menuElement.style.opacity = '1';
                    menuElement.style.visibility = 'visible';

                    setMenuMeasured(true); // Indikerar att menyn har mätts och positionerats
                }
            });
        }
    }, [menuVisible, buttonRect, menuMeasured]);

    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setMenuVisible(false);
            setActiveSubMenu(null);
        }
    };

    useEffect(() => {
        setMounted(true);
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleMouseEnter = (item, index) => {
        clearTimeout(submenuTimeout.current);

        if (model.indexOf(item) !== -1) {
            setActiveSubMenu(index);
        }
    };

    const handleMouseLeave = (index, event) => {
        if (submenuTimeout.current) {
            clearTimeout(submenuTimeout.current);
        }

        const relatedTarget = event.relatedTarget;
        const submenuElement = menuRef.current.querySelectorAll('.xlnz-submenu')[index];

        if (submenuElement && submenuElement.contains(relatedTarget)) {
            return;
        }

        submenuTimeout.current = setTimeout(() => {
            setActiveSubMenu(null);
        }, 300);
    };

    // const renderMenuItem = (item, index) => {
    //     const itemDisabled = item.disabled
    //     return <div
    //         key={index}
    //         className={"xlnz-menu-item" + (itemDisabled ? " xlnz-menu-item-disabled" : "")}
    //         onMouseEnter={() => handleMouseEnter(item, index)}
    //         onMouseLeave={(event) => handleMouseLeave(index, event)}
    //         onClick={e => {
    //             if (itemDisabled) return
    //             e.preventDefault();
    //             e.stopPropagation();
    //
    //             if (item.command) item.command();
    //             if (!item.items) {
    //                 setMenuVisible(false);
    //             }
    //         }}
    //         style={{display: 'flex', alignItems: 'center', position: 'relative'}}
    //     >
    //         {item.faicon && <div className='xlnz-menu-item-icon'>
    //             <FontAwesomeIcon icon={item.faicon}/>
    //         </div>}
    //
    //         {item.label && <span>{item.label}</span>}
    //         {item.labelI18n && <span>{i18n(item.labelI18n)}</span>}
    //
    //         {item.items && <div className="xlnz-menu-item-arrow"><FontAwesomeIcon icon={faChevronRight}/></div>}
    //         {item.items && activeSubMenu === index && <div
    //             className="xlnz-submenu"
    //             style={{position: 'absolute', top: 0, left: '100%'}}
    //             onMouseEnter={() => clearTimeout(submenuTimeout.current)}
    //             onMouseLeave={(event) => handleMouseLeave(index, event)}
    //         >
    //             {item.items.map((subItem, subIndex) => renderMenuItem(subItem, subIndex))}
    //         </div>}
    //     </div>;
    // };

    const renderMenuItem = (item, index) => {
        const itemDisabled = item.disabled;

        return (
            <div
                key={index}
                className={"xlnz-menu-item" + (itemDisabled ? " xlnz-menu-item-disabled" : "")}
                onMouseEnter={() => handleMouseEnter(item, index)}
                onMouseLeave={(event) => handleMouseLeave(index, event)}
                onClick={(e) => {
                    if (itemDisabled) return;
                    e.preventDefault();
                    e.stopPropagation();

                    if (item.command) item.command();
                    if (!item.items) {
                        setMenuVisible(false);
                    }
                }}
                style={{ display: 'flex', alignItems: 'center', position: 'relative' }}
            >
                {item.faicon && (
                    <div className='xlnz-menu-item-icon'>
                        <FontAwesomeIcon icon={item.faicon} />
                    </div>
                )}

                {item.label && <span>{item.label}</span>}
                {item.labelI18n && <span>{i18n(item.labelI18n)}</span>}

                {item.items && (
                    <div className="xlnz-menu-item-arrow">
                        <FontAwesomeIcon icon={faChevronRight} />
                    </div>
                )}

                {item.items && activeSubMenu === index && (
                    <div
                        className="xlnz-submenu"
                        ref={(submenuRef) => {
                            if (submenuRef) {
                                const submenuRect = submenuRef.getBoundingClientRect();
                                const viewportWidth = window.innerWidth;
                                const viewportHeight = window.innerHeight;

                                // Justera vänster position om den överlappar högra kanten av fönstret
                                // let leftPosition = submenuRect.left;
                                if (submenuRect.right > viewportWidth) {
                                    submenuRef.style.left = `-${submenuRect.width}px`;
                                }

                                // Justera topposition om den överlappar nederkanten av fönstret
                                if (submenuRect.bottom > viewportHeight) {
                                    const overflowY = submenuRect.bottom - viewportHeight;
                                    submenuRef.style.top = `-${overflowY}px`;
                                }
                            }
                        }}
                        style={{ position: 'absolute', top: 0, left: '100%' }}
                        onMouseEnter={() => clearTimeout(submenuTimeout.current)}
                        onMouseLeave={(event) => handleMouseLeave(index, event)}
                    >
                        {item.items.map((subItem, subIndex) => renderMenuItem(subItem, subIndex))}
                    </div>
                )}
            </div>
        );
    };


    return mounted && menuVisible
        ? ReactDOM.createPortal(
            <div
                id={id}
                ref={menuRef}
                className="xlnz-menu"
                style={{...style}}
            >
                {model.map((item, index) => renderMenuItem(item, index))}
            </div>,
            menuContainer.current
        )
        : null;
});

export default XlnzMenu;
