import React, {useCallback, useRef, useState} from "react";
import {findByField, removeByValue} from "../../utils/ArrayUtil";
import Checkbox from "../EditForm/Checkbox";
import useXlnxGridDragAndDrop from "./useXlnxGridDragAndDrop";
import useXlnzGridOutsideClickEvent from "./useXlnzGridOutsideClickEvent";
import XlnzGridContentCell from "./XlnzGridContentCell";


export default function XlnzGridContent(props) {
    const {
        disabled,
        columns,
        data,
        pagination,
        getColumnWidth,

        selection,
        onSelectionChange,
        onRowSelect,

        onRowReorder,

        contextMenuOptions,
        setContextMenuProps,

        rowClassName,
        cellClassName,

        xlnxGridExpandable
    } = props

    if (!data) throw new Error("No data/value/list provided");
    const value = data

    const [draggedDropIndex, setDraggedDropIndex] = useState()
    const xlnxGridDragAndDrop = useXlnxGridDragAndDrop(data, setDraggedDropIndex, onRowReorder)

    const [edit, setEdit] = useState()
    const editRef = useRef();

    // PAGING
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 100; // Hur många rader du vill ha per sida
    const totalPages = Math.ceil(value.length / rowsPerPage);
    const usePagination = () => pagination && totalPages > 1

    const indexOfLastRow = currentPage * rowsPerPage
    const indexOfFirstRow = indexOfLastRow - rowsPerPage
    const currentRows = usePagination() ? value.slice(indexOfFirstRow, indexOfLastRow) : value

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber)
    }


    function isEditmode(col, row, rowIndex) {
        if (disabled) return false
        return edit &&
            edit.col.field === col.field &&
            edit.rowIndex === rowIndex
    }

    function getEditor(col, row, rowIndex) {
        if (!col.editor) return

        const editor = col.editor({
            field: col.field,
            rowIndex: rowIndex,
            rowData: row,
            props: {
                value: value
            },
            editorCallback: (newValue, endEditMode) => {
                row[col.field] = newValue
                const _edit = {...edit, value: newValue}

                if (endEditMode) {
                    console.log("_edit", _edit)
                    fireOnCellEditComplete(_edit)
                    setEdit(undefined)
                } else {
                    setEdit(_edit)
                }
            }
        })

        if (!editor) {
            // Disabled editing when no editor is returned.
            setEdit(undefined)
        }

        const _editor = {...editor}

        const _props = {..._editor.props}
        _props.value = edit.value
        _props.autoFocus = true;

        _editor.props = _props;
        return _editor
    }

    const getRowIndex = row => currentRows.indexOf(row);

    async function editStart(col, row) {
        if (edit) {
            await fireOnCellEditComplete(edit)
        }
        const _edit = {
            col: col,
            row: row,
            rowIndex: getRowIndex(row),
            editor: col.editor,
            value: row[col.field],
            oldValue: row[col.field]
        }
        // console.log("editStart", _edit)
        setEdit(_edit)
    }

    const fireOnCellEditComplete = useCallback(async (_edit) => {
        // Need the get the most recent column.onCellEditComplete else edit won't work as good.
        const col = columns.find(col => col.field === _edit.col.field)

        // console.log("fireOnCellEditComplete", _edit)
        // try {
        //     throw new Error("")
        // } catch (e) {
        //     console.error("ERROR", e)
        // }

        // If you want to look into this. The problem is with paging and new rows. Thats why the fallback is there.
        let _rowIndex = value.indexOf(_edit.row)
        if (_rowIndex < 0) _rowIndex = _edit.rowIndex

        return col.onCellEditComplete({
            field: col.field,
            rowData: _edit.row,
            // rowIndex: _edit.rowIndex,
            rowIndex: _rowIndex,
            oldValue: _edit.oldValue,
            newValue: _edit.value,
            props: {
                value: value
            }
        })
    }, [columns, value])

    const editComplete = useCallback(async () => {
        if (edit) {
            await fireOnCellEditComplete(edit)
            setEdit(undefined)
        }
    }, [edit, fireOnCellEditComplete])


    const handleClickOutside = useCallback(event => {
        if (editRef.current && !editRef.current.contains(event.target)) {
            editComplete();
        }
    }, [editComplete])

    useXlnzGridOutsideClickEvent(handleClickOutside, [handleClickOutside], 'mousedown')

    function findNextEditableCell(backward) {
        let nextCol
        let nextRow

        if (backward) {
            let prevColIndex = columns.indexOf(findByField(columns, 'field', edit.col.field))
            let rowIndex = edit.rowIndex
            let editor

            do {
                prevColIndex--
                if (prevColIndex < 0) {
                    prevColIndex = columns.length - 1
                    rowIndex--
                }
                nextCol = columns[prevColIndex]
                nextRow = value[rowIndex]

                if (rowIndex < 0) {
                    // console.log("BREAKING")
                    break
                }
                editor = getEditor(nextCol, value[rowIndex], rowIndex)
            } while (editor === undefined)
        } else {
            let nextColIndex = columns.indexOf(findByField(columns, 'field', edit.col.field))
            let rowIndex = edit.rowIndex
            let editor

            do {
                nextColIndex++
                if (nextColIndex > columns.length - 1) {
                    nextColIndex = 0
                    rowIndex++
                }
                nextCol = columns[nextColIndex]
                nextRow = value[rowIndex]

                if (rowIndex > value.length - 1) {
                    // console.log("BREAKING")
                    break
                }
                editor = getEditor(nextCol, value[rowIndex], rowIndex)
            } while (editor === undefined)
        }

        if (nextCol && nextRow) {
            editStart(nextCol, nextRow)
        } else {
            editComplete()
        }

        // console.log("RESSOISO", nextRow)
        return nextRow
    }

    function onKeyDown(e) {
        switch (e.key) {
            case 'Enter':
            case 'Escape':
            case 'Tab':
                e.preventDefault() //Needed for Tab-key
                break
            default:
                break
        }
    }

    function onKeyUp(e) {
        switch (e.key) {
            case 'Enter':
            case 'Escape':
                editComplete()
                break
            case 'Tab':
                console.log("TAB")
                findNextEditableCell(e.shiftKey)
                break
            default:
                break
        }
    }

    function getClassStringFromObject(row) {
        return Object.keys(rowClassName(row))
            .filter(key => rowClassName(row)[key] === true)
            .join(' ')
    }

    const xlnxGridDragAndDropProps = edit === undefined ? xlnxGridDragAndDrop : {};

    return <div className='content'>
        {
            currentRows
                .filter(xlnxGridExpandable.isRowVisible)
                .map((row, rowIndex) => {
                    const selected = selection && selection.indexOf(row) >= 0

                    return <div key={row.id + '-' + rowIndex}
                                custom-rowindex={rowIndex}
                                className={
                                    'content-row '
                                    + (draggedDropIndex === rowIndex ? ' current-drop ' : '')
                                    + (selected ? ' selected ' : '')
                                    + (rowClassName ? getClassStringFromObject(row) + ' ' : '')
                                }

                                {...xlnxGridDragAndDropProps}
                    >
                        {xlnxGridExpandable.renderContent(row)}
                        {
                            columns.map(col => {
                                // console.log("XOOL", col, col.minWidth > 0 ? col.minWidth + 'px' : 0, getColumnWidth(col))
                                const style = {
                                    minWidth: col.minWidth > 0 ? col.minWidth : getColumnWidth(col),
                                    width: getColumnWidth(col),
                                    maxWidth: getColumnWidth(col),
                                    justifyContent: col.align,
                                    textAlign: col.align,
                                    ...col.style
                                }

                                if (col.selectionMode === 'multiple') {
                                    style.display = "flex"
                                    style.alignItems = "center"
                                    style.justifyContent = "center"
                                    // style.textAlign = 'center'
                                    return <div key={col.field}
                                                custom-rowindex={rowIndex}
                                                name={col.field}
                                                style={style}
                                                className='content-cell'>
                                        <Checkbox checked={selected}
                                                  disabled={disabled}
                                                  onChange={e => {
                                                      const _selection = [...selection]
                                                      if (selected) {
                                                          removeByValue(_selection, row)
                                                      } else {
                                                          _selection.push(row)
                                                      }
                                                      onSelectionChange({
                                                          value: _selection,
                                                          row: row,
                                                          checked: !selected
                                                      })
                                                  }}
                                        />
                                    </div>
                                }

                                const editMode = isEditmode(col, row, rowIndex)

                                return <div key={col.field}
                                            custom-rowindex={rowIndex}
                                            name={col.field}
                                            className={'content-cell'
                                                + (col.className ? ' ' + col.className : '')
                                                + (editMode ? ' edit-mode' : '')
                                            }
                                            style={style}
                                            onClick={e => {
                                                if (!col.rendererIsEditor) {
                                                    e.preventDefault()
                                                    e.stopPropagation()
                                                }

                                                // console.log("edit", edit)
                                                // console.log("col", col)
                                                // console.log("getRowIndex(row)", getRowIndex(row))

                                                if (disabled) return

                                                if (col.editor) {
                                                    if (!edit || edit.col.field !== col.field || getRowIndex(edit.row) !== getRowIndex(row)) {
                                                        // console.log("EDITOR START CLICK!!", edit, col, getRowIndex(row))
                                                        editStart(col, row)
                                                    } else {
                                                        // console.log("EDITOR CLICK", e)
                                                    }
                                                } else {
                                                    onRowSelect({data: row})
                                                }
                                            }}
                                            onContextMenu={e => {
                                                if (contextMenuOptions && !e.ctrlKey) {
                                                    e.preventDefault();
                                                    setContextMenuProps({
                                                        top: e.clientY,
                                                        left: e.clientX,
                                                        row: row
                                                    })
                                                }
                                            }}
                                >
                                    {
                                        editMode &&
                                        <div ref={editRef}
                                             autoFocus
                                             onKeyDown={onKeyDown}
                                             onKeyUp={onKeyUp}
                                             style={{width: '100%', height: '100%'}}
                                        >
                                            {getEditor(col, row, rowIndex)}
                                        </div>
                                    }

                                    {
                                        !editMode &&
                                        <XlnzGridContentCell col={col} row={row} data={data}
                                                             cellClassName={cellClassName}/>
                                    }
                                </div>
                            })
                        }
                    </div>
                })
        }


        {/* Pagination controls */}
        {
            usePagination() &&
            <div className="pagination">
                {Array.from({length: totalPages}, (_, index) => (
                    <button
                        key={index}
                        onClick={() => handlePageChange(index + 1)}
                        disabled={currentPage === index + 1}
                        className={currentPage === index + 1 ? 'active' : ''}
                    >
                        {index + 1}
                    </button>
                ))}
            </div>
        }
    </div>
}