import React, {useCallback, useMemo} from 'react';
import {useLocation} from "react-router-dom";
import FormItemCheckbox from "../../components/EditForm/FormItemCheckbox";
import FormItemDropdown from "../../components/EditForm/FormItemDropdown";
import FormItemEmail from "../../components/EditForm/FormItemEmail";
import FormItemText from "../../components/EditForm/FormItemText";
import SimpleEditForm from "../../components/EditForm/SimpleEditForm";
import Language from "../../enums/Language";
import Role from "../../enums/Role";
import Userprop from "../../enums/Userprop";
import useForceUpdate from "../../hooks/useForceUpdate";
import usePreload from "../../hooks/usePreload";
import {findByField, removeByValue} from "../../utils/ArrayUtil";
import {deepCopy} from "../../utils/ObjectUtils";
import RouterUtils from "../../utils/RouterUtils";
import {allModules} from "../modules";
import SelectRestrictPaths from "../Usergroups/SelectRestrictPaths";
import SelectRoles from "../Usergroups/SelectRoles";
import UserService from "./UserService";

const ROLE_FIELD_PREFIX = "ROLE_"

export default function UserEditForm() {
    const location = useLocation()
    const id = RouterUtils.getId(location)

    const preload = usePreload()
    const usergroupList = useMemo(() => preload.getUsergroupList(), [preload])

    const forceUpdate = useForceUpdate()

    const defaultValues = useMemo(() => ({
        id: 0,
        name: '',
        language: Language.SWEDISH.id,
        roleSet: []
    }), [])
    // const [usergroup, setUsergroup] = useState()

    const postLoadFunction = useCallback(_model => {
        _model.roleSet.forEach(roleName => _model[ROLE_FIELD_PREFIX + roleName] = true)

        Userprop.values().forEach(prop => extractProp(_model, prop.name))

        extractProp(_model, 'restrict-paths')

        if (_model['restrict-paths']) {
            _model['restrict-paths'].split(';').forEach(item => {
                _model['PATH_' + item] = true
            })
        }

        // setUsergroup(_model.usergroup)
    }, [])

    function extractProp(_model, name) {
        const prop = _model.propList.filter(p => p.name === name)[0]

        const propType = Userprop.findById(name)
        let value = prop?.value || ''

        if (propType?.type === 'boolean') {
            value = value === '1'
        }

        _model[name] = value
    }

    const preSaveFunction = data => {
        data.roleSet = Role.values()
            .filter(role => data[ROLE_FIELD_PREFIX + role.name] === true)
            .filter(role => hasDependencies(role.dependencies))
            .filter(role => role !== Role.SUPERADMIN)
            .map(role => role.name)

        const oldPropList = data.propList ? deepCopy(data.propList) : []
        data.propList = []
        Userprop.values().forEach(prop => addProp(oldPropList, data.propList, prop.name, data[prop.name],))

        let restrictPaths = ''
        if (data.usergroup > 0) {
            const hideArticleValueItem = findByField(data.propList, 'name', Userprop.HIDE_ARTICLE_PRICES.name)
            if (hideArticleValueItem) removeByValue(data.propList, hideArticleValueItem)
        } else {
            allModules.forEach(item => {
                if (data['PATH_' + item.routeProps.path]) {
                    if (restrictPaths.length > 0) restrictPaths += ';'
                    restrictPaths += item.routeProps.path
                }
                if (item.routeProps.items) {
                    item.routeProps.items.forEach(subItem => {
                        if (data['PATH_' + subItem.path]) {
                            if (restrictPaths.length > 0) restrictPaths += ';'
                            restrictPaths += subItem.path
                        }
                    })
                }
            })
        }
        addProp(oldPropList, data.propList, 'restrict-paths', restrictPaths)
    }

    function addProp(oldPropList, propList, field, value) {
        if (typeof value === 'boolean') {
            value = value ? '1' : ''
        }
        if (value?.length > 0) {
            propList.push({
                id: oldPropList.find(item => item.name === field)?.id,
                name: field,
                value: value
            })
        }
    }

    const hasDependencies = (form, dependencies) => {
        if (!dependencies || dependencies.length === 0) return true;

        const model = form.getValues()
        const numOfDependenciesChecked = dependencies.reduce((sum, d) => {
            let value = form.getValues(ROLE_FIELD_PREFIX + d.name)
            if (value === undefined) {
                value = model.roleSet.find(r => r === d) !== undefined
            }
            sum += value ? 1 : 0;
            return sum;
        }, 0);
        return dependencies.length === numOfDependenciesChecked;
    }

    return <SimpleEditForm
        writeRole={Role.USER_WRITE}
        headlineI18n='user'
        defaultValues={defaultValues}
        loadFunction={UserService.findById}
        postLoadFunction={postLoadFunction}
        preSaveFunction={preSaveFunction}
        saveFunction={UserService.addCollaborator}
        removeFunction={UserService.removeCollaborator}
        initLeftFields={form => {
            const usergroup = form.getValues('usergroup')
            return [
                <FormItemText key='name' name='name' label='Namn' maxLength={128} forceDisabled={id > 0}
                              required {...form}/>,
                <FormItemEmail key='email' name='email' label='E-post' forceDisabled={id > 0} {...form}/>,

                <FormItemDropdown key='language' name='language' labelI18n='language'
                                  options={Language.values()}
                                  showSelect={false}
                                  {...form}
                />,

                <FormItemDropdown key='usergroup' name='usergroup' labelI18n='user_group'
                                  options={usergroupList}
                                  onChange={e => {
                                      forceUpdate()
                                      //     console.log("e.target.value", e.target.value)
                                      //     form.setValue('usergroup', e.target.value)
                                      //     setUsergroup(e.target.value);
                                  }}
                                  showSelect={true}
                                  {...form}
                />,
                usergroup ? undefined : <SelectRoles key='SelectRoles' form={form}/>
            ];
        }}
        initCenterFields={form => {
            const usergroup = form.getValues('usergroup')
            return [
                usergroup ? undefined : <SelectRestrictPaths key='SelectRestrictPaths' form={form}/>
            ];
        }}
        initRightFields={form => {
            const usergroup = form.getValues('usergroup')
            return [
                usergroup ? undefined : <FormItemCheckbox key={Userprop.HIDE_ARTICLE_PRICES.name}
                                                          name={Userprop.HIDE_ARTICLE_PRICES.name}
                                                          labelI18n='hide_article_prices' {...form}/>,
            ];
        }}
    />
}