import React, {useContext} from "react";
import AppContext from "../../../AppContext";
import {CancelButton, GenerateButton} from "../../../components/Buttons/Button";
import {hideLoading, showLoading} from "../../../components/Overlay/Loading";
import XlnzDialog from "../../../components/XlnzDialog/XlnzDialog";
import useDialog from "../../../hooks/useDialog";
import {i18n} from "../../../I18n/I18n";
import {formatNumber} from "../../../utils/NumberUtils";
import {formatAddress} from "../../../utils/StringUtil";
import SupplierinvoiceService from "../SupplierinvoiceService";
import SupplierinvoiceVatType from "../SupplierinvoiceVatType";

export default function InterpretSupplierinvoicePdfButton(props) {
    const context = useContext(AppContext)
    const {
        handleSubmit,
        save,
        reset
    } = props

    const dialog = useDialog()

    const onSubmit = async data => {
        showLoading();
        const _model = await save(data)
        if (_model) {
            reset(_model)
            const _resp = await SupplierinvoiceService.interpretPdf(_model.id)
            if (_resp) {
                console.log("_resp", _resp)
                dialog.show(<InterpretSupplierinvoicePdfDialog
                    supplier={_resp.supplier}
                    supplierinvoice={_resp.supplierinvoice}
                />)
            }
            hideLoading();
        } else {
            hideLoading();
        }
    }

    if (!context.user.dev) return <></>
    return <>
        <GenerateButton
            label={'Tolka PDF'}
            // label={I18n.t('export_to_fortnox') + '...'}
            onClick={handleSubmit(onSubmit)}
        />
        {dialog.render()}
    </>
}


function InterpretSupplierinvoicePdfDialog(props) {
    // const context = useContext(AppContext)

    const {
        onHide,
        // save,
        // reset,
        supplier,
        supplierinvoice
    } = props

    return <XlnzDialog header='Tolkad data'
                       onHide={props.onHide}
                       buttons={props => [
                           // <MainButton key='save' labelI18n='save' onClick={onSave}/>,
                           <CancelButton key='cancel' onClick={e => onHide()}/>
                       ]}
    >
        <div style={{width: '500px'}}>
            <div>
                Detta är en AI-baserad fakturatolkning som automatiskt läser leverantörsfakturor.
                Den sparar tid och minskar risken för manuella fel. Eftersom fakturor kan variera i utformning kan AI:n
                ibland göra felbedömningar.
                Dubbelkolla därför alltid kritisk information som Bankgiro, Plusgiro, IBAN och belopp.
            </div>

            <h2>{i18n('supplier')}</h2>
            {
                supplier &&
                <>
                    {
                        supplier.id === 0 &&
                        <div style={{color: 'red'}}>Detta är en ny leverantör, dubbelkolla uppgifterna!</div>
                    }
                    {
                        supplier.id > 0 &&
                        <div style={{color: 'green'}}>Detta är en befintlig leverantör och den kommer inte att
                            ändras.</div>
                    }
                        <table className='info-table' style={{marginTop: '15px'}}>
                            <tbody>
                            <tr>
                                <th>{i18n('name')}</th>
                                <td>{supplier.name}</td>
                            </tr>
                            <tr>
                                <th>{i18n('address')}</th>
                                <td>{formatAddress(supplier.address)}</td>
                            </tr>
                            <tr>
                                <th>{i18n('reg_no')}</th>
                                <td>{supplier.regNo}</td>
                            </tr>
                            <tr>
                                <th>{i18n('vat_no')}</th>
                                <td>{supplier.vatNo}</td>
                            </tr>
                            <tr>
                                <th>{i18n('vat_type')}</th>
                                <td>{SupplierinvoiceVatType.getName(supplier.vatType)}</td>
                            </tr>
                            <tr>
                                <th>Bankgiro</th>
                                <td>{supplier.bankgiro}</td>
                            </tr>
                            <tr>
                                <th>Plusgiro</th>
                                <td>{supplier.plusgiro}</td>
                            </tr>
                            <tr>
                                <th>IBAN</th>
                                <td>{supplier.iban}</td>
                            </tr>
                            </tbody>
                        </table>
                </>
            }

            <div>
                <h2>{i18n('supplier_invoice')}</h2>
                <table className='info-table' style={{marginTop: '15px'}}>
                    <tbody>
                    <tr>
                        <th>{i18n('invoice_number')}</th>
                        <td>{supplierinvoice.number}</td>
                    </tr>
                    <tr>
                        <th>{i18n('invoice_date')}</th>
                        <td>{supplierinvoice.invoiceDate}</td>
                    </tr>
                    <tr>
                        <th>{i18n('due_date')}</th>
                        <td>{supplierinvoice.dueDate}</td>
                    </tr>
                    <tr>
                        <th>{i18n('price_incl_vat')}</th>
                        <td>{formatNumber(supplierinvoice.priceInclVat, 2)}</td>
                    </tr>
                    <tr>
                        <th>{i18n('vat')}</th>
                        <td>{formatNumber(supplierinvoice.vatPrice, 2)}</td>
                    </tr>
                    <tr>
                        <th>{i18n('vat_type')}</th>
                        <td>{SupplierinvoiceVatType.getName(supplierinvoice.vatType)}</td>
                    </tr>
                    <tr>
                        <th>{i18n('currency')}</th>
                        <td>{supplierinvoice.currency}</td>
                    </tr>
                    <tr>
                        <th>OCR</th>
                        <td>{supplierinvoice.ocr}</td>
                    </tr>
                    <tr>
                        <th>{i18n('your_reference')}</th>
                        <td>{supplierinvoice.yourReference}</td>
                    </tr>
                    <tr>
                        <th>{i18n('reference_no')}</th>
                        <td>{supplierinvoice.markup}</td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <div>
                <h2>{i18n('rows')}</h2>
                <table className='info-table' style={{marginTop: '15px'}}>
                    <thead>
                    <tr>
                        <th>{i18n('description')}</th>
                        <th className='alignRight'>{i18n('amount')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        supplierinvoice.rowList.map((item, index) => <tr key={item.name + index}>
                            <td>{item.name}</td>
                            <td className='alignRight'>{formatNumber(item.price, 2)}</td>
                        </tr>)
                    }
                    </tbody>
                </table>
            </div>

        </div>
    </XlnzDialog>
}
