import {useContext, useMemo, useState} from "react";
import AppContext from "../AppContext";
import {getAmountFromStartDateList} from "../components/AmountWithStartDateGrid/AmountWithStartDateGrid";
import Companyprop from "../enums/Companyprop";
import {contains, findByField, findById} from "../utils/ArrayUtil";
import {deepCopy} from "../utils/ObjectUtils";
import useCompanyProp from "./useCompanyProp";
import usePreload from "./usePreload";

export default function useEmployees() {
    const context = useContext(AppContext)
    const preload = usePreload()
    const [forceUpdateCounter, setForceUpdateCounter] = useState(0)

    const list = useMemo(() => deepCopy(preload.getEmployeeList()),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [preload, forceUpdateCounter])

    const company = context.currentCompany

    const departmentNumber = useCompanyProp(Companyprop.DEPARTMENT, -1)
    const projectNo = useCompanyProp(Companyprop.PROJECT_NO, undefined)

    const departmentList = useMemo(() => company.departmentList, [company])
    const department = useMemo(() => findByField(departmentList, 'number', departmentNumber),
        [departmentList, departmentNumber])

    const subcontractorList = useMemo(() => preload.getSupplierList().filter(item => item.subcontractor), [preload])


    if (departmentList.length > 0) {
        list.forEach(item => {
            item.departmentsStr = ''
            departmentList
                .forEach(department => {
                    if (item.company === department.id) {
                        item.departmentsStr += department.name
                    }
                })

            if (item.departmentsStr.length > 0 && item.additionalDepartments.length > 0) item.departmentsStr += ', '

            item.departmentsStr += item.additionalDepartments
                .map(dId => findById(departmentList, dId))
                .filter(d => d?.active)
                .map(d => d.name || 'ERROR:' + d.id)
                .join(', ')

            item.subcontractorName = findById(subcontractorList, item.subcontractor)?.name || ''
        })
    }


    const usable = useMemo(() => {
        function belongsToObjectsParentDepartment(employee) {
            const parentDepartment = department
                ? departmentList.find(item => !item.projectNo && item.number === department.number)
                : undefined

            if (projectNo) {
                return parentDepartment?.id === employee.company
            }
        }

        const result = list
            .filter(item =>
                !company.parentId || // If legal company
                item.company === company.id || // If employee is belongs to current company
                item.company === company.parentId || // If employee doesnt belong to any department
                belongsToObjectsParentDepartment(item) ||
                contains(item.additionalDepartments, company.id)
            )

        for (const item of result) {
            const subcontractor = findById(subcontractorList, item.subcontractor)
            if (subcontractor) {
                item.subcontractorName = subcontractor.name

                if (item.salary === 0) {
                    item.salary = getAmountFromStartDateList(subcontractor.costList, 'cost')
                }
            }
        }

        return result
    }, [list, company, departmentList, department, projectNo, subcontractorList])

    const activeUsable = useMemo(() => usable.filter(item => item.active), [usable])


    const result = useMemo(() => ({
        all: list,
        usable: usable,
        activeUsable: activeUsable,
        departmentList: departmentList,

        findById: id => findById(list, id),

        getActiveUsableOrWithId: id => usable.filter(item => item.active || item.id === id),

        forceUpdate: () => setForceUpdateCounter(forceUpdateCounter + 1)
    }), [list, usable, activeUsable, departmentList, forceUpdateCounter])

    return result
}