import React, {useCallback, useState} from 'react';
import {MainButton} from "../../../../../components/Buttons/Button";
import DatePicker from "../../../../../components/Date/DatePicker";
import Label from "../../../../../components/EditForm/Label";
import {useFilter} from "../../../../../components/Grid/filter";
import {FooterAggregate} from "../../../../../components/Grid/getFooterGroup";
import Grid from "../../../../../components/Grid/Grid";
import {hideLoading, showLoading} from "../../../../../components/Overlay/Loading";
import Icon from "../../../../../enums/Icon";
import {matchSearch} from "../../../../../functions/match";
import {GridTemplates} from "../../../../../GridTemplates";
import {getFirstDateOfMonth, todayDate} from "../../../../../utils/DateUtils";
import NetUtils from "../../../../../utils/NetUtils";

const St1 = () => {
    const filter = useFilter('St1', [
        {name: 'startDate', defaultValue: getFirstDateOfMonth()},
        {name: 'endDate', defaultValue: todayDate()},
        {name: 'search', defaultValue: ''},
    ])
    const [list, setList] = useState([])
    const filteredList = list.filter(item => matchSearch(filter, item))

    const loadData = useCallback(() => {
        showLoading()
        NetUtils.doGet('/api/reports/st1', {
            startDate: filter.startDate,
            endDate: filter.endDate
        }).then(res => {
            setList(res)
            hideLoading()
        });
    }, [filter.startDate, filter.endDate]);

    const columns = [
        {field: 'date', header: 'Datum', width: 120},
        {
            field: 'quantity',
            header: 'Antal',
            body: GridTemplates.cubicMeter,
            footer: FooterAggregate.SUM_CUBIC_METER,
            width: 150,
            align: 'right'
        },
        {
            field: 'billableQuantity',
            header: 'Debiterbart antal',
            body: GridTemplates.cubicMeter,
            footer: FooterAggregate.SUM_CUBIC_METER,
            width: 150,
            align: 'right'
        },
        {
            field: 'price',
            header: 'Pris',
            body: GridTemplates.currency,
            footer: FooterAggregate.SUM_CURRENCY,
            width: 150,
            align: 'right'
        },
        {field: 'padding', header: '',},
    ]

    const leftFilters = [
        <div>
            <Label valueI18n='start_date'/>
            <DatePicker value={filter.startDate} onChange={date => filter.update('startDate', date)}/>
        </div>,

        <div>
            <Label valueI18n='end_date'/>
            <DatePicker value={filter.endDate} onChange={date => filter.update('endDate', date)}/>
        </div>
    ]

    leftFilters.push(<div>
        <label className="label">&nbsp;</label>
        <MainButton labelI18n='create_report' onClick={() => loadData()}/>
    </div>)

    return <Grid
        id='St1'
        name='Enter - St1'
        forceDesktop={true}
        label='St1'
        icon={Icon.REPORT}
        leftFilters={leftFilters}
        filter={filter}
        updateFilter={filter.update}
        columns={columns}
        value={filteredList}
        onRowSelect={e => {
        }}
    />
}

export default St1;