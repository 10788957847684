import React, {useContext, useEffect, useState} from 'react';
import {useForm, useWatch} from "react-hook-form";
import AppContext from "../../../AppContext";
import {CancelButton, RemoveButton, SaveButton} from "../../../components/Buttons/Button";
import FormItemCheckbox from "../../../components/EditForm/FormItemCheckbox";
import FormItemDate from "../../../components/EditForm/FormItemDate";
import FormItemDropdown from "../../../components/EditForm/FormItemDropdown";
import FormItemEmployee from "../../../components/EditForm/FormItemEmployee";
import FormItemNumber from "../../../components/EditForm/FormItemNumber";
import FormItemTextArea from "../../../components/EditForm/FormItemTextArea";
import {confirmYes} from "../../../components/Overlay/Confirm";
import {hideLoading, showLoading} from "../../../components/Overlay/Loading";
import Spinner from "../../../components/Spinner/Spinner";
import XlnzDialog from "../../../components/XlnzDialog/XlnzDialog";
import Role from "../../../enums/Role";
import useMountEffect from "../../../hooks/useMountEffect";
import usePreload, {PreloadType} from "../../../hooks/usePreload";
import useUserPersmissions from "../../../hooks/useUserPersmissions";
import {i18n} from "../../../I18n/I18n";
import {filterActive, findById} from "../../../utils/ArrayUtil";
import {formatDate, getYearMonth} from "../../../utils/DateUtils";
import {isNumeric} from "../../../utils/NumberUtils";
import MonthlockService from "../../Timereg/MonthlockService";
import TimeregService from "../../Timereg/TimeregService";

const ProjectSummaryTimeregEditDialog = props => {
    const context = useContext(AppContext)
    const userPersmissions = useUserPersmissions()

    const {
        project,
        model,
        onSave,
        onHide
    } = props

    const [monthLocked, setMonthLocked] = useState()

    const preload = usePreload()
    const salarytypeList = preload.get(PreloadType.TIMEREGSALARYTYPE_LIST)
    const debittypeList = preload.get(PreloadType.TIMEREGDEBITTYPE_LIST)

    const getSalarytype = id => findById(salarytypeList, id)
    // const getDebittype = id => findById(debittypeList, id)

    const defaultValues = {
        company: context.currentCompany.id,
        project: project.id,
        salarytype: salarytypeList[0]?.id,
        debittype: salarytypeList[0]?.debittype,
        date: formatDate(),
        fixedHours: 0,
        costplusHours: 0,
        debitHours: 0,
        note: '',
        employeeComment: '',
        notarized: true
    }

    const disabled = !userPersmissions.hasRole(Role.TIMEREG_WRITE) ||
        (!userPersmissions.hasRole(Role.TIMEREG_UNLOCK_DAY) && model.locked)


    const form = {...useForm({defaultValues: defaultValues}), disabled: disabled}
    const {handleSubmit, reset, formState: {isSubmitting}, getValues, setValue, control} = form

    // const [daylockSet, setDaylockSet] = useState()
    //
    // const [windowWidth] = useWindowSize()
    // const dialogWidth = Math.min(1600, windowWidth - 80)
    //

    const watchDate = useWatch({control, name: 'date'})


    useMountEffect(async () => {
        async function load() {

            if (model?.id > 0) {
                showLoading()
                reset(await TimeregService.findById(model.id))
                hideLoading()
            }
        }

        load()
    })

    useEffect(() => {
        async function load() {
            showLoading()
            setMonthLocked(await MonthlockService.isLocked(getYearMonth(watchDate)))
            hideLoading()
        }

        load()
    }, [watchDate]);

    const onSalarytypeChange = e => {
        const value = e.target.value
        if (!isInvoiced()) setValue('debittype', getSalarytype(value)?.debittype)
    }

    // const updateDebittype = (editorEvent, debittype) => {
    //     const salarytypeEditorEvent = Object.assign({}, editorEvent)
    //     salarytypeEditorEvent.field = 'debittype'
    //     onRowChange(salarytypeEditorEvent, debittype, fireChange)
    // }
    //
    //
    function isInvoiced() {
        return !!model?.invoiced
        // return false
        // return invoicerowtimeregList?.find(item => item.timereg === timereg.id) !== undefined
    }


    const onSubmit = async () => {
        const data = getValues();
        showLoading()

        if(!isNumeric(data.fixedHours)) data.fixedHours = 0
        if(!isNumeric(data.costplusHours)) data.costplusHours = 0
        if(!isNumeric(data.debitHours)) data.debitHours = 0

        await TimeregService.update(data)
        hideLoading()
        onSave()
        onHide()
    }


    function onRemove(e) {
        confirmYes(async () => {
            showLoading()
            await TimeregService.delete(getValues())
            hideLoading()
            onSave()
            onHide()
        })
    }

    if (monthLocked === undefined) return <Spinner/>

    return <XlnzDialog headerI18n='time_registration'
                       onHide={props.onHide}
                       buttons={props => [
                           monthLocked || disabled ? undefined :
                               <SaveButton key='save' onClick={e => handleSubmit(onSubmit)()} disabled={isSubmitting}/>,
                           monthLocked || !model || disabled || isInvoiced() ? undefined :
                               <RemoveButton key='remove' onClick={onRemove}/>,
                           <CancelButton key='cancel' onClick={e => onHide()}/>
                       ]}
    >
        <div className='EditForm' style={{width: '900px'}}>
            <form>
                <div className='columnContainer'>
                    <div className='column'>
                        <div>
                            <FormItemDate name='date' labelI18n='date'
                                          {...form}
                                          disabled={model?.id > 0} // Must be disabled. A timereg belongs to a dayplanning
                            />
                            {
                                monthLocked &&
                                <div style={{color: 'red'}}>{i18n('month_is_locked')}</div>
                            }
                        </div>

                        <FormItemEmployee name='employee' labelI18n='employee' required
                                          {...form} disabled={disabled || isInvoiced()}/>
                    </div>
                    <div className='column'>
                        <div style={{display: 'flex', gap: '10px'}}>
                            <FormItemNumber name='fixedHours' labelI18n='fixed_time' maxWidth={100}
                                            min={0} max={999} {...form}/>
                            <FormItemNumber name='costplusHours' labelI18n='costplus_time' maxWidth={100}
                                            onBlur={e => {
                                                setValue('debitHours', e.target.value)
                                            }}
                                            min={0} max={999} {...form}/>
                        </div>
                        <FormItemDropdown name='salarytype' labelI18n='salary_type'
                                          options={filterActive(salarytypeList)}
                                          required
                                          onChange={onSalarytypeChange}
                                          {...form} />
                        <FormItemNumber name='debitHours' labelI18n='debit_time' maxWidth={100}
                                        min={0} max={999} {...form} disabled={disabled || isInvoiced()}/>
                        {
                            isInvoiced() &&
                            <div style={{color: 'red'}}>{i18n('invoiced')}</div>
                        }

                        <FormItemDropdown name='debittype' labelI18n='debit_type'
                                          options={filterActive(debittypeList)}
                                          {...form} disabled={disabled || isInvoiced()}/>
                    </div>
                    <div className='column'>
                        {/*<FormItemTextArea name='note' labelI18n='note' maxLength={5000} {...form}/>*/}

                        <FormItemCheckbox name='notarized' labelI18n='notarized' {...form}/>

                        <FormItemTextArea name='employeeComment' labelI18n='comment_from_employee'
                                          maxLength={5000} {...form}/>
                    </div>
                </div>
            </form>
        </div>
    </XlnzDialog>
}
export default ProjectSummaryTimeregEditDialog;