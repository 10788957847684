import React from 'react';
import {CancelButton} from "../../../components/Buttons/Button";
import Grid from "../../../components/Grid/Grid";
import PdfColumn from "../../../components/Grid/PdfColumn";
import XlnzDialog from "../../../components/XlnzDialog/XlnzDialog";
import getEmailStatusName from "../../../functions/getEmailStatusName";
import {GridTemplates} from "../../../GridTemplates";
import {i18n} from "../../../I18n/I18n";
import TenderService from "../TenderService";

const TenderEmailLogDialog = props => {
    const {
        tender,
        onHide
    } = props
    const list = tender.sentlogList

    const columns = [
        PdfColumn(() => true,
            rowData => rowData.number + ' - ' + rowData.name,
            async rowData => (await TenderService.downloadTendersendlogPdf(tender.id, rowData.id)).url
        ),
        {field: 'ctime', header: i18n('sent'), body: GridTemplates.time, width: 140},
        {field: 'toEmail', header: i18n('recipient')},
        {
            field: 'status',
            header: i18n('email_status'),
            body: rowData => getEmailStatusName(rowData.status),
            width: 100
        },
        {field: 'visitTime', header: i18n('last_opened'), body: GridTemplates.time, width: 140},
        {field: 'answerTime', header: i18n('answer_time'), body: GridTemplates.time, width: 140},
        {
            field: 'answer',
            header: i18n('answer'),
            body: row => {
                if (!row.answerTime) {
                    return ''
                } else {
                    return row.accepted ? i18n('accepted') : i18n('denied')
                }
            },
            width: 100
        }
    ]

    return <XlnzDialog headerI18n='email_log'
                       onHide={props.onHide}
                       buttons={() => [
                           <CancelButton key='cancel' onClick={e => onHide()}/>
                       ]}
    >
        <div style={{width: '1000px'}}>
            <Grid columns={columns}
                  value={list}
                  heightOffset={400}
            />
        </div>
    </XlnzDialog>
}

export default TenderEmailLogDialog;
