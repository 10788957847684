import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import AppContext from "../../AppContext";
import {AddButton} from "../../components/Buttons/Button";
import MonthDropdown from "../../components/Date/MonthDropdown";
import XlnzDropdown from "../../components/XlnzDropdown/XlnzDropdown";
import YearFilter from "../../components/filters/YearFilter";
import {useFilter} from "../../components/Grid/filter";
import {FooterAggregate} from "../../components/Grid/getFooterGroup";
import Grid from "../../components/Grid/Grid";
import PdfColumn from "../../components/Grid/PdfColumn";
import {infoAlert} from "../../components/Overlay/Alert";
import {hideLoading, showLoading} from "../../components/Overlay/Loading";
import Tooltip from "../../components/Overlay/Tooltip";
import Icon from "../../enums/Icon";
import Role from "../../enums/Role";
import {match} from "../../functions/match";
import {GridTemplates} from "../../GridTemplates";
import useMountEffect from "../../hooks/useMountEffect";
import usePreload, {PreloadType} from "../../hooks/usePreload";
import useQuery from "../../hooks/useQuery";
import useRoles from "../../hooks/useRoles";
import {i18n} from "../../I18n/I18n";
import {formatTime, todayYear} from "../../utils/DateUtils";
import Env from "../../utils/Env";
import SupplierinvoiceService from "./SupplierinvoiceService";
import SupplierinvoiceStatus from "./SupplierinvoiceStatus";

const SupplierinvoiceGrid = props => {
    const context = useContext(AppContext)
    const roles = useRoles()
    const preload = usePreload()
    const query = useQuery();
    const navigate = useNavigate()

    const attestantList = preload.get(PreloadType.EMPLOYEE_LIST)
        .filter(item => item.attestant)

    const filter = useFilter('SupplierinvoiceGrid', [
        {name: 'status', defaultValue: SupplierinvoiceStatus.DRAFT.id},
        {name: 'year', defaultValue: todayYear()},
        {name: 'month', defaultValue: undefined},
        {name: 'attestant', defaultValue: undefined},
        {name: 'search', defaultValue: ''},
    ])

    const [list, setList] = useState([])
    const filteredList = list.filter(item => match(filter, item, 'attestant'))

    filteredList.forEach(item => {
        item.label = item.supplierName
        if (item.attestant) {
            item.attestantName = attestantList.find(a => a.id === item.attestant)?.name
        }
    })

    const hasTimefilter = useCallback(() => [
                SupplierinvoiceStatus.BOOKKEEPED.id,
                SupplierinvoiceStatus.NOT_APPROVED.id,
                SupplierinvoiceStatus.CANCELLED.id].indexOf(filter.status) !== -1
            || !filter.status
        , [filter.status])

    const loadData = useCallback(async () => {
        showLoading()
        const params = {
            status: filter.status,
            year: hasTimefilter() ? filter.year : undefined,
            month: hasTimefilter() ? filter.month : undefined,
            search: filter.search
        }
        const _list = await SupplierinvoiceService.findAll(params)
        _list.forEach(item => {
            item.priceExclVat = item.priceInclVat - item.vatPrice
            item.statusName = SupplierinvoiceStatus.getName(item.status)
            item.exportedTimeString = formatTime((item.exportedTime))
        })
        setList(_list);
        hideLoading()
    }, [hasTimefilter, filter.status, filter.year, filter.month, filter.search])

    useMountEffect(() => {
        const attestant = query.get('attestant')
        if (attestant) {
            filter.update('status', SupplierinvoiceStatus.UNDER_ATTESTATION.id)
            filter.update('attestant', Number(attestant))
            navigate('/supplierinvoices', {replace: true});
        }
    })

    useEffect(() => {
        loadData()
    }, [loadData])

    const gridLabelRightContent = Env.isMobile() ? undefined : <>
        <div><b>{i18n('scanning_address')}</b></div>
        {
            context.currentCompany.customMailInboxAddress &&
            <div>{context.currentCompany.customMailInboxAddress}</div>
        }
        <div>{context.currentCompany.mailInboxAddress}</div>
    </>

    const columns = [
        PdfColumn(rowData => rowData.fileSize > 0,
            rowData => rowData.number + (rowData.supplierName ? ' - ' + rowData.supplierName : ''),
            async rowData => (await SupplierinvoiceService.getImageUrl(rowData.id)).url
        ),
        {field: 'label', headerI18n: 'supplier'},
        {field: 'number', headerI18n: 'number', width: 130, mobileWithHeader: true},
        {field: 'invoiceDate', headerI18n: 'invoice_date', width: 100, mobileWithHeader: true},
        {field: 'dueDate', headerI18n: 'due_date', width: 100},
        {
            field: 'priceExclVat',
            headerI18n: 'excl_vat',
            body: GridTemplates.currency,
            align: 'right',
            width: 90,
            footer: FooterAggregate.SUM_CURRENCY,
            mobileWithHeader: true
        },
        {
            field: 'vatPrice',
            headerI18n: 'vat',
            body: GridTemplates.currency,
            align: 'right',
            width: 90,
            footer: FooterAggregate.SUM_CURRENCY
        },
        {
            field: 'priceInclVat',
            headerI18n: 'incl_vat',
            body: GridTemplates.currency,
            align: 'right',
            width: 90,
            footer: FooterAggregate.SUM_CURRENCY,
            mobileWithHeader: true
        },
        {field: 'projectLabels', headerI18n: 'projects', mobileWithHeader: true, body: GridTemplates.onelineString},
        {field: 'statusName', headerI18n: 'status', width: 110, mobileWithHeader: true},
        {field: 'attestantName', headerI18n: 'attestant', width: 150, mobileWithHeader: true},
        // {field: 'exportedTimeString', headerI18n: 'exported_to_fortnox', width: 140},
        {
            field: 'bookkeepingId', headerI18n: 'bookkeeping_id', width: 90,
            body: rowData => <Tooltip value={rowData.exportedTimeString}>{rowData.bookkeepingId}</Tooltip>
        },
        {field: 'note', headerI18n: 'note', body: GridTemplates.stringLimit100},
    ];

    const leftFilters = [
        roles.hasRole(Role.INVOICE_WRITE) ? <div>
            <label className="label">&nbsp;</label>
            <AddButton/>
        </div> : undefined,
        <div>
            <label className="label">{i18n('status')}</label>
            <XlnzDropdown
                value={filter.status}
                options={SupplierinvoiceStatus.values()}
                optionLabel='namePlural'
                optionValue='id'
                showClear={true}
                placeholder={i18n('select') + '...'}
                onChange={e => filter.update('status', e.value)}
                style={{width: '180px'}}/>
        </div>
    ]

    if (hasTimefilter()) {
        leftFilters.push(<div>
            <label className="label">{i18n('year')}</label>
            <YearFilter value={filter.year} onChange={e => filter.update('year', e.value)}
            />
        </div>)
        leftFilters.push(<div>
            <label className="label">{i18n('month')}</label>
            <MonthDropdown value={filter.month} onChange={e => {
                filter.update('month', e.value)
            }}/>
        </div>)
    }


    leftFilters.push(<div>
        <label className="label">{'attestant'}</label>
        <XlnzDropdown
            value={filter.attestant}
            options={attestantList}
            optionLabel='name'
            optionValue='id'
            showClear={true}
            placeholder={i18n('select') + '...'}
            onChange={e => filter.update('attestant', e.value)}
            style={{width: '180px'}}/>
    </div>)

    const menuItems = []
    if (context.currentCompany.usingFortnox) {
        menuItems.push(
            {
                label: 'Hämta leverantörsfakturor från Fortnox',
                faicon: Icon.INTEGRATION,
                command: async () => {
                    showLoading()
                    if (await SupplierinvoiceService.fetchFromFortnox()) {
                        await preload.load(PreloadType.SUPPLIER_LIST)
                        await preload.load(PreloadType.ACCOUNT_LIST)
                        await loadData()
                        infoAlert(i18n('done'))
                    }
                    hideLoading()
                }
            })
    }
    if (roles.hasRole(Role.SUPERADMIN)) {
        menuItems.push(
            {
                label: 'Hämta leverantörsfakturor från mejlinboxen',
                faicon: Icon.INTEGRATION,
                command: async () => {
                    showLoading()
                    if (await SupplierinvoiceService.fetchFromMailInbox()) {
                        await loadData()
                        infoAlert(i18n('done'))
                    }
                    hideLoading()
                }
            })
    }

    return <Grid
        id='SupplierinvoiceGrid'
        labelI18n='supplier_invoices'
        icon={Icon.SUPPLIER_INVOICE}
        gridLabelRightContent={gridLabelRightContent}
        leftFilters={leftFilters}
        filter={filter}
        updateFilter={filter.update}
        columns={columns}
        value={filteredList}
        paginator={true}
        menuItems={menuItems}
    />
}

export default SupplierinvoiceGrid;
