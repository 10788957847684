import {matchPath} from "react-router-dom";

/**
 * Path example: '/customers/:id'
 */
const getIdFromPath = (location, path) => matchPath({
    path: path,
    exact: true,
    strict: true
}, location.pathname).params.id

const getId = (location) => {
    const path = location.pathname
    const result = path.substring(path.lastIndexOf("/") + 1)
    const numResult = Number(result)

    // console.log("resultresult", result, typeof result, numResult)
    if (Number.isNaN(numResult)) {
        return result;
    } else {
        return numResult
    }
}

function getQueryParam(name) {
    const queryString = window.location.search;

// Skapa en instans av URLSearchParams för att hantera query-parametrarna
    const urlParams = new URLSearchParams(queryString);

// Hämta specifika parametrar från URL:en
    return urlParams.get(name);
}


const reload = props => window.location = '/';

const RouterUtils = {
    getIdFromPath,
    getId,
    getQueryParam,

    goBack: navigate => {
        navigate(-1)
    },

    reload,

    openTab: url => {
        window.open(url, '_blank').focus();
    }
}
export default RouterUtils;
