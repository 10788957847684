import React, {useCallback, useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {MainButton} from "../../../components/Buttons/Button";
import DatePicker from "../../../components/Date/DatePicker";
import XlnzDropdown from "../../../components/XlnzDropdown/XlnzDropdown";
import Label from "../../../components/EditForm/Label";
import {useFilter} from "../../../components/Grid/filter";
import {FooterAggregate} from "../../../components/Grid/getFooterGroup";
import Grid from "../../../components/Grid/Grid";
import NumberGridInput from "../../../components/Grid/NumberGridInput";
import onRowChange from "../../../components/Grid/onRowChange";
import {hideLoading, showLoading} from "../../../components/Overlay/Loading";
import renderOverlay from "../../../components/Overlay/renderOverlay";
import Icon from "../../../enums/Icon";
import Role from "../../../enums/Role";
import {matchSearch} from "../../../functions/match";
import {GridTemplates} from "../../../GridTemplates";
import useDialog from "../../../hooks/useDialog";
import useMountEffect from "../../../hooks/useMountEffect";
import useRoles from "../../../hooks/useRoles";
import {i18n} from "../../../I18n/I18n";
import {arraySum} from "../../../utils/ArrayUtil";
import {getFirstDateOfMonth, isAfter, todayDate} from "../../../utils/DateUtils";
import NetUtils from "../../../utils/NetUtils";
import {round} from "../../../utils/NumberUtils";
import ProjectService from "../../Projects/ProjectService";
import ProjectStatus from "../../Projects/ProjectStatus";
import useProjectContextMenuItems from "../../Projects/useProjectContextMenuItems";
import ErcDialog from "./ErcDialog";

const PurchasePlan = () => {
    const filter = useFilter('PurchasingPlan', [
        {name: 'startDate', defaultValue: getFirstDateOfMonth()},
        {name: 'endDate', defaultValue: todayDate()},
        {name: 'project', defaultValue: undefined},
        {name: 'search', defaultValue: ''},
    ])

    const roles = useRoles()
    const navigate = useNavigate()
    const disabled = !roles.hasRole(Role.PROJECT_WRITE);

    const dialog = useDialog()
    const contextMenu = useProjectContextMenuItems({dialog: dialog})

    const [projectList, setProjectList] = useState([])
    const [list, setList] = useState([])
    const filteredList = list.filter(item => matchSearch(filter, item))


    function getErc(item, date) {
        return arraySum(item.estimatedRemainingCostAdjustmentList.filter(item => !isAfter(item.date, date))
            , 'value')
    }

    filteredList.forEach(item => {
        const percentageMarkupDecimal = (item.purchasePercentageMarkup / 100)
        item.purchasePriceMarkup = item.purchasePrice * percentageMarkupDecimal
        item.totalPurchaseCost = item.purchasePrice + item.purchasePriceMarkup

        item.accumulated = round(item.invoicedEndDate / (1 + percentageMarkupDecimal))
        item.invoicedPriceMarkup = round(item.invoicedEndDate - item.accumulated)


        item.estimatedRemainingCostAdjustment = getErc(item, filter.endDate)

        item.finalCostStartDate = item.invoicedStartDate + getErc(item, filter.startDate)
        item.finalCostEndDate = item.invoicedEndDate + item.estimatedRemainingCostAdjustment
        item.finalCostDiff = item.finalCostEndDate - item.finalCostStartDate
    })

    useEffect(() => {
        showLoading()
        const params = {
            status: ProjectStatus.ONGOING.id,
            startDate: filter.startDate,
            endDate: filter.endDate,
            search: ''
        }
        ProjectService.findAll(params).then(_projectList => {
            setProjectList(_projectList.filter(item => !item.parent));
            hideLoading()
        });
    }, [filter.startDate, filter.endDate])


    const loadData = useCallback(() => {
        showLoading()
        NetUtils.doGet('/api/reports/purchase-plan', {
            startDate: filter.startDate,
            endDate: filter.endDate,
            project: filter.project,
        }).then(res => {
            setList(res)
            hideLoading()
        });
    }, [filter.startDate, filter.endDate, filter.project])

    useMountEffect(() => {
        if (filter.startDate && filter.endDate && filter.project) {
            loadData()
        }
    })

    const fireChange = (_rowList, editorEvent, value) => {
        if (editorEvent.newValue !== editorEvent.oldValue) {
            ProjectService.updateValue(editorEvent.rowData.id, editorEvent.field, value)
            setList(_rowList)
        }
    }

    const uberColumns = [
        {
            // header: i18n('costplus_work').toUpperCase(),
            header: 'KALKYL',
            style: {backgroundColor: 'rgba(184, 207, 105, 0.4)'},
            startColumnField: 'budgetPrice',
            colSpan: 5
        },
        {
            header: 'UTFALL',
            style: {backgroundColor: 'rgba(184, 207, 105, 0.2)'},
            startColumnField: 'accumulated',
            colSpan: 7
        },
    ]

    const columns = [
        {field: 'label', headerI18n: 'project'},
        {
            field: 'budgetPrice', headerI18n: 'budget_price',
            hasUberHeader: true,
            body: GridTemplates.currency,
            editor: e => <NumberGridInput editorEvent={e}/>,
            onCellEditComplete: e => onRowChange(e, e.newValue, fireChange),
            footer: FooterAggregate.SUM_CURRENCY,
            align: 'right', width: 120
        },
        {
            field: 'purchasePrice', headerI18n: 'purchase_price',
            hasUberHeader: true,
            body: GridTemplates.currency,
            editor: e => <NumberGridInput editorEvent={e}/>,
            onCellEditComplete: e => onRowChange(e, e.newValue, fireChange),
            footer: FooterAggregate.SUM_CURRENCY,
            align: 'right',
            width: 120
        },
        {
            field: 'purchasePercentageMarkup', header: i18n('markup') + ' %',
            hasUberHeader: true,
            body: GridTemplates.percentTimes100,
            editor: e => <NumberGridInput editorEvent={e}/>,
            onCellEditComplete: e => onRowChange(e, e.newValue, fireChange),
            align: 'right',
            width: 70
        },
        {
            field: 'purchasePriceMarkup', header: i18n('markup'),
            hasUberHeader: true,
            body: GridTemplates.currency,
            footer: FooterAggregate.SUM_CURRENCY,
            align: 'right',
            width: 120
        },
        {
            field: 'totalPurchaseCost', headerI18n: 'to_invoice',
            hasUberHeader: true,
            body: GridTemplates.currency,
            footer: FooterAggregate.SUM_CURRENCY,
            align: 'right',
            width: 120
        },


        {
            field: 'accumulated', headerI18n: 'accrued',
            hasUberHeader: true,
            body: GridTemplates.currency,
            footer: FooterAggregate.SUM_CURRENCY,
            align: 'right',
            width: 130
        },
        {
            field: 'invoicedPriceMarkup', header: i18n('markup'),
            hasUberHeader: true,
            body: GridTemplates.currency,
            footer: FooterAggregate.SUM_CURRENCY,
            align: 'right',
            width: 120
        },
        {
            field: 'invoicedEndDate', header: i18n('invoiced'),
            hasUberHeader: true,
            body: GridTemplates.currency,
            footer: FooterAggregate.SUM_CURRENCY,
            align: 'right',
            width: 120
        },
        {
            // field: 'invoiced', header: i18n('estimated_remaining_cost_abbr'),
            field: 'estimatedRemainingCostAdjustment', header: 'BÅK +/-',
            hasUberHeader: true,
            body: (rowData, column) => {
                return <div style={{height: '100%'}}
                            onClick={e => {
                                e.stopPropagation()
                                renderOverlay(<ErcDialog
                                    rowData={rowData}
                                    onChange={_list => {
                                        rowData.estimatedRemainingCostAdjustmentList = _list
                                        setList([...list])
                                    }}
                                />)
                            }}>
                    {GridTemplates.currency(rowData, column)}
                </div>
            },
            excelBody: rowData => rowData.estimatedRemainingCostAdjustment,
            footer: FooterAggregate.SUM_CURRENCY,
            align: 'right',
            width: 120
        },

        {
            field: 'finalCostStartDate', header: i18n('cost') + ' ' + filter.startDate,
            hasUberHeader: true,
            body: GridTemplates.currency,
            footer: FooterAggregate.SUM_CURRENCY,
            align: 'right',
            width: 130
        },
        {
            field: 'finalCostEndDate', header: i18n('cost') + ' ' + filter.endDate,
            hasUberHeader: true,
            body: GridTemplates.currency,
            footer: FooterAggregate.SUM_CURRENCY,
            align: 'right',
            width: 130
        },
        {
            field: 'finalCostDiff', header: 'Förändring',
            hasUberHeader: true,
            body: GridTemplates.currency,
            footer: FooterAggregate.SUM_CURRENCY,
            align: 'right',
            width: 130
        }
    ]

    const leftFilters = [
        <div>
            <Label valueI18n='start_date'/>
            <DatePicker value={filter.startDate} onChange={date => filter.update('startDate', date)}/>
        </div>,
        <div>
            <Label valueI18n='end_date'/>
            <DatePicker value={filter.endDate} onChange={date => filter.update('endDate', date)}/>
        </div>,
        <div>
            <Label valueI18n='project'/>
            <XlnzDropdown
                value={filter.project}
                options={projectList}
                optionLabel='label'
                showClear={true}
                filter={true}
                placeholder={i18n('select') + '...'}
                optionValue='id'
                onChange={e => filter.update('project', e.value)}
                style={{width: '400px'}}/>
        </div>,
        <div>
            <Label/>
            <MainButton labelI18n='create_report' onClick={() => loadData()} disabled={!filter.project}/>
        </div>
    ]

    const menuItems = [{
        label: i18n('export_to_pdf'),
        faicon: Icon.PDF,
        command: async () => {
            showLoading()
            NetUtils.downloadFile('/api/reports/purchase-plan/pdf', {
                startDate: filter.startDate,
                endDate: filter.endDate,
                project: filter.project,
            }).then(res => {
                hideLoading()
            });
        }
    }]

    return <div className="Grid">
        <Grid
            id='PurchasePlan'
            disabled={disabled}
            forceDesktop={true}
            labelI18n='purchase_plan'
            icon={Icon.REPORT}
            leftFilters={leftFilters}
            // onExportToExcel={onExportToExcel}
            filter={filter}
            updateFilter={filter.update}
            uberColumns={uberColumns}
            columns={columns}
            value={filteredList}
            onRowSelect={item => navigate('/projects/' + item.data.id)}
            contextMenu={contextMenu}
            menuItems={menuItems}
        />
        {dialog.render()}
    </div>
}

export default PurchasePlan;