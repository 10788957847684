import React from 'react';
import Grid from "../../../components/Grid/Grid";
import NumberGridInput from "../../../components/Grid/NumberGridInput";
import onRowChange from "../../../components/Grid/onRowChange";
import {GridTemplates} from "../../../GridTemplates";
import {i18n} from "../../../I18n/I18n";
import ArrayUtil from "../../../utils/ArrayUtil";
import {deepCopy} from "../../../utils/ObjectUtils";

const ProjecttimeregdebittypepriceGrid = props => {
    const {
        timeregdebittypeList,
        value
    } = props

    const _timeregdebittypeList = deepCopy(timeregdebittypeList)

    _timeregdebittypeList.forEach(item => {
        const foo = ArrayUtil.findByField(value, 'timeregdebittype', item.id)
        item.price = foo?.price || 0
    })

    const fireChange = (list) => {
        const newValue = list.map(item => ({
            timeregdebittype: item.id,
            price: item.price
        }))
        props.onChange(newValue);
    }

    const columns = [
        {field: 'name', header: i18n('debit_type'),},
        {
            field: 'price', header: i18n('price'),
            body: GridTemplates.currencyTwoDecimals,
            editor: e => <NumberGridInput editorEvent={e}/>,
            onCellEditComplete: e => onRowChange(e, e.newValue, fireChange),
            width: 100,
            align: 'right'
        },
    ]

    return <div>
        <Grid forceDesktop={true}
              columns={columns}
              value={_timeregdebittypeList}
              scrollable={false}
        />
    </div>
}
export default ProjecttimeregdebittypepriceGrid;
