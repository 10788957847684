import {faXmark} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {isFreeRow} from "./freeRows";

const RemoveColumn = (onRowRemove, numOfFreeRows = 0, isVisible) => ({
    field: 'remove',
    body: (rowData, column) => {
        if (rowData.readonly) return

        return (numOfFreeRows > 0 && isFreeRow(column.props.value, rowData))
        || (isVisible && !isVisible(rowData))
            ? ''
            : <FontAwesomeIcon
                icon={faXmark}
                style={{cursor: 'pointer', color: '#777', fontSize: '13px'}}
                onClick={e2 => {
                    e2.stopPropagation()
                    e2.preventDefault()
                    onRowRemove(rowData)
                }}
            />
    },
    style: {maxWidth: '26px', width: '26px', textAlign: 'center'},
    align: 'center',
    width: 26,
})

export default RemoveColumn