import React from 'react';
import {CancelButton} from "../../../components/Buttons/Button";
import Grid from "../../../components/Grid/Grid";
import XlnzDialog from "../../../components/XlnzDialog/XlnzDialog";
import getEmailStatusName from "../../../functions/getEmailStatusName";
import {GridTemplates} from "../../../GridTemplates";

const ProjectdocumentEmailLogDialog = props => {
    const {
        onHide,
        project
    } = props

    const list = project.documentsentlogList

    list.forEach(item => {
        if (item.documentNames.length > 0) {
            const arr = item.documentNames.split(";")
            arr.sort()
            item.documentNames = arr.join('\n')
        }
    })

    const columns = [
        {field: 'ctime', headerI18n: 'sent', body: GridTemplates.time, width: 140},
        {field: 'toEmail', headerI18n: 'recipient', width: 300},
        {
            field: 'status',
            headerI18n: 'email_status',
            body: rowData => getEmailStatusName(rowData.status),
            width: 100
        },
        {field: 'documentNames', headerI18n: 'document', width: 400, body: GridTemplates.multilineString},
    ]

    function getButtons() {
        return <CancelButton onClick={e => props.onHide()}/>
    }

    return <XlnzDialog headerI18n='email_log' buttons={getButtons} onHide={onHide}>
        <div className="EditForm">
            <Grid
                columns={columns}
                value={list}
                heightOffset={400}
            />
        </div>
    </XlnzDialog>
}

export default ProjectdocumentEmailLogDialog;
