import React from 'react';
import {CancelButton} from "../../../components/Buttons/Button";
import Grid from "../../../components/Grid/Grid";
import XlnzDialog from "../../../components/XlnzDialog/XlnzDialog";
import InvoiceSendMethod from "../../../enums/InvoiceSendMethod";
import getEmailStatusName from "../../../functions/getEmailStatusName";
import {GridTemplates} from "../../../GridTemplates";

const InvoiceEmailLogDialog = props => {
    const {
        onHide,
        tender
    } = props

    const list = tender.sentlogList
    list.forEach(item => {
        item.typeStr = InvoiceSendMethod.findById(item.type)?.name || 'ERROR'
    })

    const columns = [
        {field: 'ctime', headerI18n: 'sent', body: GridTemplates.time, width: 140},
        {field: 'typeStr', headerI18n: 'type', width: 140},
        {field: 'toEmail', headerI18n: 'email', width: 300},
        {
            field: 'status',
            headerI18n: 'email_status',
            body: rowData => getEmailStatusName(rowData.status),
            width: 100
        },
    ]

    function getButtons() {
        return <CancelButton onClick={e => props.onHide()}/>
    }

    return <XlnzDialog headerI18n='sent_log' buttons={getButtons} onHide={onHide}>
        <div className="EditForm">
            <Grid
                columns={columns}
                value={list}
                heightOffset={400}
            />
        </div>
    </XlnzDialog>
}

export default InvoiceEmailLogDialog;
