import React from 'react';
import {i18n} from "../../I18n/I18n";
import AutoComplete from "../AutoComplete/AutoComplete";

const SelectEditor = props => {
    const {
        event,
        options,
        optionLabel = 'name',
        optionValue,
        onChange,
        hideNoneOption,
        filterBy,
        itemTemplate
    } = props
    if (!event) throw new Error(props)

    let _options
    if (hideNoneOption) {
        _options = options
    } else {
        const noneOption = {id: 'NONE'}
        noneOption[optionLabel] = '-- ' + i18n('none') + ' --'
        _options = [noneOption].concat(options)
    }

    return <AutoComplete
        options={_options}
        optionLabel={optionLabel}
        optionValue={optionValue}
        filterBy={filterBy}
        itemTemplate={itemTemplate}
        onChange={e => {
            onChange(e)
        }}
    />
}

export default SelectEditor;


export const SubInfoItemRenderer = ({name, subInfo}) => {
    return <div>
        <div>{name}</div>
        <div style={{opacity: '0.7'}}>{subInfo}</div>
    </div>
}
