import Grid from "@components/Grid/Grid";
import React from 'react';
import {FooterAggregate} from "../../components/Grid/getFooterGroup";
import StringUtils from "../../utils/StringUtil";

const EmployeeActivityGrid = ({value}) => {

    const columns = [
        {field: 'employee.id', header: 'ID', width: 80},
        {field: 'employee.name', header: 'Employee', width: 150},
        {field: 'employee.email', header: 'E-mail', width: 250},
        {
            field: 'activeDuration', header: 'ActiveDuration',
            body: row => {
                return StringUtils.formatDuration(row.activeDuration)
            },
            width: 100,
            footer: FooterAggregate.SUM_DURATION
        },
        {field: 'version', header: 'Version', width: 110},
        {field: 'companyLabel', header: 'Company'}
    ]

    return <div>
        <Grid
            scrollable={false}
            columns={columns}
            value={value}
            onRowSelect={() => {
            }}
            forceDesktop={true}
        />
    </div>
}
export default EmployeeActivityGrid;