import React from "react";
import {useNavigate} from "react-router-dom";
import ContextMenuItem from "../../components/ContextMenu/ContextMenuItem";
import Icon from "../../enums/Icon";
import Role from "../../enums/Role";
import useRoles from "../../hooks/useRoles";
import ProjectSummaryDialog from "./ProjectSummary/ProjectSummaryDialog";

export default function useProjectContextMenuItems({date, dialog}) {
    const roles = useRoles()
    const navigate = useNavigate()

    return [
        ContextMenuItem({
            i18n: 'project_summary', icon: Icon.LOG,
            command: item => dialog.show(<ProjectSummaryDialog projectId={item.id}/>)
        }),
        ContextMenuItem({
            i18n: 'edit_tender', icon: Icon.TENDER,
            command: item => navigate('/tenders/' + item.tender),
            hidden: row => !roles.hasRole(Role.TENDER_READ) || !row?.tender || row.changeOrder,
        }),
        ContextMenuItem({
            i18n: 'do_invoice', icon: Icon.INVOICE,
            command: item => {
                navigate('/invoices/0' +
                    '?project=' + (item.changeOrder ? item.parent : item.id) +
                    (date ? '&invoiceDate=' + date : '')
                )
            },
            hidden: row => !roles.hasRole(Role.INVOICE_WRITE)
        }),
        ContextMenuItem({
            i18n: 'add_subproject', icon: Icon.ADD,
            command: item => navigate('/projects/0?parent=' + item.id),
            hidden: row => row.changeOrder
        }),
        ContextMenuItem({
            i18n: 'add_change_order', icon: Icon.ADD,
            command: item => navigate('/projects/0?parent=' + item.id + '&changeOrder=1'),
            hidden: row => !roles.hasRole(Role.TENDER_WRITE) || row.changeOrder
        }),
    ]
}