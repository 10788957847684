import {faChevronDown, faXmark} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useEffect, useRef, useState} from 'react';
import './XlnzMultiSelect.scss';
import Checkbox from "../EditForm/Checkbox"; // Importera SCSS-fil för styling

export default function XlnzMultiSelect({
                                            value = [], // Listan med valda ID:n
                                            onChange,
                                            options,
                                            optionValue,
                                            optionLabel,
                                            display = 'chip',
                                            disabled = false,
                                            style = {width: '100%'},
                                        }) {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    // Stäng dropdown vid klick utanför
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleDropdown = () => {
        if (!disabled) setIsOpen(!isOpen);
    };

    const handleOptionClick = (option) => {
        const selectedId = option[optionValue]; // Få ID:t från alternativet
        if (value.includes(selectedId)) {
            // Om ID:t redan är valt, avmarkera det
            const newValues = value.filter((id) => id !== selectedId);
            onChange(newValues); // Returnera enbart ID:n
        } else {
            // Lägg till ID:t till valen
            const newValues = [...value, selectedId];
            onChange(newValues); // Returnera enbart ID:n
        }
    };

    const handleRemoveChip = (id) => {
        const newValues = value.filter((selectedId) => selectedId !== id);
        onChange(newValues); // Returnera enbart ID:n
    };

    return (
        <div className="xlnz-multi-select-container" style={style} ref={dropdownRef}>
            <div
                className={`xlnz-multi-select ${disabled ? 'disabled' : ''}`}
                onClick={toggleDropdown}
            >
                {value.length === 0 ? (
                    <span className="placeholder">Välj...</span>
                ) : (
                    <div className="no-text-selection selected-options">
                        {display === 'chip' ? (
                            value.map((id) => {
                                const option = options.find((option) => option[optionValue] === id);
                                if (!option) return null;
                                return (
                                    <div key={id} className="chip">
                                        {option[optionLabel]}

                                        <span
                                            className="remove-chip"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleRemoveChip(id);
                                            }}>
                      <FontAwesomeIcon icon={faXmark} style={{color: "#FFF"}}/>
                    </span>
                                    </div>
                                );
                            })
                        ) : (
                            <span>{value.map((id) => options.find((option) => option[optionValue] === id)?.[optionLabel]).join(', ')}</span>
                        )}
                    </div>
                )}
                <div className="arrow">
                    <FontAwesomeIcon icon={faChevronDown} style={{color: "#999"}}/>
                </div>
            </div>
            {isOpen && (
                <div className="dropdown">
                    {options.map((option) => {
                        const selected = value.includes(option[optionValue]);
                        return (
                            <div
                                key={option[optionValue]}
                                className={`no-text-selection dropdown-option ${selected ? 'selected' : ''}`}
                                onClick={() => handleOptionClick(option)}
                            >
                                <Checkbox checked={selected}/>
                                {option[optionLabel]}
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
}
